import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import SideBar from './SideBar'
import { BiLogOut } from 'react-icons/bi'
import { FaBell } from 'react-icons/fa'
import './style.scss'
import Header from '../../components/Header'
import DashCard from '../Dashboard/DashCard'

const Layout = ({children}) => {
    return (
        <Container fluid style={{ paddingLeft: 0 }}>
            <Row style={{ marginRight: 20 }}>
                <Col xs={2} sm={2} md={4} lg={3} xl={2} style={{ backgroundColor: '#fff' }}>
                    <SideBar /> 
                </Col>
                <Col xs={10} sm={10} md={8} lg={9} xl={10}  className="bodyStyle">
                    <div style={{ marginLeft: 40, paddingTop: 30 }}>
                        {children}
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Layout
