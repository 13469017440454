import React, { useState, useEffect } from 'react'
import { Row, Col,InputGroup, Form } from 'react-bootstrap'
import Modals from '../../components/Modals'
import {MdEdit,MdUpdate,MdFoodBank} from 'react-icons/md'
import Buttons from '../../components/Buttons'
import {GiShop} from 'react-icons/gi'
import {AiTwotoneShop} from 'react-icons/ai'
import { useSelector, useDispatch } from 'react-redux'
import { updateCoupon } from '../../Redux/actions/couponActions'
import { toast } from 'react-toastify'
import {Formik, Field} from 'formik';
import InputField from '../../components/InputField'
import FormSelectField from '../../components/form-select-field'
import "yup-phone";
import { RESET_ERROR } from '../../Redux/constants/couponConstants'

const EditCoupon = ({openModal, closeModal, headerColor, headerTitle, coupons}) => {

    const { updateSuccess,error } = useSelector(state => state.coupon)
    const dispatch = useDispatch();
    const [initialDatas, setInitialDatas] = useState(null)
    

    useEffect(() => {
        if(error){
            toast.error(error);
            dispatch({
                type: RESET_ERROR
            })
        }

        if(updateSuccess){
                closeModal(true)
                dispatch({
                    type: RESET_ERROR
                })
                toast.success("Coupon updated Successfully");
            }
        
       
    }, [error,updateSuccess])
    
    

    useEffect(() => {
        async function getDetails() {

            if(coupons){
                const details = {
                    id:coupons._id,
                    title: coupons.title,
                    description: coupons.description,
                    code: coupons.code,
                    limitation: coupons.limitation,
                    exp_date: coupons.exp_date,
                    stores: coupons.stores,
                    category: coupons.category,
                    product: coupons.product,
                  
                }
    
                
                console.log({details})
                setInitialDatas(details)
            }
        }

        getDetails();
    }, [coupons])

    const editCoupon =  (values) =>  {
        
        console.log({values})

        const { id,title, description,code,limitation,exp_date,stores,category,product } = values
       
        dispatch(updateCoupon(id,title, description,code,limitation,exp_date,stores,category,product));
       
    }


    return (
        <Modals showModal={openModal} hideModal={closeModal} headerColor={headerColor} modalTitle={headerTitle} >
            {initialDatas && 
            <Formik 
                initialValues={initialDatas}
                onSubmit={(values) => {
                    console.log(values)
                    editCoupon(values)
                }}
                render={({handleChange, handleSubmit, handleBlur, values, errors}) => (
                    <Form>
                        <Row className="mb-3">
            <InputField fieldName="title" fieldLabel="Coupon Title" md="4" />
            <InputField fieldName="description" fieldLabel="Coupon Description" md="8" />
            <InputField fieldName="code" fieldLabel="Coupon Code" md="4" />
            <InputField fieldName="limitation" fieldLabel="Coupon Limitation" md="4" />
            <InputField fieldName="exp_date" fieldLabel="Expiry Date" md="4" />
                
            <span class="applicable">APPLICABLE FIELDS</span>   
                
                <div className="inputareaCoupon">
                    <InputGroup className="mb-2">
                        <InputField fieldName="stores" fieldLabel="Store" md="12" />
                        <GiShop class="shop" fontSize="2.5em" style={{ position: 'absolute', top: -30, right: -20, cursor: 'pointer' }} />
                    </InputGroup>
                </div>

                <div className="inputareaCoupon">
                    <InputGroup className="mb-2">
                        <InputField fieldName="category" fieldLabel="Category" md="12" />
                        <AiTwotoneShop class="shop" fontSize="2.5em" style={{ position: 'absolute', top: -30, right: -20, cursor: 'pointer' }} />
                    </InputGroup>
                </div>

                <div className="inputareaCoupon">        
                    <InputGroup className="mb-2">
                        <InputField fieldName="product" fieldLabel="Product" md="12" />
                        <MdFoodBank class="shop" fontSize="2.5em" style={{ position: 'absolute', top: -30, right: -20, cursor: 'pointer' }} />
                    </InputGroup>
                </div>
                
                
            </Row>
                        {headerTitle === 'EDIT COUPON' && <Row>
                <center>
                    <Buttons backColor="#FF9900" buttonText="UPDATE" buttonAction={handleSubmit}>
                            <MdUpdate style={{color:'#FFFFFF'}} fontSize="2.3em" />
                    </Buttons>
                </center>
            </Row>}
                    </Form>
                )}
            />}
        </Modals>
    )
}

export default EditCoupon
