export const SLIDER_LIST_SUCCESS = 'SLIDER_LIST_SUCCESS';
export const SLIDER_LIST_FAIL = 'SLIDER_LIST_FAIL';
export const RESET_SLIDER = 'RESET_SLIDER';
export const NEW_SLIDER_SUCCESS = 'NEW_SLIDER_SUCCESS';
export const NEW_SLIDER_FAIL = 'NEW_SLIDER_FAIL';
export const DELETE_SLIDER_SUCCESS = 'DELETE_SLIDER_SUCCESS';
export const DELETE_SLIDER_FAIL = 'DELETE_SLIDER_FAIL';
export const UPDATE_SLIDER_SUCCESS = 'UPDATE_SLIDER_SUCCESS';
export const UPDATE_SLIDER_FAIL = 'UPDATE_SLIDER_FAIL';

//Slider Settings

export const COST_LIST_SUCCESS = 'COST_LIST_SUCCESS';
export const COST_LIST_FAIL = 'COST_LIST_FAIL';
export const NEW_COST_SUCCESS = 'NEW_COST_SUCCESS';
export const NEW_COST_FAIL = 'NEW_COST_FAIL';
export const DELETE_COST_SUCCESS = 'DELETE_COST_SUCCESS';
export const DELETE_COST_FAIL = 'DELETE_COST_FAIL';
export const UPDATE_COST_SUCCESS = 'UPDATE_COST_SUCCESS';
export const UPDATE_COST_FAIL = 'UPDATE_COST_FAIL';