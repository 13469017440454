export const NOTIFICATION_LIST_SUCCESS = 'NOTIFICATION_LIST_SUCCESS';
export const NOTIFICATION_LIST_FAIL = 'NOTIFICATION_LIST_FAIL';
export const RESET_NOTIFICATION = 'RESET_NOTIFICATION';
export const NEW_NOTIFICATION_SUCCESS = 'NEW_NOTIFICATION_SUCCESS';
export const NEW_NOTIFICATION_FAIL = 'NEW_NOTIFICATION_FAIL';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_FAIL = 'DELETE_NOTIFICATION_FAIL';
export const NOTIFICATION_UPDATE_FAIL = 'NOTIFICATION_UPDATE_FAIL';
export const NOTIFICATION_UPDATE_SUCCESS = 'NOTIFICATION_UPDATE_SUCCESS';

