import { Form, Formik } from 'formik'
import React, { useState, useEffect } from 'react'
import { Row ,Col} from 'react-bootstrap'
import ImageInput from '../../components/ImageInput'
import InputField from '../../components/InputField'
import * as Yup from 'yup';
import SelectInput from '../../components/SelectInput'
import Buttons from '../../components/Buttons'
import { IoMdAdd } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { getCategoryLists } from '../../Redux/actions/categoryActions'
import { saveStoresType } from '../../Redux/actions/storeActions'
import { toast } from 'react-toastify'

const NewStoreType = () => {

    const [image, setImage] = useState('')
    const { categoryLists } = useSelector(state => state.category)

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCategoryLists())
    }, [])


    const saveStoreType = async(values) => {
        if(image){
            console.log(values.category)
            let catLists = await categoryLists.filter(cat => values.category.includes(cat._id));
            console.log(catLists)
            const formData = new FormData();
            formData.append("name", values.name);
            formData.append("requested_by", values.requested_by);
            formData.append("category", JSON.stringify(catLists));
            formData.append("image", image)
            dispatch(saveStoresType(formData))
        }
        else{
            toast.error("Image Required")
        }
    }

    return (
        <Formik 
            initialValues={{
                name: '',
                requested_by:'',
                category: [],
            }}
            validationSchema={
                Yup.object({
                    name: Yup.string().required('Name Required'),
                    requested_by: Yup.string().required('Requested By is Required'),
                    category: Yup.array().required('Category Required')
                })
            }
            onSubmit={(values) => {
                saveStoreType(values)
            }}
            render={({handleChange, handleSubmit, handleBlur, values, errors}) => (
                <Form>
                    <Row>
                    <Col md="12" lg="6" xl="6" >
                        <InputField fieldName="name" fieldLabel="Title" md="12" /> 
                        <SelectInput fieldName="category" fieldLabel="Category" md={12} isMultiple={true}>
                            {categoryLists && categoryLists.map((cat, index) => (
                                <option key={index} value={cat._id}>{cat.name}</option>
                            ))}
                        </SelectInput>
                    </Col>
                    <Col md="12" lg="6" xl="6">
                    <InputField fieldName="requested_by" fieldLabel="Requested By" md="12" />
                        <ImageInput fieldName={"image"} fieldLabel="Store Type Image" image={image} md={12} imageHelperText={"512 * 512"} changeImage={(e)=> setImage(e.target.files[0]) } />
                    </Col>
                    </Row>
                    <Row>
                        <center>
                            <Buttons backColor="#D42F45" buttonText="REQUEST" buttonAction={handleSubmit}>
                                <IoMdAdd style={{color:'#FFFFFF'}} fontSize="2.3em" />
                            </Buttons>
                        </center>
                    </Row>
                </Form>
            )}
        />
    )
}

export default NewStoreType
