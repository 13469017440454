import { 
    LOADING 
} from "../constants/userConstants"
import axios from '../../CustomAxios'
import { 
    NOTIFICATION_LIST_SUCCESS,
    NOTIFICATION_LIST_FAIL,
    RESET_NOTIFICATION,
    NEW_NOTIFICATION_SUCCESS,
    NEW_NOTIFICATION_FAIL,
    DELETE_NOTIFICATION_SUCCESS,
    DELETE_NOTIFICATION_FAIL,
    NOTIFICATION_UPDATE_SUCCESS,
    NOTIFICATION_UPDATE_FAIL

} from "../constants/notificationConstants";
import reactotron from "../../ReactotronConfig";

//Save Notification
export const saveNewNotification = (form_data) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/create-notification`, form_data,{
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
    
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })

        
        dispatch({
            type: NEW_NOTIFICATION_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_NOTIFICATION_FAIL,
            payload: error
        })
    });
}


//Get Notification
export const getNotification = (type) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`admin/get-notification`, { params: {
        value: type
    } })
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })


        dispatch({
            type: NOTIFICATION_LIST_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NOTIFICATION_LIST_FAIL,
            payload: error
        })
    });
}



//DELETE single Notification 
export const deleteNotificationById = (id) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.delete(`admin/delete-notification/${id}`)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: DELETE_NOTIFICATION_SUCCESS
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: DELETE_NOTIFICATION_FAIL,
            payload: error
        })
    });
}

//Edit Notification
export const updateNotification = (formData) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.post(`admin/edit-notification`, formData,{
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: NOTIFICATION_UPDATE_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NOTIFICATION_UPDATE_FAIL,
            payload: error
        })
    });
}
