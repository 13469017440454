import React from 'react'
import Header from '../../components/Header'
import Layout from '../layout'
import StoreTypeList from './StoreTypeList'

const StoreType = () => {
    return (
        <Layout>
            <Header headerText="STORE TYPE" />
            <StoreTypeList />
        </Layout>
    )
}

export default StoreType
