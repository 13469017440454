import moment from 'moment'
import React, { useEffect, useState, Fragment } from 'react'
import { Card, Col } from 'react-bootstrap'
import { AiFillDelete } from 'react-icons/ai'
import { BsCheck2 } from 'react-icons/bs'
import { FaTimes } from 'react-icons/fa'
import { IoMdAddCircle } from 'react-icons/io'
import { MdEdit } from 'react-icons/md'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useRouteMatch } from 'react-router-dom'
import { toast } from 'react-toastify'
import Buttons from '../../components/Buttons'
import Modals from '../../components/Modals'
import ModalMedium from '../../components/ModalMedium'
import Paging from '../../components/Paging'
import { deleteUnitById, getUnits } from '../../Redux/actions/unitActions'
import { RESET_UNIT } from '../../Redux/constants/unitConstants'
import EditUnit from './EditUnit'
import NewUnit from './NewUnit'


const UnitList = () => {

    const dispatch = useDispatch()
    const match = useRouteMatch()
    const { unitLists, error, deleteUnit, unitSuccess, updateSuccess } = useSelector(state => state.unit)

    const [currentPage, setCurrentPage] = useState(1)
    const [datas, setDatas] = useState([])
    const [modalShow, setModalShow] = useState(false);
    const [modalShowEdit, setModalShowEdit] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [headerColor, setHeaderColor] = useState('')
    const [headerTitle, setHeaderTitle] = useState('')
    const [selectedUnit, setSelectedUnit] = useState('')

    useEffect(() => {
        if(error){
            toast.error(error);
            dispatch({
                type: RESET_UNIT
            })
        }

        if(deleteUnit){
            setDeleteModalShow(false)
            toast.success("Unit Deleted Successfully");
            dispatch({
                type: RESET_UNIT
            })
        }

        if(unitSuccess){
            setModalShow(false);
            dispatch({
                type: RESET_UNIT
            })
            toast.success("Unit created successfully");
        }

        if(updateSuccess){
            setModalShowEdit(false);
            dispatch({
                type: RESET_UNIT
            })
            toast.success("Unit Updated successfully");
        }

    }, [error, deleteUnit, unitSuccess, updateSuccess])

    useEffect(() => {
       dispatch(getUnits())
    }, [])

    useEffect(() => {
        if(unitLists && unitLists.length>0){
            const indexOfLastPost = currentPage * 10;
            const indexOfFirstPost = indexOfLastPost - 10;
            const currentPosts = (unitLists || []).slice(indexOfFirstPost, indexOfLastPost);
            setDatas(currentPosts)
        }
    }, [unitLists])

    const pageChanged = (page)=> {
        setCurrentPage(page)
        const indexOfLastPost = page * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const currentPosts = (unitLists || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }

    const deleteUnits = (id) => {
        setSelectedUnit(id);
        setHeaderColor('#D42F45');
        setHeaderTitle("DELETE UNIT");
        setDeleteModalShow(true);
    }

    const deleteUnitListById = () => {
        dispatch(deleteUnitById(selectedUnit))
    }

    const createNewUnit = () => {
        setHeaderColor('#D42F45');
        setHeaderTitle("NEW UNIT");
        setModalShow(true);
    }

    const editUnit = (unit) => {
        setSelectedUnit(unit);
        setHeaderColor('#FF9900');
        setHeaderTitle("EDIT UNIT");
        setModalShowEdit(true)
    }

    return (
        <Fragment>
            <Card style={{ borderRadius: 20, margin: 10 }}>
                <IoMdAddCircle className="add" fontSize="3.5em" 
                onClick={createNewUnit}
                style={{ position: 'absolute', top: -30, right: -20, cursor: 'pointer' }} />
                <Card.Body style={{ overflowX: 'auto' }}>
                    <table>
                        <thead className="tableHeader">
                            <tr>
                                <th>Unit Name</th>
                                <th>Unit Variables</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {datas && datas.map((unit, index) => (
                                <tr key={index}>
                                    <td className="tableContent">{unit.unit}</td>
                                    <td className="tableContent">{unit.varients.join()}</td>
                                    <td className="tableContent">
                                        <MdEdit color="orange" onClick={() => editUnit(unit)} />
                                        <AiFillDelete 
                                        className="add" 
                                        onClick={() => deleteUnits(unit._id)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Card.Body>
                <Col style={{ float: 'right', display:'flex', flexDirection: 'row-reverse', justifyContent: 'flex-start', alignItems: 'center', margin: 30  }}>
                <Card >
                    {unitLists && unitLists.length>10 && <Paging datas={unitLists} currentPage={currentPage} pageChange={pageChanged} />}
                </Card>
                </Col>
            </Card>
            <ModalMedium showModal={modalShow} hideModal={() => setModalShow(false)} modalTitle={headerTitle} headerColor={headerColor}>
                <NewUnit />
            </ModalMedium>
            <ModalMedium showModal={modalShowEdit} hideModal={() => setModalShowEdit(false)} modalTitle={headerTitle} headerColor={headerColor}>
                <EditUnit unit={selectedUnit} />
            </ModalMedium>
            <Modals showModal={deleteModalShow} hideModal={()=> setDeleteModalShow(false)} modalTitle={headerTitle} headerColor={headerColor}>
                <p className="deleteText">Are you sure you want to delete this unit?</p>
                <div style={{ display: 'flex', alignItems:'center', justifyContent:'center' }}>
                        <Buttons backColor="#4AAD47" buttonText="YES" buttonAction={deleteUnitListById}>
                            <BsCheck2 style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                        <Buttons backColor="#D42F45" buttonText="No" buttonAction={()=> console.log("Hello")}>
                            <FaTimes style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                </div>
            </Modals>
        </Fragment>
    )
}

export default UnitList
