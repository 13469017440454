import React, { Fragment, useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import {MdEdit} from 'react-icons/md'
import {AiFillDelete,AiFillEye} from 'react-icons/ai'
import {BsToggle2On,BsToggle2Off, BsCheck2} from 'react-icons/bs'
import {IoMdAddCircle,IoMdAdd}from 'react-icons/io'
import { FaTimes } from 'react-icons/fa'
import Pagination from 'react-paginating';
import Paging from '../../components/Paging'
import Modals from '../../components/Modals'
import Buttons from '../../components/Buttons'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { RESET_CHARGE } from '../../Redux/constants/franchiseeConstants'
import moment from 'moment'
import reactotron from '../../ReactotronConfig'
import { useRouteMatch, Link } from 'react-router-dom'
import { getChargeList,deleteChargeById} from '../../Redux/actions/franchiseeActions'
import NewDeliveryCharges from './NewDeliveryCharges'
import {Formik, Field} from 'formik';

import InputField from '../../components/InputField'
import IconInput from '../../components/IconInput'
import FormSelectField from '../../components/form-select-field'
import MultiInput from '../../components/MultiInput'
import * as Yup from 'yup';
import EditDeliveryCharges from './EditDeliveryCharges'

const DeliveryCharges = ({franchisee}) => {

    const { chargeList, error, deleteSuccess } = useSelector(state => state.deliverycharge)

    const dispatch = useDispatch();
    const match = useRouteMatch();
    const [modalShow, setModalShow] = useState(false)
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [modalShowEdit, setModalShowEdit] = useState(false);
    const [headerColor, setHeaderColor] = useState('')
    const [headerTitle, setHeaderTitle] = useState('')
    const [selectedCharge, setselectedCharge] = useState('')

    const [currentPage, setCurrentPage] = useState(1)
    const [datas, setDatas] = useState([])
    const [search, setSearch] = useState('')
    const [initialDatas, setInitialDatas] = useState(null)

    useEffect(() => {
        if(chargeList){
            const indexOfLastPost = currentPage * 10;
            const indexOfFirstPost = indexOfLastPost - 10;
            const currentPosts = (chargeList || []).slice(indexOfFirstPost, indexOfLastPost);
            setDatas(currentPosts)
        }
        
    }, [chargeList])

    const pageChanged = (page)=> {
        setCurrentPage(page)
        const indexOfLastPost = page * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const currentPosts = (chargeList || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }

    useEffect(() => {
        if(error){
            toast.error(error);
            dispatch({
                type: RESET_CHARGE
            })
        }

        
        if(deleteSuccess){
            
            setDeleteModalShow(false);
            dispatch({
                type: RESET_CHARGE
            })
            toast.success("Delivery Charge Deleted Successfully");
        }
    }, [error,deleteSuccess])



    useEffect(() => {
        let fid=franchisee._id
        console.log({fid})
        dispatch(getChargeList(fid))
    }, [])


    const newCharge = () => {
        setHeaderColor('#D42F45');
        setHeaderTitle("ADD DELIVERY CHARGES");
        setModalShow(true)
    }
    const editCharge = (deliverycharge) => {
        setselectedCharge(deliverycharge);
        setHeaderColor('#FF9900');
        setHeaderTitle("EDIT DELIVERY CHARGE");
        setModalShowEdit(true);
    }

    const deleteCharge = (id) => {
        setselectedCharge(id);
        setHeaderColor('#D42F45');
        setHeaderTitle("DELETE DELIVERY CHARGE");
        setDeleteModalShow(true);
    }
    const deleteChargeListById = () => {
        dispatch(deleteChargeById(selectedCharge))
    }
    useEffect(() => {
        async function getDetails() {

            if(franchisee){
                const details = {
                    franchisee:franchisee._id,
                    
                }
    
                console.log({details})
                setInitialDatas(details)
            }
        }

        getDetails();
    }, [franchisee])

    return (
        <Fragment>
            
            <Card style={{ borderRadius: 20, margin: 10 }}>
                <IoMdAddCircle className="add" fontSize="3.5em" 
                style={{ position: 'absolute', top: -30, right: -20, cursor: 'pointer' }} onClick={newCharge} />
                <Card.Body style={{ overflowX: 'auto' }}>
                    <table>
                        <thead className="tableHeader">
                            <tr>
                            <th>Within KM</th>
                            <th>Free Delivery Threshold</th>
                            <th>Delivery Charge</th>
                            <th>Weather Charge</th>
                            <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {datas && datas.map((deliverycharge, index) => (
                                <tr key={index}>
                                    <td className="tableContent">{deliverycharge.km}</td>
                                    <td className="tableContent">{deliverycharge.free_delivery_threshold}</td>
                                    <td className="tableContent">{deliverycharge.charge}</td>
                                    <td className="tableContent">{deliverycharge.weather_charge}</td>

                                    <td className="tableContent">
                                        
                                        <MdEdit color="orange" onClick={() => editCharge(deliverycharge)}/>
                                       
                                        <AiFillDelete className="add" onClick={() => deleteCharge(deliverycharge._id)} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Card.Body>
                <Col style={{ float: 'right', display:'flex', flexDirection: 'row-reverse', justifyContent: 'flex-start', alignItems: 'center', margin: 30  }}>
                <Card >
                    {chargeList && chargeList.length>10 && <Paging datas={chargeList} currentPage={currentPage} pageChange={pageChanged} />}
                </Card>
                </Col>
            </Card>
            {modalShow && <NewDeliveryCharges openModal={modalShow} closeModal={()=> setModalShow(false)} headerColor={headerColor} headerTitle={headerTitle} franchisee={franchisee}/>}  

            {modalShowEdit && <EditDeliveryCharges openModal={modalShowEdit} closeModal={()=> setModalShowEdit(false)} headerColor={headerColor} headerTitle={headerTitle} deliverychargelist={selectedCharge}  />}
           
            <Modals showModal={deleteModalShow} hideModal={()=> setDeleteModalShow(false)} modalTitle={headerTitle} headerColor={headerColor}>
                <p className="deleteText" >Are you sure you want to delete this Delivery charge?</p>
                <div style={{ display: 'flex', alignItems:'center', justifyContent:'center' }}>
                        <Buttons backColor="#4AAD47" buttonText="YES" buttonAction={deleteChargeListById}>
                            <BsCheck2 style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                        <Buttons backColor="#D42F45" buttonText="No" buttonAction={()=> setDeleteModalShow(false)}>
                            <FaTimes style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                </div>
            </Modals>
            
        </Fragment>
    )
}

export default DeliveryCharges
