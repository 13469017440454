import React, { useState, useEffect, useRef } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import Modals from '../../components/Modals'
import {GiBackwardTime}from 'react-icons/gi'
import Buttons from '../../components/Buttons'
import { useSelector, useDispatch } from 'react-redux'
import {Formik} from 'formik';
import InputField from '../../components/InputField'
import * as Yup from 'yup';
import "yup-phone";
import { getCategoryLists } from '../../Redux/actions/categoryActions'
import SelectInput from '../../components/SelectInput'
import SwitchInput from '../../components/SwitchInput'
import { getProductDetails, saveNewProduct, updateProduct } from '../../Redux/actions/productActions'
import TagInput from '../../components/TagInput'
import { toast } from 'react-toastify'
import { RESET_STORE } from '../../Redux/constants/storeConstants'
import SelectNew from '../../components/SelectNew'

const EditProduct = ({openModal, closeModal, headerColor, headerTitle, product}) => {

    console.log({product: product._id})


    const [units, setUnits] = useState([])
    const [category, setCategory] = useState(product.category)
    const [initialValues, setInitialValues] = useState('')


    const dispatch = useDispatch();

    const { categoryLists, error } = useSelector(state => state.category)
    const { productDetails } = useSelector(state => state.product)

    const [image, setImage] = useState(null);
    const [tags, setTags] = useState(product.tags ? product.tags : []);

    const proForm = useRef(null)



    useEffect(() => {
        dispatch(getCategoryLists())
    }, [])

    useEffect(() => {
        if(productDetails && categoryLists.length>0){
            let cat = null;
            if(productDetails?.category._id){
                cat = categoryLists.find(cat => cat._id === productDetails?.category._id)
                setUnits(cat.units)
            }
            else{
                let cate = JSON.parse(productDetails?.category);
                cat = categoryLists.find(cat => cat._id === cate._id)
                setUnits(cat.units)
            }
            
            let unit = null;
            if(productDetails.unit._id){
                let units = cat.units.find(un => un._id === product.unit._id)
                units.lable = productDetails?.unit.unit;
                units.value = productDetails?.unit.unit;
                unit = units;
            }
            else{
                let unitdecoded = JSON.parse(productDetails?.unit);
                let units = cat.units.find(un => un._id === unitdecoded._id)
                units.lable = productDetails?.unit.unit;
                units.value = productDetails?.unit.unit;
                unit = units;
            }
            let initialValues = {
                _id: productDetails?._id,
                name : productDetails?.name,
                category: cat,
                unit : unit,
                description: productDetails?.description,
                cost_price: productDetails?.cost_price,
                selling_price: productDetails?.selling_price,
                offer_price: productDetails?.offer_price,
                status: productDetails?.status.toLowerCase() === 'active' ? true : false
            }

            console.log({initialValues})
            setInitialValues(initialValues)
        }
        
    }, [productDetails, categoryLists])

    useEffect(() => {
        dispatch(getProductDetails(product._id))
    }, [product._id])

    useEffect(() => {
        if(error){
            toast.error(error)
            dispatch({
                type: RESET_STORE
            })
        }
    }, [error])

    useEffect(() => {
        categoryLists && categoryLists.map(cat => {
            if(cat.name === category){
                setUnits(cat.units)
            }
        })
    }, [category])

    const categoryChange = (value, setFieldValue) => {
        console.log(value)
        let units = value.units.map(unit => {
            return { ...unit, lable: unit.unit, value: unit.unit }
        })
        setUnits(units)
        setFieldValue('category', value)
    }



    const updateproduct = async (values) =>  {

        let cat = {
            _id: values.category._id,
            name: values.category.name
        }

        let unit = {
            _id : values.unit._id,
            unit: values.unit.unit,
            varients: values.unit.varients
        }


        var form_data = new FormData();
        form_data.append("id", values._id);

        form_data.append("name", values.name);
        form_data.append("category", JSON.stringify(cat));
        form_data.append("unit", JSON.stringify(unit));
        form_data.append("description", values.description);
        form_data.append("cost_price", values.cost_price);
        form_data.append("selling_price", values.selling_price);
        form_data.append("offer_price",values.offer_price);
        form_data.append("tags", JSON.stringify(tags));
        form_data.append("status", values.status === true ? 'Active' : 'Inactive');
        if(image){
            form_data.append("image", image);
        }
        
        form_data.append("admin_status", 'Approved');



        dispatch(updateProduct(form_data))
    }



    const handleChanges = React.useCallback((event) => {
        console.log(event.target.name, event.target.value);
        setCategory(event.target.value)
    
        proForm.current?.handleChange(event);
    }, []);

    const tagInputChange = (tags) => {
        setTags(tags)
    }


    return (
        <Modals showModal={openModal} hideModal={closeModal} headerColor={headerColor} modalTitle={headerTitle}>
            {initialValues && 
            <Formik 
                innerRef={proForm}
                initialValues={initialValues}
                validationSchema={
                    Yup.object({
                        name: Yup.string().required('Name Required'),
                        category: Yup.object().required('Category Name Required'),
                        unit: Yup.object().required('Unit name is Required'),
                        cost_price: Yup.string().required("Cost Price is Required"),
                        selling_price: Yup.string().required("Selling price is required"),
                    })
                }
                onSubmit={(values) => {
                    //console.log(values)
                    updateproduct(values)
                }}
                render={({handleChange, handleSubmit, handleBlur, setFieldTouched, setFieldValue, values, errors, touched}) => (
                    <Form>
                        <Row>
                            <Col md="8">
                                <Row>
                                    <InputField fieldName="name" fieldLabel="ProductName" md="6" />
                                    <SelectNew 
                                        md="6"
                                        fieldName="category"
                                        fieldLabel="Product Category"
                                        options={categoryLists}
                                        isMultiple={false}
                                        value={values.category}
                                        onChange={(fieldName, value)=> categoryChange(value, setFieldValue)}
                                        onBlur={setFieldTouched}
                                        error={errors.category}
                                        touched={touched.category}
                                    />
                                    <InputField fieldName="description" fieldLabel="Product Description" fieldType="text" md="12" />
                                </Row>
                            </Col>
                            <Col md="3">
                                <Form.Group as={Col} xs="12" sm="12" md="9" controlId="formGridImage" className="formStyle">
                                    <Form.Label className="formLabel">Store Image</Form.Label>
                                    <div className="imageStyles">
                                        <Form.Control type="file"  onChange={e => setImage(e.target.files[0])}  style={{ opacity: 0, position: 'absolute', bottom: 0, height: 120, width: 190 }} />
                                        {image ? <img src={URL.createObjectURL(image)} style={{ width: 200, height: 120 }} /> : product.image ? <img src={product.image} style={{ width: 200, height: 120 }} /> : <span style={{ position: 'absolute', bottom: 50 }}>512 x 512</span>}
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <InputField fieldName="cost_price" fieldLabel="Cost Price" md="4" />
                            <InputField fieldName="selling_price" fieldLabel="Selling Price" md="4" />
                            <InputField fieldName="offer_price" fieldLabel="Offer Price" md="4" />
                            <SelectNew 
                                md="4"
                                fieldName="unit"
                                fieldLabel="Unit"
                                options={units}
                                isMultiple={false}
                                value={values.unit}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                error={errors.unit}
                                touched={touched.unit}
                            />
                            <TagInput fieldName="tags" fieldLabel="Tags" md="4" handleChange={tagInputChange} tags={tags} />
                            <SwitchInput fieldName="status" fieldLabel="Active Status"  />
                            
                        </Row>
                        <Row>
                            <center>
                                <Buttons backColor="#D42F45" buttonText="UPDATE" buttonAction={handleSubmit}>
                                    <GiBackwardTime style={{color:'#FFFFFF'}} fontSize="2.3em" />
                                </Buttons>
                            </center>
                        </Row>
                    </Form>
                )}
            />}
            
        </Modals>
    )
}

export default EditProduct
