import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import Modals from '../../components/Modals'
import {IoMdAdd}from 'react-icons/io'
import Buttons from '../../components/Buttons'
import './store.scss'
import { useSelector, useDispatch } from 'react-redux'
import { addNewStore, getStoreTypes, saveStoresType } from '../../Redux/actions/storeActions'
import { toast } from 'react-toastify'
import { RESET_STORE } from '../../Redux/constants/storeConstants'
import FranchiseeForm from '../Franchisee/FranchiseeForm'
import { RESET_ERROR } from '../../Redux/constants/franchiseeConstants'
import { getFranchisee } from '../../Redux/actions/franchiseeActions'
import {Formik, Field} from 'formik';
import InputField from '../../components/InputField'
import IconInput from '../../components/IconInput'
import FormSelectField from '../../components/form-select-field'
import MultiInput from '../../components/MultiInput'
import ImageInput from '../../components/ImageInput'
import {BsPercent} from 'react-icons/bs'

import * as Yup from 'yup';
import "yup-phone";
import SelectNew from '../../components/SelectNew'
import TextArea from '../../components/TextArea'
import reactotron from '../../ReactotronConfig'

const NewStore = ({openModal, closeModal, headerColor, headerTitle}) => {

    const dispatch = useDispatch();
    const { storeTypes, error, newStoreType } = useSelector(state => state.store)
    const { error : franchiseeError, newFranchisee, franchiseeList } = useSelector(state => state.franchisee)

    const [image, setImage] = useState(null);
    const [storeName, setStoreName] = useState('');

    const [openSubModal, setOpenSubModal] = useState(false);
    const [subHeaderColor, setSubHeaderColor] = useState('');
    const [subHeaderTitle, setSubHeaderTitle] = useState('');

    const [openFranchiseeModal, setOpenFranchiseeModal] = useState(false)

    useEffect(() => {
        if(error){
            toast.error(error);
            dispatch({
                type: RESET_STORE
            })
        }

        if(franchiseeError){
            toast.error(franchiseeError);
            dispatch({
                type: RESET_ERROR
            })
        }

        if(newStoreType){ //close store type modal
            setOpenSubModal(false)
        }

        if(newFranchisee){
            toast.success("New Franchisee created Successfully");
            setOpenFranchiseeModal(false);
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [error, newStoreType, franchiseeError, newFranchisee])


    const closeSubModel = () => {
        setOpenSubModal(false);
    }

    const closeFranchiseeModal = () => {
        setOpenFranchiseeModal(false)
    }


    useEffect(() => {
        dispatch(getStoreTypes())
        dispatch(getFranchisee())
    }, [])

    const saveStore = async (values) =>  {

        let stores = await values.store_type.map((store) => store.name)

        let fran = {
            name: values.franchisee_name.name,
            id: values.franchisee_name._id
        }

        const working_hrs = [
            {
                day: 'Monday',
                start_time : values.day1,
                end_time : values.day1_to
            },
            {
                day: 'Tuesday',
                start_time : values.day2,
                end_time : values.day2_to
            },
            {
                day: 'Wednesday',
                start_time : values.day3,
                end_time : values.day3_to
            },
            {
                day: 'Thursday',
                start_time : values.day4,
                end_time : values.day4_to
            },
            {
                day: 'Friday',
                start_time : values.day5,
                end_time : values.day5_to
            },
            {
                day: 'Saturday',
                start_time : values.day6,
                end_time : values.day6_to
            },
            {
                day: 'Sunday',
                start_time : values.day7,
                end_time : values.day7_to
            }
        ]

        //console.log({working_hrs})
        

        var form_data = new FormData();
        form_data.append("name", values.name);
        form_data.append("email", values.email);
        form_data.append("phone", parseInt(values.phone.substr(values.phone.length - 10)));
        form_data.append("city", values.city);
        form_data.append("address", values.address);
        form_data.append("gst", values.gst);
        form_data.append("tax",parseInt(values.tax));
        form_data.append("franchisee_name", JSON.stringify(fran));
        form_data.append("longitude", values.longitude);
        form_data.append("lattitude", values.latitude);
        form_data.append("store_type", JSON.stringify(stores));
        form_data.append("working_hrs", JSON.stringify(working_hrs));
        form_data.append("image", image);
        form_data.append("status", 'Active');
        form_data.append("delivery_status",'Active' );
        form_data.append("estd_delivery_time", values.estd_delivery_time);

        //console.log(form_data);

        dispatch(addNewStore(form_data))
    }

    const saveStoreType = () => {
        dispatch(saveStoresType(storeName))
    }

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    return (
        <Modals showModal={openModal} hideModal={closeModal} headerColor={headerColor} modalTitle={headerTitle} className="my-modal">
            {!openFranchiseeModal && 
            <Formik 
                initialValues={{
                    name: '',
                    email: '',
                    phone: '',
                    city: '',
                    address: '',
                    gst: '',
                    tax: '',
                    franchisee_name: '',
                    latitude: '',
                    longitude: '',
                    store_type: '',
                    delivery_status: '',
                    status: '',
                    day1: '',
                    day1_to: '',
                    day2: '',
                    day2_to: '',
                    day3: '',
                    day3_to: '',
                    day4: '',
                    day4_to: '',
                    day5: '',
                    day5_to: '',
                    day6: '',
                    day6_to: '',
                    day7: '',
                    day7_to : '',
                    estd_delivery_time: '',
                    image: ''
                }}
                validationSchema={
                    
                    Yup.object({
                        name: Yup.string().min(8, 'Must be 8 characters or less').required('Name Required'),
                        email: Yup.string().email('Invalid email address').required('Store Email Required'),
                        phone: Yup.string().phone("IN").required('Phone Number is Required'),
                        city: Yup.string().required("City is Required"),
                        address: Yup.string().required("Address is required"),
                        gst: Yup.string().required('GST Required'),
                        tax: Yup.string().required('Tax is Required'),
                        franchisee_name :  Yup.object().required('Franchisee Name required'),
                        latitude : Yup.string().required("Latitude is Required"),
                        longitude : Yup.string().required("Longitude is Required"),
                        store_type: Yup.array().required("Store Type Required"),
                        estd_delivery_time : Yup.number().required('Esimated Delivery Time Required'),
                    })
                }
                onSubmit={(values) => {
                    reactotron.log(values)
                    saveStore(values)
                }}
                render={({handleChange, handleSubmit, handleBlur, values, errors, setFieldValue, setFieldTouched, touched}) => (
                    <Form>
                        <Row>
                            
                            
                            <Col md="12" lg="8" xl="8" >
                                <Row>
                                    <InputField fieldName="name" fieldLabel="Name" md="4" />
                                    <InputField fieldName="email" fieldLabel="Store Email Address" fieldType="email" md="4" />
                                    <InputField fieldName="phone" fieldLabel="Store Phone Number" fieldType="text" md="4" />
                                    <InputField fieldName="city" fieldLabel="City" fieldType="text" md="4" />
                                    <TextArea fieldName="address" fieldLabel="Address" fieldType="text" md="4" />
                                    <ImageInput fieldName="image" fieldLabel="Store Image" image={image} md="4" imageHelperText={"512 * 512"} changeImage={(e)=> setImage(e.target.files[0]) } />
                                    <SelectNew 
                                        md="4"
                                        fieldName="store_type"
                                        fieldLabel="Store Type"
                                        options={storeTypes}
                                        isMultiple={true}
                                        value={values.store_type}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        error={errors.store_type}
                                        touched={touched.store_type}
                                    />
                                    <SelectNew 
                                        md="4"
                                        fieldName="franchisee_name"
                                        fieldLabel="Franchisee Name"
                                        options={franchiseeList}
                                        isMultiple={false}
                                        value={values.franchisee_name}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        error={errors.franchisee_name}
                                        touched={touched.franchisee_name}
                                    />
                                    <IconInput fieldName="tax" fieldLabel="TAX" fieldType="text" md="4">
                                        <BsPercent fontSize="1.5em"/>
                                    </IconInput>
                                    <InputField fieldName="gst" fieldLabel="GST No." fieldType="text" md="4" />
                                    <Col md="8">
                                        <Row>
                                            <MultiInput fieldName="latitude" fieldName2="longitude" fieldLabel="Longitude & Latitude" md="6" />
                                            
                                            
                                            <IconInput fieldName="estd_delivery_time" fieldLabel="Estimated Time For Delivery" fieldType="text" md="6">
                                                <span>MINS</span>
                                            </IconInput>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md="12" lg="4" xl="4">
                            
                                <Form.Group as={Col} xs="12" sm="12" md="12" className="formStyle">
                                    <Form.Label className="formLabel">Working Days & Hours</Form.Label>
                                    <Row style={{ marginBottom: 5 }}>
                                        <Col xs="12" sm="12" md="12" lg="4" xl="4">
                                            <Form.Label className="formLabel">MON</Form.Label>
                                        </Col>
                                        <Col xs="12" sm="12" md="6" lg="4" xl="4">
                                            <Field 
                                                name='day1'
                                            >
                                                {({field, form, meta}) => <Form.Control 
                                                        type="time"
                                                        id="day1"
                                                        value={field.value} 
                                                        onChange={field.onChange}
                                                        style={{ borderRadius: 10 }}
                                                />}
                                            </Field>
                                        </Col>
                                        <Col xs="12" sm="12" md="6" lg="4" xl="4">
                                            <Field 
                                                name='day1_to'
                                            >
                                                {({field, form, meta}) => <Form.Control 
                                                        type="time"
                                                        id="day1_to"
                                                        value={field.value} 
                                                        onChange={field.onChange}
                                                        style={{ borderRadius: 10 }}
                                                />}
                                            </Field>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginBottom: 5 }}>
                                        <Col xs="12" sm="12" md="12" lg="4" xl="4">
                                            <Form.Label className="formLabel">TUE</Form.Label>
                                        </Col>
                                        <Col xs="12" sm="12" md="6" lg="4" xl="4">
                                            <Field 
                                                name='day2'
                                            >
                                                {({field, form, meta}) => <Form.Control 
                                                        type="time"
                                                        id="day2"
                                                        value={field.value} 
                                                        onChange={field.onChange}
                                                        style={{ borderRadius: 10 }}
                                                />}
                                            </Field>
                                        </Col>
                                        <Col xs="12" sm="12" md="6" lg="4" xl="4">
                                            <Field 
                                                name='day2_to'
                                            >
                                                {({field, form, meta}) => <Form.Control 
                                                        type="time"
                                                        id="day2_to"
                                                        value={field.value} 
                                                        onChange={field.onChange}
                                                        style={{ borderRadius: 10 }}
                                                />}
                                            </Field>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginBottom: 5 }}>
                                        <Col xs="12" sm="12" md="12" lg="4" xl="4">
                                            <Form.Label className="formLabel">WED</Form.Label>
                                        </Col>
                                        <Col xs="12" sm="12" md="6" lg="4" xl="4">
                                            <Field 
                                                name='day3'
                                            >
                                                {({field, form, meta}) => <Form.Control 
                                                        type="time"
                                                        id="day3"
                                                        value={field.value} 
                                                        onChange={field.onChange}
                                                        style={{ borderRadius: 10 }}
                                                />}
                                            </Field>
                                        </Col>
                                        <Col xs="12" sm="12" md="6" lg="4" xl="4">
                                            <Field 
                                                name='day3_to'
                                            >
                                                {({field, form, meta}) => <Form.Control 
                                                        type="time"
                                                        id="day3_to"
                                                        value={field.value} 
                                                        onChange={field.onChange}
                                                        style={{ borderRadius: 10 }}
                                                />}
                                            </Field>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginBottom: 5 }}>
                                        <Col xs="12" sm="12" md="12" lg="4" xl="4">
                                            <Form.Label className="formLabel">THU</Form.Label>
                                        </Col>
                                        <Col xs="12" sm="12" md="6" lg="4" xl="4">
                                            <Field 
                                                name='day4'
                                            >
                                                {({field, form, meta}) => <Form.Control 
                                                        type="time"
                                                        id="day4"
                                                        value={field.value} 
                                                        onChange={field.onChange}
                                                        style={{ borderRadius: 10 }}
                                                />}
                                            </Field>
                                        </Col>
                                        <Col xs="12" sm="12" md="6" lg="4" xl="4">
                                            <Field 
                                                name='day4_to'
                                            >
                                                {({field, form, meta}) => <Form.Control 
                                                        type="time"
                                                        id="day4_to"
                                                        value={field.value} 
                                                        onChange={field.onChange}
                                                        style={{ borderRadius: 10 }}
                                                />}
                                            </Field>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginBottom: 5 }}>
                                        <Col xs="12" sm="12" md="12" lg="4" xl="4">
                                            <Form.Label className="formLabel">FRI</Form.Label>
                                        </Col>
                                        <Col xs="12" sm="12" md="6" lg="4" xl="4">
                                            <Field 
                                                name='day5'
                                            >
                                                {({field, form, meta}) => <Form.Control 
                                                        type="time"
                                                        id="day5"
                                                        value={field.value} 
                                                        onChange={field.onChange}
                                                        style={{ borderRadius: 10 }}
                                                />}
                                            </Field>
                                        </Col>
                                        <Col xs="12" sm="12" md="6" lg="4" xl="4">
                                            <Field 
                                                name='day5_to'
                                            >
                                                {({field, form, meta}) => <Form.Control 
                                                        type="time"
                                                        id="day5_to"
                                                        value={field.value} 
                                                        onChange={field.onChange}
                                                        style={{ borderRadius: 10 }}
                                                />}
                                            </Field>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginBottom: 5 }}>
                                        <Col xs="12" sm="12" md="12" lg="4" xl="4">
                                            <Form.Label className="formLabel">SAT</Form.Label>
                                        </Col>
                                        <Col xs="12" sm="12" md="6" lg="4" xl="4">
                                            <Field 
                                                name='day6'
                                            >
                                                {({field, form, meta}) => <Form.Control 
                                                        type="time"
                                                        id="day6"
                                                        value={field.value} 
                                                        onChange={field.onChange}
                                                        style={{ borderRadius: 10 }}
                                                />}
                                            </Field>
                                        </Col>
                                        <Col xs="12" sm="12" md="6" lg="4" xl="4">
                                            <Field 
                                                name='day6_to'
                                            >
                                                {({field, form, meta}) => <Form.Control 
                                                        type="time"
                                                        id="day6_to"
                                                        value={field.value} 
                                                        onChange={field.onChange}
                                                        style={{ borderRadius: 10 }}
                                                />}
                                            </Field>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginBottom: 5 }}>
                                        <Col xs="12" sm="12" md="12" lg="4" xl="4">
                                            <Form.Label className="formLabel">SUN</Form.Label>
                                        </Col>
                                        <Col xs="12" sm="12" md="6" lg="4" xl="4">
                                            <Field 
                                                name='day7'
                                            >
                                                {({field, form, meta}) => <Form.Control 
                                                        type="time"
                                                        id="day7"
                                                        value={field.value} 
                                                        onChange={field.onChange}
                                                        style={{ borderRadius: 10 }}
                                                />}
                                            </Field>
                                        </Col>
                                        <Col xs="12" sm="12" md="6" lg="4" xl="4">
                                            <Field 
                                                name='day7_to'
                                            >
                                                {({field, form, meta}) => <Form.Control 
                                                        type="time"
                                                        id="day7_to"
                                                        value={field.value} 
                                                        onChange={field.onChange}
                                                        style={{ borderRadius: 10 }}
                                                />}
                                            </Field>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </Row>
                        {headerTitle === 'ADD STORE' && 
                        <Row>
                            <center>
                                <Buttons backColor="#D42F45" buttonText="ADD" buttonAction={handleSubmit}>
                                    <IoMdAdd style={{color:'#FFFFFF'}} fontSize="2.3em" />
                                </Buttons>
                            </center>
                        </Row>}
                    </Form>
                )}
            />}
            
            <Modals showModal={openSubModal} hideModal={closeSubModel} headerColor={subHeaderColor} modalTitle={subHeaderTitle} modalSize="sm">
                <Row className="mb-3">
                    <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" controlId="formGridAddress" className="formStyle">
                        <Form.Label className="formLabel">Store Type</Form.Label>
                        <Form.Control className="inputStyle" type="storeType" value={storeName} onChange={e => setStoreName(e.target.value)} />
                    </Form.Group>
                </Row>
                <center>
                    <Buttons backColor="#D42F45" buttonText="ADD" buttonAction={saveStoreType}>
                        <IoMdAdd style={{color:'#FFFFFF'}} fontSize="2.3em" />
                    </Buttons>
                </center>
            </Modals>
            <FranchiseeForm openModal={openFranchiseeModal} closeModal={closeFranchiseeModal} headerTitle="ADD FRANCHISEE" />
        </Modals>
    )
}

export default NewStore
