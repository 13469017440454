import { 
    NEW_SER_CATEGORY_FAIL,
    NEW_SER_CATEGORY_SUCCESS,
    NEW_SER_MAIN_CATEGORY_FAIL, 
    NEW_SER_MAIN_CATEGORY_SUCCESS, 
    SER_CATEGORY_DETAILS_FAIL, 
    SER_CATEGORY_DETAILS_SUCCESS, 
    SER_CATEGORY_LIST_FAIL, 
    SER_CATEGORY_LIST_SUCCESS, 
    SER_MAIN_CATEGORY_LIST_FAIL, 
    SER_MAIN_CATEGORY_LIST_SUCCESS,
    UPDATE_SER_CATEGORY_FAIL,
    UPDATE_SER_CATEGORY_SUCCESS,
    DELETE_SER_CATEGORY_FAIL, 
    DELETE_SER_CATEGORY_SUCCESS, 
    DELETE_SER_MAIN_CATEGORY_FAIL,
    DELETE_SER_MAIN_CATEGORY_SUCCESS,
    RESET_SER_CATEGORY,
} from "../constants/serviceConstants";

export  const servicecategoryReducer = (state = { serviceMainCategories: [] }, action) => {
    switch(action.type){

        case SER_CATEGORY_LIST_SUCCESS:
            return {
                ...state,
                serviceCategoryList: action.payload
            }

        case SER_CATEGORY_LIST_FAIL:
        case NEW_SER_MAIN_CATEGORY_FAIL:
        case SER_MAIN_CATEGORY_LIST_FAIL:
        case NEW_SER_CATEGORY_FAIL:
        case SER_CATEGORY_DETAILS_FAIL:
        case DELETE_SER_CATEGORY_FAIL:
        case UPDATE_SER_CATEGORY_FAIL:
        case DELETE_SER_MAIN_CATEGORY_FAIL:
            return {
                ...state,
                error: action.payload.message
            }

        case RESET_SER_CATEGORY:
            return{
                ...state,
                error: null,
                servicecategorySuccess: null,
                updateSuccess: null,
                deleteServicecategory: null,
                
            }

        

        case SER_MAIN_CATEGORY_LIST_SUCCESS:
            return {
                ...state,
                mainCategories: action.payload
            }

        case NEW_SER_CATEGORY_SUCCESS:
            return {
                ...state,
                serviceCategoryList : state.serviceCategoryList ? [...state.serviceCategoryList, action.payload] : [ action.payload ],
                servicecategorySuccess: true
            }

        case SER_CATEGORY_DETAILS_SUCCESS:
            return{
                ...state,
                mainCategories: action.payload
            }

        case UPDATE_SER_CATEGORY_SUCCESS:
            return {
                ...state,
                serviceCategoryList: state.serviceCategoryList.map(cat => cat._id === action.payload._id ? action.payload : cat),
                updateSuccess: true
            }

        case DELETE_SER_CATEGORY_SUCCESS:
            return {
                ...state,
                serviceCategoryList: state.serviceCategoryList.filter(cat => cat._id !== action.payload ),
                deleteServicecategory: true
            }

        case NEW_SER_MAIN_CATEGORY_SUCCESS:
            return {
                ...state,
                mainCategories: state.mainCategories ? [...state.mainCategories , action.payload] : [action.payload],
                newMainCategory: true
            }

        case DELETE_SER_MAIN_CATEGORY_SUCCESS:
            let newsliders = state.storeDetails.slider.filter(slide => slide._id != action.payload)
            let remainStoreDetails = { ...state.storeDetails, slider: newsliders }
            return {
                ...state,
                sliderDelete: true,
                storeDetails : remainStoreDetails
            }

        default: 
            return state;
    }
}

