import { Field, ErrorMessage } from 'formik'
import React from 'react'
import { Form, Col } from 'react-bootstrap'

const SwitchInput = ({ fieldName, fieldLabel, md, readOnly }) => {
    return (
        <Form.Group as={Col} xs="12" sm="12" md={md ? md : "4"} controlId={fieldName} className="formStyle">
            <Form.Label className="formLabel">{fieldLabel}</Form.Label>
            <div style={{ display: 'flex', flexDirection: 'row', height: 40, borderRadius: 10, border: "1px solid #417AE85E", backgroundColor: '#417AE80A', alignItems:'center', justifyContent:'center' }}>
                <span style={{ marginRight: 10, fontFamily: "Avenir-Black", color: '#1B2F5B' }}>Deactive</span>
                <Field 
                    name={fieldName}
                >
                    {({field, form, meta}) => <Form.Check 
                            readOnly={readOnly}
                            type="switch"
                            label="Active"
                            checked={field.value}
                            value={field.value} 
                            onChange={field.onChange}
                            style={{ fontFamily: "Avenir-Black", color: '#1B2F5B' }}
                        />}
                </Field>
            </div>
        </Form.Group>
    )
}

export default SwitchInput
