import React, { Fragment } from 'react'
import { Card } from 'react-bootstrap'
import './style.scss'
import Logo from '../assets/Images/dummylogo.png'
import { 
    FaBell
} from 'react-icons/fa'


const Header = ({ headerText }) => {
    return (
        <Card className="shadow" style={{ width: '97%', height: 80,  borderRadius: 20 }}>
            <Card.Body style={{ display: 'flex', alignItems:'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                <span className="headerText">{headerText}</span>
                <div style={{ display: 'flex', flexDirection:'row' }}>
                    <div style={{ display: 'flex', flexDirection:'column' }}>
                        <span className="username">Athir</span>
                        <span className="role">Admin</span>
                    </div>
                    <img src={Logo} style={{ width: 30, height: 30 }} />
                </div>
            </Card.Body>
            <FaBell style={{ marginLeft: 15, color: '#ADADB1', position:'absolute', right: -40, top: 20, zIndex: 1 }} size={30} />
        </Card>
    )
}

export default Header

