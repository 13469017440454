import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import Modalsmall from '../../components/Modalsmall'
import {MdEdit,MdUpdate} from 'react-icons/md'
import Buttons from '../../components/Buttons'
import { useSelector, useDispatch } from 'react-redux'
import { updateTime } from '../../Redux/actions/timeActions'
import { toast } from 'react-toastify'
import {Formik, Field} from 'formik';
import InputField from '../../components/InputField'
import "yup-phone";
import { RESET_TIME } from '../../Redux/constants/timeConstants'


const EditTime = ({openModal, closeModal, headerColor, headerTitle, times}) => {

    const { updateSuccess } = useSelector(state => state.time)
    const [image, setImage] = useState(null)
    const dispatch = useDispatch();
    const [initialDatas, setInitialDatas] = useState(null)

    useEffect(() => {
        
        if(updateSuccess){
                closeModal(true)
                dispatch({
                    type: RESET_TIME
                })
                toast.success("Time updated Successfully");
            }
        
       
    }, [updateSuccess])
    
    useEffect(() => {
        async function getDetails() {

            if(times){
                const details = {
                    id:times._id,
                    from: times.from,
                    to: times.to,
                    when:times.when
                }
    
                
                console.log({details})
                setInitialDatas(details)
            }
        }

        getDetails();
    }, [times])

    const editTime =  (values) =>  {


        console.log({values})
        const { id, from, to, when } = values
        dispatch(updateTime(id, from, to, when));
       
    }


    return (
        <Modalsmall showModal={openModal} hideModal={closeModal} headerColor={headerColor} modalTitle={headerTitle} >
            {initialDatas && 
            <Formik 
                initialValues={initialDatas}
                onSubmit={(values) => {
                    console.log(values)
                    editTime(values)
                }}
                render={({handleChange, handleSubmit, handleBlur, values, errors}) => (
                    <Form>
                        <Row>
                            <InputField fieldName="from" fieldLabel="From Time" fieldType="time" md="12"/>
                            <InputField fieldName="to" fieldLabel="To Time" fieldType="time" md="12"/>
                        </Row>
                        {headerTitle === 'EDIT TIME' && <Row>
                <center>
                    <Buttons backColor="#FF9900" buttonText="UPDATE" buttonAction={handleSubmit}>
                            <MdUpdate style={{color:'#FFFFFF'}} fontSize="2.3em" />
                    </Buttons>
                </center>
            </Row>}
                    </Form>
                )}
            />}
        </Modalsmall>
    )
}

export default EditTime
