export const STORE_PAYOUT_SUCCESS = 'STORE_PAYOUT_SUCCESS';
export const STORE_PAYOUT_FAIL = 'STORE_PAYOUT_FAIL';
export const STORE_PAYOUT_INPUT = 'STORE_PAYOUT_INPUT';
export const RESET_PAYOUT = 'RESET_PAYOUT';
export const FRANCHISEE_PAYOUT_FAIL = 'FRANCHISEE_PAYOUT_FAIL';
export const FRANCHISEE_PAYOUT_SUCCESS = 'FRANCHISEE_PAYOUT_SUCCESS';
export const DELIVERYBOY_PAYOUT_FAIL = 'DELIVERYBOY_PAYOUT_FAIL';
export const DELIVERYBOY_PAYOUT_SUCCESS = 'DELIVERYBOY_PAYOUT_SUCCESS';
export const ADMIN_PAYOUT_FAIL = 'ADMIN_PAYOUT_FAIL';
export const ADMIN_PAYOUT_SUCCESS = 'ADMIN_PAYOUT_SUCCESS';
