import React, { Fragment, useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import {MdEdit} from 'react-icons/md'
import {AiFillDelete,AiFillEye} from 'react-icons/ai'
import {BsToggle2On,BsToggle2Off, BsCheck2} from 'react-icons/bs'
import {IoMdAddCircle,IoMdAdd}from 'react-icons/io'
import { FaTimes } from 'react-icons/fa'
import Pagination from 'react-paginating';
import Search from '../../components/Search'
import Paging from '../../components/Paging'
import Modals from '../../components/Modals'
import Buttons from '../../components/Buttons'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { RESET_STORE } from '../../Redux/constants/storeConstants'
import moment from 'moment'
import reactotron from '../../ReactotronConfig'
import { useRouteMatch, Link } from 'react-router-dom'
import { getCategoryLists ,deleteCategoryById} from '../../Redux/actions/categoryActions'
import NewCategory from './NewCategory'
import {Formik, Field} from 'formik';

import InputField from '../../components/InputField'
import IconInput from '../../components/IconInput'
import FormSelectField from '../../components/form-select-field'
import MultiInput from '../../components/MultiInput'
import * as Yup from 'yup';
import EditCategory from './EditCategory'

const CategoryList = () => {

    const { categoryLists, error, categorySuccess,deleteCategorySuccess } = useSelector(state => state.category)

    const dispatch = useDispatch();
    const match = useRouteMatch();
    const [showModal, setShowModal] = useState(false)
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [modalShowEdit, setModalShowEdit] = useState(false);
    const [headerColor, setHeaderColor] = useState('')
    const [headerTitle, setHeaderTitle] = useState('')
    const [selectedCategory, setselectedCategory] = useState('')

    const [currentPage, setCurrentPage] = useState(1)
    const [datas, setDatas] = useState([])
    const [search, setSearch] = useState('')

    useEffect(() => {
        if(categoryLists && categoryLists.length>0){
            const indexOfLastPost = currentPage * 10;
            const indexOfFirstPost = indexOfLastPost - 10;
            const searchResult = categoryLists.filter((category) => category.name.toLowerCase().includes(search.toLowerCase()))
            const currentPosts = (searchResult || []).slice(indexOfFirstPost, indexOfLastPost);
            setDatas(currentPosts)
        }
    }, [categoryLists, search])

    useEffect(() => {
        if(error){
            toast.error(error);
            dispatch({
                type: RESET_STORE
            })
        }

        
        if(deleteCategorySuccess){
            //dispatch(getCategoryLists())
            setDeleteModalShow(false);
            dispatch({
                type: RESET_STORE
            })
            toast.success("Category Deleted Successfully");
        }
    }, [error,deleteCategorySuccess])



    useEffect(() => {
        dispatch(getCategoryLists())
    }, [])

    const pageChanged = (page)=> {
        setCurrentPage(page)
        const indexOfLastPost = page * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const searchResult = categoryLists.filter((category) => category.name.toLowerCase().includes(search.toLowerCase()))
        const currentPosts = (searchResult || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }

    const newCategory = () => {
        setHeaderColor('#D42F45');
        setHeaderTitle("ADD CATEGORY");
        setShowModal(true)
    }
    const editCategory = (category) => {
        setselectedCategory(category);
        setHeaderColor('#FF9900');
        setHeaderTitle("EDIT CATEGORY");
        setModalShowEdit(true);
    }

    const deleteCategory = (id) => {
        setselectedCategory(id);
        setHeaderColor('#D42F45');
        setHeaderTitle("DELETE CATEGORY");
        setDeleteModalShow(true);
    }
    const deleteCategoryListById = () => {
        dispatch(deleteCategoryById(selectedCategory))
    }

    return (
        <Fragment>
            <Search placeHolder="SEARCH CATEGORY" searchValue={search} searchChanged={e => setSearch(e.target.value)} />
            <Card style={{ borderRadius: 20, margin: 10 }}>
                <IoMdAddCircle className="add" fontSize="3.5em" 
                style={{ position: 'absolute', top: -30, right: -20, cursor: 'pointer' }} onClick={newCategory} />
                <Card.Body style={{ overflowX: 'auto' }}>
                    <table>
                        <thead className="tableHeader">
                            <tr>
                                <th>Category Name</th>
                                <th>Units</th>
                                <th>Image</th>
                                <th>Active Status</th>
                                <th>Created Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {datas && datas.map((category, index) => {
                                const units =  category.units.map((unit) => unit.unit);
                                return(
                                <tr key={index}>
                                    <td className="tableContent">{category.name}</td>
                                    <td className="tableContent">{units.join()}</td>
                                    <td className="tableContent"><img src={category.image} width={50} height={50} /></td>
                                    <td className="tableContent">{category.status === 'Active' ? <BsToggle2On className="green" fontSize="1.5em"/> : <BsToggle2Off fontSize="1.5em" color="#F95555" />}</td>
                                    <td className="tableContent">{moment(category.created_at).local().format("DD-MM-YYYY")}</td>
                                    <td className="tableContent">
                                        {/* <Link to={{
                                            pathname: `${match.path}/edit`,
                                            state: {
                                                id: category._id,
                                                name: category.name
                                            }
                                        }}>  </Link>*/}
                                        <MdEdit color="orange" onClick={() => editCategory(category)}/>
                                       
                                        <AiFillDelete className="add" onClick={() => deleteCategory(category._id)} />
                                    </td>
                                </tr>
                            )})}
                        </tbody>
                    </table>
                </Card.Body>
                <Col style={{ float: 'right', display:'flex', flexDirection: 'row-reverse', justifyContent: 'flex-start', alignItems: 'center', margin: 30  }}>
                <Card >
                    {categoryLists && categoryLists.length>10 && <Paging datas={categoryLists} currentPage={currentPage} pageChange={pageChanged} />}
                </Card>
                </Col>
            </Card>
            {modalShowEdit && <EditCategory openModal={modalShowEdit} closeModal={()=> setModalShowEdit(false)} headerColor={headerColor} headerTitle={headerTitle} categorylist={selectedCategory}  />}
           
            <Modals showModal={deleteModalShow} hideModal={()=> setDeleteModalShow(false)} modalTitle={headerTitle} headerColor={headerColor}>
                <p className="deleteText" >Are you sure you want to delete this Category?</p>
                <div style={{ display: 'flex', alignItems:'center', justifyContent:'center' }}>
                        <Buttons backColor="#4AAD47" buttonText="YES" buttonAction={deleteCategoryListById}>
                            <BsCheck2 style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                        <Buttons backColor="#D42F45" buttonText="No" buttonAction={()=> setDeleteModalShow(false)}>
                            <FaTimes style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                </div>
            </Modals>
            <NewCategory openModal={showModal} closeModal={()=> setShowModal(false)} modalTitle={headerTitle} headerColor={headerColor}  />
            
        </Fragment>
    )
}

export default CategoryList
