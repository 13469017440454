import React, { Fragment, useState } from 'react'
import Header from '../../components/Header'
import Layout from '../layout'
import ProductList from './ProductList'

const Products = () => {

    
    return (
        
        <Layout>
            <Header headerText="PRODUCTS" />
            <ProductList />
            
        </Layout>
       
    )
}

export default Products
