import { 
    DELETE_FRANCHISEE_FAIL,
    DELETE_FRANCHISEE_SUCCESS,
    FRANCHISEE_LIST_FAIL,
    FRANCHISEE_LIST_SUCCESS,
    NEW_FRANCHISEE_FAIL, 
    NEW_FRANCHISEE_SUCCESS, 
    RESET_ERROR, 
    UPDATE_FRANCHISEE_SUCCESS,
    UPDATE_FRANCHISEE_FAIL,
    RESET_CHARGE, 
    CHARGE_LIST_FAIL,
    CHARGE_LIST_SUCCESS,
    NEW_CHARGE_FAIL, 
    NEW_CHARGE_SUCCESS, 
    UPDATE_CHARGE_FAIL, 
    UPDATE_CHARGE_SUCCESS,
    DELETE_CHARGE_FAIL,
    DELETE_CHARGE_SUCCESS,
    CHARGE_DETAILS_SUCCESS,
    CHARGE_DETAILS_FAIL,
    DELIVERY_CHARGE_INPUT
    
} from "../constants/franchiseeConstants";

export  const franchiseeReducer = (state = { storeTypes: [] }, action) => {
    switch(action.type){

        case NEW_FRANCHISEE_SUCCESS:
            return {
                ...state,
                franchiseeList : state.franchiseeList ? [...state.franchiseeList, action.payload] : [action.payload],
                newFranchisee: true
            }

        case NEW_FRANCHISEE_FAIL:
        case FRANCHISEE_LIST_FAIL:
        case DELETE_FRANCHISEE_FAIL:
            return {
                ...state,
                error: action.payload.message
            }

        case RESET_ERROR:
            return {
                ...state,
                error: null,
                newFranchisee: null,
                deleteSuccess:null,
                updateSuccess:null
            }

        case FRANCHISEE_LIST_SUCCESS:
            return {
                ...state,
                franchiseeList: action.payload
            }

        case UPDATE_FRANCHISEE_SUCCESS:
            const item = action.payload;
            return{
                ...state,
                franchiseeList: state.franchiseeList.map(i => i._id === item._id ? item: i),
                updateSuccess:true
            }
        case UPDATE_FRANCHISEE_FAIL:
            return {
                ...state,
                error: action.payload.message
            }    

        
        case DELETE_FRANCHISEE_FAIL:
            return {
                ...state,
                error: action.payload.message
            }
        case DELETE_FRANCHISEE_SUCCESS:
            return {
                ...state,
                franchiseeList: state.franchiseeList.filter((fran) => fran._id !== action.payload),
                deleteSuccess: true
            }
        default:
            return state;
    }
}

//DELIVERY CHARGE DETAILS

export  const deliverychargeReducer = (state = {  }, action) => {
    switch(action.type){

        case NEW_CHARGE_SUCCESS:
            return {
                ...state,
                chargeList : state.chargeList ? [...state.chargeList, action.payload] : [action.payload],
                newCharge: true
            }

        case NEW_CHARGE_FAIL:
        case CHARGE_DETAILS_FAIL:
        case CHARGE_LIST_FAIL:
            return {
                ...state,
                error: action.payload.message
            }


        case RESET_CHARGE:
            return {
                ...state,
                error: null,
                newCharge: null,
                deleteSuccess:null,
                updateSuccess:null,
                
            }
        case CHARGE_DETAILS_SUCCESS:
            return{
                ...state,
                chargeDetails: action.payload
            }
        case CHARGE_LIST_SUCCESS:
            return {
                ...state,
                chargeList: action.payload
            }

        case UPDATE_CHARGE_SUCCESS:
            return{
                ...state,
                chargeList: state.chargeList.map(charge => charge._id === action.payload._id ? action.payload: charge),
                updateSuccess:true
            }

        case UPDATE_CHARGE_FAIL:
            return {
                ...state,
                error: action.payload.message
            }    

        
        case DELETE_CHARGE_FAIL:
            return {
                ...state,
                error: action.payload.message
            }

        case DELETE_CHARGE_SUCCESS:
            return {
                ...state,
                chargeList: state.chargeList.filter(charge => charge._id !== action.payload),
                deleteSuccess: true
            }

        case DELIVERY_CHARGE_INPUT:
            return{
                ...state,
                [action.payload.prop] : action.payload.value
            }

        default:
            return state;
    }
}