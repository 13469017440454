import React, { Fragment, useState } from 'react'
import { Card, Col,Row } from 'react-bootstrap'
import Header from '../../components/Header'
import Layout from '../layout'
import AddSlider from './AddSlider'
import SliderSettings from './SliderSettings'
import { Route, Link, useRouteMatch } from 'react-router-dom'



const Sliders = ({history}) =>  {

    //const history = createBrowserHistory();

    const match = useRouteMatch()

    console.log({history})

    //const path = history.location.pathname;

    return (
        
        <Layout>
        <Header headerText="SLIDER MANAGEMENT" />
        <Row style={{  width: "100%" }}>
            <Col xs="12" sm="12" md="6" lg="4" xl="3" style={{  borderRadius: 20, marginTop: 30, marginBottom: 30 }}>
                <Link to={`${match.path}/add`} style={{ 
                        color: (history.location.pathname === `${match.path}/add` || history.location.pathname === `${match.path}`) ? '#fff' : '#545454', 
                        textDecoration:'none', 
                         
                    }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: (history.location.pathname === `${match.path}/add` || history.location.pathname === `${match.path}`) ? '#4AAD47' : '#fff'  }}>
                        <Card.Body style={{ display: 'flex', alignItems:'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <span className="headerService">ADD SLIDER</span>
                            
                        </Card.Body>
                    
                    </Card>
                </Link>
            </Col>
            <Col xs="12" sm="12" md="6" lg="4" xl="3" style={{  borderRadius: 20, marginTop: 30, marginBottom: 30 }}>
                <Link to={`${match.path}/settings`} style={{ 
                    color: history.location.pathname === `${match.path}/settings` ? '#fff' : '#545454', 
                    textDecoration:'none', 
                    
                 }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: history.location.pathname === `${match.path}/settings` ? '#4AAD47' : '#fff' }}>
                        <Card.Body style={{ display: 'flex', alignItems:'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <span className="headerService">SLIDER SETTINGS</span>
                            
                        </Card.Body>
                    
                    </Card>
                </Link>
            </Col>
            
        </Row> 
            <Route path={`${match.path}`} exact>
                <AddSlider/>   
            </Route>
            <Route path={`${match.path}/add`} exact>
                <AddSlider/>   
            </Route>
            <Route path={`${match.path}/settings`} exact>
                <SliderSettings/>   
            </Route>
            
        
        
        
         
        </Layout>
       
    )
}

export default Sliders
