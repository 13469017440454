import React, { Fragment, useState } from 'react'
import { Card, Col,Row } from 'react-bootstrap'
import Header from '../../components/Header'
import Layout from '../layout'
import './style.scss'
import StoreReviews from './StoreReviews'
import ProductReviews from './ProductReviews'
import DeliveryReviews from './DeliveryReviews'
import { Route, Link, useRouteMatch } from 'react-router-dom'



const Review = ({history}) =>  {

    //const history = createBrowserHistory();

    const match = useRouteMatch()

    console.log({history})

    //const path = history.location.pathname;

    return (
        
        <Layout>
        <Header headerText="REVIEW MANAGEMENT" />
        <Row style={{  width: "100%" }}>
            <Col xs="12" sm="12" md="6" lg="4" xl="3" style={{  borderRadius: 20, marginTop: 30, marginBottom: 30 }}>
                <Link to={`${match.path}/storereview`} style={{ 
                        color: (history.location.pathname === `${match.path}/storereview` || history.location.pathname === `${match.path}`) ? '#fff' : '#545454', 
                        textDecoration:'none', 
                         
                    }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: (history.location.pathname === `${match.path}/storereview` || history.location.pathname === `${match.path}`) ? '#4AAD47' : '#fff', justifyContent:'center', alignItems:'center'  }}>
                            <span className="headerReview">STORE REVIEW</span>
                    
                    </Card>
                </Link>
            </Col>
            <Col xs="12" sm="12" md="6" lg="4" xl="3" style={{  borderRadius: 20, marginTop: 30, marginBottom: 30 }}>
                <Link to={`${match.path}/productreview`} style={{ 
                    color: history.location.pathname === `${match.path}/productreview` ? '#fff' : '#545454', 
                    textDecoration:'none', 
                    
                 }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: history.location.pathname === `${match.path}/productreview` ? '#4AAD47' : '#fff', justifyContent:'center', alignItems:'center'  }}>
                            <span className="headerReview">PRODUCT REVIEWS</span>
                    </Card>
                </Link>
            </Col>
            <Col xs="12" sm="12" md="6" lg="4" xl="3" style={{  borderRadius: 20, marginTop: 30, marginBottom: 30 }}>
                <Link to={`${match.path}/deliveryreview`} style={{ 
                    color: history.location.pathname === `${match.path}/deliveryreview` ? '#fff' : '#545454', 
                    textDecoration:'none', 
                    
                 }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: history.location.pathname === `${match.path}/deliveryreview` ? '#4AAD47' : '#fff', justifyContent:'center', alignItems:'center'  }}>
                            <span className="headerReview">DELIVERY BOY REVIEWS</span>
                    </Card>
                </Link>
            </Col>

        </Row> 
            <Route path={`${match.path}`} exact>
                <StoreReviews/>   
            </Route>
            <Route path={`${match.path}/storereview`} exact>
                <StoreReviews/>   
            </Route>
            <Route path={`${match.path}/productreview`} exact>
                <ProductReviews/>   
            </Route>
            <Route path={`${match.path}/deliveryreview`} exact>
                <DeliveryReviews/>   
            </Route>
            
        
        
         
        </Layout>
       
    )
}

export default Review
