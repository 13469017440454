import reactotron from "../../ReactotronConfig";
import { 
    LOADING 
} from "../constants/userConstants"
import axios from '../../CustomAxios'
import { 
    DELETE_UNIT_FAIL,
    DELETE_UNIT_SUCCESS,
    NEW_UNIT_FAIL, 
    NEW_UNIT_SUCCESS, 
    UNIT_LIST_FAIL, 
    UNIT_LIST_SUCCESS,
    UPDATE_UNIT_FAIL,
    UPDATE_UNIT_SUCCESS
} from "../constants/unitConstants";



//GET unit
export const getUnits = () => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/units`)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })

        response.data.map(un => {
            un.label = un.unit;
            un.value = un._id;
        })
        
        dispatch({
            type: UNIT_LIST_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: UNIT_LIST_FAIL,
            payload: error
        })
    });
}

//SAVE unit
export const saveUnit = (formData) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/create-unit`, formData,{
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_UNIT_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_UNIT_FAIL,
            payload: error
        })
    });
}

//Update Unit
export const updateUnit = (formData) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/edit-unit`, formData,{
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: UPDATE_UNIT_SUCCESS,
            payload: response.data.unit
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: UPDATE_UNIT_FAIL,
            payload: error
        })
    });
}


//Delete Unit By ID
export const deleteUnitById = (id) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.delete(`admin/delete-unit/${id}`)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: DELETE_UNIT_SUCCESS,
            payload: id
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: DELETE_UNIT_FAIL,
            payload: error
        })
    });
}
