import { 
    TIME_LIST_SUCCESS,
    TIME_LIST_FAIL, 
    RESET_TIME,
    NEW_TIME_SUCCESS,
    NEW_TIME_FAIL,
    DELETE_TIME_SUCCESS,
    DELETE_TIME_FAIL,
    TIME_UPDATE_SUCCESS,
    TIME_UPDATE_FAIL
} from "../constants/timeConstants";

export  const timeReducer = (state = {}, action) => {
    switch(action.type){

        case TIME_LIST_SUCCESS:
            return {
                ...state,
                timeList: action.payload
            }

        case TIME_LIST_FAIL:
            return {
                ...state,
                error: action.payload.message
            }
        case NEW_TIME_FAIL:
            return {
                ...state,
                error: action.payload.message
            }

        case RESET_TIME:
            return{
                ...state,
                error: null,
                timeSuccess: null,
                deleteSuccess:null,
                updateSuccess:null
            }

        

        case NEW_TIME_SUCCESS:
            return {
                ...state,
                timeList : state.timeList ? [...state.timeList, action.payload.time] : [ action.payload.time ],
                timeSuccess: true
            }
        case DELETE_TIME_FAIL:
            return {
                ...state,
                error: action.payload.message
            }
        case DELETE_TIME_SUCCESS:
            return {
                ...state,
                deleteSuccess: true
            }
        case TIME_UPDATE_SUCCESS:
            return {
                ...state,
                timeList: state.timeList.map(ti => ti._id === action.payload._id ? action.payload : ti),
                updateSuccess: true
            }
        case TIME_UPDATE_FAIL:
            return {
                ...state,
                error: action.payload.message
            }
        default: 
            return state;
    }
}