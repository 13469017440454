import React, { Fragment, useState } from 'react'
import { Card, Col,Row ,Form} from 'react-bootstrap'
import Header from '../../components/Header'
import Layout from '../layout'
import './style.scss'
import {BsArrowLeft} from 'react-icons/bs'
import { MdCheck, MdClose } from 'react-icons/md'
import {AiOutlineSearch} from 'react-icons/ai'
import TodayReport from './TodayReport'
import WeeklyReport from './WeeklyReport'
import MonthlyReport from './MonthlyReport'
import { Route, Link, useRouteMatch } from 'react-router-dom'
import {AiOutlineCheck} from 'react-icons/ai'


const Reports = ({history}) =>  {

    //const history = createBrowserHistory();

    const match = useRouteMatch()

    console.log({history})

    //const path = history.location.pathname;

    return (
        
        <Layout>
        <Header headerText="STORE ORDERS" />
        <Row style={{  width: "100%" }}>
            <Col xs="12" sm="12" md="6" lg="4" xl="3" style={{  borderRadius: 20, marginTop: 30, marginBottom: 30 }}>
                <Link to={`${match.path}/today`} style={{ 
                        color: (history.location.pathname === `${match.path}/today` || history.location.pathname === `${match.path}`) ? '#fff' : '#545454', 
                        textDecoration:'none', 
                         
                    }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: (history.location.pathname === `${match.path}/today` || history.location.pathname === `${match.path}`) ? '#4AAD47' : '#fff'  }}>
                        <Card.Body style={{ display: 'flex', alignItems:'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <span className="headerReport">TODAY'S REPORT</span>
                            
                        </Card.Body>
                    
                    </Card>
                </Link>
            </Col>
            <Col xs="12" sm="12" md="6" lg="4" xl="3" style={{  borderRadius: 20, marginTop: 30, marginBottom: 30 }}>
                <Link to={`${match.path}/weekly`} style={{ 
                    color: history.location.pathname === `${match.path}/weekly` ? '#fff' : '#545454', 
                    textDecoration:'none', 
                    
                 }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: history.location.pathname === `${match.path}/weekly` ? '#4AAD47' : '#fff' }}>
                        <Card.Body style={{ display: 'flex', alignItems:'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <span className="headerReport">WEEKLY REPORT</span>
                            
                        </Card.Body>
                    
                    </Card>
                </Link>
            </Col>
            <Col xs="12" sm="12" md="6" lg="4" xl="3" style={{  borderRadius: 20, marginTop: 30, marginBottom: 30 }}>
                <Link to={`${match.path}/monthly`} style={{ 
                    color: history.location.pathname === `${match.path}/monthly` ? '#fff' : '#545454', 
                    textDecoration:'none', 
                    
                 }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: history.location.pathname === `${match.path}/monthly` ? '#4AAD47' : '#fff' }}>
                        <Card.Body style={{ display: 'flex', alignItems:'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <span className="headerReport">MONTHLY REPORT</span>
                            
                        </Card.Body>
                    
                    </Card>
                </Link>
            </Col>
               
            <Card style={{  height: 58, width:'60%', borderRadius: 20, backgroundColor: history.location.pathname === `${match.path}/product` ? '#4AAD47' : '#fff' }}>
                        <Card.Body style={{ display: 'flex', alignItems:'center', flexDirection: 'row', justifyContent: 'space-between',paddingTop:10 }}>
                            <span className="headerService" style={{ whiteSpace:'nowrap' }}>CUSTOM DATE</span>
                            <span class="divider"> </span>
                            <Col>
                                <span className="headerService" style={{ whiteSpace:'nowrap' }}>FROM</span>
                            </Col>
                                        <Col>
                                           
                                             <Form.Control 
                                                        type="date"
                                                        id="day1"
                                                        // value={field.value} 
                                                        // onChange={field.onChange}
                                                        style={{ borderRadius: 10 , width:200,}}
                                                />
                                        </Col>
                            <Col>
                                <span className="headerService" style={{ whiteSpace:'nowrap' }}>To</span>
                            </Col>
                                        <Col>
                                                <Form.Control 
                                                        type="date"
                                                        id="day1"
                                                        // value={field.value} 
                                                        // onChange={field.onChange}
                                                        style={{ borderRadius: 10,width:200 }}
                                                />
                                        </Col>
                                        <Col>
                                        
                                                <span className="checkText">
                                                        <AiOutlineCheck   color="white" style={{ fontSize: 30 }} />
                                                </span>
                                        </Col>
                                        
                        </Card.Body>
                </Card>
            
            
            
        </Row> 
            <Route path={`${match.path}`} exact>
                <TodayReport/>   
            </Route>
            <Route path={`${match.path}/today`} exact>
                <TodayReport/>   
            </Route>
            <Route path={`${match.path}/weekly`} exact>
                <WeeklyReport/>   
            </Route>
            <Route path={`${match.path}/monthly`} exact>
                <MonthlyReport/>   
            </Route>
            
        
        
        
         
        </Layout>
       
    )
}

export default Reports
