import React, { useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { FaAngleRight } from 'react-icons/fa'

let today = [{
    id: 1,
    date: '12-05-2021',
    order: 12,
    revenue: 200
},
{
    id: 2,
    date: '12-05-2021',
    order: 12,
    revenue: 200
},
{
    id: 3,
    date: '12-05-2021',
    order: 12,
    revenue: 200
},
{
    id: 4,
    date: '12-05-2021',
    order: 12,
    revenue: 200
}];

let weekly = [{
    id: 1,
    date: '12-05-2021',
    order: 14,
    revenue: 250
},
{
    id: 2,
    date: '12-05-2021',
    order: 12,
    revenue: 200
},
{
    id: 3,
    date: '12-05-2021',
    order: 12,
    revenue: 200
},
{
    id: 4,
    date: '12-05-2021',
    order: 12,
    revenue: 200
}];

let monthly = [{
    id: 1,
    date: '12-05-2021',
    order: 16,
    revenue: 250
},
{
    id: 2,
    date: '12-05-2021',
    order: 12,
    revenue: 200
},
{
    id: 3,
    date: '12-05-2021',
    order: 12,
    revenue: 200
},
{
    id: 4,
    date: '12-05-2021',
    order: 12,
    revenue: 200
}];




const Revenue = () => {

    const [active, setActive] = useState('today')

    const renderDetails = () => {
        switch(active){
            case 'today':
                return(
                    <div style={{ marginBottom:15 }}>
                        {today.map((today, index) => (
                            <Row style={{ margin:5 }} key={index}>
                                <Col className="tableContent">{today.date}</Col>
                                <Col className="tableContent">{today.order}</Col>
                                <Col className="tableContent">{today.revenue}</Col>
                            </Row>
                        ))}
                    </div>
                )
            case 'weekly':
                return(
                    <div style={{ marginBottom:15 }}>
                        {weekly.map((today, index) => (
                            <Row style={{ margin:5 }} key={index}>
                                <Col className="tableContent">{today.date}</Col>
                                <Col className="tableContent">{today.order}</Col>
                                <Col className="tableContent">{today.revenue}</Col>
                            </Row>
                        ))}
                    </div>
                )
            case 'monthly':
                return(
                    <div style={{ marginBottom:15 }}>
                        {monthly.map((today, index) => (
                            <Row style={{ margin:5 }} key={index}>
                                <Col className="tableContent">{today.date}</Col>
                                <Col className="tableContent">{today.order}</Col>
                                <Col className="tableContent">{today.revenue}</Col>
                            </Row>
                        ))}
                    </div>
                )
            default:
                return(
                    <div style={{ marginBottom:15 }}>
                        {today.map((today, index) => (
                            <Row style={{ margin:5 }} key={index}>
                                <Col className="tableContent">{today.date}</Col>
                                <Col className="tableContent">{today.order}</Col>
                                <Col className="tableContent">{today.revenue}</Col>
                            </Row>
                        ))}
                    </div>
                )
        }
    }


    return (
        <Card style={{ borderRadius:20, marginTop: 20 }}>
            <Card.Body  style={{ display:'flex', flexDirection: 'column' }}>
                <span className="torder">Total</span>
                <span className="torder" style={{ fontSize: 20 }}>Revenue</span>
                <Row style={{ margin:5, marginTop:20 }}>
                    <Col className="tabHeader" 
                        onClick ={() => setActive('today')}
                        style={{ color: active === 'today' ? '#D42F45' : '#1B2F5B', cursor: 'pointer' }}>Today's Report</Col>
                    <Col 
                        className="tabHeader" 
                        onClick ={() => setActive('weekly')}
                        style={{ cursor: 'pointer', color: active === 'weekly' ? '#D42F45' : '#1B2F5B' }}
                        >Weekly Report</Col>
                    <Col 
                        className="tabHeader"
                        onClick ={() => setActive('monthly')}
                        style={{ cursor: 'pointer', color: active === 'monthly' ? '#D42F45' : '#1B2F5B' }}
                    >Monthly Report</Col>
                </Row>
                
                <Row style={{ backgroundColor: '#FFF2D5', margin:5, marginTop: 10 }}>
                    <Col className="tableHeader">Date</Col>
                    <Col className="tableHeader">Total Orders</Col>
                    <Col className="tableHeader">Total Revenue</Col>
                </Row>
                {renderDetails()}
                <span className="viewmore">View More <FaAngleRight /></span>
            </Card.Body>
        </Card>
    )
}
 
export default Revenue
