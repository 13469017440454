export const DELETE_SURPRISE_SUCCESS = 'DELETE_SURPRISE_SUCCESS';
export const DELETE_SURPRISE_FAIL = 'DELETE_SURPRISE_FAIL';

export const UPDATE_SURPRISE_SUCCESS = 'UPDATE_SURPRISE_SUCCESS';
export const UPDATE_SURPRISE_FAIL = 'UPDATE_SURPRISE_FAIL';

export const NEW_SURPRISE_SUCCESS = 'NEW_SURPRISE_SUCCESS';
export const NEW_SURPRISE_FAIL = 'NEW_SURPRISE_FAIL';

export const SURPRISE_LIST_SUCCESS = 'SURPRISE_LIST_SUCCESS';
export const SURPRISE_LIST_FAIL = 'SURPRISE_LIST_FAIL';

export const SURPRISE_DETAILS_SUCCESS = 'SURPRISE_DETAILS_SUCCESS';
export const SURPRISE_DETAILS_FAIL = 'SURPRISE_DETAILS_FAIL';

export const RESET_SURPRISE = 'RESET_SURPRISE';

export const SURPRISE_INPUT = 'SURPRISE_INPUT';