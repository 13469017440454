import reactotron from "../../ReactotronConfig";
import { 
    LOADING 
} from "../constants/userConstants"
import axios from '../../CustomAxios'
import { 
    NEW_SLIDER_FAIL,
    NEW_SLIDER_SUCCESS,
    SLIDER_LIST_FAIL, 
    SLIDER_LIST_SUCCESS, 
    DELETE_SLIDER_SUCCESS,
    DELETE_SLIDER_FAIL, 
    UPDATE_SLIDER_SUCCESS,
    UPDATE_SLIDER_FAIL,
    NEW_COST_FAIL,
    NEW_COST_SUCCESS,
    COST_LIST_FAIL, 
    COST_LIST_SUCCESS, 
    DELETE_COST_SUCCESS,
    DELETE_COST_FAIL, 
    UPDATE_COST_SUCCESS,
    UPDATE_COST_FAIL,
} from "../constants/sliderConstants";


export const getSliderLists = () => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/sliders`)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: SLIDER_LIST_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: SLIDER_LIST_FAIL,
            payload: error
        })
    });
}


//ADD NEW SLIDER
export const addNewSlider = (form_data) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    
    await axios.post(`admin/create-slider`, form_data,{
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_SLIDER_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_SLIDER_FAIL,
            payload: error
        })
    });
}


//Delete Slider
export const deleteSliderById = (id) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.delete(`admin/delete-slider/${id}`)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: DELETE_SLIDER_SUCCESS,
            payload: id
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: DELETE_SLIDER_FAIL,
            payload: error
        })
    });
}


//Edit SLIDER
export const updateSlider = (data) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.post(`admin/edit-slider`, data,{
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: UPDATE_SLIDER_SUCCESS,
            payload: response.data.slider
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: UPDATE_SLIDER_FAIL,
            payload: error
        })
    });
}


//Slider Settings
 //Get Cost
export const getCostLists = () => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/get-cost`)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: COST_LIST_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: COST_LIST_FAIL,
            payload: error
        })
    });
}


//ADD NEW COST
export const addNewCost = (form_data) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    
    await axios.post(`admin/add-cost`, form_data,{
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_COST_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_COST_FAIL,
            payload: error
        })
    });
}


//Delete COST
export const deleteCostById = (id) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.delete(`admin/delete-cost/${id}`)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: DELETE_COST_SUCCESS,
            payload: id
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: DELETE_COST_FAIL,
            payload: error
        })
    });
}


//Edit COST
export const updateCost = (data) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.post(`admin/edit-cost`, data,{
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: UPDATE_COST_SUCCESS,
            payload: response.data.cost
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: UPDATE_COST_FAIL,
            payload: error
        })
    });
}
