import React, { Fragment, useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import {MdEdit} from 'react-icons/md'
import {AiFillDelete,AiFillEye} from 'react-icons/ai'
import {BsToggle2On,BsToggle2Off, BsCheck2} from 'react-icons/bs'
import {IoMdAddCircle,IoMdAdd}from 'react-icons/io'
import { FaTimes } from 'react-icons/fa'
import Pagination from 'react-paginating';
import Search from '../../components/Search'
import Paging from '../../components/Paging'
import NewUser from './NewUser'
import Modals from '../../components/Modals'
import Buttons from '../../components/Buttons'
import { useSelector, useDispatch } from 'react-redux'
import { getUserLists ,deleteUserListById } from '../../Redux/actions/userActions'
import { toast } from 'react-toastify'
import { CLEAR_USER, RESET_USER } from '../../Redux/constants/userConstants'
import moment from 'moment'
import reactotron from '../../ReactotronConfig'
import EditUser from './EditUser'
import './user.scss'



const UserList = () => {

    const { userList, error, userSuccess,deleteSuccess, updateSuccess } = useSelector(state => state.user)

    const dispatch = useDispatch();

    const [modalShow, setModalShow] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [modalShowEdit, setModalShowEdit] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [datas, setDatas] = useState([])
    const [headerColor, setHeaderColor] = useState('')
    const [headerTitle, setHeaderTitle] = useState('')
    const [search, setSearch] = useState('')
    const [selectedUser, setselectedUser] = useState('')
    const [role, setRole] = useState('admin')

    const [searchResult, setSearchResult] = useState([])

    useEffect(() => {
        console.log({userList})
        if(userList && userList.length>0){
            
            const indexOfLastPost = currentPage * 10;
            const indexOfFirstPost = indexOfLastPost - 10;
            const lists = userList.filter((users) => users?.role?.toLowerCase() === role.toLowerCase())
            const searchResult = lists.filter((users) => users?.name?.toLowerCase().includes(search.toLowerCase()) || users.email.toLowerCase().includes(search.toLowerCase())
            )
            setSearchResult(searchResult)
            const currentPosts = (searchResult || []).slice(indexOfFirstPost, indexOfLastPost);
            console.log(currentPosts)
            setDatas(currentPosts)
        }
    }, [userList, search, role])

    const pageChanged = (page)=> {
        setCurrentPage(page)
        const indexOfLastPost = page * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const lists = userList.filter((users) => users?.role?.toLowerCase() === role.toLowerCase())
        const searchResult = lists.filter(users => users?.name?.toLowerCase().contains(search.toLowerCase()) || users?.email?.toLowerCase().contains(search.toLowerCase()))
        setSearchResult(searchResult)
        const currentPosts = (searchResult || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }

    

    useEffect(() => {
        if(deleteSuccess){
            //dispatch(getUserLists())
            setDeleteModalShow(false);
            dispatch({
                type: CLEAR_USER
            })
            toast.success("User Deleted Successfully");
        }

        if(updateSuccess){
            setModalShowEdit(false)
            dispatch({
                type: CLEAR_USER
            })
            toast.success("User Updated Successfully");
        }
    }, [deleteSuccess, updateSuccess])

    useEffect(() => {
        dispatch(getUserLists())
    }, [])
    const deleteUserById = () => {
        dispatch(deleteUserListById(selectedUser))
    }

    const createNewUser = () => {
        setHeaderColor('#D42F45');
        if(role === 'admin'){
            setHeaderTitle("ADD ADMIN");
        }
        else if(role === 'franchisee'){
            setHeaderTitle("ADD FRANCHISEE");
        }
        else if(role === 'store'){
            setHeaderTitle("ADD STORE MANAGER");
        }
        else{
            setHeaderTitle("ADD DELIVERY BOY");
        }
        setModalShow(true);
    }

    const viewUserDetails = () => {
        setHeaderColor('#417AE8');
        setHeaderTitle("VIEW USER");
        setModalShow(true);
    }
    const editUser = (user) => {
        setselectedUser(user);
        setHeaderColor('#FF9900');
        setHeaderTitle("EDIT USER");
        setModalShowEdit(true);
    }

    const deleteUser = (id) => {
        setselectedUser(id);
        setHeaderColor('#D42F45');
        setHeaderTitle("DELETE USER");
        setDeleteModalShow(true);
    }

    return (
        <Fragment>
            <Row style={{  width: "100%" }}>
                <Col onClick={()=> setRole('admin')} style={{  borderRadius: 20, marginTop: 30, marginBottom: 30, cursor: 'pointer' }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: role === 'admin' ? '#4AAD47' : '#fff', justifyContent:'center'  }}>
                            <span className="headerService" style={{color:  role === 'admin' ? '#fff' : '#545454' }}>ADMIN</span>
                    
                    </Card>
                </Col>
                <Col onClick={()=> setRole('franchisee')} style={{  borderRadius: 20, marginTop: 30, marginBottom: 30, cursor: 'pointer' }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: role === 'franchisee' ? '#4AAD47' : '#fff', justifyContent:'center'  }}>
                            <span className="headerService" style={{color:  role === 'franchisee' ? '#fff' : '#545454' }}>FRANCHISEE</span>
                    
                    </Card>
                </Col>
                <Col onClick={()=> setRole('store')} style={{  borderRadius: 20, marginTop: 30, marginBottom: 30, cursor: 'pointer' }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: role === 'store' ? '#4AAD47' : '#fff', justifyContent:'center'  }}>
                            <span className="headerService" style={{color:  role === 'store' ? '#fff' : '#545454' }}>STORE MANAGER</span>
                    </Card>
                </Col>
                <Col onClick={()=> setRole('delivery-boy')} style={{  borderRadius: 20, marginTop: 30, marginBottom: 30, cursor: 'pointer' }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: role === 'delivery-boy' ? '#4AAD47' : '#fff', justifyContent:'center'  }}>
                            <span className="headerService" style={{color:  role === 'delivery-boy' ? '#fff' : '#545454' }}>DELIVERY BOY</span>
                    </Card>
                </Col>
                <Search placeHolder="SEARCH USER" searchValue={search} searchChanged={e => setSearch(e.target.value)} />
            </Row>
            
            <Card style={{ borderRadius: 20, margin: 10, minHeight: '70vh' }}>
                {role !='franchisee' && <IoMdAddCircle class="add" fontSize="3.5em" 
                onClick={createNewUser}
                style={{ position: 'absolute', top: -30, right: -20, cursor: 'pointer' }} />}
                <Card.Body style={{ overflowX: 'auto' }}>
                    <table>
                        <thead class="tableHeader">
                            
                            <th>Name</th>
                            <th>Email Address</th>
                            <th>Phone</th>
                            <th>Employee ID</th>
                            {role === 'franchisee' && <th>Location</th>}
                            {(role === 'store' || role === 'delivery-boy') && <th>Franchisee</th>}
                            <th>Last Login</th>
                            <th>Status</th>
                            <th>Action</th>
                        </thead>
                        <tbody>
                            {datas && datas.map((user, index) => (
                                <tr key={index}>
                                    
                                    <td class="tableContent">{user.name}</td>
                                    <td class="tableContent">{user.email}</td>
                                    <td class="tableContent">{user.mobile}</td>
                                    <td class="tableContent">{user._id}</td>
                                    {role === 'franchisee' && <th>Location</th>}
                                    {(role === 'store' || role === 'delivery-boy') && <td class="tableContent">{user.franchisee?.name}</td>}
                                    <td class="tableContent">{user.lastlogin}</td>
                                    <td class="tableContent">
                                        {user.status === 'Active' ? <BsToggle2On color="#0DD404" fontSize="1.5em"/> : <BsToggle2Off fontSize="1.5em" color="#F95555" />}
                                    </td>
                                    <td class="tableContent">
                                        
                                        <MdEdit color="orange" onClick={() => editUser(user)}/>
                                        <AiFillDelete class="add" onClick={() => deleteUser(user._id)}/>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Card.Body>
                <Col style={{ float: 'right', display:'flex', flexDirection: 'row-reverse', justifyContent: 'flex-start', alignItems: 'center', margin: 30  }}>
                <Card >
                    {searchResult && searchResult.length>10 && <Paging datas={searchResult} currentPage={currentPage} pageChange={pageChanged} />}
                </Card>
                </Col>
            </Card>
            {modalShowEdit && <EditUser openModal={modalShowEdit} closeModal={()=> setModalShowEdit(false)} headerColor={headerColor} headerTitle={headerTitle} role={role} userlist={selectedUser}  />}
           
            <NewUser openModal={modalShow} closeModal={()=> setModalShow(false)} headerColor={headerColor} headerTitle={headerTitle} role={role} />
            <Modals showModal={deleteModalShow} hideModal={()=> setDeleteModalShow(false)} modalTitle={headerTitle} headerColor={headerColor}>
                <p className="deleteText">Are you sure you want to delete this user?</p>
                <div style={{ display: 'flex', alignItems:'center', justifyContent:'center' }}>
                        <Buttons backColor="#4AAD47" buttonText="YES" buttonAction={deleteUserById}>
                            <BsCheck2 style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                        <Buttons backColor="#D42F45" buttonText="No" buttonAction={()=> setDeleteModalShow(false)}>
                            <FaTimes style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                </div>
            </Modals>
        </Fragment>
    )
}

export default UserList
