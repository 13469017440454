import React, { useState, useEffect, useRef } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import Modals from '../../components/Modals'
import {IoMdAdd}from 'react-icons/io'
import Buttons from '../../components/Buttons'
import { useSelector, useDispatch } from 'react-redux'
import {Formik} from 'formik';
import InputField from '../../components/InputField'
import * as Yup from 'yup';
import "yup-phone";
import { getCategoryLists } from '../../Redux/actions/categoryActions'
import SelectInput from '../../components/SelectInput'
import SwitchInput from '../../components/SwitchInput'
import { saveNewProduct } from '../../Redux/actions/productActions'
import TagInput from '../../components/TagInput'
import SelectNew from '../../components/SelectNew'

const ViewProduct = ({openModal, closeModal, headerColor, headerTitle, product}) => {

    const tags = ["Best Seller", "Recommended", "Trending"]

    const [units, setUnits] = useState([])
    const [category, setCategory] = useState('')

    const dispatch = useDispatch();

    const { categoryLists, error } = useSelector(state => state.category)

    const [image, setImage] = useState(null);

    const proForm = useRef(null)

    const [initialValues, setInitialValues] = useState('')

    useEffect(() => {
        if(product && categoryLists.length>0){
            let cat = null;
            if(product?.category?._id){
                cat = categoryLists.find(cat => cat._id === product?.category._id)
                setUnits(cat.units)
            }
            let unit = null;
            if(product.unit._id){
                let units = cat.units.find(un => un._id === product.unit._id)
                units.lable = product?.unit.unit;
                units.value = product?.unit.unit;
                unit = units;
            }
            else{
                let unitdecoded = JSON.parse(product?.unit);
                let units = cat.units.find(un => un._id === unitdecoded._id)
                units.lable = product?.unit.unit;
                units.value = product?.unit.unit;
                unit = units;
            }
            let initialValues = {
                name : product?.name,
                category: cat,
                unit : unit,
                description: product?.description,
                cost_price: product?.cost_price,
                selling_price: product?.selling_price,
                offer_price: product?.offer_price,
                status: product?.status.toLowerCase() === 'active' ? true : false
            }

            console.log({initialValues})
            setInitialValues(initialValues)
        }
        
    }, [product, categoryLists])



    useEffect(() => {
        dispatch(getCategoryLists())
    }, [])

    useEffect(() => {
        console.log({category})
        categoryLists && categoryLists.map(cat => {
            if(cat._id === category){
                setUnits(cat.units)
            }
        })
    }, [category])





    const categoryChange = (value, setFieldValue) => {
        console.log(value)
        let units = value.units.map(unit => {
            return { ...unit, lable: unit.unit, value: unit.unit }
        })
        setUnits(units)
        setFieldValue('category', value)
    }

    

    return (
        <Modals showModal={openModal} hideModal={closeModal} headerColor={headerColor} modalTitle={headerTitle}>
            {initialValues && <Formik 
                innerRef={proForm}
                initialValues={initialValues}
                validationSchema={
                    Yup.object({
                        name: Yup.string().required('Name Required'),
                        category: Yup.object().required('Category Name Required'),
                        unit: Yup.object().required('Unit name is Required'),
                        cost_price: Yup.string().required("Cost Price is Required"),
                        selling_price: Yup.string().required("Selling price is required"),
                    })
                }
                onSubmit={(values) => {
                    console.log(values)
                }}
                render={({handleChange, handleSubmit, handleBlur, values, errors, setFieldTouched, setFieldValue, touched}) => (
                    <Form>
                        <Row>
                            <Col md="8">
                                <Row>
                                    <InputField fieldName="name" fieldLabel="ProductName" md="6" readOnly />
                                    <SelectNew 
                                        md="6"
                                        fieldName="category"
                                        fieldLabel="Product Category"
                                        options={categoryLists}
                                        isMultiple={false}
                                        value={values.category}
                                        onChange={(fieldName, value)=> categoryChange(value, setFieldValue)}
                                        onBlur={setFieldTouched}
                                        error={errors.category}
                                        touched={touched.category}
                                        readOnly={true}
                                    />
                                    <InputField fieldName="description" fieldLabel="Product Description" fieldType="text" md="12" readOnly />
                                </Row>
                            </Col>
                            <Col md="3">
                                <Form.Group as={Col} xs="12" sm="12" md="9" controlId="formGridImage" className="formStyle">
                                    <Form.Label className="formLabel">Store Image</Form.Label>
                                    <div className="imageStyles">
                                        {product.image ? <img src={product.image} style={{ width: 200, height: 120 }} /> : <span style={{ position: 'absolute', bottom: 50 }}>512 x 512</span>}
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <InputField fieldName="cost_price" fieldLabel="Cost Price" md="4" readOnly />
                            <InputField fieldName="selling_price" fieldLabel="Selling Price" md="4" readOnly />
                            <InputField fieldName="offer_price" fieldLabel="Offer Price" md="4" readOnly />
                            <SelectNew 
                                md="4"
                                fieldName="unit"
                                fieldLabel="Unit"
                                options={units}
                                isMultiple={false}
                                value={values.unit}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                error={errors.unit}
                                touched={touched.unit}
                                readOnly={true}
                            />
                            <TagInput fieldName="tags" fieldLabel="Tags" md="4"  tags={product.tags ? product.tags : []} readOnly />
                            <SwitchInput fieldName="status" fieldLabel="Active Status" readOnly={true} />
                        </Row>
                    </Form>
                )}
            />}
            
        </Modals>
    )
}

export default ViewProduct
