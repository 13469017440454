import React, { Fragment, useState } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import Header from '../../components/Header'
import Layout from '../layout'
import CategoryList from './CategoryList'

const Category = () => {

    const match = useRouteMatch();

    console.log(match.path)

    return (
        <Layout>
            <Route path={`${match.path}`} exact>
                <Header headerText="CATEGORY MANAGEMENT" />
                <CategoryList />
            </Route>
            <Route path={`${match.path}/edit`} >
            </Route>
        </Layout>
    )
}

export default Category
