import React, { Fragment, useEffect, useState } from 'react'
import { Card, Row, Form, Col, InputGroup } from 'react-bootstrap'
import {MdEdit,MdUpdate} from 'react-icons/md'
import {AiFillDelete,AiFillEye} from 'react-icons/ai'
import {BsToggle2On,BsToggle2Off, BsCheck2} from 'react-icons/bs'
import { MdCheck, MdClose } from 'react-icons/md'
import {IoMdAddCircle,IoMdAdd}from 'react-icons/io'
import { FaTimes } from 'react-icons/fa'
import Paging from '../../components/Paging'
import Modals from '../../components/Modals'
import Buttons from '../../components/Buttons'

import reactotron from '../../ReactotronConfig'
import { useSelector, useDispatch } from 'react-redux'
import { addNewSubscription } from '../../Redux/actions/subscriptionActions'
import { getSubscriptionLists ,deleteSubscriptionById} from '../../Redux/actions/subscriptionActions'
import { toast } from 'react-toastify'
import { RESET_SUBSCRIPTION } from '../../Redux/constants/serviceConstants'
import Select from 'react-select';
import {Formik, Field} from 'formik';
import EditSubscriptionFee from './EditSubscriptionFee'
import InputField from '../../components/InputField'
import IconInput from '../../components/IconInput'
import FormSelectField from '../../components/form-select-field'
import * as Yup from 'yup';
import "yup-phone";


const SubscriptionFee = () => {
    const { subscriptionFee, error, subscriptionSuccess ,deleteSubscription} = useSelector(state => state.subscription)
    const dispatch = useDispatch();
    const { loading } = useSelector(state => state.auth)

    const [modalShow, setModalShow] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [modalShowEdit, setModalShowEdit] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [datas, setDatas] = useState([])
    const [headerColor, setHeaderColor] = useState('')
    const [headerTitle, setHeaderTitle] = useState('')
    const [selectedService, setselectedService] = useState('')

    useEffect(() => {
        const indexOfLastPost = currentPage * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const currentPosts = (subscriptionFee || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }, [subscriptionFee])

    const pageChanged = (page)=> {
        setCurrentPage(page)
        const indexOfLastPost = page * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const currentPosts = (subscriptionFee || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }

    useEffect(() => {
        if(error){
            toast.error(error);
            dispatch({
                type: RESET_SUBSCRIPTION
            })
        }
    }, [error])
    useEffect(() => {
        if(subscriptionSuccess){
            setModalShow(false);
            dispatch({
                type: RESET_SUBSCRIPTION
            })
            toast.success("New Subscription Added Successfully");
        }
        if(deleteSubscription){
            dispatch(getSubscriptionLists())
            setDeleteModalShow(false);
            dispatch({
                type: RESET_SUBSCRIPTION
            })
            toast.success("Subscription fee Deleted Successfully");
        }
    }, [subscriptionSuccess,deleteSubscription])
    useEffect(() => {
        dispatch(getSubscriptionLists())
    }, [])
    const deleteServiceById = () => {
        dispatch(deleteSubscriptionById(selectedService))
    }

    const createNewSubscription = () => {
        setHeaderColor('#D42F45');
        setHeaderTitle("ADD SUBSCRIPTION")
        setModalShow(true);
    }
    const editSubscription = (subscription) => {
        setselectedService(subscription);
        setHeaderColor('#FF9900');
        setHeaderTitle("EDIT SUBSCRIPTION")
        setModalShowEdit(true);
    }

    const deleteService = (id) => {
        setselectedService(id);
        setHeaderColor('#D42F45');
        setHeaderTitle("DELETE SUBSCRIPTION");
        setDeleteModalShow(true);
    }

    const saveSubscription =  (values) =>  {


        console.log({values})

        const { provider_name, category,phone_number,city,subscription_fee,expiry_date } = values
        dispatch(addNewSubscription(provider_name, category,phone_number,city,subscription_fee,expiry_date));
       
    }
    return (
        <Fragment>
            
            <Card style={{ borderRadius: 20, margin: 10, minHeight: '70vh' }}>
                <Card.Body>
                <center><h4>Coming Soon</h4></center>
                {/*<IoMdAddCircle class="add" fontSize="3.5em" 
                onClick={createNewSubscription}
                style={{ position: 'absolute', top: -30, right: -20, cursor: 'pointer' }} />
                <Card.Body style={{ overflowX: 'auto' }}>
                    <table>
                        <thead class="tableHeader">
                            <th>Name</th>
                            <th>Category Name</th>
                            <th>City</th>
                            <th>Phone Number</th>
                            <th>Subscription Fee (Per Month)</th>
                            <th>Expiry Date</th>
                            <th>Actions</th>
                        </thead>
                        <tbody>
                            {datas && datas.map((subscription, index) => (
                                <tr key={index}>
                                    <td class="tableContent">{subscription.provider_name}</td>
                                    <td class="tableContent">{subscription.category}</td>
                                    <td class="tableContent">{subscription.city}</td>
                                    <td class="tableContent">{subscription.phone_number}</td>
                                    <td class="tableContent">{subscription.subscription_fee}</td>
                                    <td class="tableContent">{subscription.expiry_date}</td>
                                    <td class="tableContent">
                                        <MdEdit color="orange" onClick={() => editSubscription(subscription)}/>
                                        <AiFillDelete class="add" onClick={() => deleteService(subscription._id)}/>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Card.Body>
                <Col style={{ float: 'right', display:'flex', flexDirection: 'row-reverse', justifyContent: 'flex-start', alignItems: 'center', margin: 30  }}>
                <Card >
                    {subscriptionFee && subscriptionFee.length>10 && <Paging datas={subscriptionFee} currentPage={currentPage} pageChange={pageChanged} />}
                </Card>
                            </Col>*/}
                </Card.Body>
            </Card>
            {modalShowEdit && <EditSubscriptionFee openModal={modalShowEdit} closeModal={()=> setModalShowEdit(false)} headerColor={headerColor} headerTitle={headerTitle} servicelist={selectedService}  />}
           
            <Modals showModal={deleteModalShow} hideModal={()=> setDeleteModalShow(false)} modalTitle={headerTitle} headerColor={headerColor}>
                <p className="deleteText" >Are you sure you want to delete this Subscription Fee?</p>
                <div style={{ display: 'flex', alignItems:'center', justifyContent:'center' }}>
                        <Buttons backColor="#4AAD47" buttonText="YES" buttonAction={deleteServiceById}>
                            <BsCheck2 style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                        <Buttons backColor="#D42F45" buttonText="No" buttonAction={()=> console.log("Hello")}>
                            <FaTimes style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                </div>
            </Modals>
            <Modals  showModal={modalShow} hideModal={()=> setModalShow(false)} headerColor={headerColor} modalTitle={headerTitle} >
            <Formik 
                initialValues={{
                    provider_name: '',
                    category: '',
                    phone_number: '',
                    city: '',
                    subscription_fee: '',
                    expiry_date: '',
                    
                }}
                validationSchema={
                    
                    Yup.object({
                        provider_name: Yup.string().min(5, 'Must be 5 characters or more').required('Name Required'),
                        category: Yup.string().required('Category Required'),
                        phone_number: Yup.string().phone("IN").required('Phone Number is Required'),
                        city: Yup.string().required("City is Required"),
                        subscription_fee: Yup.string().required("Subscription Fee is required"),
                        expiry_date: Yup.string().required('Expiry Date is Required'),
                        
                    })
                }
                onSubmit={(values) => {
                    console.log(values)
                    saveSubscription(values)
                }}
                render={({handleChange, handleSubmit, handleBlur, values, errors}) => (
            <Form>  
            <Row>
                <InputField fieldName="category" fieldLabel="Category" fieldType="text" />
                <InputField fieldName="provider_name" fieldLabel="Service Provider Name" />
                <InputField fieldName="city" fieldLabel="City" fieldType="text" />
            </Row>
            <Row>
                <InputField fieldName="phone_number" fieldLabel="Phone Number" fieldType="text" />
                <InputField fieldName="subscription_fee" fieldLabel="Subscription Fee (Per Month)" fieldType="text" />
                <InputField fieldName="expiry_date" fieldLabel="Expiry Date" fieldType="text" />
            </Row>
            
            {headerTitle === 'ADD SUBSCRIPTION' && <Row>
                <center>
                    <Buttons backColor="#D42F45" buttonText="ADD" buttonAction={handleSubmit}>
                        <IoMdAdd style={{color:'#FFFFFF'}} fontSize="2.3em" />
                    </Buttons>
                </center>
            </Row>}
            {headerTitle === 'EDIT SUBSCRIPTION' && <Row>
                <center>
                    <Buttons backColor="#FF9900" buttonText="UPDATE" buttonAction={()=> console.log("pressed")}>
                            <MdUpdate style={{color:'#FFFFFF'}} fontSize="2.3em" />
                    </Buttons>
                </center>
            </Row>}
            </Form>
            )}
            />
        </Modals>
 
        </Fragment>
    )
}

export default SubscriptionFee
