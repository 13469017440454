import React from 'react'
import Modal from 'react-bootstrap/Modal'
import CloseButton from 'react-bootstrap/CloseButton'
import './style.scss'


const Modals = ({showModal, hideModal, modalTitle, children, headerColor, modalSize, className}) => {
    return (
        <Modal 
            show={showModal} 
            onHide={hideModal}
            dialogClassName={className}
            size={ modalSize ? modalSize : "lg" }
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header
                className="modalHeader" style={{ color: '#fff', backgroundColor: headerColor, margin: 0 }}>
                <Modal.Title className="modalTitle">{modalTitle}</Modal.Title>
                <CloseButton variant="white" onClick={hideModal} />
            </Modal.Header> 
            <Modal.Body> 
                {children}
            </Modal.Body>
            
        </Modal>
    )
}

export default Modals
