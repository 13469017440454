import reactotron from "../../ReactotronConfig";
import { 
    LOADING 
} from "../constants/userConstants"
import axios from '../../CustomAxios'
import { 
    
    STORE_REVIEW_FAIL, 
    STORE_REVIEW_SUCCESS, 
    PRODUCT_REVIEW_FAIL, 
    PRODUCT_REVIEW_SUCCESS,
    DELIVERYBOY_REVIEW_FAIL, 
    DELIVERYBOY_REVIEW_SUCCESS, 
    DELETE_STORE_REVIEW_SUCCESS,
    DELETE_STORE_REVIEW_FAIL,
    DELETE_PRODUCT_REVIEW_SUCCESS,
    DELETE_PRODUCT_REVIEW_FAIL,
    DELETE_DELIVERYBOY_REVIEW_SUCCESS,
    DELETE_DELIVERYBOY_REVIEW_FAIL
    
} from "../constants/reviewConstants";

// GET STORE Review
export const getStoreReview = () => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/store-reviews`)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: STORE_REVIEW_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: STORE_REVIEW_FAIL,
            payload: error
        })
    });
}

//GET Product Review
export const getProductReview = () => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/product-reviews`)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: PRODUCT_REVIEW_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: PRODUCT_REVIEW_FAIL,
            payload: error
        })
    });
}


//GET DELIVERY BOY Review
export const getDeliveryboyReview = () => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/delivery-reviews`)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: DELIVERYBOY_REVIEW_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: DELIVERYBOY_REVIEW_FAIL,
            payload: error
        })
    });
}

//Delete STORE REVIEW
export const deleteStoreReviewById = (id) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.delete(`admin/delete-store-review/${id}`)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: DELETE_STORE_REVIEW_SUCCESS,
            payload: id
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: DELETE_STORE_REVIEW_FAIL,
            payload: error
        })
    });
}

//Delete PRODUCT REVIEW
export const deleteProductReviewById = (id) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.delete(`admin/delete-product-review/${id}`)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: DELETE_PRODUCT_REVIEW_SUCCESS,
            payload: id
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: DELETE_PRODUCT_REVIEW_FAIL,
            payload: error
        })
    });
}

//Delete DELIVERY BOY REVIEW
export const deleteDeliveryboyReviewById = (id) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.delete(`admin/delete-delivery-review/${id}`)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: DELETE_DELIVERYBOY_REVIEW_SUCCESS,
            payload: id
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: DELETE_DELIVERYBOY_REVIEW_FAIL,
            payload: error
        })
    });
}

