import { 
    ORDER_LIST_SUCCESS,
    ORDER_LIST_FAIL, 
    RESET_ORDER,
    NEW_ORDER_SUCCESS,
    NEW_ORDER_FAIL,
    CHANGE_ORDER_INPUT
} from "../constants/orderConstants";

export  const orderReducer = (state = { orderTypes: [] }, action) => {
    switch(action.type){

        case ORDER_LIST_SUCCESS:
            return {
                ...state,
                orderList: action.payload
            }

        case ORDER_LIST_FAIL:
        case NEW_ORDER_FAIL:
            return {
                ...state,
                error: action.payload.message
            }

        case RESET_ORDER:
            return{
                ...state,
                error: null,
                newOrderType: null,
                orderSuccess: null
            }

        case CHANGE_ORDER_INPUT:
            return {
                ...state,
                [action.payload.prop] : action.payload.value
            }

        
        case NEW_ORDER_SUCCESS:
            return {
                ...state,
                orderList : state.orderList ? [...state.orderList, action.payload.order] : [ action.payload.order ],
                orderSuccess: true
            }


        default: 
            return state;
    }
}