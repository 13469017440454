import { 
    SURPRISE_LIST_SUCCESS,
    SURPRISE_LIST_FAIL, 
    RESET_SURPRISE,
    NEW_SURPRISE_SUCCESS,
    NEW_SURPRISE_FAIL,
    DELETE_SURPRISE_SUCCESS,
    DELETE_SURPRISE_FAIL,
    UPDATE_SURPRISE_SUCCESS,
    UPDATE_SURPRISE_FAIL,
    SURPRISE_DETAILS_SUCCESS,
    SURPRISE_DETAILS_FAIL,
    SURPRISE_INPUT
} from "../constants/surpriseConstants";
export  const surpriseReducer = (state = {}, action) => {
    switch(action.type){
        case SURPRISE_LIST_SUCCESS:
            return {
                ...state,
                surpriseLists: action.payload
            }

        case SURPRISE_LIST_FAIL:
        case UPDATE_SURPRISE_FAIL:
        case DELETE_SURPRISE_FAIL:
        case NEW_SURPRISE_FAIL:
        case SURPRISE_DETAILS_FAIL:
            return {
                ...state,
                error: action.payload.message
            }

        case DELETE_SURPRISE_SUCCESS:
            return {
                ...state,
                surpriseLists: state.surpriseLists.filter(sur => sur._id !== action.payload ),
                deleteSurprise: true
            }


        case UPDATE_SURPRISE_SUCCESS:
            return {
                ...state,
                surpriseLists: state.surpriseLists.map(sur => sur._id === action.payload._id ? action.payload : sur),
                updateSuccess: true
            }



        case RESET_SURPRISE:
            return{
                ...state,
                error: null,
                surpriseSuccess: null,
                deleteSurprise: null,
                updateSuccess:null
            }

        

        case NEW_SURPRISE_SUCCESS:
            return {
                ...state,
                surpriseLists : state.surpriseLists ? [...state.surpriseLists, action.payload.surprise] : [ action.payload.surprise ],
                surpriseSuccess: true
            }

        case SURPRISE_INPUT:
            return {
                ...state,
                [action.payload.prop] : action.payload.value
            }

        case SURPRISE_DETAILS_SUCCESS:
            return {
                ...state,
                surpriseDetails: action.payload
            }

        default:
            return state;
    }
}