import { 
    SUBSCRIPTION_LIST_SUCCESS,
    SUBSCRIPTION_LIST_FAIL, 
    RESET_SUBSCRIPTION,
    NEW_SUBSCRIPTION_SUCCESS,
    NEW_SUBSCRIPTION_FAIL,
    DELETE_SUBSCRIPTION_SUCCESS,
    DELETE_SUBSCRIPTION_FAIL,
    SUBSCRIPTION_UPDATE_SUCCESS,
    SUBSCRIPTION_UPDATE_FAIL
} from "../constants/serviceConstants";

export  const subscriptionReducer = (state = {}, action) => {
    switch(action.type){

        case SUBSCRIPTION_LIST_SUCCESS:
            return {
                ...state,
                subscriptionFee: action.payload
            }

        case SUBSCRIPTION_LIST_FAIL:
        case SUBSCRIPTION_UPDATE_FAIL:
        case DELETE_SUBSCRIPTION_FAIL:
        case NEW_SUBSCRIPTION_FAIL:
            return {
                ...state,
                error: action.payload.message
            }
        case DELETE_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                deleteSubscription: true
            }
        case SUBSCRIPTION_UPDATE_SUCCESS:
            return {
                 ...state,
                updateSuccess: true
            }

        case RESET_SUBSCRIPTION:
            return{
                ...state,
                error: null,
                subscriptionSuccess: null,
                deleteSubscription: null,
                updateSuccess:null
            }

        

        case NEW_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                subscriptionFee : state.subscriptionFee ? [...state.subscriptionFee, action.payload.subscription] : [ action.payload.subscription ],
                subscriptionSuccess: true
            }


        default: 
            return state;
    }
}