import React, { Fragment, useEffect, useState } from 'react'
import { Card, Row, Form, Col, InputGroup } from 'react-bootstrap'
import {MdEdit,MdUpdate} from 'react-icons/md'
import {AiFillDelete,AiFillEye} from 'react-icons/ai'
import {BsToggle2On,BsToggle2Off, BsCheck2} from 'react-icons/bs'
import { MdCheck, MdClose } from 'react-icons/md'
import {IoMdAddCircle,IoMdAdd}from 'react-icons/io'
import { FaTimes } from 'react-icons/fa'
import Paging from '../../components/Paging'
import Modals from '../../components/Modals'
import Buttons from '../../components/Buttons'
import Modalsmall from '../../components/Modalsmall'
import './style.scss'

import { useSelector, useDispatch } from 'react-redux'
import { addNewTime } from '../../Redux/actions/timeActions'
import { getTimeLists,deleteTimeById} from '../../Redux/actions/timeActions'
import { toast } from 'react-toastify'
import { RESET_TIME } from '../../Redux/constants/timeConstants'
import Select from 'react-select';
import {Formik, Field} from 'formik';
import InputField from '../../components/InputField'
import IconInput from '../../components/IconInput'
import FormSelectField from '../../components/form-select-field'
import * as Yup from 'yup';
import EditTime from './EditTime'


const AfterNoon = () => {
    const {timeList, error, timeSuccess,deleteSuccess } = useSelector(state => state.time)
    const dispatch = useDispatch();
    const { loading } = useSelector(state => state.auth)
    

    const [modalShow, setModalShow] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [modalShowEdit, setModalShowEdit] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [datas, setDatas] = useState([])
    const [headerColor, setHeaderColor] = useState('')
    const [headerTitle, setHeaderTitle] = useState('')
    const [selectedTime, setselectedTime] = useState('')


    useEffect(() => {
        const indexOfLastPost = currentPage * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const currentPosts = (timeList || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }, [timeList])

    const pageChanged = (page)=> {
        setCurrentPage(page)
        const indexOfLastPost = page * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const currentPosts = (timeList || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }
    useEffect(() => {
        if(error){
            toast.error(error);
            dispatch({
                type: RESET_TIME
            })
        }
    }, [error])
    useEffect(() => {
        if(timeSuccess){
            
            setModalShow(false);
            dispatch({
                type: RESET_TIME
            })
            toast.success("New Time Added Successfully");
            dispatch(getTimeLists("afternoon"))
        }
        if(deleteSuccess){
            
            setDeleteModalShow(false);
            dispatch({
                type: RESET_TIME
            })
            toast.success("Time Deleted Successfully");
            dispatch(getTimeLists("afternoon"))
        }
    }, [timeSuccess,deleteSuccess])
    useEffect(() => {
        dispatch(getTimeLists("afternoon"))
        console.log({timeList})
    }, [])
    

    const createNewTime = () => {
        setHeaderColor('#D42F45');
        setHeaderTitle("ADD TIME");
        setModalShow(true);
    }

    const deleteTime = (id) => {
        setselectedTime(id);
        setHeaderColor('#D42F45');
        setHeaderTitle("DELETE TIME");
        setDeleteModalShow(true);
    }
    const editTime = (time) => {
        setselectedTime(time);
        setHeaderColor('#FF9900');
        setHeaderTitle("EDIT TIME");
        setModalShowEdit(true);
    }

    const deletetimeListById = () => {
        dispatch(deleteTimeById(selectedTime))
    }
    const saveTime =  (values) =>  {


        console.log({values})

        const { from, to, when } = values
        dispatch(addNewTime(from, to, when));
        
    }

    return (
        <Fragment>
            
            <Card style={{ borderRadius: 20, margin: 10, minHeight: '70vh' }}>
                <IoMdAddCircle class="add" fontSize="3.5em" 
                onClick={createNewTime}
                style={{ position: 'absolute', top: -30, right: -20, cursor: 'pointer' }} />
                <Card.Body style={{ overflowX: 'auto' }}>
                    <table>
                        <thead class="tableHeader">
                            <th>From Time</th>
                            <th>To Time</th>
                            <th>Actions</th>
                        </thead>
                        <tbody>
                            {datas && datas.map((time, index) => (
                                <tr key={index}>
                                    <td class="tableContent">{time.from}</td>
                                    <td class="tableContent">{time.to}</td>
                                    <td class="tableContent">
                                        <MdEdit color="orange" onClick={() => editTime(time)}/>
                                        <AiFillDelete class="add" onClick={() => deleteTime(time._id)}/>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Card.Body>
                <Col style={{ float: 'right', display:'flex', flexDirection: 'row-reverse', justifyContent: 'flex-start', alignItems: 'center', margin: 30  }}>
                <Card >
                    {timeList && timeList.length>10 && <Paging datas={timeList} currentPage={currentPage} pageChange={pageChanged} />}
                </Card>
                </Col>
            </Card>
            {/* <FranchiseeForm openModal={modalShow} closeModal={()=> setModalShow(false)} headerColor={headerColor} headerTitle={headerTitle} />
             */}
            <Modals showModal={deleteModalShow} hideModal={()=> setDeleteModalShow(false)} modalTitle={headerTitle} headerColor={headerColor}>
                <p className="deleteText" >Are you sure you want to delete this time?</p>
                <div style={{ display: 'flex', alignItems:'center', justifyContent:'center' }}>
                        <Buttons backColor="#4AAD47" buttonText="YES" buttonAction={deletetimeListById}>
                            <BsCheck2 style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                        <Buttons backColor="#D42F45" buttonText="No" buttonAction={()=> console.log("Hello")}>
                            <FaTimes style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                </div>
            </Modals>
            <Modalsmall  showModal={modalShow} hideModal={()=> setModalShow(false)} headerColor={headerColor} modalTitle={headerTitle} >
            <Formik 
                initialValues={{
                    from: '',
                    to: '',
                    when: 'afternoon',
                    
                    
                }}
                validationSchema={
                    
                    Yup.object({
                        
                        from: Yup.string().required('From time is Required'),
                        to: Yup.string().required("To time is Required"),
                        
                    })
                }
                onSubmit={(values) => {
                    console.log(values)
                    saveTime(values)
                }}
                render={({handleChange, handleSubmit, handleBlur, values, errors}) => (
            <Form>   
                    
                    <InputField fieldName="from" fieldLabel="From Time" fieldType="time" md="12"/>
                    
                    <InputField fieldName="to" fieldLabel="To Time" fieldType="time" md="12"/>
                    
            
            {headerTitle === 'ADD TIME' && <Row>
                <center>
                    <Buttons backColor="#D42F45" buttonText="ADD" buttonAction={handleSubmit}>
                        <IoMdAdd style={{color:'#FFFFFF'}} fontSize="2.3em" />
                    </Buttons>
                </center>
            </Row>}
            
            </Form>
            )}
            />
        </Modalsmall>
        {modalShowEdit && <EditTime openModal={modalShowEdit} closeModal={()=> setModalShowEdit(false)} headerColor={headerColor} headerTitle={headerTitle} times={selectedTime}  />}
           
        </Fragment>
    )
}

export default AfterNoon
