import { 
    LOADING 
} from "../constants/userConstants"
import axios from '../../CustomAxios'
import { 
    DELETE_FRANCHISEE_FAIL,
    DELETE_FRANCHISEE_SUCCESS,
    FRANCHISEE_LIST_FAIL,
    FRANCHISEE_LIST_SUCCESS,
    NEW_FRANCHISEE_FAIL, 
    NEW_FRANCHISEE_SUCCESS, 
    UPDATE_FRANCHISEE_FAIL, 
    UPDATE_FRANCHISEE_SUCCESS,
    CHARGE_LIST_FAIL,
    CHARGE_LIST_SUCCESS,
    NEW_CHARGE_FAIL, 
    NEW_CHARGE_SUCCESS, 
    UPDATE_CHARGE_FAIL, 
    UPDATE_CHARGE_SUCCESS,
    DELETE_CHARGE_FAIL,
    DELETE_CHARGE_SUCCESS,
    CHARGE_DETAILS_SUCCESS,
    CHARGE_DETAILS_FAIL
} from "../constants/franchiseeConstants";
import reactotron from "../../ReactotronConfig";

//Save Franchisee
export const saveNewFranchisee = (data) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/create-franchisee`, data)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })

        let details = response.data.franchisee;
        details.label=details.name;
        details.value = details.name;
        dispatch({
            type: NEW_FRANCHISEE_SUCCESS,
            payload: details
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_FRANCHISEE_FAIL,
            payload: error
        })
    });
}


//Get Franchisee
export const getFranchisee = () => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`admin/franchisees`)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })

        await response.data.map(fran => {
            fran.label = fran.name;
            fran.value = fran.name;
        })

        dispatch({
            type: FRANCHISEE_LIST_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: FRANCHISEE_LIST_FAIL,
            payload: error
        })
    });
}


//Update Franchiee
export const updateFranchiseeDetails = (id,name, employee_name,email,city,emp_proof_type,phonenumber,emp_id,status) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    let data={
        id,name, employee_name,email,city,emp_proof_type,phonenumber,emp_id,status
    }
    await axios.post(`admin/edit-franchisee`, data)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: UPDATE_FRANCHISEE_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: UPDATE_FRANCHISEE_FAIL,
            payload: error
        })
    });
}

//Delete FRANCHISEE
export const deleteFranchiseeById = (id) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.delete(`admin/delete-franchisee/${id}`)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: DELETE_FRANCHISEE_SUCCESS,
            payload: id
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: DELETE_FRANCHISEE_FAIL,
            payload: error
        })
    });
}

//DELIVERY CHARGES

//Save CHARGE
export const saveNewCharge = (km,free_delivery_threshold,charge, weather_charge,franchisee) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    let data={
        km,free_delivery_threshold,charge, weather_charge,franchisee
    }
    await axios.post(`admin/create-deliverycharge`, data)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })

        dispatch({
            type: NEW_CHARGE_SUCCESS,
            payload: response.data.deliveryCharge
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_CHARGE_FAIL,
            payload: error
        })
    });
}


//Get CHARGE
// export const getCharge = () => async(dispatch) => {
//     dispatch({
//         type: LOADING,
//         payload: true
//     })
//     await axios.get(`admin/delivery-charges`)
//     .then(async response => {
//         dispatch({
//             type: LOADING,
//             payload: false
//         })

//         dispatch({
//             type: CHARGE_LIST_SUCCESS,
//             payload: response.data
//         })
//     })
//     .catch(async error => {
//         dispatch({
//             type: LOADING,
//             payload: false
//         })
//         dispatch({
//             type: CHARGE_LIST_FAIL,
//             payload: error
//         })
//     });
// }


//Update CHARGE
export const updateDeliveryCharge = (id,km,free_delivery_threshold,charge, weather_charge,franchisee) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    let data={
        id,km,free_delivery_threshold,charge, weather_charge,franchisee
    }
    await axios.post(`admin/edit-deliverycharge`, data)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: UPDATE_CHARGE_SUCCESS,
            payload: response.data.deliveryCharge
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: UPDATE_CHARGE_FAIL,
            payload: error
        })
    });
}

//Delete CHARGE
export const deleteChargeById = (id) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.delete(`admin/delete-deliverycharge/${id}`)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: DELETE_CHARGE_SUCCESS,
            payload: id
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: DELETE_CHARGE_FAIL,
            payload: error
        })
    });
}

//Get DELIVERY CHARGE for Particular Franchisee
export const getChargeList = (fid) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`admin/get-deliverycharge/${fid}`,fid)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })

        dispatch({
            type: CHARGE_LIST_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: CHARGE_LIST_FAIL,
            payload: error
        })
    });
}
