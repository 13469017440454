import { Form, Formik } from 'formik'
import React, { Fragment, useEffect, useState, useRef } from 'react'
import InputField from '../../components/InputField'
import SelectInput from '../../components/SelectInput'
import * as Yup from 'yup';
import { Card, Col, FormGroup, FormLabel, Row, Table } from 'react-bootstrap';
import Buttons from '../../components/Buttons';
import { IoMdAdd } from 'react-icons/io';
import './style.scss'
import { useSelector, useDispatch } from 'react-redux';
import { SearchProduct } from '../../Redux/actions/productActions';
import { CLEAR_SEARCH, STORE_INPUT } from '../../Redux/constants/storeConstants';
import { getFranchisee } from '../../Redux/actions/franchiseeActions';
import { listStores } from '../../Redux/actions/storeActions';
import { getSingleSurprise, saveSurprise, updateSurprise } from '../../Redux/actions/surpriseActions';
import { AiOutlinePlus, AiOutlineMinus, AiOutlineClose } from 'react-icons/ai'
import SelectNew from '../../components/SelectNew';
import { toast } from 'react-toastify';
import { RESET_SURPRISE } from '../../Redux/constants/surpriseConstants';
import { GiBackwardTime } from 'react-icons/gi';

const EditSurprise = ({id}) => {

    const dispatch = useDispatch();
    const { searchProducts } = useSelector(state => state.product)
    const { franchiseeList } = useSelector(state => state.franchisee)
    const { storeList } = useSelector(state => state.store)
    const { surpriseDetails } = useSelector(state => state.surprise)

    const [franchisee, setFranchisee] = useState('')
    const [store, setStore] = useState([])
    const proForm = useRef(null)
    const [selectedProducts, setSelectedProducts] = useState([])
    const [searchInput, setSearchInput] = useState([])
    const [initialValues, setInitialValues] = useState({})

    //get surprise details based on ID
    useEffect(() => {
        if(id){
            dispatch(getSingleSurprise(id))
        }
    }, [id])



    useEffect(() => {
        if(surpriseDetails){
            dispatch(listStores(surpriseDetails.franchisee._id))
            let fran = surpriseDetails.franchisee;
            fran.label = fran.name;
            fran.value = fran.name;
            let details = {
                id: surpriseDetails._id,
                name: surpriseDetails.name,
                cost: surpriseDetails.total,
                franchisee: fran,
                stores: '',
                datails:surpriseDetails.details,
                selling_price: surpriseDetails.selling_price
            }

            setInitialValues(details);
        }
    }, [surpriseDetails])

    useEffect(() => {
        dispatch(getFranchisee())
        dispatch({
            type: STORE_INPUT,
            payload: {
                prop: 'storeList',
                value: null
            }
        })
    }, [])


    

    useEffect(() => {
        if(searchInput.length > 2){
            let data = {
                storeID: [proForm.current.values.stores._id],
                value: searchInput
            }
            dispatch(SearchProduct(data))
        }
        else{
            dispatch({
                type: CLEAR_SEARCH
            })
        }
    }, [searchInput])


    useEffect(() => {
        if(franchisee!=''){
            dispatch(listStores(franchisee))
        }
    }, [franchisee])

    useEffect(() => {
        dispatch({
            type: CLEAR_SEARCH
        })
    }, [])



    const saveSurpriseOrder = async (values) =>  {

        if(selectedProducts.length>0){
            //console.log({values})

            let stores =await selectedProducts.map(pr => pr.storeID)
    
            let unique = [...new Set(stores)];
    
            
            let storesList = await storeList.filter(st => unique.includes(st._id))
    
            let finalStore = storesList.map(st => { 
                return { _id: st._id, name: st.name }
            })
    
            //console.log(finalStore)
    
            let fran = {
                _id: values.franchisee._id,
                name: values.franchisee.name
            }
    
            let details = {
                id: values.id,
                name: values.name,
                details: selectedProducts,
                total: values.cost,
                franchisee: fran,
                stores: finalStore
            }

            dispatch(updateSurprise(details));
        }
        

        //const { title,cost,franchisee,stores,datails } = values
        //dispatch(saveSurprise(title,cost,franchisee,stores,datails));
       
    }

    const addItem = async (product, setFieldValue) => {
        product.counts=1;
        setSearchInput('')
        let products = [];
        if(selectedProducts.length>0){
            let exist = await selectedProducts.find((pro) => pro._id === product._id);
            if(exist){
                exist.counts = exist.counts+1;
                await selectedProducts.map(prod => prod._id === product._id ? exist : prod)
                products=selectedProducts;
            }
            else{
                products=[...selectedProducts, product];
            }
        }
        else{
            products=[product];
        }
        setSelectedProducts(products);
        const total = await products.reduce((prev, current) => {
            return prev + (parseInt(current.cost_price) * current.counts);
        }, 0);
        setFieldValue('cost', total)
        //console.log(products)
    }


    const removeOneItem = async (productId, setFieldValue) => {
        let product = await selectedProducts.find(prod => prod._id === productId)
        if(product){
            let finalProd = [];
            if(product.counts === 1){
                finalProd = await selectedProducts.filter(prod => prod._id !== productId);
                setSelectedProducts(finalProd)
            }
            else{
                product.counts = product.counts-1
                finalProd = await selectedProducts.map(prod => prod._id === productId ? product : prod);
                setSelectedProducts(finalProd)
            }

            const total = await finalProd.reduce((prev, current) => {
                return prev + (parseInt(current.cost_price) * current.counts);
            }, 0);
            setFieldValue('cost', total)
            
        }

        
    }

    const addOneItem = async (productId, setFieldValue) => {
        let product = await selectedProducts.find(prod => prod._id === productId)
        if(product){
            product.counts = product.counts+1
        }

        let finalProd = await selectedProducts.map(prod => prod._id === productId ? product : prod);
        setSelectedProducts(finalProd)

        const total = await finalProd.reduce((prev, current) => {
            return prev + (parseInt(current.cost_price) * current.counts);
        }, 0);
        setFieldValue('cost', total)
    }

    const removeItem = async (productId, setFieldValue) => {
        let finalProd = await selectedProducts.filter(prod => prod._id !== productId);
        setSelectedProducts(finalProd)

        const total = await finalProd.reduce((prev, current) => {
            return prev + (parseInt(current.cost_price) * current.counts);
        }, 0);
        setFieldValue('cost', total)
    }

    const franchiseeChange = (value, setFieldValue) => {
        //console.log(value)
        setFranchisee(value._id)
        setFieldValue('franchisee', value)
    }


    const storeChange = (value, setFieldValue) => {
        //console.log(value)
        setStore(value._id)
        setFieldValue('stores', value)
    }

    return (
        <Formik 
            innerRef={proForm}
            initialValues={initialValues}
            validationSchema={
                Yup.object({
                    name: Yup.string().required('Title Required'),
                    cost: Yup.number().required('Cost Required'),
                    franchisee: Yup.object().required("Franchisee Name Required"),
                    stores: Yup.object().required("Store Name Required"),
                    selling_price: Yup.number().required("Selling Price Required"),
                })
            }
            onSubmit={(values) => {
                //console.log(values)
                saveSurpriseOrder(values)
            }}
            render={({handleChange, handleSubmit, handleBlur, values, errors, setFieldTouched, touched, setFieldValue}) => (
                <Form>
                    <Row>
                        <InputField fieldName="name" fieldLabel="Name" md={4} />
                        <SelectNew 
                             md="4"
                             fieldName="franchisee"
                             fieldLabel="Franchisee"
                             options={franchiseeList}
                             isMultiple={false}
                             value={values.franchisee}
                             onChange={(fieldName, value)=> franchiseeChange(value, setFieldValue)}
                             onBlur={setFieldTouched}
                             error={errors.franchisee}
                             touched={touched.franchisee}
                        />
                        <SelectNew 
                             md="4"
                             fieldName="stores"
                             fieldLabel="Store"
                             options={storeList}
                             isMultiple={false}
                             value={values.stores}
                             onChange={(fieldName, value)=> storeChange(value, setFieldValue)}
                             onBlur={setFieldTouched}
                             error={errors.franchisee}
                             touched={touched.franchisee}
                        />
                        {/*<SelectInput fieldName="franchisee" fieldLabel="Franchisee" md={4} onChange={handleChanges}>
                            <option>Select Franchisee</option>
                            {franchiseeList && franchiseeList.map((fran, index) => (
                                <option key={index} value={fran}>{fran.name}</option>
                            ))}
                            </SelectInput>
                        <SelectInput fieldName="stores" fieldLabel="Store" md={4}  onChange={handleStoreChange}>
                            <option>Select Store</option>
                            {storeList && storeList.map((store, index) => (
                                <option key={index} value={store}>{store.name}</option>
                            ))}
                        </SelectInput>*/}
                        <Col md={6}>
                            <InputField fieldName="cost" fieldLabel="Order Cost" md={12} readOnly />
                            <InputField fieldName="selling_price" fieldLabel="Selling Price" md={12} />
                        </Col>
                        <Col md={6}>
                            <FormGroup controlId={"datails"} as={Col}  xs="12" sm="12" md={12} className="formStyle">
                                <FormLabel className="formLabel">{"Order Items"}</FormLabel>
                                <div className='orderList' md={8}>
                                    <input className='orderInput' value={searchInput} style={{ fontSize: 12 }} placeholder='Search Product' onChange={(e) => setSearchInput(e.target.value)} />
                                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap:'wrap' }}>
                                        {selectedProducts && selectedProducts.map((product, index) => (
                                            <div key={index} style={{ display: 'flex', flexDirection: 'row', marginRight: 5, marginBottom: 5 }}>
                                                <span className='itemStyle'><AiOutlineMinus onClick={() => removeOneItem(product._id, setFieldValue)} /></span>
                                                <span className='itemStyle'>{product.counts}</span>
                                                <span className='itemStyle'><AiOutlinePlus onClick={() => addOneItem(product._id, setFieldValue)} /></span>
                                                <span className='itemStyle'>{product.name}</span>
                                                <span className='itemStyle'><AiOutlineClose onClick={() => removeItem(product._id, setFieldValue)} /></span>
                                            </div>
                                        ))}
                                    {searchProducts && <div className='searchResult'>
                                    {searchProducts.map((product, index) => (
                                        <Card key={index}>
                                            <div onClick={() => addItem(product, setFieldValue)}>
                                            {`${product.name}(₹${product.cost_price})`}
                                            </div>
                                        </Card>
                                    ))}
                                    </div>}
                                    </div>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <center>
                            <Buttons backColor="#D42F45" buttonText="UPDATE" buttonAction={handleSubmit}>
                                <GiBackwardTime style={{color:'#FFFFFF'}} fontSize="2.3em" />
                            </Buttons>
                        </center>
                    </Row>
                </Form>
            )}
        />
    )
}

export default EditSurprise
