import { 
    LOADING, LOGIN_FAIL, LOGIN_SUCCESS, RESET_AUTH, RE_LOGIN, 
    USER_LIST_SUCCESS,
    USER_LIST_FAIL, 
    RESET_USER,
    NEW_USER_SUCCESS,
    ROLE_LIST_FAIL,
    ROLE_LIST_SUCCESS,
    NEW_ROLE_SUCCESS,
    NEW_ROLE_FAIL,
    NEW_ID_SUCCESS,
    NEW_ID_FAIL,
    ID_LIST_SUCCESS,
    ID_LIST_FAIL,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAIL,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_FAIL,
    NEW_USER_FAIL,
    CLEAR_USER
    
} from "../constants/userConstants";

export  const authReducer = (state = {}, action) => {
    switch(action.type){

        case LOADING:
            return {
                ...state,
                loading: action.payload
            }

        case RESET_AUTH:
            return {
                loading: null,
                isAuthenticated: null,
                loginError: null
            }

        case LOGIN_SUCCESS:
            return {
                ...state,
                user: action.payload,
                isAuthenticated: true,
                loading: false
            }

        case LOGIN_FAIL:
            return {
                ...state,
                isAuthenticated: false,
                loginError : 'Invalid Username / Password',
                loading: false
            }

        case RE_LOGIN:
            return {
                ...state,
                user: null,
                isAuthenticated : false,
                loading: false
            }


        default: 
            return state;
    }
}
export  const userReducer = (state = { }, action) => {
    switch(action.type){

        case USER_LIST_SUCCESS:
            return {
                ...state,
                userList: action.payload
            }

        case USER_LIST_FAIL:
        case NEW_USER_FAIL:
        case DELETE_USER_FAIL:
        case NEW_ROLE_FAIL:
        case ROLE_LIST_FAIL:
        case NEW_ID_FAIL:
        case ID_LIST_FAIL:
        case USER_UPDATE_FAIL:
            return{
                ...state,
                error: action.payload.message
            }


        case NEW_ROLE_SUCCESS:
            return {
                ...state,
                roles: state.roles ? [...state.roles , action.payload] : [action.payload],
                roleSuccess: true
            }

        case ROLE_LIST_SUCCESS:
            return {
                ...state,
                roles: action.payload
            }

        case NEW_ID_SUCCESS:
            return {
                ...state,
                idtypes: state.idtypes ? [...state.idtypes , action.payload] : [action.payload],
                idSuccess: true
            }

        case ID_LIST_SUCCESS:
            return {
                ...state,
                idtypes: action.payload
            }

        case CLEAR_USER:
            return{
                ...state,
                error: null,
                userSuccess: null,
                roleSuccess: null,
                deleteSuccess:null,
                updateSuccess:null
            }


        case NEW_USER_SUCCESS:
            //console.log({userList: state.userList})
            return {
                ...state,
                userList : state.userList ? [...state.userList, action.payload.user] : [ action.payload.user ],
                userSuccess: true
            }

        case DELETE_USER_SUCCESS:
            //console.log(state.userList);
            return {
                ...state,
                userList : state.userList.filter(user => user._id != action.payload),
                deleteSuccess: true
            }

       case USER_UPDATE_SUCCESS:
            return {
                ...state,
                userList : state.userList.map(user => user._id === action.payload._id ? action.payload: user),
                updateSuccess: true
            }

        default: 
            return state;
    }
}