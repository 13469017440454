import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import Modals from '../../components/Modals'
import {MdEdit,MdUpdate} from 'react-icons/md'
import Buttons from '../../components/Buttons'
import { useSelector, useDispatch } from 'react-redux'
import { updateSlider} from '../../Redux/actions/sliderActions'
import { toast } from 'react-toastify'
import {Formik, Field} from 'formik';
import InputField from '../../components/InputField'
import FormSelectField from '../../components/form-select-field'
import "yup-phone";
import { RESET_SLIDER } from '../../Redux/constants/sliderConstants'
import ImageInput from '../../components/ImageInput'

const EditSlider = ({openModal, closeModal, headerColor, headerTitle, sliderlist}) => {

    const { updateSuccess } = useSelector(state => state.slider)
    const dispatch = useDispatch();
    const [initialDatas, setInitialDatas] = useState(null)
    const [image, setImage] = useState(null)
    
    useEffect(() => {
        async function getDetails() {

            if(sliderlist){
                const details = {
        
                    requested_by: '',
                    id:sliderlist._id,
                    title: sliderlist.title,
                    slide_no: sliderlist.slide_no,
                    cost: sliderlist.cost,
                    requested_by: sliderlist.requested_by,
                    exp_date: sliderlist.exp_date,
                }
    
                
                console.log({details})
                setInitialDatas(details)
            }
        }

        getDetails();
    }, [sliderlist])

    const editSlider =  (values) =>  {
        

        let formData = new FormData();
        formData.append("id", values.id);
        formData.append("title", values.title);
        formData.append("slide_no", values.slide_no);
        formData.append("cost", values.cost);
        formData.append("requested_by", values.requested_by);
        formData.append("exp_date", values.exp_date);
        if(image){
            formData.append("image", image);
        }
        
        
        dispatch(updateSlider(formData));
       
    }


    return (
        <Modals showModal={openModal} hideModal={closeModal} headerColor={headerColor} modalTitle={headerTitle} modalSize="md">
            {initialDatas && 
            <Formik 
                initialValues={initialDatas}
                onSubmit={(values) => {
                    console.log(values)
                    editSlider(values)
                }}
                render={({handleChange, handleSubmit, handleBlur, values, errors}) => (
                    <Form>
                        <Row className="mb-3">
                
                <InputField fieldName="title" fieldLabel="Title" fieldType="text" />
                <InputField fieldName="slide_no" fieldLabel="Slider No" fieldType="text" />
                <InputField fieldName="cost" fieldLabel="Cost(Per Day)" fieldType="text" />
                <InputField fieldName="exp_date" fieldLabel="Expiry Date" fieldType="text" />
                
                
                <InputField fieldName="requested_by" fieldLabel="Requested By" fieldType="text" />

                <ImageInput 
                                fieldLabel="Slider Image" 
                                imageHelperText={"512 * 512"} 
                                fieldName={"image"} 
                                image={image} 
                                imageUrl={sliderlist.image}
                                changeImage={(e) => setImage(e.target.files[0])} 
                                md={6}
                            />

         
        </Row>
                        {headerTitle === 'EDIT SLIDER' && <Row>
                <center>
                    <Buttons backColor="#FF9900" buttonText="UPDATE" buttonAction={handleSubmit}>
                            <MdUpdate style={{color:'#FFFFFF'}} fontSize="2.3em" />
                    </Buttons>
                </center>
            </Row>}
                    </Form>
                )}
            />}
        </Modals>
    )
}

export default EditSlider
