import React, { useState, useEffect } from 'react'
import { Row, Form, Col, InputGroup, FormGroup, FormLabel } from 'react-bootstrap'
import Modals from '../../components/Modals'
import {
    MdFoodBank,MdUpdate
} from 'react-icons/md'
import {
    IoMdAdd
} from 'react-icons/io'
import {GiShop} from 'react-icons/gi'
import {AiTwotoneShop} from 'react-icons/ai'
import Buttons from '../../components/Buttons'
import './style.scss'
import { Field, ErrorMessage,Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import InputField from '../../components/InputField'
import FormSelectField from '../../components/form-select-field'
import * as Yup from 'yup';
import "yup-phone";
import { saveNewCharge } from '../../Redux/actions/franchiseeActions'
import { RESET_CHARGE } from '../../Redux/constants/franchiseeConstants'
import { getStoreLists } from '../../Redux/actions/storeActions'
import { getCategoryLists } from '../../Redux/actions/categoryActions'
import reactotron from 'reactotron-react-js'

const NewDeliveryCharges = ({openModal, closeModal, headerColor, headerTitle, franchisee}) => {

    //reactotron.log({table: franchisee})
    const { newCharge,error } = useSelector(state => state.deliverycharge)
    const dispatch = useDispatch();
    const { storeList } = useSelector(state => state.store)
    const { categoryLists } = useSelector(state => state.category)
    const [initialDatas, setInitialDatas] = useState(null)
    useEffect(() => {
        if(error){
            toast.error(error);
            dispatch({
                type: RESET_CHARGE
            })
        }

        if(newCharge){
            closeModal(true)
            toast.success("Delivery Charge Added Successfully");
            dispatch({
                type: RESET_CHARGE

            })
        }
        
    }, [error, newCharge])

    useEffect(() => {
        async function getDetails() {
            
            if(franchisee){
                const details = {
                    franchisee:{
                        id: franchisee._id,
                        name: franchisee.name
                    },
                    km: '',
                    free_delivery_threshold:'',
                    charge:'',
                    weather_charge: '',
                }
    
                
                console.log({details})
                setInitialDatas(details)
            }
        }

        getDetails();
    }, [franchisee])
    const saveCharge = async (values) =>  {
        
        console.log({values})

        const { km,free_delivery_threshold,charge, weather_charge,franchisee} = values
        dispatch(saveNewCharge(km,free_delivery_threshold,charge, weather_charge,franchisee));
       
       
    }

    return (
        <Modals showModal={openModal} hideModal={closeModal} headerColor={headerColor} modalTitle={headerTitle}>
            {initialDatas && 
            <Formik 
                initialValues={initialDatas}
                validationSchema={
                    
                    Yup.object({
                        km: Yup.string().required('Within KM is Required'),
                        free_delivery_threshold: Yup.string().required('Free Delivery Threshold is Required'),
                        charge: Yup.string().required('Delivery charge is Required'),
                        weather_charge: Yup.string().required('Weather charge is Required'),

                    })
                }
                onSubmit={(values) => {
                    console.log(values)
                    saveCharge(values)
                }}
                render={({handleChange, handleSubmit, handleBlur, values, errors}) => (
                    <Form>
            <Row className="mb-3">
            <InputField fieldName="km" fieldLabel="Within KM" md="6" />
            <InputField fieldName="free_delivery_threshold" fieldLabel="Free Delivery Threshold" md="6" />
            <InputField fieldName="charge" fieldLabel="Delivery Charge" md="6" />
            <InputField fieldName="weather_charge" fieldLabel="Weather Charge" md="6" />
                
           
            </Row>
            {headerTitle === 'ADD DELIVERY CHARGES' && <Row>
                <center>
                    <Buttons backColor="#D42F45" buttonText="ADD" buttonAction={handleSubmit}>
                        <IoMdAdd style={{color:'#FFFFFF'}} fontSize="2.3em" />
                    </Buttons>
                </center>
            </Row>}
            
            </Form>
                )}
            />}
        </Modals>
    )
}

export default NewDeliveryCharges
