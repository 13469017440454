import React,{ useState, useEffect } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import Modals from '../../components/Modals'
import InputGroup from 'react-bootstrap/InputGroup'
import {BsPercent} from 'react-icons/bs'
import {
    MdUpload
} from 'react-icons/md'
import {IoMdAdd}from 'react-icons/io'
import Buttons from '../../components/Buttons'
import reactotron from '../../ReactotronConfig'
import { useSelector, useDispatch } from 'react-redux'
import { addNewUser,getRoles,saveRole,getProofs,saveProoftype } from '../../Redux/actions/userActions'
import { getStoreLists, listStores } from '../../Redux/actions/storeActions'
import { toast } from 'react-toastify'
import { CLEAR_USER, RESET_USER } from '../../Redux/constants/userConstants'
import FranchiseeForm from '../Franchisee/FranchiseeForm'
import { RESET_ERROR } from '../../Redux/constants/franchiseeConstants'
import Select from 'react-select';
import {Formik, Field,ErrorMessage} from 'formik';
import InputField from '../../components/InputField'
import IconInput from '../../components/IconInput'
import FormSelectField from '../../components/form-select-field'
import * as Yup from 'yup';
import "yup-phone";
import SelectNew from '../../components/SelectNew'
import ImageInput from '../../components/ImageInput'
import InputImage from '../../components/InputImage'
import { getFranchisee } from '../../Redux/actions/franchiseeActions'
import { STORE_INPUT } from '../../Redux/constants/storeConstants'

const NewUser = ({openModal, closeModal, headerColor, headerTitle, role}) => {
    
    const { roles, savesRole ,error,idtypes,savesIdtype,userSuccess} = useSelector(state => state.user)
    const { franchiseeList } = useSelector(state => state.franchisee)
    const { storeList } = useSelector(state => state.store)
    
            const dispatch = useDispatch();
            const { loading } = useSelector(state => state.auth)
            const [image, setImage] = useState(null);
            const [emp_id, setEmpid] = useState(null);
            const [roleName, setRoleName] = useState('');
            const [idtypeName, setIdtypeName] = useState('');
            const [openSubModal, setOpenSubModal] = useState(false);
            const [openSubModalId, setOpenSubModalId] = useState(false);
            const [subHeaderColor, setSubHeaderColor] = useState('');
            const [subHeaderTitle, setSubHeaderTitle] = useState('');
            const [RoleTitle, setRoleTitle] = useState('')

            const closeSubModel = () => {
                setOpenSubModal(false);
            }
            const closeSubModelId = () => {
                setOpenSubModalId(false);
            }
            useEffect(() => {
                if(error){
                    toast.error(error);
                    dispatch({
                        type: CLEAR_USER
                    })
                }
                if(userSuccess){
                    closeModal(true);
                    dispatch({
                        type: CLEAR_USER
                    })
                    toast.success("New User Added Successfully");
                }
                if(savesRole){ //close ROLE modal
                    setOpenSubModal(false)
                }
            
                if(savesIdtype){ //close ID type modal
                    setOpenSubModalId(false)
                }
                
        
            }, [savesRole,savesIdtype,error,userSuccess ])
            
            
            useEffect(() => {
                if(role=="store" || role === 'delivery-boy'){
                    dispatch(getFranchisee())
                    dispatch({
                        type: STORE_INPUT,
                        payload: {
                            prop: 'storeList',
                            value: null
                        }
                    })
                }
            }, [role])
            useEffect(() => {
                dispatch(getStoreLists())
                
            }, [])
            useEffect(() => {
                dispatch(getProofs())
                
            }, [])

            const saveUser =  (values) =>  {

                let store = {
                    _id: values.storeName._id,
                    name: values.storeName.name
                }

                let fran = {
                    _id: values.franchisee._id,
                    name: values.franchisee.name
                }

                var form_data = new FormData();
                form_data.append("name", values.name);
                form_data.append("email", values.email);
                form_data.append("mobile", parseInt(values.mobile.substring(values.mobile.length - 10)));
                form_data.append("password", values.password);
                form_data.append("password_confirmation", values.password);
                form_data.append("emp_id_type", values.emp_id_type.label);
                if(values.emp_id){
                    form_data.append("id_number", values.emp_id);
                }
                if(values.emp_id_image){
                    form_data.append("emp_id_file", values.emp_id_image);
                }
                form_data.append("status", 'Active');
                form_data.append("role", values.role);
                form_data.append("image", values.profilePic);
                //console.log({values})
                if(role === 'store'){
                    form_data.append("city", values.city);
                    form_data.append("franchisee", JSON.stringify(fran));
                    form_data.append("store_name", JSON.stringify(store));
                }
                else if(role === 'delivery-boy'){
                    form_data.append("franchisee", JSON.stringify(fran));
                    form_data.append("vehicle_no", values.vehicle_no);
                }
                dispatch(addNewUser(form_data))
                
            }


            const newRole = () => {
                dispatch(saveRole(roleName))
            }
            const newIdtype = () => {
                dispatch(saveProoftype(idtypeName))
            }

            const onFranchiseeChange = (value, setFieldValue) => {
                setFieldValue("franchisee", value);
                dispatch(listStores(value._id));
            }
        //delivery-boy
    return (
        <Modals showModal={openModal} hideModal={closeModal} headerColor={headerColor} modalTitle={headerTitle}>
            <Formik 
                initialValues={{
                    role: role,
                    name: '',
                    email: '',
                    mobile: '',
                    password:'',
                    emp_id_type: '',
                    emp_id: '',
                    emp_id_image: '',
                    profilePic: '',
                    franchisee: '',
                    storeName: '',
                    city: '',
                    vehicle_no: ''
                }}
                validationSchema={
                    
                    Yup.object({
                        name: Yup.string().max(100, 'Must be less than 100 characters').min(2,'Must be 2 characters or more').required('Name Required'),
                        email: Yup.string().email('Invalid email address').max(100,'Must be 100 characters or less').required('Store Email Required'),
                        mobile: Yup.string().phone("IN").required('Phone Number is Required'),
                        password: Yup.string().min(6,'Must be 6 characters or more').required("Password is Required"),
                        emp_id_type: Yup.object().required("Employee Id Type is required"),
                        profilePic: Yup.string().required("Profile Picture is required"),
                        city: Yup.string().when('role', { is: (role) => (role === 'store' || role === 'franchisee'),then: Yup.string().required('City is required.'), }),
                        franchisee: Yup.object().when('role', { is: (role) => (role === 'store' || role === 'delivery-boy'),then: Yup.object().required('Franchisee Name is required.'), }),
                        storeName: Yup.object().when('role', { is: 'store',then: Yup.object().required('Store Name is required.'), }),
                        vehicle_no: Yup.string().when('role', { is: 'delivery-boy',then: Yup.string().required('Vehicle Number is required.'), }),
                    })
                }
                onSubmit={(values) => {
                    //console.log(values)
                    saveUser(values)
                }}
                render={({handleChange, handleSubmit, handleBlur, setFieldValue, setFieldTouched, values, errors, touched}) => (
                    <Form>
            <Row className="mb-3">
                    <InputField fieldName="name" fieldLabel="Name" />
                    <InputField fieldName="email" fieldLabel="Email Address" fieldType="email"/>
                    <InputField fieldName="password" fieldLabel="Password" fieldType="password" />
                    <InputField fieldName="mobile" fieldLabel="Phone Number" fieldType="text" />
                    {(role === 'franchisee' || role === 'store') && <InputField fieldName="city" fieldLabel="City" fieldType="text"/>}
                    {(role === 'store' || role === 'delivery-boy') && <SelectNew 
                        md="4"
                        fieldName="franchisee"
                        fieldLabel="Franchisee"
                        options={franchiseeList}
                        isMultiple={false}
                        value={values.franchisee}
                        onChange={(fieldName, value) => onFranchiseeChange(value, setFieldValue)}
                        onBlur={setFieldTouched}
                        error={errors.franchisee}
                        touched={touched.franchisee}
                    />}
                    {role === 'store' &&  <SelectNew 
                        md="4"
                        fieldName="storeName"
                        fieldLabel="Store"
                        options={storeList}
                        isMultiple={false}
                        value={values.storeName}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        error={errors.storeName}
                        touched={touched.storeName}
                    />
                    }
                    {role === 'delivery-boy' && <InputField fieldName="vehicle_no" fieldLabel="Vehicle No ( Delivery Boy )" fieldType="text" />}
                    <SelectNew 
                        md="4"
                        fieldName="emp_id_type"
                        fieldLabel="Employee Id Type"
                        options={idtypes}
                        isMultiple={false}
                        value={values.emp_id_type}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        error={errors.emp_id_type}
                        touched={touched.emp_id_type}
                    />
                    <InputImage 
                        fieldName="emp_id" 
                        fieldLabel="Employee Id" 
                        md={"4"} 
                        placeHolder="Employee Id"
                        image={values.emp_id_image}
                        changeImage={e => setFieldValue("emp_id_image",e.target.files[0])} 
                        fileType={"image/*"}
                    />
                    <ImageInput 
                        fieldName="profilePic" 
                        fieldLabel="Profile Picture"
                        md="4" 
                        image={values.profilePic}
                        changeImage={e => setFieldValue("profilePic", e.target.files[0])} 
                        imageHelperText="512 x 512" 
                    />
            </Row>
            <Row>
                <center>
                    <Buttons backColor="#D42F45" buttonText="ADD" buttonAction={handleSubmit}>
                        <IoMdAdd style={{color:'#FFFFFF'}} fontSize="2.3em" />
                    </Buttons>
                </center>
            </Row>
            </Form>
                )}
            />
            <Modals showModal={openSubModal} hideModal={closeSubModel} headerColor={subHeaderColor} modalTitle={subHeaderTitle} modalSize="sm">
                <Row className="mb-3">
                    <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" controlId="formGridAddress" className="formStyle">
                        <Form.Label className="formLabel">Role</Form.Label>
                        <Form.Control className="inputStyle"  value={roleName} onChange={e => setRoleName(e.target.value)} />
                    </Form.Group>
                </Row>
                <center>
                    <Buttons backColor="#D42F45" buttonText="ADD" buttonAction={newRole}>
                        <IoMdAdd style={{color:'#FFFFFF'}} fontSize="2.3em" />
                    </Buttons>
                </center>
            </Modals>
            <Modals showModal={openSubModalId} hideModal={closeSubModelId} headerColor={subHeaderColor} modalTitle={subHeaderTitle} modalSize="sm">
                <Row className="mb-3">
                    <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" controlId="formGridAddress" className="formStyle">
                        <Form.Label className="formLabel">ID Type</Form.Label>
                        <Form.Control className="inputStyle"  value={idtypeName} onChange={e => setIdtypeName(e.target.value)} />
                    </Form.Group>
                </Row>
                <center>
                    <Buttons backColor="#D42F45" buttonText="ADD" buttonAction={newIdtype}>
                        <IoMdAdd style={{color:'#FFFFFF'}} fontSize="2.3em" />
                    </Buttons>
                </center>
            </Modals>
        </Modals>
    )
}

export default NewUser
