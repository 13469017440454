import reactotron from "../../ReactotronConfig";
import { 
    LOADING 
} from "../constants/userConstants"
import axios from '../../CustomAxios'
import { 
    DELETE_SLIDER_FAIL,
    DELETE_SLIDER_SUCCESS,
    DELETE_STORETYPE_FAIL,
    DELETE_STORETYPE_SUCCESS,
    NEW_SLIDER_FAIL,
    NEW_SLIDER_SUCCESS,
    NEW_STORE_FAIL,
    NEW_STORE_SUCCESS,
    NEW_STORE_TYPE_FAIL, 
    NEW_STORE_TYPE_SUCCESS, 
    STORE_DELETE_FAIL, 
    STORE_DELETE_SUCCESS, 
    STORE_DETAILS_FAIL, 
    STORE_DETAILS_SUCCESS, 
    STORE_LIST_FAIL, 
    STORE_LIST_SUCCESS, 
    STORE_TYPE_LIST_FAIL, 
    STORE_TYPE_LIST_SUCCESS,
    STORE_UNDER_FRANCHISEE_FAIL,
    STORE_UNDER_FRANCHISEE_SUCCESS,
    STORE_UPDATE_FAIL,
    STORE_UPDATE_SUCCESS,
    UPDATE_STORETYPE_FAIL,
    UPDATE_STORETYPE_SUCCESS
} from "../constants/storeConstants";


export const getStoreLists = () => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/stores`)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: STORE_LIST_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: STORE_LIST_FAIL,
            payload: error
        })
    });
}


//ADD NEW STORE
export const addNewStore = (form_data) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/create-store`, form_data,{
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_STORE_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_STORE_FAIL,
            payload: error
        })
    });
}


//GET STORE TYPES
export const getStoreTypes = () => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/store-types`)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        let details = [];
        await response.data.map(store => {
            details.push({ ...store, id: store._id, label: store.name, value : store.name })
        })
        dispatch({
            type: STORE_TYPE_LIST_SUCCESS,
            payload: details
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: STORE_TYPE_LIST_FAIL,
            payload: error
        })
    });
}

//SAVE Store Types
export const saveStoresType = (formData) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/create-storetype`, formData,{
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_STORE_TYPE_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_STORE_TYPE_FAIL,
            payload: error
        })
    });
}

//Update Store Type
export const updateStoresType = (formData) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/edit-storetype`, formData,{
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: UPDATE_STORETYPE_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: UPDATE_STORETYPE_FAIL,
            payload: error
        })
    });
}


//Delete Store Type By ID
export const deleteStoreTypeById = (id) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.delete(`admin/delete-storetype/${id}`)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: DELETE_STORETYPE_SUCCESS,
            payload: id
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: DELETE_STORETYPE_FAIL,
            payload: error
        })
    });
}

//Get Store Details By Id
export const getStoreDetails = (storeId) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.get(`admin/store/${storeId}`, storeId)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: STORE_DETAILS_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: STORE_DETAILS_FAIL,
            payload: error
        })
    });
}

export const updateStore = (data) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.post(`admin/edit-store`, data)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: STORE_UPDATE_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: STORE_UPDATE_FAIL,
            payload: error
        })
    });
}


//DELETE Store By Id
export const deleteStoresById = (id) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.delete(`admin/delete-store/${id}`)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: STORE_DELETE_SUCCESS,
            payload: id
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: STORE_DELETE_FAIL,
            payload: error
        })
    });
}

//ADD SLIDER TO STORE
export const addSlider = (form_data) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.post(`admin/add-slide`, form_data)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: NEW_SLIDER_SUCCESS,
            payload: response.data.slider
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_SLIDER_FAIL,
            payload: error
        })
    });
}


//Delete Slider
export const deleteSliderById = (id) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.delete(`admin/delete-slide/${id}`)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: DELETE_SLIDER_SUCCESS,
            payload: id
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: DELETE_SLIDER_FAIL,
            payload: error
        })
    });
}


//Get List of stores under Franchisee
export const listStores = (franchisee) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.get(`admin/list-stores/${franchisee}`)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })

        response.data.map(st => {
            st.label = st.name;
            st.value = st.name
        })
        
        dispatch({
            type: STORE_UNDER_FRANCHISEE_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => { 
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: STORE_UNDER_FRANCHISEE_FAIL,
            payload: error
        })
    });
}


