import reactotron from "../../ReactotronConfig";
import { 
    LOADING 
} from "../constants/userConstants"
import axios from '../../CustomAxios'
import { 
    DELETE_SURPRISE_FAIL,
    DELETE_SURPRISE_SUCCESS,
    NEW_SURPRISE_FAIL, 
    NEW_SURPRISE_SUCCESS, 
    SURPRISE_DETAILS_FAIL, 
    SURPRISE_DETAILS_SUCCESS, 
    SURPRISE_LIST_FAIL, 
    SURPRISE_LIST_SUCCESS,
    UPDATE_SURPRISE_FAIL,
    UPDATE_SURPRISE_SUCCESS
} from "../constants/surpriseConstants";



//GET surprise
export const getSurprises = () => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/packages`)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: SURPRISE_LIST_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: SURPRISE_LIST_FAIL,
            payload: error
        })
    });
}

//SAVE surprise
export const saveSurprise = (details) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/create-package`, details)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_SURPRISE_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_SURPRISE_FAIL,
            payload: error
        })
    });
}

//Update surprise
export const updateSurprise = (formData) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/edit-package`, formData,{
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: UPDATE_SURPRISE_SUCCESS,
            payload: response.data.surprisePackage
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: UPDATE_SURPRISE_FAIL,
            payload: error
        })
    });
}


//Delete surprise By ID
export const deleteSingleSurpriseById = (id) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.delete(`admin/delete-package/${id}`)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: DELETE_SURPRISE_SUCCESS,
            payload: id
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: DELETE_SURPRISE_FAIL,
            payload: error
        })
    });
}



//Get Details of single Surprise
export const getSingleSurprise = (id) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.delete(`admin/package/${id}`)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: SURPRISE_DETAILS_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: SURPRISE_DETAILS_FAIL,
            payload: error
        })
    });
}