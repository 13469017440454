import React, { Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import Header from '../../components/Header'
import Layout from '../layout'
import DashCard from './DashCard'
import Graph from './Graph'
import RecentOrders from './RecentOrders'
import Revenue from './Revenue'

const Dashboard = () => {
    return (
        <Layout>
            <Header headerText="DASHBOARD" />
            <DashCard />
            <Row>
                <Col xs="12" sm="12" md="4" xl="4" lg="4">
                    <Graph />
                </Col>
                <Col xs="12" sm="12" md="8" xl="8" lg="8">
                    <Revenue />
                </Col>
            </Row>
            <RecentOrders />
        </Layout>
    )
}

export default Dashboard
