import { 
    STORE_PAYOUT_FAIL, 
    STORE_PAYOUT_SUCCESS, 
    FRANCHISEE_PAYOUT_FAIL, 
    FRANCHISEE_PAYOUT_SUCCESS,
    DELIVERYBOY_PAYOUT_FAIL, 
    DELIVERYBOY_PAYOUT_SUCCESS, 
    ADMIN_PAYOUT_FAIL, 
    ADMIN_PAYOUT_SUCCESS,
    STORE_PAYOUT_INPUT,
} from "../constants/payoutConstants";

export  const storepayoutReducer = (state = {}, action) => {
    switch(action.type){

        case STORE_PAYOUT_SUCCESS:
            return {
                ...state,
                storePayout: action.payload
            }

        case STORE_PAYOUT_FAIL:
            return{
                ...state,
                error: action.payload
            }

        case STORE_PAYOUT_INPUT:
            return{
                ...state,
                [action.payload.prop] :  action.payload.value
            }
        
        default: 
            return state;
    }
}

export  const franchiseepayoutReducer = (state = {}, action) => {
    switch(action.type){

        case FRANCHISEE_PAYOUT_SUCCESS:
            return {
                ...state,
                franchiseePayout: action.payload
            }

        case FRANCHISEE_PAYOUT_FAIL:
        
        default: 
            return state;
    }
}

export  const deliverypayoutReducer = (state = {}, action) => {
    switch(action.type){

        case DELIVERYBOY_PAYOUT_SUCCESS:
            return {
                ...state,
                deliveryPayout: action.payload
            }

        case DELIVERYBOY_PAYOUT_FAIL:
        
        default: 
            return state;
    }
}

export  const adminpayoutReducer = (state = {}, action) => {
    switch(action.type){

        case ADMIN_PAYOUT_SUCCESS:
            return {
                ...state,
                adminPayout: action.payload
            }

        case ADMIN_PAYOUT_FAIL:
        
        default: 
            return state;
    }
}