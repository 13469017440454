import { 
    UNIT_LIST_SUCCESS,
    UNIT_LIST_FAIL, 
    RESET_UNIT,
    NEW_UNIT_SUCCESS,
    NEW_UNIT_FAIL,
    DELETE_UNIT_SUCCESS,
    DELETE_UNIT_FAIL,
    UPDATE_UNIT_SUCCESS,
    UPDATE_UNIT_FAIL
} from "../constants/unitConstants";

export  const unitReducer = (state = {}, action) => {
    switch(action.type){

        case UNIT_LIST_SUCCESS:
            return {
                ...state,
                unitLists: action.payload
            }

        case UNIT_LIST_FAIL:
            return {
                ...state,
                error: action.payload.message
            }
        case UPDATE_UNIT_FAIL:
            return {
                ...state,
                error: action.payload.message
            }
        case DELETE_UNIT_FAIL:
            return {
                ...state,
                error: action.payload.message
            }
        case DELETE_UNIT_SUCCESS:
            return {
                ...state,
                unitLists: state.unitLists.filter(un => un._id !== action.payload ),
                deleteUnit: true
            }
        case UPDATE_UNIT_SUCCESS:
            return {
                 ...state,
                 unitLists: state.unitLists.map(un => un._id === action.payload._id ? action.payload : un),
                updateSuccess: true
            }
        case NEW_UNIT_FAIL:
            return {
                ...state,
                error: action.payload.message
            }

        case RESET_UNIT:
            return{
                ...state,
                error: null,
                unitSuccess: null,
                deleteUnit: null,
                updateSuccess:null
            }

        

        case NEW_UNIT_SUCCESS:
            return {
                ...state,
                unitLists : state.unitLists ? [...state.unitLists, action.payload.unit] : [ action.payload.unit ],
                unitSuccess: true
            }


        default: 
            return state;
    }
}