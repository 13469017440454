import React, { Fragment, useEffect, useState } from 'react'
import { Card, Col } from 'react-bootstrap'
import {IoMdAddCircle,IoMdAdd,IoMdClock}from 'react-icons/io'
import { MdCheck, MdClose } from 'react-icons/md'
import Pagination from 'react-paginating';
import ViewOrder from './ViewOrder';
import EditOrder from './EditOrder';
import NewOrder from './NewOrder'
import Search from '../../components/Search'
import Paging from '../../components/Paging'
import {ImCross} from 'react-icons/im'
import {MdEdit,MdDeliveryDining,MdDownloadDone} from 'react-icons/md'
import {AiFillDelete,AiFillEye} from 'react-icons/ai'
import {BsToggle2On,BsToggle2Off,BsFillXCircleFill} from 'react-icons/bs'
import { useSelector, useDispatch } from 'react-redux'
import { getOrderLists, getStoresOrder } from '../../Redux/actions/orderActions'
import { toast } from 'react-toastify'
import { CHANGE_ORDER_INPUT, RESET_ORDER } from '../../Redux/constants/orderConstants'
import moment from 'moment'
import reactotron from '../../ReactotronConfig'



const OrderList = ({storeId}) => {

    //reactotron.log({storeId})
    const { orderList, error, orderSuccess } = useSelector(state => state.order)

    const dispatch = useDispatch();

    const [modalShow, setModalShow] = useState(false);
    const [modalviewShow, setModalviewShow] = useState(false);
    const [modalnewShow, setModalnewShow] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [datas, setDatas] = useState([])
    const [headerColor, setHeaderColor] = useState('')
    const [headerTitle, setHeaderTitle] = useState('')
    const [search, setSearch] = useState('')
    

    


    const createNewOrder = () => {
        setHeaderColor('#D42F45');
        setHeaderTitle("ADD ORDER");
        setModalnewShow(true);
    }
    const viewOrder = (id) => {
        setHeaderColor('#417AE8');
        setHeaderTitle("VIEW ORDER");
        setModalviewShow(true);
    }
    const editOrder = (id) => {
        setHeaderColor('#FF9900');
        setHeaderTitle("EDIT ORDER");
        setModalShow(true);
    }
    useEffect(() => {
        if(orderList?.length>0){
            const indexOfLastPost = currentPage * 10;
            const indexOfFirstPost = indexOfLastPost - 10;
            const currentPosts = (orderList || []).slice(indexOfFirstPost, indexOfLastPost);
            setDatas(currentPosts)
        }
        
    }, [orderList])

    const pageChanged = (page)=> {
        setCurrentPage(page)
        const indexOfLastPost = page * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const currentPosts = (orderList || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }
    useEffect(() => {
        if(orderSuccess){
            setModalShow(false);
            dispatch({
                type: RESET_ORDER
            })
            toast.success("New Order Added Successfully");
        }
    }, [orderSuccess])

    useEffect(() => {
        
    }, [search])

    useEffect(() => {
        if(storeId){
            dispatch(getStoresOrder(storeId))
        }
        else{
            dispatch(getOrderLists())
        }
        
    }, [])

    return (
        <Fragment>
            <Search placeHolder="SEARCH ORDER" />
            <Card style={{ borderRadius: 20, margin: 10, minHeight: '70vh' }}>
                {/*<IoMdAddCircle className="add" fontSize="3.5em" 
                onClick={createNewOrder}
    style={{ position: 'absolute', top: -30, right: -20, cursor: 'pointer' }} />*/}
               
                <Card.Body style={{ overflowX: 'auto' }}>
                    <table>
                        <thead className="tableHeader">
                            <tr>
                                <th>Order ID</th>
                                <th>Order Details</th>
                                <th>Total Price</th>
                                <th>Customer Name</th>
                                <th>Location</th>
                                <th>Date & Time</th>
                                {/* <th>Order Status</th> */}
                                <th>Delivery Status</th>
                                <th>Delivery Type</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {datas && datas.map((order, index) => (
                                <tr key={index}>
                                    <td class="tableContent">{order.storeID}</td>
                                    <td class="tableContent">{order.details}</td>
                                    <td class="tableContent">{order.total_price}</td>
                                    <td class="tableContent">{order.customer_name}</td>
                                    <td class="tableContent">{order.location}</td>
                                    <td class="tableContent">{order.date}</td>
                                    <td class="tableContent">
                                        {order.delivery_status === 'Active' ? <span className="accepted">ACCEPTED</span> : order.delivery_status === 'Inactive' ? <span className="canceled">CANCELED</span> : (<div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'center' }}>
                                            <MdCheck fontSize="1.5em" style={{ color: '#4AAD47', margin: 5 }} /> 
                                            <MdClose fontSize="1.5em" style={{ color: '#D42F45', margin: 5 }} />
                                        </div>)}
                                    </td>
                                    {/* <td class="tableContent">
                                        {order.status === 'active' ? <BsToggle2On color="#0DD404" fontSize="1.5em"/> : <BsToggle2Off fontSize="1.5em" color="#F95555" />}
                                    </td> */}
                                    <td>{order.delivery_type=='ontheway'?<MdDeliveryDining color="orange"  fontSize="2.5em"/>:<IoMdClock color="skyblue"  fontSize="2.5em"/>}</td>
                                    <td className="tableContent"><AiFillEye color="blue" onClick={viewOrder}/>
                                        <MdEdit color="orange" onClick={editOrder}/>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Card.Body>
                <Col style={{ float: 'right', display:'flex', flexDirection: 'row-reverse', justifyContent: 'flex-start', alignItems: 'center', margin: 30  }}>
                <Card >
                    {orderList && orderList.length>10 && <Paging datas={orderList} currentPage={currentPage} pageChange={pageChanged} />}
                </Card>
                </Col>
            </Card>
            <NewOrder openModal={modalnewShow} closeModal={()=> setModalnewShow(false)} headerColor={headerColor} headerTitle={headerTitle} />
            <ViewOrder openModal={modalviewShow} closeModal={()=> setModalviewShow(false)} headerColor={headerColor} />
            <EditOrder openModal={modalShow} closeModal={()=> setModalShow(false)} headerColor={headerColor}/>
        </Fragment>
    )
}
export default OrderList
