import React, { useState, useEffect } from 'react'
import { Row, Form, Col, InputGroup } from 'react-bootstrap'
import Modals from '../../components/Modals'
import {
    MdUpload
} from 'react-icons/md'
import {
    IoMdAdd
} from 'react-icons/io'
import Buttons from '../../components/Buttons'
import reactotron from '../../ReactotronConfig'
import { useSelector, useDispatch } from 'react-redux'
import { saveNewFranchisee } from '../../Redux/actions/franchiseeActions'
import { toast } from 'react-toastify'
import {Formik, Field} from 'formik';
import * as Yup from 'yup';
import "yup-phone";
import InputField from '../../components/InputField'
import FormSelectField from '../../components/form-select-field'
import { RESET_ERROR } from '../../Redux/constants/franchiseeConstants'
import {getProofs } from '../../Redux/actions/userActions'

const FranchiseeForm = ({openModal, closeModal, headerColor, headerTitle}) => {


    const { idtypes} = useSelector(state => state.user)


    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(getProofs())
        
    }, [])
    

    const saveFranchisee = (values) => {
        values.status =  values.status === true ? 'Active' : 'Inactive';
        dispatch(saveNewFranchisee(values))
    }


    return (
        <Modals showModal={openModal} hideModal={closeModal} headerColor={headerColor} modalTitle={headerTitle}>
            <Formik 
                initialValues={{
                    name: '',
                    employee_name: '',
                    email: '',
                    mobile: '',
                    city: '',
                    password: '',
                    confirmPassword: '',
                    emp_proof_type: '',
                    status: '',
                    emp_id: ''
                }}
                validationSchema={
                    
                    Yup.object({
                        name: Yup.string().required('Name Required'),
                        employee_name: Yup.string().required('Employee Name Required'),
                        email: Yup.string().email('Invalid email address').required('Store Email Required'),
                        mobile: Yup.string().phone("IN").required('Phone Number is Required'),
                        password: Yup.string().required('Password is required'),
                        confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
                        city: Yup.string().required("City is Required"),
                        emp_proof_type: Yup.string().required("Employee ID Type Required")
                    })
                }
                onSubmit={(values) => {
                    console.log(values)
                    saveFranchisee(values)
                }}
                render={({handleChange, handleSubmit, handleBlur, values, errors}) => (
                <Form>
                    <Row className="mb-3">
                        <InputField fieldName="name" fieldLabel="Franchisee Name" />
                        <InputField fieldName="employee_name" fieldLabel="Employee Name" />
                        <InputField fieldName="email" fieldLabel="Email Address"  />
                        <InputField fieldName="mobile" fieldLabel="Phone Number" />
                        <InputField fieldName="password" fieldLabel="Password" fieldType="password" />
                        <InputField fieldName="confirmPassword" fieldLabel="Confirm Password" fieldType="password" />
                        <InputField fieldName="city" fieldLabel="City" fieldType="text" />
                        <FormSelectField
                                        md="4"
                                        label="Employee Id Type"
                                        type="text"
                                        name="emp_proof_type"
                                        
                                    >
                                        <option value="">Select Id</option>
                                        {idtypes && idtypes.map((user, index) => (
                                            <option key={index} value={user.value}>{user.value}</option>
                                        ))}
                                        
                    </FormSelectField>
                        <Form.Group as={Col} xs="12" sm="12" md="6" lg="4" xl="4" controlId="emp_id" className="formStyle">
                            <Form.Label className="formLabel">Employee ID</Form.Label>
                            <InputGroup className="mb-2">
                                <Field name="emp_id">
                                    {({field, form, meta}) =>  <Form.Control 
                                        className="workStyle"  
                                        placeholder="Type or Upload ID Proof" 
                                        value={field.value} 
                                        onChange={field.onChange} 
                                    /> }
                                </Field>
                                
                                <InputGroup.Text style={{color:'#1B2F5B',backgroundColor:'#FFFFFF',borderColor:'#B4CBF7' , borderTopRightRadius: 10, borderBottomRightRadius: 10}}>
                                    <MdUpload fontSize="1.5em"  />
                                    <input type="file" style={{ opacity: 0, width: 50, position:'absolute', right: 0 }} />
                                </InputGroup.Text>
                            </InputGroup>
                        </Form.Group>
                        {headerTitle === 'VIEW FRANCHISEE' && (
                            <Form.Group controlId="formGridName" as={Col}  xs="12" sm="12" md="6" lg="4" xl="4" className="formStyle">
                                <Form.Label className="formLabel">Created On</Form.Label>
                                <Form.Control className="inputStyle" type="text"  />
                            </Form.Group>
                        )}
                        <Form.Group as={Col} xs="12" sm="12" md="6" controlId="status" className="formStyle">
                                <Form.Label className="formLabel">Active Status</Form.Label>
                                <div style={{ display: 'flex', flexDirection: 'row', height: 40, borderRadius: 10, border: "1px solid #417AE85E", backgroundColor: '#417AE80A', alignItems:'center', justifyContent:'center' }}>
                                    <span style={{ marginRight: 10, fontFamily: "Avenir-Black", color: '#1B2F5B' }}>Deactive</span>
                                    <Field 
                                        name='Status'
                                    >
                                        {({field, form, meta}) => <Form.Check 
                                                type="switch"
                                                id="status"
                                                label="Active"
                                                value={field.value} 
                                                onChange={field.onChange}
                                                style={{ fontFamily: "Avenir-Black", color: '#1B2F5B' }}
                                            />}
                                    </Field>
                                </div>
                            </Form.Group>
                    </Row>
                    {headerTitle === 'ADD FRANCHISEE' && <Row>
                        <center>
                            <Buttons backColor="#D42F45" buttonText="ADD" buttonAction={handleSubmit}>
                                <IoMdAdd style={{color:'#FFFFFF'}} fontSize="2.3em" />
                            </Buttons>
                        </center>
                    </Row>}
                </Form>
            )}
        />
        </Modals>
    )
}

export default FranchiseeForm
