import React, { Fragment, useState } from 'react'
import { Card, Col,Row } from 'react-bootstrap'
import Header from '../../components/Header'
import Layout from '../layout'
import './style.scss'
import PushNotification from './PushNotification'
import SmsNotification from './SmsNotification'
import EmailNotification from './EmailNotification'
import Templates from './Templates'
import { Route, Link, useRouteMatch } from 'react-router-dom'



const Notifications = ({history}) =>  {

    //const history = createBrowserHistory();

    const match = useRouteMatch()

    console.log({history})

    //const path = history.location.pathname;

    return (
        
        <Layout>
        <Header headerText="NOTIFICATIONS" />
        <Row style={{  width: "100%" }}>
            <Col xs="12" sm="12" md="6" lg="4" xl="3" style={{  borderRadius: 20, marginTop: 30, marginBottom: 30 }}>
                <Link to={`${match.path}/push`} style={{ 
                        color: (history.location.pathname === `${match.path}/push` || history.location.pathname === `${match.path}`) ? '#fff' : '#545454', 
                        textDecoration:'none', 
                         
                    }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: (history.location.pathname === `${match.path}/push` || history.location.pathname === `${match.path}`) ? '#4AAD47' : '#fff'  }}>
                        <Card.Body style={{ display: 'flex', alignItems:'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <span className="headerNotification">PUSH NOTIFICATIONS</span>
                            
                        </Card.Body>
                    
                    </Card>
                </Link>
            </Col>
            <Col xs="12" sm="12" md="6" lg="4" xl="3" style={{  borderRadius: 20, marginTop: 30, marginBottom: 30 }}>
                <Link to={`${match.path}/sms`} style={{ 
                    color: history.location.pathname === `${match.path}/sms` ? '#fff' : '#545454', 
                    textDecoration:'none', 
                    
                 }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: history.location.pathname === `${match.path}/sms` ? '#4AAD47' : '#fff' }}>
                        <Card.Body style={{ display: 'flex', alignItems:'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <span className="headerNotification">SMS NOTIFICATIONS</span>
                            
                        </Card.Body>
                    
                    </Card>
                </Link>
            </Col>
            <Col xs="12" sm="12" md="6" lg="4" xl="3" style={{  borderRadius: 20, marginTop: 30, marginBottom: 30 }}>
                <Link to={`${match.path}/email`} style={{ 
                    color: history.location.pathname === `${match.path}/email` ? '#fff' : '#545454', 
                    textDecoration:'none', 
                    
                 }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: history.location.pathname === `${match.path}/email` ? '#4AAD47' : '#fff' }}>
                        <Card.Body style={{ display: 'flex', alignItems:'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <span className="headerNotification">EMAIL NOTIFICATIONS</span>
                            
                        </Card.Body>
                    
                    </Card>
                </Link>
            </Col>
            <Col xs="12" sm="12" md="6" lg="4" xl="3" style={{  borderRadius: 20, marginTop: 30, marginBottom: 30 }}>
                <Link to={`${match.path}/templates`} style={{ 
                    color: history.location.pathname === `${match.path}/templates` ? '#fff' : '#545454', 
                    textDecoration:'none', 
                    
                }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: history.location.pathname === `${match.path}/templates` ? '#4AAD47' : '#fff'  }}>
                        <Card.Body style={{ display: 'flex', alignItems:'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <span className="headerNotification">TEMPLATES</span>
                            
                        </Card.Body>
                    
                    </Card>
                </Link>
            </Col>
        </Row> 
            <Route path={`${match.path}`} exact>
                <PushNotification/>   
            </Route>
            <Route path={`${match.path}/push`} exact>
                <PushNotification/>   
            </Route>
            <Route path={`${match.path}/sms`} exact>
                <SmsNotification/>   
            </Route>
            <Route path={`${match.path}/email`} exact>
                <EmailNotification/>   
            </Route>
            <Route path={`${match.path}/templates`} exact>
                <Templates/>   
            </Route>
        
        
        
         
        </Layout>
       
    )
}

export default Notifications
