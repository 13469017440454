import { 
    DELETE_PRODUCT_FAIL,
    DELETE_PRODUCT_SUCCESS,
    NEW_PRODUCT_FAIL,
    NEW_PRODUCT_SUCCESS,
    PRODUCT_DETAILS_FAIL,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_LIST_FAIL, 
    PRODUCT_LIST_SUCCESS, 
    SEARCH_PRODUCT_SUCCESS,
    SEARCH_PRODUCT_FAIL, 
    UPDATE_PRODUCT_FAIL, 
    UPDATE_PRODUCT_SUCCESS
} from "../constants/storeConstants";
import { LOADING } from "../constants/userConstants";
import axios from '../../CustomAxios'


//Get Products Lists
export const getProductsList = (storeId) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })



    await axios.get(`admin/show-products/${storeId}`)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: PRODUCT_LIST_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: PRODUCT_LIST_FAIL,
            payload: error
        })
    });
}


//Save New Products
export const saveNewProduct = (form_data) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/create-product`, form_data)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: NEW_PRODUCT_SUCCESS,
            payload: response.data.product
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_PRODUCT_FAIL,
            payload: error
        })
    });
}

//Get Product Details
export const getProductDetails = (productId) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/product/${productId}`)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: PRODUCT_DETAILS_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: PRODUCT_DETAILS_FAIL,
            payload: error
        })
    });
}


//Update Product
export const updateProduct = (form_data) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/edit-product`, form_data)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: UPDATE_PRODUCT_SUCCESS,
            payload: response.data.product
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: UPDATE_PRODUCT_FAIL,
            payload: error
        })
    });
}

//DELETE Product by iD
export const deleteProductsById = (id) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.delete(`admin/delete-product/${id}`)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: DELETE_PRODUCT_SUCCESS
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: DELETE_PRODUCT_FAIL,
            payload: error
        })
    });
}

//Search Product by product Name
export const SearchProduct = (data) => async(dispatch) => {

    await axios.post(`admin/get-products`,data)  
    .then(async response => {
        dispatch({
            type: SEARCH_PRODUCT_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: SEARCH_PRODUCT_FAIL,
            payload: error
        })
    });
}