import { 
    SERVICE_LIST_SUCCESS,
    SERVICE_LIST_FAIL, 
    RESET_SERVICE,
    NEW_SERVICE_SUCCESS,
    NEW_SERVICE_FAIL,
    DELETE_SER_LISTING_SUCCESS,
    DELETE_SER_LISTING_FAIL,
    SER_LISTING_DETAILS_SUCCESS,
    SER_LISTING_DETAILS_FAIL,
    SER_LIST_UPDATE_SUCCESS,
    SER_LIST_UPDATE_FAIL,
    SUB_SERVICE_SUCCESS
} from "../constants/serviceConstants";

export  const servicelistingReducer = (state = {}, action) => {
    switch(action.type){

        case SERVICE_LIST_SUCCESS:
            return {
                ...state,
                serviceListing: action.payload
            }

        case SERVICE_LIST_FAIL:
        case DELETE_SER_LISTING_FAIL:
        case   SER_LIST_UPDATE_FAIL:
        case SER_LISTING_DETAILS_FAIL:
        case NEW_SERVICE_FAIL:
            return {
                ...state,
                error: action.payload.message
            }

        case DELETE_SER_LISTING_SUCCESS:
            return {
                ...state,
                serviceListing: state.serviceListing.filter(ser => ser._id !== action.payload),
                deleteServicelisting: true
            }

        case RESET_SERVICE:
            return{
                ...state,
                error: null,
                serviceSuccess: null,
                deleteServicelisting: null,
                updateSuccess:null,
            }

        

        case NEW_SERVICE_SUCCESS:
            return {
                ...state,
                serviceListing : state.serviceListing ? [...state.serviceListing, action.payload.service] : [ action.payload.service ],
                serviceSuccess: true
            }

        case SER_LIST_UPDATE_SUCCESS:
            return {
                ...state,
                serviceListing: state.serviceListing.map(ser => ser._id === action.payload._id ? action.payload: ser),
                updateSuccess: true
            }

        case SER_LISTING_DETAILS_SUCCESS:
            return{
                ...state,
                serviceDetails: action.payload
            }

        case SUB_SERVICE_SUCCESS:
            return{
                ...state,
                subServices: action.payload
            }

        default: 
            return state;
    }
}