export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS';
export const ORDER_LIST_FAIL = 'ORDER_LIST_FAIL';
export const RESET_ORDER = 'RESET_ORDER'

export const NEW_ORDER_TYPE_SUCCESS = 'NEW_ORDER_TYPE_SUCCESS';
export const NEW_ORDER_TYPE_FAIL = 'NEW_ORDER_TYPE_FAIL';

export const ORDER_TYPE_LIST_SUCCESS = 'ORDER_TYPE_LIST_SUCCESS';
export const ORDER_TYPE_LIST_FAIL = 'ORDER_TYPE_LIST_FAIL';

export const NEW_ORDER_SUCCESS = 'NEW_ORDER_SUCCESS';
export const NEW_ORDER_FAIL = 'NEW_ORDER_FAIL';

export const CHANGE_ORDER_INPUT = 'CHANGE_ORDER_INPUT'