import React, { useState, useEffect } from 'react'
import Pagination from 'react-paginating';

const Paging = ({datas, currentPage, pageChange}) => {

  const [limit, setLimit] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [total, setTotal] = useState(datas.length);


  useEffect(() => {
    let count = Math.ceil(datas.length/10)
    if(count>5){
      setPageCount(5)
    }
    else{
      setPageCount(count)
    }
  }, [])



  return (
    <Pagination
      className="bg-red"
      total={total}
      limit={limit}
      pageCount={pageCount}
      currentPage={currentPage}
    >
      {({
        pages,
        currentPage,
        hasNextPage,
        hasPreviousPage,
        previousPage,
        nextPage,
        totalPages,
        getPageItemProps
      }) => (
        <div>
          <button
          style={{ backgroundColor:'#fff', color:  currentPage === 1 ? '#D42F45' : '#4D4F5C', fontSize: 14, border: "1px solid #D7DAE2" }}
            {...getPageItemProps({
              pageValue: 1,
              onPageChange: pageChange
            })}
          >
            First
          </button>

          {hasPreviousPage && (
            <button
              style={{ backgroundColor:'#fff',  fontSize: 14, border: "1px solid #D7DAE2" }}
              {...getPageItemProps({
                pageValue: previousPage,
                onPageChange: pageChange
              })}
            >
              {'<'}
            </button>
          )}

          {pages.map(page => {
            let activePage = null;
            if (currentPage === page) {
              activePage = { backgroundColor: '#D42F45', fontSize: 14, border: "1px solid #D7DAE2", color: '#fff' };
            }
            else{
              activePage={ backgroundColor:'#fff',  fontSize: 14, border: "1px solid #D7DAE2" }
            }
            return (
              <button
                {...getPageItemProps({
                  pageValue: page,
                  key: page,
                  style: activePage,
                  onPageChange: pageChange
                })}
              >
                {page}
              </button>
            );
          })}

          {hasNextPage && (
            <button
            style={{ backgroundColor:'#fff',  fontSize: 14, border: "1px solid #D7DAE2" }}
              {...getPageItemProps({
                pageValue: nextPage,
                onPageChange: pageChange
              })}
            >
              {'>'}
            </button>
          )}

          <button
          style={{ backgroundColor:'#fff', color:  currentPage === totalPages ? '#D42F45' : '#4D4F5C', fontSize: 14, border: "1px solid #D7DAE2" }}
            {...getPageItemProps({
              pageValue: totalPages,
              onPageChange: pageChange
            })}
          >
            Last
          </button>
        </div>
      )}
    </Pagination>
  )
}

export default Paging
