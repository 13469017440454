import { 
    COUPON_LIST_FAIL,
    COUPON_LIST_SUCCESS,
    NEW_COUPON_FAIL, 
    NEW_COUPON_SUCCESS, 
    RESET_ERROR, 
    UPDATE_COUPON_SUCCESS,
    UPDATE_COUPON_FAIL,
    DELETE_COUPON_SUCCESS,
    DELETE_COUPON_FAIL
} from "../constants/couponConstants";

export  const couponReducer = (state = { }, action) => {
    switch(action.type){

        case NEW_COUPON_SUCCESS:
            return {
                ...state,
                couponLists : state.couponLists ? [...state.couponLists, action.payload.coupon] : [ action.payload.coupon ],
                newCoupon: true
            }

        case NEW_COUPON_FAIL:
        case UPDATE_COUPON_FAIL:
        case COUPON_LIST_FAIL:
            return {
                ...state,
                error: action.payload.message
            }
        case DELETE_COUPON_FAIL:
            return {
                ...state,
                error: action.payload.message
            }
        case DELETE_COUPON_SUCCESS:
            return {
                ...state,
                couponLists: state.couponLists.filter(cou => cou._id !== action.payload ),
                deleteSuccess: true
            }
        case RESET_ERROR:
            return {
                ...state,
                error: null,
                newCoupon: null,
                deleteSuccess:null,
                updateSuccess:null
            }

        case COUPON_LIST_SUCCESS:
            return {
                ...state,
                couponLists: action.payload
            }

        case UPDATE_COUPON_SUCCESS:
            
            return{
                ...state,
                couponLists: state.couponLists.map(cou => cou._id === action.payload._id ? action.payload : cou),
                updateSuccess: true
            }

        default:
            return state;
    }
}