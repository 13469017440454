import { 
    NOTIFICATION_LIST_SUCCESS,
    NOTIFICATION_LIST_FAIL,
    RESET_NOTIFICATION,
    NEW_NOTIFICATION_SUCCESS,
    NEW_NOTIFICATION_FAIL,
    DELETE_NOTIFICATION_SUCCESS,
    DELETE_NOTIFICATION_FAIL,
    NOTIFICATION_UPDATE_SUCCESS,
    NOTIFICATION_UPDATE_FAIL
} from "../constants/notificationConstants";


//  NOTIFICATION

export  const notificationReducer = (state = { }, action) => {
    switch(action.type){

        case NOTIFICATION_LIST_SUCCESS:
            return{
                ...state,
                notificationList: action.payload
            }

        case NOTIFICATION_LIST_FAIL:
        case NEW_NOTIFICATION_FAIL:
        case NOTIFICATION_UPDATE_FAIL:
        case DELETE_NOTIFICATION_FAIL:
            return {
                ...state,
                error: action.payload.message
            }
        case NEW_NOTIFICATION_SUCCESS:
            return {
                ...state,
                notificationLists: state.notificationLists ? [...state.notificationLists] : [action.payload],
                newNotification: true
            }
       
        case DELETE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                deleteSuccess: true
            }
        case NOTIFICATION_UPDATE_SUCCESS:
            return {
                ...state,
                updateSuccess: true
            }
            
        case RESET_NOTIFICATION:
            return {
                ...state,
                error: null,
                newNotification: null,
                deleteSuccess:null,
                updateSuccess:null
                
            }

        default: 
            return state;
    }
}