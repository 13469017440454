import reactotron from "../../ReactotronConfig";
import { 
    LOADING 
} from "../constants/userConstants"
import axios from '../../CustomAxios'
import { 
    NEW_ORDER_FAIL,
    NEW_ORDER_SUCCESS,
    ORDER_LIST_FAIL, 
    ORDER_LIST_SUCCESS, 
    
} from "../constants/orderConstants";


export const getOrderLists = () => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/orders`)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: ORDER_LIST_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: ORDER_LIST_FAIL,
            payload: error
        })
    });
}


//ADD NEW ORDER
export const addNewOrder = (form_data) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/create-order`, form_data,{
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_ORDER_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_ORDER_FAIL,
            payload: error
        })
    });
}


//Get Order List under a store
export const getStoresOrder = (storeId) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`admin/store-orders/${storeId}`)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: ORDER_LIST_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: ORDER_LIST_FAIL,
            payload: error
        })
    });
}


