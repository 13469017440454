import React, { Fragment, useState } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import Header from '../../components/Header'
import Layout from '../layout'
import EditFranchise from './EditFranchise'
import FranchiseeList from './FranchiseeList'
import './style.scss'




const Franchisee = () => {

    const match = useRouteMatch();

    return (
        
        <Layout>
            <Route path={`${match.path}`} exact>
                <Header headerText="FRANCHISEE MANAGEMENT" />
                <FranchiseeList />
            </Route>
            <Route path={`${match.path}/edit`}>
                <EditFranchise/>   
            </Route>
            
            
        </Layout>
       
    )
}

export default Franchisee
