import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import Modalsmall from '../../components/Modalsmall'
import {MdEdit,MdUpdate} from 'react-icons/md'
import Buttons from '../../components/Buttons'
import { useSelector, useDispatch } from 'react-redux'
import { getserviceCategories, updateServiceCategory } from '../../Redux/actions/servicecategoryActions'
import { toast } from 'react-toastify'
import {Formik, Field} from 'formik';
import InputField from '../../components/InputField'
import * as Yup from 'yup';
import "yup-phone";
import { RESET_SER_CATEGORY } from '../../Redux/constants/serviceConstants'
import SelectNew from '../../components/SelectNew'

const EditServiceCategory = ({openModal, closeModal, headerColor, headerTitle, servicelist}) => {

    const { updateSuccess, mainCategories } = useSelector(state => state.servicecategory)
    const dispatch = useDispatch();
    const [initialDatas, setInitialDatas] = useState(null)

    useEffect(() => {
        
        if(updateSuccess){
                closeModal(true)
                dispatch({
                    type: RESET_SER_CATEGORY
                })
                toast.success("Service Category updated Successfully");
            }
        
       
    }, [updateSuccess])

    //get Service Main Categories
    useEffect(() => {
        dispatch(getserviceCategories())
    }, [])
    
    useEffect(() => {
        async function getDetails() {

            if(servicelist && mainCategories){
                let cat = await mainCategories.find(cat => cat.id === servicelist.mainCategoryID)
                console.log({cat, servicelist, mainCategories})
                const details = {
                    name: servicelist.name,
                    mainCategory: cat,
                    requestBy: servicelist.requestBy,
                    id:servicelist._id
                  
                }
    
                
                console.log({details})
                setInitialDatas(details)
            }
        }

        getDetails();
    }, [servicelist, mainCategories])

    const updateService =  (values) =>  {


        let details = {
            id: values.id,
            name: values.name,
            mainCategory: values.mainCategory.value,
            mainCategoryID: values.mainCategory.id,
            requestBy: values.requestBy
        }
        dispatch(updateServiceCategory(details));
       
    }


    return (
        
        <Modalsmall showModal={openModal} hideModal={closeModal} headerColor={headerColor} modalTitle={headerTitle} >
            {initialDatas && 
            <Formik 
                initialValues={initialDatas}
                onSubmit={(values) => {
                    console.log(values)
                    updateService(values)
                }}
                render={({handleChange, handleSubmit, handleBlur, setFieldTouched, setFieldValue, touched, values, errors}) => (
                    <Form>
                        <SelectNew 
                            md="12"
                            fieldName="mainCategory"
                            fieldLabel="Main Category"
                            options={mainCategories}
                            isMultiple={false}
                            value={values.mainCategory}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            error={errors.mainCategory}
                            touched={touched.mainCategory}
                        />
                        <InputField fieldName="name" fieldLabel="Category Name" fieldType="text" md="12" />
                        {headerTitle === 'EDIT CATEGORY' && <Row>
                <center>
                    <Buttons backColor="#FF9900" buttonText="UPDATE" buttonAction={handleSubmit}>
                            <MdUpdate style={{color:'#FFFFFF'}} fontSize="2.3em" />
                    </Buttons>
                </center>
            </Row>}
                    </Form>
                )}
            />}
        </Modalsmall>
        
    )
}

export default EditServiceCategory
