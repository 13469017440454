import React, { useState, useEffect } from 'react'
import { Row, Form, Col, InputGroup, Card } from 'react-bootstrap'
import Modals from '../../components/Modals'
import {
    MdUpload
} from 'react-icons/md'
import Buttons from '../../components/Buttons'
import { useSelector, useDispatch } from 'react-redux'
import { saveNewFranchisee, updateFranchiseeDetails } from '../../Redux/actions/franchiseeActions'
import { toast } from 'react-toastify'
import {Formik, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import "yup-phone";
import InputField from '../../components/InputField'
import FormSelectField from '../../components/form-select-field'
import { RESET_ERROR } from '../../Redux/constants/franchiseeConstants'
import { GiBackwardTime } from 'react-icons/gi'

const BasicDetails = ({franchisee}) => {


    const idTypes = [{ name: 'Aadhaar'}, { name: 'PAN' }, { name: 'Driving Licence' }]
    const dispatch = useDispatch();
    const [initialDatas, setInitialDatas] = useState(null)
    const { error, updateSuccess} = useSelector(state => state.franchisee)

    useEffect(() => {
        if(error){
            toast.error(error);
            dispatch({
                type: RESET_ERROR
            })
        }

        
        if(updateSuccess){
            
            dispatch({
                type: RESET_ERROR
            })
            toast.success("Franchisee Updated Successfully");
        }
    }, [error,updateSuccess])
    useEffect(() => {
        async function getDetails() {

            if(franchisee){
                const details = {
                    id:franchisee._id,
                    name: franchisee.name,
                    employee_name: franchisee.employee_name,
                    email: franchisee.email,
                    phonenumber: franchisee.phonenumber,
                    city: franchisee.city,
                    emp_proof_type: franchisee.emp_proof_type,
                    emp_id: franchisee.emp_id,
                    status: franchisee.status,

                }
    
                
                console.log({details})
                setInitialDatas(details)
            }
        }

        getDetails();
    }, [franchisee])
    const updateFranchisee = (values) => {
        values.status =  values.status === true ? 'Active' : 'Inactive';
        const { id,name, employee_name,email,city,emp_proof_type,phonenumber,emp_id,status } = values
       
        dispatch(updateFranchiseeDetails(id,name, employee_name,email,city,emp_proof_type,phonenumber,emp_id,status))
    }


    return (
        <Card style={{ borderRadius: 20, margin: 10 }}>
            <Card.Body>
            {initialDatas && 
            <Formik 
                 initialValues={initialDatas}
                 validationSchema={
                    
                    Yup.object({
                        name: Yup.string().required('Name Required'),
                        employee_name: Yup.string().required('Employee Name Required'),
                        email: Yup.string().email('Invalid email address').required('Store Email Required'),
                        city: Yup.string().required("City is Required"),
                        emp_proof_type: Yup.string().required("Employee ID Type Required")
                    })
                }
                onSubmit={(values) => {
                    console.log(values)
                    updateFranchisee(values)
                }}
                render={({handleChange, handleSubmit, handleBlur, values, errors}) => (
                <Form>
                    <Row className="mb-3">
                        <InputField fieldName="name" fieldLabel="Franchisee Name" />
                        <InputField fieldName="employee_name" fieldLabel="Employee Name" />
                        <InputField fieldName="email" fieldLabel="Email Address" fieldType="email" />
                        <InputField fieldName="phonenumber" fieldLabel="Phone Number" fieldType="text" />
                        <InputField fieldName="city" fieldLabel="City" fieldType="text" />
                        <FormSelectField
                            label="Employee ID Type"
                            type="text"
                            name="emp_proof_type"
                        >
                            <option value="">Select Id</option>
                            {idTypes.map((type, index) => (
                                <option key={index} value={type.name}>{type.name}</option>
                            ))}
                        </FormSelectField>
                        <Form.Group as={Col} xs="12" sm="12" md="6" lg="4" xl="4" controlId="emp_id" className="formStyle">
                            <Form.Label className="formLabel">Employee ID</Form.Label>
                            <InputGroup className="mb-2">
                                <Field name="emp_id">
                                    {({field, form, meta}) =>  <Form.Control 
                                        className="workStyle"  
                                        placeholder="Type or Upload ID Proof" 
                                        value={field.value} 
                                        onChange={field.onChange} 
                                    /> }
                                </Field>
                                
                                <InputGroup.Text style={{color:'#1B2F5B',backgroundColor:'#FFFFFF',borderColor:'#B4CBF7' , borderTopRightRadius: 10, borderBottomRightRadius: 10}}>
                                    <MdUpload fontSize="1.5em"  />
                                    <input type="file" style={{ opacity: 0, width: 50, position:'absolute', right: 0 }} />
                                </InputGroup.Text>
                            </InputGroup>
                            <ErrorMessage name="emp_id">
                                { msg => <div style={{ color: 'red' }}>{msg}</div> }
                            </ErrorMessage>
                        </Form.Group>
                        <Form.Group as={Col} xs="12" sm="12" md="6" lg="4" xl="4" controlId="status" className="formStyle">
                                <Form.Label className="formLabel">Active Status</Form.Label>
                                <div style={{ display: 'flex', flexDirection: 'row', height: 40, borderRadius: 10, border: "1px solid #417AE85E", backgroundColor: '#417AE80A', alignItems:'center', justifyContent:'center' }}>
                                    <span style={{ marginRight: 10, fontFamily: "Avenir-Black", color: '#1B2F5B' }}>Deactive</span>
                                    <Field 
                                        name='Status'
                                    >
                                        {({field, form, meta}) => <Form.Check 
                                                type="switch"
                                                id="status"
                                                label="Active"
                                                value={field.value} 
                                                onChange={field.onChange}
                                                style={{ fontFamily: "Avenir-Black", color: '#1B2F5B' }}
                                            />}
                                    </Field>
                                </div>
                            </Form.Group>
                    </Row>
                    <Row>
                        <center>
                            <Buttons backColor="#FF9900" buttonText="UPDATE" buttonAction={handleSubmit}>
                                <GiBackwardTime style={{color:'#FFFFFF'}} fontSize="2.3em" />
                            </Buttons>
                        </center>
                    </Row>
                </Form>
                
            )}
        />}
        </Card.Body>
        </Card>
    )
}

export default BasicDetails
