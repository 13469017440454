import React, { Fragment, useEffect, useState } from 'react'
import { Card, Row, Form, Col, InputGroup } from 'react-bootstrap'
import {MdEdit,MdUpload} from 'react-icons/md'
import {AiFillDelete,AiFillEye} from 'react-icons/ai'
import {BsToggle2On,BsToggle2Off, BsCheck2} from 'react-icons/bs'
import { MdCheck, MdClose } from 'react-icons/md'
import {IoMdAddCircle,IoMdAdd}from 'react-icons/io'
import { FaTimes } from 'react-icons/fa'
import Paging from '../../components/Paging'
import Modals from '../../components/Modals'
import Buttons from '../../components/Buttons'

import { useSelector, useDispatch } from 'react-redux'
import { getNotification,deleteNotificationById } from '../../Redux/actions/notificationActions'
import { toast } from 'react-toastify'
import { RESET_NOTIFICATION } from '../../Redux/constants/notificationConstants'
import AddPushNotification from './AddPushNotification'
import EditPushNotification from './EditNotification/EditPushNotification'


const PushNotification = () => {
    const { notificationList,deleteSuccess, error} = useSelector(state => state.notification)
    const dispatch = useDispatch();
    const { loading } = useSelector(state => state.auth)

   
    const [showModal, setShowModal] = useState(false)
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [modalShowEdit, setModalShowEdit] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [datas, setDatas] = useState([])
    const [headerColor, setHeaderColor] = useState('')
    const [headerTitle, setHeaderTitle] = useState('')
    const [selectedNotification, setselectedNotification] = useState('')




    useEffect(() => {
        if(notificationList && notificationList.length>0){
            const indexOfLastPost = currentPage * 10;
            const indexOfFirstPost = indexOfLastPost - 10;
            const currentPosts = (notificationList || []).slice(indexOfFirstPost, indexOfLastPost);
            setDatas(currentPosts)
        }
    }, [notificationList])
    const pageChanged = (page)=> {
        setCurrentPage(page)
        const indexOfLastPost = page * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const currentPosts = (notificationList || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }
    useEffect(() => {
        if(error){
            toast.error(error);
            dispatch({
                type: RESET_NOTIFICATION
            })
        }
        
        if(deleteSuccess){
            dispatch(getNotification("push"))
            setDeleteModalShow(false);
            dispatch({
                type: RESET_NOTIFICATION
            })
            toast.success("Notification Deleted Successfully");
        }
    }, [error,deleteSuccess])
    
    useEffect(() => {
        dispatch(getNotification("push"))
        console.log({notificationList})
    }, [])

    const createPushNotification = () => {
        setHeaderColor('#D42F45');
        setHeaderTitle("ADD PUSH NOTIFICATION");
        setShowModal(true);
    }
    const editNotification = (notification) => {
        setselectedNotification(notification);
        setHeaderColor('#FF9900');
        setHeaderTitle("EDIT NOTIFICATION");
        setModalShowEdit(true);
    }

    const deleteNotification = (id) => {
        setselectedNotification(id);
        setHeaderColor('#D42F45');
        setHeaderTitle("DELETE NOTIFICATION");
        setDeleteModalShow(true);
    }
    const deleteNotiById = () => {
        dispatch(deleteNotificationById(selectedNotification))
    }
    
    return (
        <Fragment>
            
            <Card style={{ borderRadius: 20, margin: 10, minHeight: '70vh' }}>
                <IoMdAddCircle class="add" fontSize="3.5em" 
                onClick={createPushNotification}
                style={{ position: 'absolute', top: -30, right: -20, cursor: 'pointer' }} />
                <Card.Body style={{ overflowX: 'auto' }}>
                    <table>
                        <thead class="tableHeader">
                            <th>Title</th>
                            <th>Description</th>
                            <th>Screen</th>
                            <th>Image</th>
                            <th>Actions</th>
                        </thead>
                        <tbody>
                            {datas && datas.map((notification, index) => (
                                <tr key={index}>
                                    <td class="tableContent">{notification.title}</td>
                                    <td class="tableContent">{notification.description}</td>
                                    <td class="tableContent">{notification.screen}</td>
                                    <td class="tableContent">
                                    <img src={notification.image} width={50} height={50} />
                                    </td>
                                    <td class="tableContent">
                                        <MdEdit color="orange" onClick={() => editNotification(notification)}/>
                                        <AiFillDelete class="add" onClick={() => deleteNotification (notification._id)}/>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Card.Body>
                <Col style={{ float: 'right', display:'flex', flexDirection: 'row-reverse', justifyContent: 'flex-start', alignItems: 'center', margin: 30  }}>
                <Card >
                    {notificationList && notificationList.length>10 && <Paging datas={notificationList} currentPage={currentPage} pageChange={pageChanged} />}
                </Card>
                </Col>
            </Card>
            
            <Modals showModal={deleteModalShow} hideModal={()=> setDeleteModalShow(false)} modalTitle={headerTitle} headerColor={headerColor}>
                <p className="deleteText" >Are you sure you want to delete this notification?</p>
                <div style={{ display: 'flex', alignItems:'center', justifyContent:'center' }}>
                        <Buttons backColor="#4AAD47" buttonText="YES" buttonAction={deleteNotiById}>
                            <BsCheck2 style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                        <Buttons backColor="#D42F45" buttonText="No" buttonAction={()=> console.log("Hello")}>
                            <FaTimes style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                </div>
            </Modals>
            <AddPushNotification openModal={showModal} closeModal={()=> setShowModal(false)} modalTitle={headerTitle} headerColor={headerColor}  />
            {modalShowEdit && <EditPushNotification openModal={modalShowEdit} closeModal={()=> setModalShowEdit(false)} headerColor={headerColor} headerTitle={headerTitle} notifi={selectedNotification}  />}
           
 
        </Fragment>
    )
}

export default PushNotification
