export const SERVICE_LIST_SUCCESS = 'SERVICE_LIST_SUCCESS';
export const SERVICE_LIST_FAIL = 'SERVICE_LIST_FAIL';
export const RESET_SERVICE = 'RESET_SERVICE';
export const NEW_SERVICE_SUCCESS = 'NEW_SERVICE_SUCCESS';
export const NEW_SERVICE_FAIL = 'NEW_SERVICE_FAIL';
export const DELETE_SER_LISTING_FAIL = 'DELETE_SER_LISTING_FAIL';
export const DELETE_SER_LISTING_SUCCESS = 'DELETE_SER_LISTING_SUCCESS';
export const SER_LISTING_DETAILS_FAIL = 'SER_LISTING_DETAILS_FAIL';
export const SER_LISTING_DETAILS_SUCCESS = 'SER_LISTING_DETAILS_SUCCESS';
export const SER_LIST_UPDATE_FAIL = 'SER_LIST_UPDATE_FAIL';
export const SER_LIST_UPDATE_SUCCESS = 'SER_LIST_UPDATE_SUCCESS';



export const SUBSCRIPTION_LIST_SUCCESS = 'SUBSCRIPTION_LIST_SUCCESS';
export const SUBSCRIPTION_LIST_FAIL = 'SUBSCRIPTION_LIST_FAIL';
export const RESET_SUBSCRIPTION = 'RESET_SUBSCRIPTION';
export const NEW_SUBSCRIPTION_SUCCESS = 'NEW_SUBSCRIPTION_SUCCESS';
export const NEW_SUBSCRIPTION_FAIL = 'NEW_SUBSCRIPTION_FAIL';
export const DELETE_SUBSCRIPTION_FAIL = 'DELETE_SUBSCRIPTION_FAIL';
export const DELETE_SUBSCRIPTION_SUCCESS = 'DELETE_SUBSCRIPTION_SUCCESS';
export const SUBSCRIPTION_UPDATE_FAIL = 'SUBSCRIPTION_UPDATE_FAIL';
export const SUBSCRIPTION_UPDATE_SUCCESS = 'SUBSCRIPTION_UPDATE_SUCCESS';

export const SER_CATEGORY_LIST_SUCCESS = 'SER_CATEGORY_LIST_SUCCESS';
export const SER_CATEGORY_LIST_FAIL = 'SER_CATEGORY_LIST_FAIL';
export const SER_MAIN_CATEGORY_LIST_SUCCESS = 'SER_MAIN_CATEGORY_LIST_SUCCESS';
export const SER_MAIN_CATEGORY_LIST_FAIL = 'SER_MAIN_CATEGORY_LIST_FAIL';
export const RESET_SER_CATEGORY = 'RESET_SER_CATEGORY';
export const NEW_SER_CATEGORY_FAIL = 'NEW_SER_CATEGORY_FAIL';
export const NEW_SER_CATEGORY_SUCCESS = 'NEW_SER_CATEGORY_SUCCESS';
export const NEW_SER_MAIN_CATEGORY_FAIL = 'NEW_SER_MAIN_CATEGORY_FAIL';
export const NEW_SER_MAIN_CATEGORY_SUCCESS = 'NEW_SER_MAIN_CATEGORY_SUCCESS';
export const DELETE_SER_CATEGORY_FAIL = 'DELETE_SER_CATEGORY_FAIL';
export const DELETE_SER_CATEGORY_SUCCESS = 'DELETE_SER_CATEGORY_SUCCESS';
export const DELETE_SER_MAIN_CATEGORY_FAIL = 'DELETE_SER_MAIN_CATEGORY_FAIL';
export const DELETE_SER_MAIN_CATEGORY_SUCCESS = 'DELETE_SER_MAIN_CATEGORY_SUCCESS';
export const UPDATE_SER_CATEGORY_SUCCESS = 'UPDATE_SER_CATEGORY_SUCCESS';
export const UPDATE_SER_CATEGORY_FAIL = 'UPDATE_SER_CATEGORY_FAIL';
export const SER_CATEGORY_DETAILS_SUCCESS = 'SER_CATEGORY_DETAILS_SUCCESS';
export const SER_CATEGORY_DETAILS_FAIL = 'SER_CATEGORY_DETAILS_FAIL';


export const SUB_SERVICE_SUCCESS = 'SUB_SERVICE_SUCCESS'
export const SUB_SERVICE_FAIL = 'SUB_SERVICE_FAIL'