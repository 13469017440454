import React, { useState, useEffect } from 'react'
import { Row, Form, Col, InputGroup } from 'react-bootstrap'
import Modals from '../../components/Modals'
import {
    MdUpload
} from 'react-icons/md'
import {
    IoMdAdd
} from 'react-icons/io'
import Buttons from '../../components/Buttons'
import reactotron from '../../ReactotronConfig'
import { useSelector, useDispatch } from 'react-redux'
import { saveNewFranchisee } from '../../Redux/actions/franchiseeActions'
import { toast } from 'react-toastify'
import {Formik, Field} from 'formik';
import * as Yup from 'yup';
import "yup-phone";
import InputField from '../../components/InputField'
import FormSelectField from '../../components/form-select-field'
import moment from 'moment'

const FranchiseeView = ({openModal, closeModal, headerColor, headerTitle, franchisee}) => {

    const idTypes = [{ name: 'Aadhaar'}, { name: 'PAN' }, { name: 'Driving Licence' }]

    const dispatch = useDispatch();

    

    const saveFranchisee = (values) => {
        dispatch(saveNewFranchisee(values))
    }


    return (
        <Modals showModal={openModal} hideModal={closeModal} headerColor={headerColor} modalTitle={headerTitle}>
            <Formik 
                initialValues={franchisee}
                validationSchema={
                    
                    Yup.object({
                        name: Yup.string().min(8, 'Must be 8 characters or less').required('Name Required'),
                        employee_name: Yup.string().min(8, 'Must be 8 characters or less').required('Employee Name Required'),
                        email: Yup.string().email('Invalid email address').required('Store Email Required'),
                        city: Yup.string().required("City is Required"),
                        emp_proof_type: Yup.string().required("Employee ID Type Required")
                    })
                }
                onSubmit={(values) => {
                    console.log(values)
                    saveFranchisee(values)
                }}
                render={({handleChange, handleSubmit, handleBlur, values, errors}) => (
                <Form>
                    <Row className="mb-3">
                        <InputField fieldName="name" fieldLabel="Franchisee Name" readOnly />
                        <InputField fieldName="employee_name" fieldLabel="Employee Name" readOnly />
                        <InputField fieldName="email" fieldLabel="Email Address" fieldType="email" readOnly />
                        <InputField fieldName="phonenumber" fieldLabel="Phone Number" fieldType="text" readOnly />
                        <InputField fieldName="city" fieldLabel="City" fieldType="text" readOnly />
                        <FormSelectField
                            label="Employee ID Type"
                            type="text"
                            name="emp_proof_type"
                            readOnly
                        >
                            <option value="">Select Id</option>
                            {idTypes.map((type, index) => (
                                <option key={index} value={type.name}>{type.name}</option>
                            ))}
                        </FormSelectField>
                        <Form.Group as={Col} xs="12" sm="12" md="6" lg="4" xl="4" controlId="emp_id" className="formStyle">
                            <Form.Label className="formLabel">Employee ID</Form.Label>
                            <InputGroup className="mb-2">
                                <Field name="emp_id">
                                    {({field, form, meta}) =>  <Form.Control 
                                        className="workStyle" 
                                        readOnly 
                                        placeholder="Type or Upload ID Proof" 
                                        value={field.value} 
                                        onChange={field.onChange} 
                                    /> }
                                </Field>
                                
                                <InputGroup.Text style={{color:'#1B2F5B',backgroundColor:'#FFFFFF',borderColor:'#B4CBF7' , borderTopRightRadius: 10, borderBottomRightRadius: 10}}>
                                    <MdUpload fontSize="1.5em"  />
                                    <input type="file" style={{ opacity: 0, width: 50, position:'absolute', right: 0 }} />
                                </InputGroup.Text>
                            </InputGroup>
                        </Form.Group>
                        {headerTitle === 'VIEW FRANCHISEE' && (
                            <Form.Group controlId="formGridName" as={Col}  xs="12" sm="12" md="6" lg="4" xl="4" className="formStyle">
                                <Form.Label className="formLabel">Created On</Form.Label>
                                <Field name="created_at">
                                    {({field, form, meta}) =>  <Form.Control 
                                        readOnly
                                        className="inputStyle"  
                                        value={ moment(field.value).format("DD-MM-YYYY hh:mm A") } 
                                        onChange={field.onChange} 
                                    /> }
                                </Field>
                            </Form.Group>
                        )}
                        <Form.Group as={Col} xs="12" sm="12" md="6" lg="4" xl="4" controlId="formGridActive" className="formStyle">
                            <Form.Label className="formLabel">Active Status</Form.Label>
                            <div style={{ display: 'flex', flexDirection: 'row', height: 40, borderRadius: 10, border: "1px solid #417AE85E", backgroundColor: '#417AE80A', alignItems:'center', justifyContent:'center' }}>
                                <span style={{ marginRight: 10, fontFamily: "Avenir-Black", color: '#1B2F5B' }}>Deactive</span>
                                <Field name="status">
                                    {({field, form, meta}) =>  
                                    <Form.Check 
                                        readOnly
                                        type="switch"
                                        id="status"
                                        label="Active"
                                        style={{ fontFamily: "Avenir-Black", color: '#1B2F5B' }}
                                        value={field.value}
                                        checked={field.value}
                                        onChange={field.onChange}
                                    />}
                                </Field>
                               
                            </div>
                        </Form.Group>
                    </Row>
                    
                </Form>
            )}
        />
        </Modals>
    )
}

export default FranchiseeView
