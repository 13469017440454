import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import Modals from '../../components/Modals'
import {MdEdit,MdUpdate} from 'react-icons/md'
import Buttons from '../../components/Buttons'
import { useSelector, useDispatch } from 'react-redux'
import { updateSubscriptionFee } from '../../Redux/actions/subscriptionActions'
import { toast } from 'react-toastify'
import {Formik, Field} from 'formik';
import InputField from '../../components/InputField'
import "yup-phone";
import { RESET_SUBSCRIPTION } from '../../Redux/constants/serviceConstants'

const EditSubscriptionFee = ({openModal, closeModal, headerColor, headerTitle, servicelist}) => {

    const { updateSuccess } = useSelector(state => state.subscription)
    const dispatch = useDispatch();
    const [initialDatas, setInitialDatas] = useState(null)

    useEffect(() => {
        
        if(updateSuccess){
                closeModal(true)
                dispatch({
                    type: RESET_SUBSCRIPTION
                })
                toast.success("Subscription updated Successfully");
            }
        
       
    }, [updateSuccess])
    
    useEffect(() => {
        async function getDetails() {

            if(servicelist){
                const details = {
                    provider_name: servicelist.provider_name,
                    category: servicelist.category,
                    phone_number: servicelist.phone_number,
                    city: servicelist.city,
                    subscription_fee: servicelist.subscription_fee,
                    expiry_date: servicelist.expiry_date,
                    id:servicelist._id
                  
                }
    
                
                console.log({details})
                setInitialDatas(details)
            }
        }

        getDetails();
    }, [servicelist])

    const updateSubscription =  (values) =>  {


        console.log({values})

        const {id, provider_name, category,phone_number,city,subscription_fee,expiry_date } = values
        dispatch(updateSubscriptionFee(id,provider_name, category,phone_number,city,subscription_fee,expiry_date));
       
    }


    return (
        
        <Modals showModal={openModal} hideModal={closeModal} headerColor={headerColor} modalTitle={headerTitle} >
            {initialDatas && 
            <Formik 
                initialValues={initialDatas}
                onSubmit={(values) => {
                    console.log(values)
                    updateSubscription(values)
                }}
                render={({handleChange, handleSubmit, handleBlur, values, errors}) => (
                    <Form>
                    <Row>
                        <InputField fieldName="category" fieldLabel="Category" fieldType="text" />
                        <InputField fieldName="provider_name" fieldLabel="Service Provider Name" />
                        <InputField fieldName="city" fieldLabel="City" fieldType="text" />
                    </Row>
                    <Row>
                        <InputField fieldName="phone_number" fieldLabel="Phone Number" fieldType="text" />
                        <InputField fieldName="subscription_fee" fieldLabel="Subscription Fee (Per Month)" fieldType="text" />
                        <InputField fieldName="expiry_date" fieldLabel="Expiry Date" fieldType="text" />
                    </Row>
                                {headerTitle === 'EDIT SUBSCRIPTION' && <Row>
                        <center>
                            <Buttons backColor="#FF9900" buttonText="UPDATE" buttonAction={handleSubmit}>
                                    <MdUpdate style={{color:'#FFFFFF'}} fontSize="2.3em" />
                            </Buttons>
                        </center>
                    </Row>}
                    </Form>
                )}
            />}
        </Modals>
        
    )
}

export default EditSubscriptionFee
