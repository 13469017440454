import React from 'react'
import Modal from 'react-bootstrap/Modal'
import CloseButton from 'react-bootstrap/CloseButton'

const Modalsmall = ({showModal, hideModal, modalTitle, children, headerColor}) => {
    return (
        <Modal 
            show={showModal} 
            onHide={hideModal}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header
                className="modalHeader" style={{ color: '#fff', backgroundColor: headerColor }}>
                <Modal.Title className="modalsmallTitle">{modalTitle}</Modal.Title>
                <CloseButton variant="white" onClick={hideModal} />
            </Modal.Header> 
            <Modal.Body> 
                {children}
            </Modal.Body>
            
        </Modal>
    )
}

export default Modalsmall
