import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import Modals from '../../components/Modals'
import {MdEdit,MdUpdate} from 'react-icons/md'
import Buttons from '../../components/Buttons'
import { useSelector, useDispatch } from 'react-redux'
import { getSubCategoriesUnderMainCat, updateService } from '../../Redux/actions/servicelistingActions'
import { toast } from 'react-toastify'
import {Formik, Field} from 'formik';
import InputField from '../../components/InputField'
import FormSelectField from '../../components/form-select-field'
import "yup-phone";
import { RESET_SERVICE, RESET_SER_CATEGORY } from '../../Redux/constants/serviceConstants'
import SelectNew from '../../components/SelectNew'
import { getserviceCategories, getServiceLists } from '../../Redux/actions/servicecategoryActions'
import * as Yup from 'yup';


const days = [
    {
        label : 'Sunday',
        value: 'Sunday'
    },
    {
        label : 'Monday',
        value: 'Monday'
    },
    {
        label : 'Tuesday',
        value: 'Tuesday'
    },
    {
        label : 'Wednesday',
        value: 'Wednesday'
    },
    {
        label : 'Thursday',
        value: 'Thursday'
    },
    {
        label : 'Friday',
        value: 'Friday'
    },
    {
        label : 'Saturday',
        value: 'Saturday'
    },
]

const EditServiceListing = ({openModal, closeModal, headerColor, headerTitle, servicelist}) => {

    const { updateSuccess, subServices } = useSelector(state => state.servicelisting)
    const { error, mainCategories } = useSelector(state => state.servicecategory)

    const dispatch = useDispatch();
    const [initialDatas, setInitialDatas] = useState(null)

    useEffect(() => {
        
        if(updateSuccess){
            closeModal(true)
            dispatch({
                type: RESET_SERVICE
            })
            toast.success("Service updated Successfully");
        }

        if(error){
            toast.error(error);
            dispatch({
                type: RESET_SER_CATEGORY
            })
        }
        
       
    }, [updateSuccess, error])

    //get Service Main Categories
    useEffect(() => {
        dispatch(getserviceCategories()) //get All Main Categories
        dispatch(getSubCategoriesUnderMainCat(servicelist.category.mainCategoryID))
    }, [])
    
    useEffect(() => {
        async function getDetails() {

            if(servicelist && mainCategories && subServices){

                const {  _id, provider_name, category, city, phone_number, working_days, working_hrs } = servicelist

                let mainCat = mainCategories.find(main => main.id === category.mainCategoryID)
                let cat = subServices.find(sub => sub._id === category._id );

                let workingDays  = working_days.map(work => {
                    return { label : work, value: work }
                })

                const details = {
                    id: _id,
                    mainCategory: mainCat,
                    provider_name,
                    category: cat,
                    phone_number,
                    city,
                    working_days : workingDays,
                    working_hrs_from: working_hrs.from,
                    working_hrs_to: working_hrs.to
                  
                }
    
                
                console.log({details})
                setInitialDatas(details)
            }
        }

        getDetails();
    }, [servicelist, mainCategories, subServices])

    const editService =  (values) =>  {


        console.log({values})
        let cat = {
            _id: values.category._id,
            name: values.category.name,
            mainCategory: values.category.mainCategory,
            mainCategoryID: values.category.mainCategoryID
        }
        let work = values.working_days.map(work => work.label)

        let details = {
            id: values.id,
            provider_name : values.provider_name,   
            category: cat,
            city: values.city,
            phone_number: values.phone_number,
            working_days: work,
            working_hrs: {
                from: values.working_hrs_from,
                to: values.working_hrs_to
            }
        }

        dispatch(updateService(details))

        //const { id,provider_name, category,phone_number,city,working_days,working_hrs } = values
        //dispatch(updateService(id,provider_name, category,phone_number,city,working_days,working_hrs));
       
    }


    return (
        <Modals showModal={openModal} hideModal={closeModal} headerColor={headerColor} modalTitle={headerTitle} >
            {initialDatas && 
            <Formik 
                initialValues={initialDatas}
                validationSchema={
                    
                    Yup.object({
                        provider_name: Yup.string().max(8, 'Must be 8 characters or less').required('Name Required'),
                        category: Yup.object().required('Category Required'),
                        phone_number: Yup.string().phone("IN").required('Phone Number is Required'),
                        city: Yup.string().required("City is Required"),
                        working_days: Yup.array().required("Working Days is required"),
                        working_hrs_from: Yup.string().required('Working hours Start time is Required'),
                        working_hrs_to: Yup.string().required('Working hours end time is Required'),
                    })
                }
                onSubmit={(values) => {
                    console.log(values)
                    editService(values)
                }}
                render={({handleChange, handleSubmit, handleBlur, values, errors, setFieldTouched, setFieldValue, touched}) => (
                    <Form>
                        <Row>
                            <InputField fieldName="provider_name" fieldLabel="Service Provider Name" />
                            <SelectNew 
                                fieldName="mainCategory"
                                fieldLabel="Main Category"
                                options={mainCategories}
                                isMultiple={false}
                                value={values.mainCategory}
                                onChange={(fieldName, value) => setFieldValue}
                                onBlur={setFieldTouched}
                                error={errors.mainCategory}
                                touched={touched.mainCategory}
                            />
                            <SelectNew 
                                fieldName="category"
                                fieldLabel="Category"
                                options={subServices}
                                isMultiple={false}
                                value={values.category}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                error={errors.category}
                                touched={touched.category}
                            />
                            <InputField fieldName="city" fieldLabel="City" fieldType="text" />
                            <InputField fieldName="phone_number" fieldLabel="Phone Number" fieldType="text" />
                            <SelectNew 
                                md="4"
                                fieldName="working_days"
                                fieldLabel="Working Days"
                                options={days}
                                isMultiple={true}
                                value={values.working_days}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                error={errors.working_days}
                                touched={touched.working_days}
                            />
                            <p className="formLabel">Working Hours</p>
                            <InputField fieldName="working_hrs_from" fieldLabel="From Time" fieldType="time" />
                            <InputField fieldName="working_hrs_to" fieldLabel="To Time" fieldType="time" />
                        </Row>
                        {headerTitle === 'EDIT SERVICE' && <Row>
                <center>
                    <Buttons backColor="#FF9900" buttonText="UPDATE" buttonAction={handleSubmit}>
                            <MdUpdate style={{color:'#FFFFFF'}} fontSize="2.3em" />
                    </Buttons>
                </center>
            </Row>}
                    </Form>
                )}
            />}
        </Modals>
    )
}

export default EditServiceListing
