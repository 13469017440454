import { 
    SLIDER_LIST_SUCCESS,
    SLIDER_LIST_FAIL, 
    RESET_SLIDER,
    NEW_SLIDER_SUCCESS,
    NEW_SLIDER_FAIL,
    DELETE_SLIDER_FAIL,
    DELETE_SLIDER_SUCCESS,
    UPDATE_SLIDER_SUCCESS,
    UPDATE_SLIDER_FAIL,
    NEW_COST_FAIL,
    NEW_COST_SUCCESS,
    COST_LIST_FAIL, 
    COST_LIST_SUCCESS, 
    DELETE_COST_SUCCESS,
    DELETE_COST_FAIL, 
    UPDATE_COST_SUCCESS,
    UPDATE_COST_FAIL,
} from "../constants/sliderConstants";

export  const sliderReducer = (state = {}, action) => {
    switch(action.type){

        case SLIDER_LIST_SUCCESS:
            return {
                ...state,
                sliderLists: action.payload
            }

        case SLIDER_LIST_FAIL:
            return {
                ...state,
                error: action.payload.message
            }
        case UPDATE_SLIDER_FAIL:
            return {
                ...state,
                error: action.payload.message
            }
        case NEW_SLIDER_FAIL:
            return {
                ...state,
                error: action.payload.message
            }
        case DELETE_SLIDER_FAIL:
            return {
                ...state,
                error: action.payload.message
            }
        case RESET_SLIDER:
            return{
                ...state,
                error: null,
                sliderSuccess: null,
                deleteSuccess:null,
                updateSuccess:null
            }

        

        case NEW_SLIDER_SUCCESS:
            return {
                ...state,                
                sliderLists : state.sliderLists ? [...state.sliderLists, action.payload.slider] : [ action.payload.slider ],
                sliderSuccess: true
            }
        case UPDATE_SLIDER_SUCCESS:
            return{
                ...state,
                sliderLists: state.sliderLists.map(sli => sli._id === action.payload._id ? action.payload : sli),
                updateSuccess: true
            }
        case DELETE_SLIDER_SUCCESS:
            return {
                ...state,
                deleteSuccess: true
            }    

        
        default: 
            return state;
    }
}


//SLIDER SETTINGS

export  const costReducer = (state = {}, action) => {
    switch(action.type){

        case COST_LIST_SUCCESS:
            return {
                ...state,
                costLists: action.payload
            }

        case COST_LIST_FAIL:
            return {
                ...state,
                error: action.payload.message
            }
        case UPDATE_COST_FAIL:
            return {
                ...state,
                error: action.payload.message
            }
        case NEW_COST_FAIL:
            return {
                ...state,
                error: action.payload.message
            }
        case DELETE_COST_FAIL:
            return {
                ...state,
                error: action.payload.message
            }
        case RESET_SLIDER:
            return{
                ...state,
                error: null,
                costSuccess: null,
                deleteSuccess:null,
                updateSuccess:null
            }

        

        case NEW_COST_SUCCESS:
            return {
                ...state,                
                costLists : state.costLists ? [...state.costLists, action.payload.cost] : [ action.payload.cost ],
                costSuccess: true
            }
        case UPDATE_COST_SUCCESS:
            return{
                ...state,
                costLists: state.costLists.map(sli => sli._id === action.payload._id ? action.payload : sli),
                updateSuccess: true
            }
        case DELETE_COST_SUCCESS:
            return {
                ...state,
                deleteSuccess: true
            }    

        
        default: 
            return state;
    }
}