import React, { Fragment } from 'react'
import { MdOutlineArrowLeft } from 'react-icons/md'

const NavMenu = ({title, children, selected}) => {
    return (
        <div className="menuItem" style={{ borderLeft : selected ? '3px solid red' : 'none', paddingLeft: 10 }}>
            <div style={{ display:'flex', flexDirection:'row', alignItems:'center' }}>
                {children}
                <span className="iconStyle">{title}</span>
            </div>
            {selected && <div className="selected">
                <MdOutlineArrowLeft color="red" style={{ position: 'relative', top: -15 }} />
            </div>}
        </div>
    )
}

export default NavMenu
