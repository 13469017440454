export const NEW_FRANCHISEE_SUCCESS = 'NEW_FRANCHISEE_SUCCESS'
export const NEW_FRANCHISEE_FAIL = 'NEW_FRANCHISEE_FAIL'

export const RESET_ERROR = 'RESET_ERROR'

export const FRANCHISEE_LIST_SUCCESS = 'FRANCHISEE_LIST_SUCCESS';
export const FRANCHISEE_LIST_FAIL = 'FRANCHISEE_LIST_FAIL';

export const UPDATE_FRANCHISEE_SUCCESS = 'UPDATE_FRANCHISEE_SUCCESS'
export const UPDATE_FRANCHISEE_FAIL = 'UPDATE_FRANCHISEE_FAIL'

export const DELETE_FRANCHISEE_SUCCESS = 'DELETE_FRANCHISEE_SUCCESS'
export const DELETE_FRANCHISEE_FAIL = 'DELETE_FRANCHISEE_FAIL'

// DELIVERY CHARGE
export const NEW_CHARGE_SUCCESS = 'NEW_CHARGE_SUCCESS'
export const NEW_CHARGE_FAIL = 'NEW_CHARGE_FAIL'

export const RESET_CHARGE = 'RESET_CHARGE'

export const CHARGE_LIST_SUCCESS = 'CHARGE_LIST_SUCCESS';
export const CHARGE_LIST_FAIL = 'CHARGE_LIST_FAIL';

export const UPDATE_CHARGE_SUCCESS = 'UPDATE_CHARGE_SUCCESS'
export const UPDATE_CHARGE_FAIL = 'UPDATE_CHARGE_FAIL'

export const DELETE_CHARGE_SUCCESS = 'DELETE_CHARGE_SUCCESS'
export const DELETE_CHARGE_FAIL = 'DELETE_CHARGE_FAIL'

export const CHARGE_DETAILS_SUCCESS = 'CHARGE_DETAILS_SUCCESS'
export const CHARGE_DETAILS_FAIL = 'CHARGE_DETAILS_FAIL'

export const DELIVERY_CHARGE_INPUT = 'DELIVERY_CHARGE_INPUT'
