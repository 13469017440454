import React, { Fragment } from 'react'
import { Route, Link, useRouteMatch } from 'react-router-dom'
import Management from './Management'
import PayoutDetails from './PayoutDetails'


const Payout = () => {
    const match = useRouteMatch()

    console.log(match)

    return (
        <Fragment>
            <Route path={`${match.path}/management`}>
                <Management/>   
            </Route>
            <Route path={`${match.path}/details`}>
                <PayoutDetails/>   
            </Route>
        </Fragment>
    )
}

export default Payout
