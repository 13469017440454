import React, { Fragment, useState } from 'react'
import { BiSliderAlt } from 'react-icons/bi';
import './style.scss';
import Filter from '../assets/Images/filter.svg'
import Cash from '../assets/Images/cash.svg'
import Group from '../assets/Images/group.svg'
import Time from '../assets/Images/time.svg'

const Fab = ({timesClick, groupClick, cashClick, fabOpen, fabClick}) => {

    return (
        <Fragment>
            <div className='box' onClick={fabClick}>
                <img src={Filter} style={{ width: 30 }} alt="React Logo" />
            </div>
            {fabOpen && <div style={{ position:'absolute', top: 50, right: -10 }}>
                <div className='times' onClick={timesClick}>
                    <img src={Time} style={{ width: 30 }} alt="React Logo" />
                </div>
                <div className='group' onClick={groupClick}>
                    <img src={Group} style={{ width: 30 }} alt="React Logo" />
                </div>
                <div className='cash' onClick={cashClick}>
                    <img src={Cash} style={{ width: 30 }} alt="React Logo" />
                </div>
            </div>}
        </Fragment>
    )
}

export default Fab
