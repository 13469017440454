import React, { Fragment, useState, useEffect } from 'react'
import { Row, Col, Card,  } from 'react-bootstrap'
import { Link, Route, useRouteMatch } from 'react-router-dom'
import Header from '../../components/Header'
import Layout from '../layout'
import BasicDetails from './BasicDetails'
import DeliveryCharges from './DeliveryCharges'
import {createBrowserHistory } from 'history'
import { useDispatch } from 'react-redux'
import { DELIVERY_CHARGE_INPUT } from '../../Redux/constants/franchiseeConstants'


const EditFranchise = () => {

    const match = useRouteMatch();
    const dispatch = useDispatch();
    const history = createBrowserHistory();
    const [franchisee, setFranchisee] = useState(history?.location?.state?.franchisee);
    const [header, setHeader] = useState(history?.location?.state?.name);
   
    useEffect(() => {
        dispatch({
            type: DELIVERY_CHARGE_INPUT,
            payload: {
                prop: 'chargeList',
                payload: null
            }
        })
        if(history?.location?.state?.franchisee){
            setFranchisee(history?.location?.state?.franchisee)
            setHeader(history?.location?.state?.name)
        }
        else{
            history.push("/franchisee")
        }
        
    }, [])
    

    return (
        <Fragment>
        <Header headerText={header} />
        <Row style={{  width: "100%" }}>
            <Col xs="12" sm="12" md="6" lg="4" xl="4" style={{  borderRadius: 20, marginTop: 30, marginBottom: 30 }}>
                <Link to={`${match.path}/basic`} style={{ 
                        color: (history.location.pathname === `${match.path}/basic` || history.location.pathname === `${match.path}`) ? '#fff' : '#545454', 
                        textDecoration:'none', 
                         
                    }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: (history.location.pathname === `${match.path}/basic` || history.location.pathname === `${match.path}`) ? '#4AAD47' : '#fff'  }}>
                        <Card.Body style={{ display: 'flex', alignItems:'center', flexDirection: 'row', justifyContent: 'center' }}>
                            <span className="headerService">BASIC DETAILS</span>
                            
                        </Card.Body>
                    
                    </Card>
                </Link>
            </Col>

                 {/* <Link to={`${match.path}/category`} style={{ 
                    color: history.location.pathname === `${match.path}/category` ? '#fff' : '#545454', 
                    textDecoration:'none', 
                    
                 }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: history.location.pathname === `${match.path}/category` ? '#4AAD47' : '#fff' }}>
                        <Card.Body style={{ display: 'flex', alignItems:'center', flexDirection: 'row', justifyContent: 'center' }}>
                            <span className="headerService">CATEGORY CHARGES</span>
                            
                        </Card.Body>
                    
                    </Card>
                </Link> */}
            

            <Col xs="12" sm="12" md="6" lg="4" xl="4" style={{  borderRadius: 20, marginTop: 30, marginBottom: 30 }}>
                <Link to={`${match.path}/delivery`} style={{ 
                    color: history.location.pathname === `${match.path}/delivery` ? '#fff' : '#545454', 
                    textDecoration:'none', 
                    
                 }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: history.location.pathname === `${match.path}/delivery` ? '#4AAD47' : '#fff' }}>
                        <Card.Body style={{ display: 'flex', alignItems:'center', flexDirection: 'row', justifyContent: 'center' }}>
                            <span className="headerService">DELIVERY CHARGES</span>
                            
                        </Card.Body>
                    
                    </Card>
                </Link>
            </Col>
        </Row> 
            <Route path={`${match.path}`} exact>
                <BasicDetails franchisee={franchisee} />   
            </Route>
            <Route path={`${match.path}/basic`} exact>
                <BasicDetails franchisee={franchisee}/>   
            </Route>
            <Route path={`${match.path}/delivery`} exact>
                <DeliveryCharges franchisee={franchisee}/>
            </Route>
        </Fragment>
    )
}

export default EditFranchise
