import React, { Fragment, useEffect, useState } from 'react'
import { Card, Row, Form, Col, InputGroup } from 'react-bootstrap'
import {MdEdit,MdUpload} from 'react-icons/md'
import {AiFillDelete,AiFillEye} from 'react-icons/ai'
import {BsToggle2On,BsToggle2Off, BsCheck2} from 'react-icons/bs'
import { MdCheck, MdClose } from 'react-icons/md'
import {IoMdAddCircle,IoMdAdd}from 'react-icons/io'
import { FaTimes } from 'react-icons/fa'
import Paging from '../../components/Paging'
import Modals from '../../components/Modals'
import ModalMedium from '../../components/ModalMedium'
import Buttons from '../../components/Buttons'

import reactotron from '../../ReactotronConfig'
import { useSelector, useDispatch } from 'react-redux'
import { deleteSliderById,addNewSlider ,getSliderLists} from '../../Redux/actions/sliderActions'
import { toast } from 'react-toastify'
import { RESET_SLIDER } from '../../Redux/constants/sliderConstants'
import Select from 'react-select';
import {Formik, Field} from 'formik';
import InputField from '../../components/InputField'
import IconInput from '../../components/IconInput'
import FormSelectField from '../../components/form-select-field'
import * as Yup from 'yup';
import moment from 'moment';
import EditSlider from './EditSlider'


const AddSlider = () => {
    const { sliderLists, error,updateSuccess, sliderSuccess, deleteSuccess } = useSelector(state => state.slider)
    const dispatch = useDispatch();
    const { loading } = useSelector(state => state.auth)
    const [search, setSearch] = useState('')
    const [image, setImage] = useState(null);
    const [selectedSlider, setselectedSlider] = useState('')
    const [modalShow, setModalShow] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [modalShowEdit, setModalShowEdit] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [datas, setDatas] = useState([])
    const [headerColor, setHeaderColor] = useState('')
    const [headerTitle, setHeaderTitle] = useState('')
    

    useEffect(() => {
        if(sliderLists && sliderLists.length>0){
            reactotron.log({sliderLists})
            const indexOfLastPost = currentPage * 10;
            const indexOfFirstPost = indexOfLastPost - 10;
            const searchResult = sliderLists.filter((sliders) => sliders.title.toLowerCase().includes(search.toLowerCase()) || sliders.slide_no.toLowerCase().includes(search.toLowerCase())
            )
            const currentPosts = (searchResult || []).slice(indexOfFirstPost, indexOfLastPost);
            console.log(currentPosts)
            setDatas(currentPosts)
        }
    }, [sliderLists, search])

    const pageChanged = (page)=> {
        setCurrentPage(page)
        const indexOfLastPost = page * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const searchResult = sliderLists.filter(sliders => sliders.name.toLowerCase().contains(search.toLowerCase()) || sliders.slide_no.toLowerCase().contains(search.toLowerCase()))
        const currentPosts = (searchResult || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }
    
    useEffect(() => {
        if(error){
            toast.error(error);
            dispatch({
                type: RESET_SLIDER
            })
        }
        if(sliderSuccess){
            dispatch(getSliderLists())
            setModalShow(false);
            dispatch({
                type: RESET_SLIDER
            })
            
            toast.success("New Slider Added Successfully");
            
            
        }
        
        
        if(updateSuccess){
            setModalShowEdit(false);
                dispatch({
                    type: RESET_SLIDER
                })
                dispatch(getSliderLists())
                toast.success("Slider updated Successfully");
        }
       
        
        if(deleteSuccess){
            
            setDeleteModalShow(false);
            dispatch({
                type: RESET_SLIDER
            })
            dispatch(getSliderLists())
            toast.success("Slider Deleted Successfully");
            
        }
    }, [error,sliderSuccess,updateSuccess,deleteSuccess])
    useEffect(() => {
        dispatch(getSliderLists())
    }, [])
    
    const saveSlider =  (values) =>  {


        console.log({values})
        var form_data = new FormData();
        form_data.append("title", values.title);
        form_data.append("slide_no", values.slide_no);
        form_data.append("cost", values.cost);
        form_data.append("exp_date", values.exp_date);
        form_data.append("requested_by", values.requested_by);
        form_data.append("image", image);
        

        console.log(form_data);

        dispatch(addNewSlider(form_data))
        
    }

    const createaddSlider = () => {
        setHeaderColor('#D42F45');
        setHeaderTitle("ADD SLIDER");
        setModalShow(true);
    }
    const editSlider = (slider) => {
        setselectedSlider(slider);
        setHeaderColor('#FF9900');
        setHeaderTitle("EDIT SLIDER");
        setModalShowEdit(true);
    }

    const deletetheSlider = (id) => {
        setselectedSlider(id);
        setHeaderColor('#D42F45');
        setHeaderTitle("DELETE SLIDER");
        setDeleteModalShow(true);
    }
    const deleteSliderListById = () => {
        dispatch(deleteSliderById(selectedSlider))
    }

    return (
        <Fragment>
            
            <Card style={{ borderRadius: 20, margin: 10, minHeight: '70vh' }}>
                <IoMdAddCircle class="add" fontSize="3.5em" 
                onClick={createaddSlider}
                style={{ position: 'absolute', top: -30, right: -20, cursor: 'pointer' }} />
                <Card.Body style={{ overflowX: 'auto' }}>
                    <table>
                        <thead class="tableHeader">
                            <th>No</th>
                            <th>Title</th>
                            <th>Image</th>
                            <th>Cost(Per Day)</th>
                            <th>Posted Date</th>
                            <th>Expiry date</th>
                            <th>Requested By</th>
                            <th>Approval</th>
                            <th>Actions</th>
                        </thead>
                        <tbody>
                            {datas && datas.map((slider, index) => (
                                <tr key={index}>
                                    <td class="tableContent">{slider.slide_no}</td>
                                    <td class="tableContent">{slider.title}</td>
                                    <td class="tableContent">
                                        <img
                                        src={slider.image}
                                        width={169}
                                        height={57}
                                        alt='Push'
                                        />
                                    </td>
                                    <td class="tableContent">{slider.cost}</td>
                                    <td class="tableContent">{moment(slider.created_at).format("DD-MM-YYYY")}</td>
                                    <td class="tableContent">{slider.exp_date}</td>
                                    <td class="tableContent">{slider.requested_by}</td>
                                    <td class="tableContent">
                                        {slider.status === 'approved' ? <span className="approved">APPROVED</span> : slider.status === 'rejected' ? <span className="rejected">REJECTED</span> : (<div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'center' }}>
                                            <MdCheck fontSize="1.5em" style={{ color: '#4AAD47', margin: 5 }} /> 
                                            <MdClose fontSize="1.5em" style={{ color: '#D42F45', margin: 5 }} />
                                        </div>)}
                                    </td>
                                    <td class="tableContent">
                                        <MdEdit color="orange" onClick={() => editSlider(slider)} style={{cursor: 'pointer' }} />
                                        <AiFillDelete class="add" onClick={() => deletetheSlider(slider._id)} style={{cursor: 'pointer' }}/>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Card.Body>
                <Col style={{ float: 'right', display:'flex', flexDirection: 'row-reverse', justifyContent: 'flex-start', alignItems: 'center', margin: 30  }}>
                <Card >
                    {sliderLists && sliderLists.length>10 && <Paging datas={sliderLists} currentPage={currentPage} pageChange={pageChanged} />}
                </Card>
                </Col>
            </Card>
            
            <Modals showModal={deleteModalShow} hideModal={()=> setDeleteModalShow(false)} modalTitle={headerTitle} headerColor={headerColor}>
                <p className="deleteText" >Are you sure you want to delete this Slider?</p>
                <div style={{ display: 'flex', alignItems:'center', justifyContent:'center' }}>
                        <Buttons backColor="#4AAD47" buttonText="YES" buttonAction={deleteSliderListById}>
                            <BsCheck2 style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                        <Buttons backColor="#D42F45" buttonText="No" buttonAction={()=> console.log("pressed")}>
                            <FaTimes style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                </div>
            </Modals>
            {modalShowEdit && <EditSlider openModal={modalShowEdit} closeModal={()=> setModalShowEdit(false)} headerColor={headerColor} headerTitle={headerTitle} sliderlist={selectedSlider}  />}
       
            <ModalMedium  showModal={modalShow} hideModal={()=> setModalShow(false)} headerColor={headerColor} modalTitle={headerTitle} >
            <Formik 
                initialValues={{
                    title: '',
                    slide_no: '',
                    cost: '',
                    exp_date: '',
                    
                    requested_by: '',
                    
                }}
                validationSchema={
                    
                    Yup.object({
                        title: Yup.string().max(8, 'Must be 8 characters or less').required('Name Required'),
                        slide_no: Yup.string().required('Slider No is Required'),
                        cost: Yup.string().required('Cost is Required'),
                        exp_date: Yup.string().required("Expiry Date is Required"),
                        
                        requested_by: Yup.string().required('Requested By is Required'),
                        
                    })
                }
                onSubmit={(values) => {
                    console.log(values)
                    saveSlider(values)
                }}
                render={({handleChange, handleSubmit, handleBlur, values, errors}) => (
            <Form>  
            
            
            <Row className="mb-3">
                
                    <InputField fieldName="title" fieldLabel="Title" fieldType="text" />
                    <InputField fieldName="slide_no" fieldLabel="Slider No" fieldType="text" />
                    <InputField fieldName="cost" fieldLabel="Cost(Per Day)" fieldType="text" />
                    <InputField fieldName="exp_date" fieldLabel="Expiry Date" fieldType="text" />
                    
                    
                    <InputField fieldName="requested_by" fieldLabel="Requested By" fieldType="text" />

                    <Form.Group as={Col} xs="12" sm="12" md="6" lg="4" xl="6" controlId="formGridImage" className="formStyle">
                                <Form.Label className="formLabel">Slider Image</Form.Label>
                                <div className="imageStyles">
                                    <Form.Control type="file"  onChange={e => setImage(e.target.files[0])}  style={{ opacity: 0, position: 'absolute', bottom: 0, height: 120, width: 190 }} />
                                    {image ? <img src={URL.createObjectURL(image)} style={{ width: 200, height: 120 }} /> : <span style={{ position: 'absolute', bottom: 50 }}>512 x 512</span>}
                                </div>
                    </Form.Group>

             
            </Row>
            <Row>
                <center>
                    <Buttons backColor="#D42F45" buttonText="ADD" buttonAction={handleSubmit}>
                        <IoMdAdd style={{color:'#FFFFFF'}} fontSize="2.3em" />
                    </Buttons>
                </center>
            </Row>
            </Form>
            )}
            />
        </ModalMedium>
 
        </Fragment>
    )
}

export default AddSlider
