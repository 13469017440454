import React, { Fragment } from 'react'
import {FormControl, FormGroup, FormLabel, Col, Form} from 'react-bootstrap';
import { Field, ErrorMessage } from 'formik';

const SelectInput = ({children, fieldName, fieldLabel, md, readOnly, onChange, isMultiple}) => {
    return (
        <FormGroup controlId={fieldName} as={Col}  xs="12" sm="12" md={md ? md : "4"} className="formStyle">
            <FormLabel className="formLabel">{fieldLabel}</FormLabel>
            <Field 
                name={fieldName}
            >
                {({field, form, meta}) => (<Form.Select 
                readOnly={readOnly} 
                className="inputStyle" 
                value={field.value} 
                multiple={isMultiple}
                onChange={onChange ? onChange : field.onChange} >
                    {children}
                </Form.Select>)}
            </Field>
            <ErrorMessage name={fieldName}>
                { msg => <div style={{ color: 'red' }}>{msg}</div> }
            </ErrorMessage>
        </FormGroup>
    )
}

export default SelectInput
