import { 
    LOADING 
} from "../constants/userConstants"
import axios from '../../CustomAxios'
import { 
    COUPON_LIST_FAIL,
    COUPON_LIST_SUCCESS,
    NEW_COUPON_FAIL, 
    NEW_COUPON_SUCCESS, 
    UPDATE_COUPON_FAIL, 
    UPDATE_COUPON_SUCCESS,
    DELETE_COUPON_SUCCESS,
    DELETE_COUPON_FAIL
} from "../constants/couponConstants";
import reactotron from "../../ReactotronConfig";

//Save COUPON
export const saveNewCoupon = (title, description,code,limitation,exp_date,stores,category,product) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    let data={
        title,
        description,
        code,
        limitation,
        exp_date,
        stores,
        category,
        product
    }
    await axios.post(`admin/create-coupon`, data)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })

        dispatch({
            type: NEW_COUPON_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_COUPON_FAIL,
            payload: error
        })
    });
}


//Get COUPON
export const getCoupon = () => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`admin/coupons`)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })


        dispatch({
            type: COUPON_LIST_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: COUPON_LIST_FAIL,
            payload: error
        })
    });
}


//Update Coupon
export const updateCoupon = (id,title, description,code,limitation,exp_date,stores,category,product) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    let data={
        id,title, description,code,limitation,exp_date,stores,category,product
    }
    await axios.post(`admin/edit-coupon`, data)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: UPDATE_COUPON_SUCCESS,
            payload: response.data.coupon
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: UPDATE_COUPON_FAIL,
            payload: error
        })
    });
}

//DELETE single Notification 
export const deleteCouponById = (id) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.delete(`admin/delete-coupon/${id}`)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: DELETE_COUPON_SUCCESS,
            payload: id
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: DELETE_COUPON_FAIL,
            payload: error
        })
    });
}
