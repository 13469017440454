export const RESET_USER = 'RESET_USER';
export const LOADING = 'LOADING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const RE_LOGIN = 'RE_LOGIN';

export const RESET_AUTH = 'RESET_AUTH';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAIL = 'USER_LIST_FAIL';


export const NEW_USER_SUCCESS = 'NEW_USER_SUCCESS';
export const NEW_USER_FAIL = 'NEW_USER_FAIL';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';

export const ROLE_LIST_SUCCESS = 'ROLE_LIST_SUCCESS';
export const ROLE_LIST_FAIL = 'ROLE_LIST_FAIL';
export const NEW_ROLE_SUCCESS = 'NEW_ROLE_SUCCESS';
export const NEW_ROLE_FAIL = 'NEW_ROLE_FAIL';

export const ID_LIST_SUCCESS = 'ID_LIST_SUCCESS';
export const ID_LIST_FAIL = 'ID_LIST_FAIL';
export const NEW_ID_SUCCESS = 'NEW_ID_SUCCESS';
export const NEW_ID_FAIL = 'NEW_ID_FAIL';
export const CLEAR_USER = 'CLEAR_USER';



