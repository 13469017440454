export const TIME_LIST_SUCCESS = 'TIME_LIST_SUCCESS';
export const TIME_LIST_FAIL = 'TIME_LIST_FAIL';
export const RESET_TIME = 'RESET_TIME';

export const NEW_TIME_SUCCESS = 'NEW_TIME_SUCCESS';
export const NEW_TIME_FAIL = 'NEW_TIME_FAIL';

export const DELETE_TIME_SUCCESS = 'DELETE_TIME_SUCCESS';
export const DELETE_TIME_FAIL = 'DELETE_TIME_FAIL';

export const TIME_UPDATE_FAIL = 'TIME_UPDATE_FAIL';
export const TIME_UPDATE_SUCCESS = 'TIME_UPDATE_SUCCESS';