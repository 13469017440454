import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import Modals from '../../../components/Modals'
import {MdEdit,MdUpdate} from 'react-icons/md'
import Buttons from '../../../components/Buttons'
import { useSelector, useDispatch } from 'react-redux'
import { getNotification,updateNotification } from '../../../Redux/actions/notificationActions'
import { toast } from 'react-toastify'
import {Formik, Field} from 'formik';
import InputField from '../../../components/InputField'
import "yup-phone";
import { RESET_NOTIFICATION } from '../../../Redux/constants/notificationConstants'
import ImageInput from '../../../components/ImageInput'

const EditSmsNotification = ({openModal, closeModal, headerColor, headerTitle, notifi}) => {

    const { updateSuccess } = useSelector(state => state.notification)
    const [image, setImage] = useState(null)
    const dispatch = useDispatch();
    const [initialDatas, setInitialDatas] = useState(null)

    useEffect(() => {
        
        if(updateSuccess){
                closeModal(true)
                dispatch({
                    type: RESET_NOTIFICATION
                })
                dispatch(getNotification("sms"))
                toast.success("Notification updated Successfully");
            }
        
       
    }, [updateSuccess])
    
    useEffect(() => {
        async function getDetails() {

            if(notifi){
                const details = {
                    id:notifi._id,
                    title: notifi.title,
                    description: notifi.description,
                    screen: notifi.screen,
                   
                    
                }
    
                
                console.log({details})
                setInitialDatas(details)
            }
        }

        getDetails();
    }, [notifi])

    const editNotification =  (values) =>  {


        console.log({values})
        let formData = new FormData();
        formData.append("id", values.id);
        formData.append("title", values.title);
        formData.append("description", values.description);
        formData.append("screen", values.screen);
        
        dispatch(updateNotification(formData));
       
    }


    return (
        <Modals showModal={openModal} hideModal={closeModal} headerColor={headerColor} modalTitle={headerTitle} >
            {initialDatas && 
            <Formik 
                initialValues={initialDatas}
                onSubmit={(values) => {
                    console.log(values)
                    editNotification(values)
                }}
                render={({handleChange, handleSubmit, handleBlur, values, errors}) => (
                    <Form>
                    <Row>
                        <InputField fieldName="title" fieldLabel="Title" fieldType="text" md="6"/>
                        <InputField fieldName="screen" fieldLabel="Screen" fieldType="text" md="6"/>
                        <InputField fieldName="description" fieldLabel="Notification Description" fieldType="text" md="6" style={{ height: 120 }}/>
                          
                    </Row>
                        {headerTitle === 'EDIT NOTIFICATION' && <Row>
                <center>
                    <Buttons backColor="#FF9900" buttonText="UPDATE" buttonAction={handleSubmit}>
                            <MdUpdate style={{color:'#FFFFFF'}} fontSize="2.3em" />
                    </Buttons>
                </center>
            </Row>}
                    </Form>
                )}
            />}
        </Modals>
    )
}

export default EditSmsNotification
