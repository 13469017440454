import React, { useEffect, useState } from 'react'
import { Card, Form, Button, Spinner } from 'react-bootstrap'
import TopImage from '../../assets/Images/dummylogo.png'
import './login.scss'
import { MdEmail, MdLock, MdCopyright } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginUsers } from '../../Redux/actions/userActions';
import { toast } from 'react-toastify';
import { RESET_AUTH } from '../../Redux/constants/userConstants';
import { Formik, Field, ErrorMessage } from 'formik';
import Logininput from './LoginInput'
import * as Yup from 'yup';



const Login = ({history}) => {


    const { loading, isAuthenticated, loginError } = useSelector(state => state.auth)
    const dispatch = useDispatch();

    useEffect(() => {
        if(isAuthenticated){
            history.push('dashboard')
        }

        if(loginError){
            toast.error(loginError)
            dispatch({
                type: RESET_AUTH
            })
        }
        
    }, [isAuthenticated, loginError])

    const loginUser = (values) => {
        const { email, password } = values
        dispatch(loginUsers(email, password));
    }

    return (
        <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={
        
                Yup.object({
                    password: Yup
                    .string()
                    .required('Password Required')
                    .matches(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                    ),
                    email: Yup.string().email('Invalid email address').required('Email Required'),
                })
            }
            onSubmit={(values) => {
                loginUser(values)
            }}
            render={({handleChange, handleSubmit, handleBlur, values, errors}) => (
                <Form>
                    <div className="loginBackground">
                        <div style={{ display: 'flex', flexDirection:'row-reverse' }}>
                            <Card  style={{  borderRadius: 20 }} className="shadow loginContainer login">
                                <img src={TopImage} style={{ width: '28%',  marginTop: 20 }} />
                                <Form.Group className="mb-3" controlId="email" style={{ width: '90%',marginTop: 10 }}>
                                    <MdEmail style={{ position: 'absolute', left: 40, marginTop: 18, fontSize: 20 }} />
                                    <Field name="email" >
                                    {({field, form, meta}) => <Form.Control type="email" 
                                        placeholder="Email"  
                                        style={{ 
                                            height: 50, 
                                            borderRadius: 10, 
                                            backgroundColor: '#F0F0F0', 
                                            border: 'none', 
                                            fontSize: 16,
                                            paddingLeft: 45 
                                        }} 
                                        value={field.value}
                                        onChange={field.onChange}
                                        className="shadowInput"
                                    />}
                                    </Field>
                                    <ErrorMessage name={"email"}>
                                        { msg => <div style={{ color: 'red' }}>{msg}</div> }
                                    </ErrorMessage>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="password" style={{ width: '90%' }}>
                                    <MdLock style={{ position: 'absolute', left: 40, marginTop: 18, fontSize: 20 }} />
                                    <Field name="password" >
                                    {({field, form, meta}) => <Form.Control type="password" 
                                        placeholder="Password"  
                                        style={{ 
                                            height: 50, 
                                            borderRadius: 10, 
                                            backgroundColor: '#F0F0F0', 
                                            border: 'none', 
                                            fontSize: 16,
                                            paddingLeft: 45 
                                        }} 
                                        value={field.value}
                                        onChange={field.onChange}
                                        className="shadowInput"
                                    />}
                                    </Field>
                                    <ErrorMessage name={"password"}>
                                        { msg => <div style={{ color: 'red' }}>{msg}</div> }
                                    </ErrorMessage>
                                </Form.Group>
                                <div style={{ display:'flex', flexDirection:'row-reverse', width:'100%' }}>
                                    <Link className="forgetPass">
                                        FORGET PASSWORD ?
                                    </Link>
                                </div>
                                <button className="loginButton" onClick={handleSubmit }>
                                    LOGIN
                                </button>
                                <div className="footer">
                                    <hr />
                                    <div style={{ display: 'flex', alignItems:'center', flexDirection:'row', justifyContent:'center' }}>
                                        <span className="copyright">COPYRIGHTS <MdCopyright fontSize={20} /> <span className="copyright"> CATLIN. Developed By </span><span className="diginest"> DIGINEST SOLUTIONS</span></span>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
            </Form>
        )}
        />
    )
}

export default Login
