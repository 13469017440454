import { ErrorMessage } from 'formik'
import React from 'react'
import { Form, Col } from 'react-bootstrap'

const ImageInput = ({fieldName, fieldLabel, image, md, changeImage, imageHelperText, imageUrl}) => {
    return (
        <Form.Group as={Col} xs="12" sm="12" md={md ? md : "4"} controlId={fieldName} className="formStyle">
            <Form.Label className="formLabel">{fieldLabel}</Form.Label>
            <div className="imageStyles">
                <Form.Control type="file" accept="image/*"  onChange={changeImage}  style={{ opacity: 0, position: 'absolute', bottom: 0, height: 120, width: 190 }} />
                {((image && image!='') || (imageUrl && imageUrl!='')) ? <img src={image ? URL.createObjectURL(image): imageUrl} style={{ width: 200, height: 100, borderRadius: 20 }} /> : <span style={{ position: 'absolute', bottom: 50 }}>{imageHelperText}</span>}
            </div>
            <ErrorMessage name={fieldName}>
                { msg => <div style={{ color: 'red' }}>{msg}</div> }
            </ErrorMessage>
        </Form.Group>
    )
}

export default ImageInput
