import React from "react";
import { Form, InputGroup, Col } from "react-bootstrap";
import { Field } from "formik";
import {IoMdAdd}from 'react-icons/io'


const FormSelectField = ({
  label,
  name,
  type,
  inputGroupPrepend,
  children,
  multiple,
  iconAction,
  readOnly,
  md
}) => {
  return (
    <Field
      name={name}
    >
      {({field, form, meta}) => {
        const isValid = !form.errors[field.name];
        const isInvalid = form.touched[field.name] && !isValid;
        return (
          <Form.Group as={Col} xs="12" sm="12" md={md ? md : "4"} controlId={name}>
            <Form.Label className="formLabel">{label}</Form.Label>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center' }}>
            <InputGroup>
              {inputGroupPrepend}
              <Form.Control
                className="inputStyle"
                {...field}
                type={type}
                readOnly={readOnly}
                isValid={form.touched[field.name] && isValid}
                isInvalid={isInvalid}
                feedback={form.errors[field.name]}
                as="select"
                multiple={multiple}
              >
                {children}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {form.errors[field.name]}
              </Form.Control.Feedback>
            </InputGroup>
            {iconAction && <IoMdAdd style={{color:'red'}} fontSize="1.5em" onClick={iconAction} />}
            </div>
          </Form.Group>
        );
      }}
    </Field>
  );
};

FormSelectField.defaultProps = {
  type: "select",
  inputGroupPrepend: null
};

export default FormSelectField;