import React, { Fragment } from 'react'
import Logo from '../../assets/Images/dummylogo.png'
import { 
    MdOutlineBarChart, 
    MdStore, 
    MdOutlineListAlt, 
    MdCategory, 
    MdMiscellaneousServices, 
    MdOutlinePersonOutline, 
    MdGroups, 
    MdOutlinePayments, 
    MdNoteAlt,
    MdSettings,
    MdOutlineDeliveryDining,
    MdNotificationImportant,
    MdImage,
    MdOutlineCardGiftcard,
    MdAccessTime,
    MdPersonPin, 
    MdStoreMallDirectory,
    MdStorefront
} from 'react-icons/md'
import { FaToriiGate, FaRegListAlt, FaPercent } from 'react-icons/fa'
import { IoMdPricetag } from 'react-icons/io'
import { RiScales3Fill } from 'react-icons/ri'
import './style.scss'
import { Link, useLocation } from 'react-router-dom'
import { BiLogOut } from 'react-icons/bi'
import NavMenu from './NavMenu'
import { createBrowserHistory } from "history";
import reactotron from '../../ReactotronConfig'
import { useDispatch } from 'react-redux'
import { RESET_USER } from '../../Redux/constants/userConstants'


const SideBar = () => {

    const history = createBrowserHistory();
    const dispatch = useDispatch()

    //const location = window.location.origin;

    //reactotron.log({pathname: history.location.pathname})

    const logoutUser = async() => {
        await localStorage.removeItem("token");
        await localStorage.removeItem("user");
        dispatch({
            type: RESET_USER,
        })
        history.replace("/signin")
    }

    return (
        <div style={{ display:'flex', flexDirection: 'column',height: '100vh', backgroundColor: '#fff' }}>
            <img src={Logo} className="imageStyle" />
            <div className="menus">
                <Link to ="/Dashboard" className="link">
                    <NavMenu title="Dashboard" selected={history.location.pathname.startsWith('/Dashboard')}>
                        <MdOutlineBarChart size={22} style={{ color: history.location.pathname.includes('/Dashboard') ? '#D42F45' : '#A5A4BF' }} /> 
                    </NavMenu>
                </Link>
                <Link to="/store/" className="link">
                    <NavMenu title="Store Management" selected={history.location.pathname.startsWith('/store/')}>
                        <MdStore size={22} style={{ color: history.location.pathname.startsWith('/store/') ? '#D42F45' : '#A5A4BF' }} />
                    </NavMenu>
                </Link>
                <Link to="/order" className="link">
                    <NavMenu title="Order Management" selected={history.location.pathname.startsWith('/order')}>
                        <MdOutlineListAlt size={22} style={{ color: history.location.pathname.startsWith('/order') ? '#D42F45' : '#A5A4BF' }} />
                    </NavMenu>
                </Link>
                <Link to="/franchisee" className="link">
                    <NavMenu title="Franchisee Management" selected={history.location.pathname.startsWith('/franchisee')}>
                        <FaToriiGate size={22} style={{ color: history.location.pathname.startsWith('/franchisee') ? '#D42F45' : '#A5A4BF' }} />
                    </NavMenu>
                </Link>
                <Link to="/category" className="link">
                    <NavMenu title="Category Management" selected={history.location.pathname.startsWith('/category')}>
                        <MdCategory size={22} style={{ color: history.location.pathname.startsWith('/category') ? '#D42F45' : '#A5A4BF' }} />
                    </NavMenu>
                </Link>
                <Link to="/Service" className="link">
                    <NavMenu title="Service Management" selected={history.location.pathname.startsWith('/Service')}>
                        <MdMiscellaneousServices size={22} style={{ color: history.location.pathname.startsWith('/Service') ? '#D42F45' : '#A5A4BF' }} />
                    </NavMenu>
                </Link>
                <Link to="/user" className="link">
                    <NavMenu title="User Management" selected={history.location.pathname.startsWith('/user')}>
                        <MdOutlinePersonOutline size={22} style={{ color: history.location.pathname.startsWith('/user') ? '#D42F45' : '#A5A4BF' }} />
                    </NavMenu>
                </Link>
                <Link to="/customers" className="link">
                    <NavMenu title="Customers" selected={history.location.pathname.startsWith('/customers')}>
                        <MdGroups size={22} style={{ color: history.location.pathname.startsWith('/customers') ? '#D42F45' : '#A5A4BF' }} />
                    </NavMenu>
                </Link>
                <Link to="/Payout/management" className="link">
                    <NavMenu title="Payout Management" selected={history.location.pathname.startsWith('/Payout')}>
                        <MdOutlinePayments size={22} style={{ color: history.location.pathname.startsWith('/Payout') ? '#D42F45' : '#A5A4BF' }} />
                    </NavMenu>
                </Link>
                <Link to="/Review" className="link">
                    <NavMenu title="Review Management" selected={history.location.pathname.startsWith('/Review')}>
                        <MdNoteAlt size={22} style={{ color: history.location.pathname.startsWith('/Review') ? '#D42F45' : '#A5A4BF' }} />
                    </NavMenu>
                </Link>
                <Link to="/Reports" className="link">
                    <NavMenu title="Reports" selected={history.location.pathname.startsWith('/reports')}>
                        <FaRegListAlt size={22} style={{ color: history.location.pathname.startsWith('/reports') ? '#D42F45' : '#A5A4BF' }} />
                    </NavMenu>
                </Link>
                <div className="link">
                    <NavMenu title="Global Settings" selected={history.location.pathname.startsWith('default')}>
                        <MdSettings size={22} style={{ color: history.location.pathname.startsWith('default') ? '#D42F45' : '#A5A4BF' }} />
                    </NavMenu>
                </div>
                <Link className="link" to="/storetype/">
                    <NavMenu title="Store Type" selected={history.location.pathname.startsWith('/storetype/')}>
                        <MdStorefront size={22} style={{ color: history.location.pathname.startsWith('/storetype/') ? '#D42F45' : '#A5A4BF' }} />
                    </NavMenu>
                </Link>
                <Link className="link" to="/unit/">
                    <NavMenu title="Unit Management" selected={history.location.pathname.includes('unit/')}>
                        <RiScales3Fill size={22} style={{ color: history.location.pathname.includes('unit/') ? '#D42F45' : '#A5A4BF' }} />
                    </NavMenu>
                </Link>
                {/*<div className="link">
                    <NavMenu title="Delivery Management" selected={history.location.pathname.startsWith('default')}>
                        <MdOutlineDeliveryDining size={22} style={{ color: history.location.pathname.startsWith('default') ? '#D42F45' : '#A5A4BF' }} />
                    </NavMenu>
                </div>
                <div  className="link">
                    <NavMenu title="Franchisee Profit(%)" selected={history.location.pathname.startsWith('default')}>
                        <FaPercent size={22} style={{ color: history.location.pathname.startsWith('default') ? '#D42F45' : '#A5A4BF' }} />
                    </NavMenu>
    </div>*/}
                <Link to="/Notifications" className="link">
                    <NavMenu title="Notifications" selected={history.location.pathname.startsWith('/notificatons')}>
                        <MdNotificationImportant size={22} style={{ color: history.location.pathname.startsWith('/notificatons') ? '#D42F45' : '#A5A4BF' }} />
                    </NavMenu>
                </Link>
                <Link to="/Sliders" className="link">
                    <NavMenu title="Sliders" selected={history.location.pathname.startsWith('/sliders')}>
                        <MdImage size={22} style={{ color: history.location.pathname.startsWith('/sliders') ? '#D42F45' : '#A5A4BF' }} />
                    </NavMenu>
                </Link>
                <Link to="/Coupons" className="link">
                    <NavMenu title="Coupons" selected={history.location.pathname.startsWith('/coupons')}>
                        <IoMdPricetag size={22} style={{ color: history.location.pathname.startsWith('/coupons') ? '#D42F45' : '#A5A4BF' }} />
                    </NavMenu>
                </Link>
                <Link className="link" to={"/surprise"}>
                    <NavMenu title="Surprice Orders" selected={history.location.pathname.startsWith('/surprise')}>
                        <MdOutlineCardGiftcard size={22} style={{ color: history.location.pathname.startsWith('/surprise') ? '#D42F45' : '#A5A4BF' }} />
                    </NavMenu>
                </Link>
                <Link to="/SetTime" className="link">
                    <NavMenu title="Set Time" selected={history.location.pathname.startsWith('/settime')}>
                        <MdAccessTime size={22} style={{ color: history.location.pathname.startsWith('/settime') ? '#D42F45' : '#A5A4BF' }} />
                    </NavMenu>
                </Link>
                <Link to="/Profile" className="link">
                    <NavMenu title="Profile" selected={history.location.pathname.startsWith('/profile')}>
                        <MdPersonPin size={22} style={{ color: history.location.pathname.startsWith('/profile') ? '#D42F45' : '#A5A4BF' }} />
                    </NavMenu>
                </Link>
            </div>
            <div className="buttonStyle">
                <button className="red" onClick={logoutUser}>
                <BiLogOut size={30} className="logout" style={{ color: '#fff', marginTop: 10 }} /> <span className="buttonText">Logout</span>
                </button>
            </div>
            <div className="topStyle"></div>
        </div>
    )
}

export default SideBar
