import React, { Fragment, useEffect, useState } from 'react'
import { Card, Row, Form, Col, InputGroup } from 'react-bootstrap'
import {MdEdit,MdUpdate} from 'react-icons/md'
import {AiFillDelete,AiFillEye} from 'react-icons/ai'
import {BsToggle2On,BsToggle2Off, BsCheck2} from 'react-icons/bs'
import { MdCheck, MdClose } from 'react-icons/md'
import {IoMdAddCircle,IoMdAdd}from 'react-icons/io'
import { FaTimes } from 'react-icons/fa'
import Paging from '../../components/Paging'
import Modals from '../../components/Modals'
import Buttons from '../../components/Buttons'
import Modalsmall from '../../components/Modalsmall'

import reactotron from '../../ReactotronConfig'
import { useSelector, useDispatch } from 'react-redux'
import { addNewService,deleteServiceCategoryById,updateServiceCategory } from '../../Redux/actions/servicecategoryActions'
import { getServiceLists,getServiceDetails } from '../../Redux/actions/servicecategoryActions'
import { toast } from 'react-toastify'
import { getserviceCategories, saveCategoriesMain } from '../../Redux/actions/servicecategoryActions'
import { RESET_SER_CATEGORY } from '../../Redux/constants/serviceConstants'
import Select from 'react-select';
import {Formik, Field} from 'formik';
import InputField from '../../components/InputField'
import IconInput from '../../components/IconInput'
import FormSelectField from '../../components/form-select-field'
import * as Yup from 'yup';
import EditServiceCategory from './EditServiceCategory'
import SelectNew from '../../components/SelectNew'


const ServiceCategory = () => {
    const { serviceCategory, error, servicecategorySuccess,updateSuccess,deleteServicecategory,mainCategories,newMainCategory, serviceCategoryList } = useSelector(state => state.servicecategory)
    
    const dispatch = useDispatch();
    const { loading } = useSelector(state => state.auth)

    const [modalShow, setModalShow] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [modalShowEdit, setModalShowEdit] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [datas, setDatas] = useState([])
    const [headerColor, setHeaderColor] = useState('')
    const [headerTitle, setHeaderTitle] = useState('')
    const [openSubModal, setOpenSubModal] = useState(false);
    const [mainserviceName, setMainserviceName] = useState('');
    const [subHeaderColor, setSubHeaderColor] = useState('');
    const [subHeaderTitle, setSubHeaderTitle] = useState('');
    const [selectedService, setselectedService] = useState('')
    const [initialDatas, setInitialDatas] = useState(null)
    

    useEffect(() => {
        const indexOfLastPost = currentPage * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const currentPosts = (serviceCategoryList || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }, [serviceCategoryList])

    const pageChanged = (page)=> {
        setCurrentPage(page)
        const indexOfLastPost = page * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const currentPosts = (serviceCategoryList || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }

    useEffect(() => {
        if(error){
            toast.error(error);
            dispatch({
                type: RESET_SER_CATEGORY
            })
        }
    }, [error])


    useEffect(() => {
        if(servicecategorySuccess){
            setModalShow(false);
            dispatch({
                type: RESET_SER_CATEGORY
            })
            toast.success("New Service Category Added Successfully");
        }
        if(deleteServicecategory){
            dispatch(getServiceLists())
            setDeleteModalShow(false);
            dispatch({
                type: RESET_SER_CATEGORY
            })
            toast.success("Service Deleted Successfully");
        }
    }, [servicecategorySuccess,deleteServicecategory])

    
    useEffect(() => {
        dispatch(getServiceLists())
    }, [])

    // useEffect(() => {
    //     if(serviceCategory){
    //         dispatch(getServiceDetails(serviceCategory))
    //     }

    //     if(updateSuccess){
    //         toast.success("Store Update Success");
    //         dispatch({
    //             type: RESET_SER_CATEGORY
    //         })
    //     }
    // }, [serviceCategory, updateSuccess])

    
    useEffect(() => {

        if(newMainCategory){ //close store type modal
            setOpenSubModal(false)
        }


    }, [newMainCategory])
    

    //get Service Main Categories
    useEffect(() => {
        dispatch(getserviceCategories())
    }, [])

    const closeSubModel = () => {
        setOpenSubModal(false);
    }

    const createNewCategory = () => {
        setHeaderColor('#D42F45');
        setHeaderTitle("ADD CATEGORY");
        setModalShow(true);
    }

    const editService = (serviceCategory) => {
        setselectedService(serviceCategory);
        setHeaderColor('#FF9900');
        setHeaderTitle("EDIT CATEGORY");
        setModalShowEdit(true);
    }

    const deleteService = (id) => {
        setselectedService(id);
        setHeaderColor('#D42F45');
        setHeaderTitle("DELETE SERVICE");
        setDeleteModalShow(true);
    }
    
    const saveCategoryMain = () => {
        dispatch(saveCategoriesMain(mainserviceName))
    }
    const deleteServiceById = () => {
        dispatch(deleteServiceCategoryById(selectedService))
    }

    const saveService =  (values) =>  {

        let details = {
            name: values.name,
            mainCategory: values.mainCategory.value,
            mainCategoryID: values.mainCategory.id,
            requestBy: 'admin'
        }

        dispatch(addNewService(details));
       
    }
    

    

    return (
        <Fragment>
            
            <Card style={{ borderRadius: 20, margin: 10, minHeight: '70vh' }}>
                <IoMdAddCircle class="add" fontSize="3.5em" 
                onClick={createNewCategory}
                style={{ position: 'absolute', top: -30, right: -20, cursor: 'pointer' }} />
                <Card.Body style={{ overflowX: 'auto' }}>
                    <table>
                        <thead class="tableHeader">
                            <th>Category Name</th>
                            <th>Main Category</th>
                            <th>Actions</th>
                        </thead>
                        <tbody>
                            {datas && datas.map((serviceCategory, index) => (
                                <tr key={index}>
                                    <td class="tableContent">{serviceCategory.name}</td>
                                    <td class="tableContent">{serviceCategory.mainCategory}</td>
                                    <td class="tableContent">
                                        <MdEdit color="orange" onClick={() => editService(serviceCategory)}/>
                                        <AiFillDelete class="add" onClick={() => deleteService(serviceCategory._id)}/>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Card.Body>
                <Col style={{ float: 'right', display:'flex', flexDirection: 'row-reverse', justifyContent: 'flex-start', alignItems: 'center', margin: 30  }}>
                <Card >
                    {serviceCategory && serviceCategory.length>10 && <Paging datas={serviceCategory} currentPage={currentPage} pageChange={pageChanged} />}
                </Card>
                </Col>
            </Card>
            {/* <FranchiseeForm openModal={modalShow} closeModal={()=> setModalShow(false)} headerColor={headerColor} headerTitle={headerTitle} />
             */}
            {modalShowEdit && <EditServiceCategory openModal={modalShowEdit} closeModal={()=> setModalShowEdit(false)} headerColor={headerColor} headerTitle={headerTitle} servicelist={selectedService}  />}
           
            <Modalsmall  showModal={modalShow} hideModal={()=> setModalShow(false)} headerColor={headerColor} modalTitle={headerTitle} servicelist={selectedService}>
            
            <Formik 
            initialValues={{
                mainCategory: '',
                name: '',
            }}
                validationSchema={
                    
                    Yup.object({
                        mainCategory: Yup.object().required('Main Category is Required'),
                        name: Yup.string().required('Category name is Required'),
                        
                    })
                }
               
                    onSubmit={(values) => {
                        //console.log(values)
                        saveService(values)
                       
                        
                }
                }
                
                render={({handleChange, handleSubmit, handleBlur, setFieldValue, setFieldTouched, values, errors, touched}) => (
            <Form>  
                <SelectNew 
                    md="12"
                    fieldName="mainCategory"
                    fieldLabel="Main Category"
                    options={mainCategories}
                    isMultiple={false}
                    value={values.mainCategory}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.mainCategory}
                    touched={touched.mainCategory}
                />
                
                 
                <InputField fieldName="name" fieldLabel="Category Name" fieldType="text" md="12" />
            {headerTitle === 'ADD CATEGORY' && <Row>
                <center>
                    <Buttons backColor="#D42F45" buttonText="ADD" buttonAction={handleSubmit}>
                        <IoMdAdd style={{color:'#FFFFFF'}} fontSize="2.3em" />
                    </Buttons>
                </center>
            </Row>}
            {headerTitle === 'EDIT CATEGORY' && <Row>
                <center>
                    <Buttons backColor="#FF9900" buttonText="UPDATE" buttonAction={()=> console.log("pressed")}>
                        <MdUpdate style={{color:'#FFFFFF'}} fontSize="2.3em" />
                    </Buttons>
                </center>
            </Row>}
            </Form>
            )}
            />
        </Modalsmall>
            <Modals showModal={openSubModal} hideModal={closeSubModel} headerColor={subHeaderColor} modalTitle={subHeaderTitle} modalSize="sm">
                <Row className="mb-3">
                    <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" controlId="formGridAddress" className="formStyle">
                        <Form.Label className="formLabel">Main Category</Form.Label>
                        <Form.Control className="inputStyle" type="storeCategory" value={mainserviceName} onChange={e => setMainserviceName(e.target.value)} />
                    </Form.Group>
                </Row>
                <center>
                    <Buttons backColor="#D42F45" buttonText="ADD" buttonAction={saveCategoryMain}>
                        <IoMdAdd style={{color:'#FFFFFF'}} fontSize="2.3em" />
                    </Buttons>
                </center>
            </Modals>

            <Modals showModal={deleteModalShow} hideModal={()=> setDeleteModalShow(false)} modalTitle={headerTitle} headerColor={headerColor}>
                <p className="deleteText">Are you sure you want to delete this Service Category?</p>
                <div style={{ display: 'flex', alignItems:'center', justifyContent:'center' }}>
                        <Buttons backColor="#4AAD47" buttonText="YES" buttonAction={deleteServiceById}>
                            <BsCheck2 style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                        <Buttons backColor="#D42F45" buttonText="No" buttonAction={()=> setDeleteModalShow(false)}>
                            <FaTimes style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                </div>
            </Modals>
        </Fragment>
    )
}

export default ServiceCategory
