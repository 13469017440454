import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Row, Col, Card,  } from 'react-bootstrap'
import { Link, Route, useRouteMatch, useHistory } from 'react-router-dom'
import Header from '../../../components/Header'
import {createBrowserHistory } from 'history'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import BasicDetails from './BasicDetails'
import Products from '../../Products/ProductList'
import OrderList from '../../Order/OrderList'
import CategoryCharges from './Category'
import Sliders from './Sliders'
import BankAccount from './BankAccount'
import reactotron from '../../../ReactotronConfig'
import { CHANGE_ORDER_INPUT } from '../../../Redux/constants/orderConstants'
import { useDispatch } from 'react-redux'
import { STORE_INPUT } from '../../../Redux/constants/storeConstants'
import Payout from './Payout'
import { STORE_PAYOUT_INPUT } from '../../../Redux/constants/payoutConstants'


const EditStore = () => {


    const match = useRouteMatch();
    const history = useHistory();
    const [storeId, setStoreId] = useState(history?.location?.state?.id);
    const [header, setHeader] = useState(history?.location?.state?.name);
    const [enableScroll, setEnableScroll] = useState(true)

    const dispatch = useDispatch()

    const scrolldiv = useRef(null)

    useEffect(() => {
        
        if(history?.location?.state?.id){
            setStoreId(history?.location?.state?.id)
            setHeader(history?.location?.state?.name)
        }
        else{
            history.push("/store")
        }
    }, [])

    useEffect(() => {
        dispatch({
            type: CHANGE_ORDER_INPUT,
            payload: {
                prop: 'orderList',
                value: null
            }
        })
        dispatch({
            type: STORE_PAYOUT_INPUT,
            payload: {
                prop: 'storePayout',
                value: null
            }
        })
    }, [])

    const scrolls = (value) =>{
        setEnableScroll(!enableScroll)
        scrolldiv.current.scrollLeft += value;
    }


    return (
        <Fragment>
        <Header headerText={header} />
        <div ref={scrolldiv} style={{  display:'flex', flexDirection: 'row', overflowX:'scroll', flexWrap:'nowrap', borderRadius: 20, marginTop: 30, marginBottom: 30, marginRight: 20, height: 80, scrollbarWidth: 'thin'  }} className="scrollbar-hidden" >
                <Link to={`${match.path}/basic`} style={{ 
                        color: (history.location.pathname === `${match.path}/basic` || history.location.pathname === `${match.path}`) ? '#fff' : '#545454', 
                        textDecoration:'none', 
                        marginRight: 20 
                    }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: (history.location.pathname === `${match.path}/basic` || history.location.pathname === `${match.path}`) ? '#4AAD47' : '#fff', flexWrap:'nowrap'  }}>
                        <Card.Body style={{ display: 'flex', alignItems:'center', flexDirection: 'row', justifyContent: 'center' }}>
                            <span className="headerService" style={{ whiteSpace:'nowrap' }}>BASIC DETAILS</span>
                        </Card.Body>
                    
                    </Card>
                </Link>
                <Link to={`${match.path}/product`} style={{ 
                    color: history.location.pathname === `${match.path}/product` ? '#fff' : '#545454', 
                    textDecoration:'none', marginRight: 20 
                    
                 }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: history.location.pathname === `${match.path}/product` ? '#4AAD47' : '#fff' }}>
                        <Card.Body style={{ display: 'flex', alignItems:'center', flexDirection: 'row', justifyContent: 'center'  }}>
                            <span className="headerService" style={{ whiteSpace:'nowrap' }}>PRODUCT MANAGEMENT</span>
                            
                        </Card.Body>
                    
                    </Card>
                </Link>
                <Link to={`${match.path}/order`} style={{ 
                    color: history.location.pathname === `${match.path}/order` ? '#fff' : '#545454', 
                    textDecoration:'none', marginRight: 20 
                    
                 }}>
                    <Card style={{  height: 58, borderRadius: 20, backgroundColor: history.location.pathname === `${match.path}/order` ? '#4AAD47' : '#fff' }}>
                        <Card.Body style={{ display: 'flex', alignItems:'center', flexDirection: 'row', justifyContent: 'center' }}>
                            <span className="headerService" style={{ whiteSpace:'nowrap' }}>ORDER MANAGEMENT</span>
                            
                        </Card.Body>
                    
                    </Card>
                </Link>
                <Link to={`${match.path}/category`} style={{ 
                    color: history.location.pathname === `${match.path}/category` ? '#fff' : '#545454', 
                    textDecoration:'none', marginRight: 20
                    
                 }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: history.location.pathname === `${match.path}/category` ? '#4AAD47' : '#fff' }}>
                        <Card.Body style={{ display: 'flex', alignItems:'center', flexDirection: 'row', justifyContent: 'center' }}>
                            <span className="headerService" style={{ whiteSpace:'nowrap' }}>CATEGORY CHARGES</span>
                            
                        </Card.Body>
                    
                    </Card>
                </Link>
                <Link to={`${match.path}/sliders`} style={{ 
                    color: history.location.pathname === `${match.path}/sliders` ? '#fff' : '#545454', 
                    textDecoration:'none', 
                    marginRight: 20
                 }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: history.location.pathname === `${match.path}/sliders` ? '#4AAD47' : '#fff' }}>
                        <Card.Body style={{ display: 'flex', alignItems:'center', flexDirection: 'row', justifyContent: 'center' }}>
                            <span className="headerService" style={{ whiteSpace:'nowrap' }}>SLIDERS</span>
                            
                        </Card.Body>
                    
                    </Card>
                </Link>
                <Link to={`${match.path}/account`} style={{ 
                    color: history.location.pathname === `${match.path}/account` ? '#fff' : '#545454', 
                    textDecoration:'none', 
                    marginRight: 20
                 }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: history.location.pathname === `${match.path}/account` ? '#4AAD47' : '#fff' }}>
                        <Card.Body style={{ display: 'flex', alignItems:'center', flexDirection: 'row', justifyContent: 'center' }}>
                            <span className="headerService" style={{ whiteSpace:'nowrap' }}>BANK ACCOUNT DETAILS</span>
                            
                        </Card.Body>
                    
                    </Card>
                </Link>
                <Link to={`${match.path}/payout`} style={{ 
                    color: history.location.pathname === `${match.path}/payout` ? '#fff' : '#545454', 
                    textDecoration:'none'
                    
                 }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: history.location.pathname === `${match.path}/payout` ? '#4AAD47' : '#fff' }}>
                        <Card.Body style={{ display: 'flex', alignItems:'center', flexDirection: 'row', justifyContent: 'center' }}>
                            <span className="headerService" style={{ whiteSpace:'nowrap' }}>PAYOUT</span>
                            
                        </Card.Body>
                    
                    </Card>
                </Link>
            {enableScroll && <FaArrowRight style={{ position:'absolute', right: 0, top: 130, fontSize: 30, color:'red' }} onClick={()=> scrolls(800) } /> }
            {!enableScroll && <FaArrowLeft style={{ position:'absolute', right: 0, top: 130, fontSize: 30, color:'red' }} onClick={()=> scrolls(-800) } /> }
        </div> 
            <Route path={`${match.path}`} exact> 
                 <BasicDetails storeId={storeId} />
            </Route>
            <Route path={`${match.path}/basic`} exact> 
                 <BasicDetails storeId={storeId} />
            </Route>
            <Route path={`${match.path}/product`} exact>
                <Products storeId={storeId} />
            </Route>
            <Route path={`${match.path}/order`} exact>
                <OrderList storeId={storeId} />
            </Route>
            <Route path={`${match.path}/category`} exact>
                <CategoryCharges storeId={storeId} name={header} />
            </Route>
            <Route path={`${match.path}/sliders`} exact>
                <Sliders storeId={storeId} name={header} />
            </Route>
            <Route path={`${match.path}/account`} exact>
                <BankAccount storeId={storeId} name={header} />
            </Route>
            <Route path={`${match.path}/payout`} exact>
                <Payout storeId={storeId} />
            </Route>
        </Fragment>
    )
}

export default EditStore
