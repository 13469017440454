import React, { Fragment } from 'react'
import {InputGroup, FormGroup, FormLabel, Col, Form} from 'react-bootstrap';
import { Field, ErrorMessage } from 'formik';


const IconInput = ({fieldName, fieldType, fieldLabel, readOnly, md, children}) => {
    return (
        <FormGroup controlId={fieldName} as={Col}  xs="12" sm="12" md={md ? md : "4"} className="formStyle">
            <FormLabel className="formLabel">{fieldLabel}</FormLabel>
            <InputGroup className="mb-2">
                <Field 
                    name={fieldName}
                >
                    {({field, form, meta}) => <Form.Control readOnly={readOnly} className="workStyle" type={ fieldType ? fieldType : 'text'} value={field.value} onChange={field.onChange} />}
                </Field>
                <InputGroup.Text style={{color:'#1B2F5B',backgroundColor:'#FFFFFF',borderColor:'#B4CBF7' , borderTopRightRadius: 10, borderBottomRightRadius: 10}}>
                    {children}
                </InputGroup.Text>
            </InputGroup>
            <ErrorMessage name={fieldName}>
                { msg => <div style={{ color: 'red' }}>{msg}</div> }
            </ErrorMessage>
        </FormGroup>
    )
}

export default IconInput
