import { 
    STORE_LIST_SUCCESS,
    STORE_LIST_FAIL, 
    RESET_STORE,
    NEW_STORE_TYPE_SUCCESS,
    NEW_STORE_TYPE_FAIL,
    STORE_TYPE_LIST_SUCCESS,
    STORE_TYPE_LIST_FAIL,
    NEW_STORE_SUCCESS,
    NEW_STORE_FAIL,
    STORE_DETAILS_SUCCESS,
    STORE_DETAILS_FAIL,
    STORE_UPDATE_SUCCESS,
    STORE_UPDATE_FAIL,
    PRODUCT_LIST_SUCCESS,
    PRODUCT_LIST_FAIL,
    CATEGORY_LIST_SUCCESS,
    CATEGORY_LIST_FAIL,
    NEW_CATEGORY_SUCCESS,
    NEW_CATEGORY_FAIL,
    UPDATE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_FAIL,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_FAIL,
    NEW_PRODUCT_SUCCESS,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_DETAILS_FAIL,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_FAIL,
    CATEGORY_COMMISION_SUCCESS,
    CATEGORY_COMMISION_FAIL,
    NEW_CATEGORY_COMMISION_SUCCESS,
    NEW_CATEGORY_COMMISION_FAIL,
    UPDATE_CATEGORY_COMMISION_SUCCESS,
    STORE_DELETE_SUCCESS,
    STORE_DELETE_FAIL,
    DELETE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_FAIL,
    DELETE_CATEGORY_COMMISION_SUCCESS,
    DELETE_CATEGORY_COMMISION_FAIL,
    NEW_SLIDER_SUCCESS,
    NEW_SLIDER_FAIL,
    DELETE_SLIDER_SUCCESS,
    DELETE_SLIDER_FAIL,
    DELETE_STORETYPE_SUCCESS,
    DELETE_STORETYPE_FAIL,
    UPDATE_STORETYPE_SUCCESS,
    SEARCH_PRODUCT_SUCCESS,
    SEARCH_PRODUCT_FAIL,
    CLEAR_SEARCH,
    STORE_UNDER_FRANCHISEE_SUCCESS,
    STORE_UNDER_FRANCHISEE_FAIL,
    STORE_INPUT,
    PRODUCT_INPUT
} from "../constants/storeConstants";

export  const storeReducer = (state = { storeTypes: [] }, action) => {
    switch(action.type){

        case STORE_LIST_SUCCESS:
            return {
                ...state,
                storeList: action.payload
            }

        case STORE_LIST_FAIL:
        case NEW_STORE_TYPE_FAIL:
        case STORE_TYPE_LIST_FAIL:
        case NEW_STORE_FAIL:
        case STORE_DETAILS_FAIL:
        case STORE_DELETE_FAIL:
        case NEW_SLIDER_FAIL:
        case STORE_UPDATE_FAIL:
        case DELETE_SLIDER_FAIL:
        case STORE_UNDER_FRANCHISEE_FAIL:
            return {
                ...state,
                error: action.payload.message
            }

        case RESET_STORE:
            return{
                ...state,
                error: null,
                newStoreType: null,
                storeSuccess: null,
                updateSuccess: null,
                deleteStore: null,
                newSlider: null,
                sliderDelete: null,
                deleteStoretype: null,
                storeTypeUpdate: null
            }

        case NEW_STORE_TYPE_SUCCESS:
            return {
                ...state,
                storeTypes: state.storeTypes ? [...state.storeTypes , action.payload.storeType] : [action.payload.storeType],
                newStoreType: true
            }

        case UPDATE_STORETYPE_SUCCESS:
            
            return {
                ...state,
                storeTypes: state.storeTypes.map(store => store._id === action.payload._id ? action.payload : store),
                storeTypeUpdate: true
            }

        case STORE_TYPE_LIST_SUCCESS:
            return {
                ...state,
                storeTypes: action.payload
            }

        case DELETE_STORETYPE_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case DELETE_STORETYPE_SUCCESS:

            return {
                ...state,
                storeTypes :  state.storeTypes.filter(store => store._id !== action.payload),
                deleteStoretype: true
            }

        case NEW_STORE_SUCCESS:
            return {
                ...state,
                storeList : state.storeList ? [...state.storeList, action.payload.store] : [ action.payload.store ],
                storeSuccess: true
            }

        case STORE_DETAILS_SUCCESS:
            return{
                ...state,
                storeDetails: action.payload
            }

        case STORE_UPDATE_SUCCESS:
            return {
                ...state,
                storeDetails: action.payload.store,
                updateSuccess: true
            }

        case STORE_DELETE_SUCCESS:
            return {
                ...state,
                storeList : state.storeList.filter(st => st._id !== action.payload),
                deleteStore: true
            }

        case NEW_SLIDER_SUCCESS:
            let sliders = state.storeDetails.slider ? [...state.storeDetails.slider, action.payload] : [action.payload];
            let newStoreDetails = { ...state.storeDetails, slider: sliders }
            return {
                ...state,
                newSlider: true,
                storeDetails : newStoreDetails
            }

        case DELETE_SLIDER_SUCCESS:
            let newsliders = state.storeDetails.slider.filter(slide => slide._id != action.payload)
            let remainStoreDetails = { ...state.storeDetails, slider: newsliders }
            return {
                ...state,
                sliderDelete: true,
                storeDetails : remainStoreDetails
            }

        case STORE_UNDER_FRANCHISEE_SUCCESS:
            return{
                ...state,
                storeList : action.payload
            }

        case STORE_INPUT:
            return{
                ...state,
                [action.payload.prop] : action.payload.value
            }

        default: 
            return state;
    }
}

export  const productReducer = (state = { }, action) => {
    switch(action.type){

        case PRODUCT_LIST_SUCCESS:
            return{
                ...state,
                productsList: action.payload
            }

        case PRODUCT_LIST_FAIL:
        case PRODUCT_DETAILS_FAIL:
        case UPDATE_PRODUCT_FAIL:
        case SEARCH_PRODUCT_FAIL:
        case DELETE_PRODUCT_FAIL:
            return {
                ...state,
                error: action.payload.message,
                productsList: []
            }

        case NEW_PRODUCT_SUCCESS:
            return {
                ...state,
                productsList: state.productsList ? [...state.productsList, action.payload] : [action.payload],
                newProduct: true
            }

        case PRODUCT_DETAILS_SUCCESS:
            return {
                ...state,
                productDetails: action.payload
            }

        case UPDATE_PRODUCT_SUCCESS:
            return {
                ...state,
                productsList: state.productsList.map(prod => prod._id === action.payload._id ? action.payload : prod),
                productUpdate : true
            }

        case DELETE_PRODUCT_SUCCESS:
            return {
                ...state,
                deleteProduct: true
            }

        case SEARCH_PRODUCT_SUCCESS:
            return{
                ...state,
                searchProducts: action.payload
            }

        case CLEAR_SEARCH:
            return {
                ...state,
                searchProducts: null
            }

        case RESET_STORE:
            return {
                ...state,
                error: null,
                newProduct: null,
                productUpdate: null,
                deleteProduct: null
            }

        case PRODUCT_INPUT:
            return {
                ...state,
                [action.payload.prop] : action.payload.value
            }

        default: 
            return state;
    }
}

export  const categoryReducer = (state = { }, action) => {
    switch(action.type){

        case CATEGORY_LIST_SUCCESS:
            return{
                ...state,
                categoryLists: action.payload
            }

        case CATEGORY_LIST_FAIL:
        case NEW_CATEGORY_FAIL:
        case DELETE_CATEGORY_FAIL:
        case CATEGORY_COMMISION_FAIL:
        case UPDATE_CATEGORY_FAIL:
        case NEW_CATEGORY_COMMISION_FAIL:
        case DELETE_CATEGORY_COMMISION_FAIL:
            return{
                ...state,
                error: action.payload.message
            }

        case RESET_STORE:
            return{
                ...state,
                error: null,
                categorySuccess: null,
                commissionSuccess: null,
                commissionUpdate: null,
                deleteCommission: null,
                deleteCategorySuccess:null,
                updateCategorySuccess:null
            }

        case NEW_CATEGORY_SUCCESS:
            return {
                ...state,
                categoryLists: state.categoryLists.length>0 ? [...state.categoryLists, action.payload] : [action.payload],
                categorySuccess: true
            }

        case UPDATE_CATEGORY_SUCCESS:
            return{
                ...state,
                categoryLists: state.categoryLists.map(cat => cat._id === action.payload._id ? action.payload : cat),
                updateCategorySuccess: true
            }

        case DELETE_CATEGORY_SUCCESS:
            return {
                ...state,
                categoryLists: state.categoryLists.filter(cat => cat._id !== action.payload),
                deleteCategorySuccess: true
            }    

        case CATEGORY_COMMISION_SUCCESS:
            return {
                ...state,
                commisionLists: action.payload
            }

        case NEW_CATEGORY_COMMISION_SUCCESS:
            return{
                ...state,
                commisionLists: state.commisionLists ? [...state.commisionLists, action.payload] : [action.payload],
                commissionSuccess: true
            }

        case UPDATE_CATEGORY_COMMISION_SUCCESS:
            return{
                ...state,
                commisionLists: state.commisionLists.map(comm => comm._id === action.payload._id ? action.payload: comm),
                commissionUpdate: true
            }

        case DELETE_CATEGORY_COMMISION_SUCCESS:
            return {
                ...state,
                commisionLists: state.commisionLists.filter(cat => cat._id !== action.payload),
                deleteCommission : true
            }

        default:
            return state;
    }
}