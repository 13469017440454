import reactotron from "../../ReactotronConfig";
import { 
    LOADING 
} from "../constants/userConstants"
import axios from '../../CustomAxios'
import { 
    
    STORE_PAYOUT_FAIL, 
    STORE_PAYOUT_SUCCESS, 
    FRANCHISEE_PAYOUT_FAIL, 
    FRANCHISEE_PAYOUT_SUCCESS,
    DELIVERYBOY_PAYOUT_FAIL, 
    DELIVERYBOY_PAYOUT_SUCCESS, 
    ADMIN_PAYOUT_FAIL, 
    ADMIN_PAYOUT_SUCCESS, 
} from "../constants/payoutConstants";

// GET STORE PAYOUT
export const getStorePayout = () => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/storepayout-summary`)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: STORE_PAYOUT_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: STORE_PAYOUT_FAIL,
            payload: error
        })
    });
}

//GET FRANCHISEE PAYOUT
export const getFranchiseePayout = () => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/franchiseepayout-summary`)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: FRANCHISEE_PAYOUT_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: FRANCHISEE_PAYOUT_FAIL,
            payload: error
        })
    });
}


// GET DELIVERY BOY PAYOUT
// export const getStorePayout = () => async(dispatch) => {
//     dispatch({
//         type: LOADING,
//         payload: true
//     })

//     await axios.get(`admin/subscription-fees`)
//     .then(async response => {
//         dispatch({
//             type: LOADING,
//             payload: false
//         })
//         dispatch({
//             type: DELIVERYBOY_PAYOUT_SUCCESS,
//             payload: response.data
//         })
//     })
//     .catch(async error => {
//         dispatch({
//             type: LOADING,
//             payload: false
//         })
//         dispatch({
//             type: DELIVERYBOY_PAYOUT_FAIL,
//             payload: error
//         })
//     });
// }
//GET ADMIN PAYOUT
export const getAdminPayout = () => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/adminpayout-summary`)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: ADMIN_PAYOUT_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: ADMIN_PAYOUT_FAIL,
            payload: error
        })
    });
}

export const getStorePayoutByStoreId = (storeId) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/storepayout-summary/${storeId}`)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })

        dispatch({
            type: STORE_PAYOUT_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })

        dispatch({
            type: STORE_PAYOUT_FAIL,
            payload: error
        })
    });
}

