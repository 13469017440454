import React, { Fragment, useState } from 'react'
import Header from '../../components/Header'
import Layout from '../layout'
import './order.scss'
import OrderList from './OrderList'

const Order = () => {

    
    return (
        
        <Layout>
            <Header headerText="ORDER MANAGEMENT" />
            <OrderList />
            
        </Layout>
       
    )
}

export default Order
