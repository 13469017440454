import reactotron from "../../ReactotronConfig";
import { 
    LOADING 
} from "../constants/userConstants"
import axios from '../../CustomAxios'
import { 
    NEW_SER_CATEGORY_FAIL,
    NEW_SER_CATEGORY_SUCCESS,
    NEW_SER_MAIN_CATEGORY_FAIL, 
    NEW_SER_MAIN_CATEGORY_SUCCESS, 
    SER_CATEGORY_DETAILS_FAIL, 
    SER_CATEGORY_DETAILS_SUCCESS, 
    SER_CATEGORY_LIST_FAIL, 
    SER_CATEGORY_LIST_SUCCESS, 
    SER_MAIN_CATEGORY_LIST_FAIL, 
    SER_MAIN_CATEGORY_LIST_SUCCESS,
    UPDATE_SER_CATEGORY_FAIL,
    UPDATE_SER_CATEGORY_SUCCESS,
    DELETE_SER_CATEGORY_FAIL, 
    DELETE_SER_CATEGORY_SUCCESS, 
    DELETE_SER_MAIN_CATEGORY_FAIL,
    DELETE_SER_MAIN_CATEGORY_SUCCESS,
} from "../constants/serviceConstants";

//get all service categories

export const getServiceLists = () => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/service-categories`)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })

        response.data.map(cat => {
            cat.label = cat.name;
            cat.value = cat.name;
        })
        dispatch({
            type: SER_CATEGORY_LIST_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: SER_CATEGORY_LIST_FAIL,
            payload: error
        })
    });
}


//ADD NEW Service Category
export const addNewService = (details) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/create-servicecategory`, details)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_SER_CATEGORY_SUCCESS,
            payload: response.data.serviceCategory
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_SER_CATEGORY_FAIL,
            payload: error
        })
    });
}


//GET list of all service main categories
export const getserviceCategories = () => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/service-maincategories`)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        let details = [];
        await response.data.map(service => {
            details.push({ id: service._id, label: service.name, value : service.name, created_at: service.created_at })
        })
        dispatch({
            type: SER_MAIN_CATEGORY_LIST_SUCCESS,
            payload: details
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: SER_MAIN_CATEGORY_LIST_FAIL,
            payload: error
        })
    });
}

//create new service main category
export const saveCategoriesMain = (mainserviceName) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    let data = {
        name: mainserviceName
    }

    await axios.post(`admin/create-maincategory`, data)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        let details = {
            id: response.data.mainserviceName._id, 
            label: response.data.mainserviceName.name, 
            value : response.data.mainserviceName.name, 
            created_at: response.data.mainserviceName.created_at
        }
        dispatch({
            type: NEW_SER_MAIN_CATEGORY_SUCCESS,
            payload: details
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_SER_MAIN_CATEGORY_FAIL,
            payload: error
        })
    });
}

//Get details of single service category
export const getServiceDetails = (id) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.get(`admin/service-category/${id}`, id)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: SER_CATEGORY_DETAILS_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: SER_CATEGORY_DETAILS_FAIL,
            payload: error
        })
    });
}
//edit service category

export const updateServiceCategory = (details) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/edit-servicecategory`, details)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: UPDATE_SER_CATEGORY_SUCCESS,
            payload: response.data.serviceCategory
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: UPDATE_SER_CATEGORY_FAIL,
            payload: error
        })
    });
}


//DELETE single service category
export const deleteServiceCategoryById = (id) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.delete(`admin/delete-servicecategory/${id}`)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: DELETE_SER_CATEGORY_SUCCESS,
            payload: id
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: DELETE_SER_CATEGORY_FAIL,
            payload: error
        })
    });
}



//Delete single service main category
export const deleteMainCategoryById = (id) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.delete(`admin/delete-maincategory/${id}`)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: DELETE_SER_MAIN_CATEGORY_SUCCESS,
            payload: id
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: DELETE_SER_MAIN_CATEGORY_FAIL,
            payload: error
        })
    });
}

