import React, { Fragment, useEffect, useState } from 'react'
import { Card,  Col} from 'react-bootstrap'
import { MdCheck,MdPayments,MdOutlinePending } from 'react-icons/md'
import Paging from '../../components/Paging'
import Buttons from '../../components/Buttons'

import reactotron from '../../ReactotronConfig'
import { useSelector, useDispatch } from 'react-redux'
import { getFranchiseePayout } from '../../Redux/actions/payoutActions'


const FranchiseePayout = () => {
    const { franchiseePayout } = useSelector(state => state.franchiseepayout)
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1)
    const [datas, setDatas] = useState([])
    const [search, setSearch] = useState('')
    

    useEffect(() => {
        if(franchiseePayout && franchiseePayout.length>0){
            reactotron.log({franchiseePayout})
            const indexOfLastPost = currentPage * 10;
            const indexOfFirstPost = indexOfLastPost - 10;
            const searchResult = franchiseePayout.filter((franchiseepayouts) => franchiseepayouts.name.toLowerCase().includes(search.toLowerCase()) || franchiseepayouts.email.toLowerCase().includes(search.toLowerCase())
            )
            const currentPosts = (searchResult || []).slice(indexOfFirstPost, indexOfLastPost);
            console.log(currentPosts)
            setDatas(currentPosts)
        }
    }, [franchiseePayout, search])

    const pageChanged = (page)=> {
        setCurrentPage(page)
        const indexOfLastPost = page * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const searchResult = franchiseePayout.filter(franchiseepayouts => franchiseepayouts.name.toLowerCase().contains(search.toLowerCase()) || franchiseepayouts.email.toLowerCase().contains(search.toLowerCase()))
        const currentPosts = (searchResult || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }
    useEffect(() => {
        dispatch(getFranchiseePayout())
        console.log({franchiseePayout})
    }, [])

    return (
        <Fragment>
            
            <Card style={{ borderRadius: 20, margin: 10, minHeight: '70vh' }}>
                  <Card.Body style={{ overflowX: 'auto' }}>
                    <table>
                        <thead class="tableHeader">
                            <th>Franchisee Name</th>
                            <th>City</th>
                            <th>Total Stores</th>
                            <th>Total Orders</th>
                            <th>Total Revenue</th>
                            <th>Franchisee Payout</th>
                            <th>Payout</th>
                        </thead>
                        <tbody>
                            {datas && datas.map((franchiseepayout, index) => (
                                <tr key={index}>
                                    <td class="tableContent">{franchiseepayout.franchisee_name}</td>
                                    <td class="tableContent">{franchiseepayout.city}</td>
                                    <td class="tableContent">{franchiseepayout.totalStores}</td>
                                    <td class="tableContent">{franchiseepayout.totalOrders}</td>
                                    <td class="tableContent">{franchiseepayout.totalRevenue}</td>
                                    <td class="tableContent">{franchiseepayout.franchisee_payout}</td>
                                    <td class="tableContent">
                                        {franchiseepayout.payout === 'pay' ? 
                                        <Buttons backColor="#D42F45" buttonText="PAY" buttonAction={()=> console.log("pressed")}>
                                        <MdPayments style={{color:'#FFFFFF'}} fontSize="2.3em" />
                                        </Buttons>
                                     :   franchiseepayout.payout === 'paid' ? 
                                        <Buttons backColor="#4AAD47" buttonText="PAID" buttonAction={()=> console.log("pressed")}>
                                        <MdCheck style={{color:'#FFFFFF'}} fontSize="2.3em" />
                                        </Buttons>
                                     :  
                                        <Buttons backColor="#FC9D21" buttonText="PENDING"  buttonAction={()=> console.log("pressed")}>
                                        <MdOutlinePending style={{color:'#FFFFFF'}} fontSize="2.3em" />
                                        </Buttons>}
                                    </td>
                                    
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Card.Body>
                <Col style={{ float: 'right', display:'flex', flexDirection: 'row-reverse', justifyContent: 'flex-start', alignItems: 'center', margin: 30  }}>
                <Card >
                    {franchiseePayout && franchiseePayout.length>10 && <Paging datas={franchiseePayout} currentPage={currentPage} pageChange={pageChanged} />}
                </Card>
                </Col>
            </Card>
 
        </Fragment>
    )
}

export default FranchiseePayout
