import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import Modals from '../../components/Modals'
import InputGroup from 'react-bootstrap/InputGroup'
import {BsPercent} from 'react-icons/bs'
import {MdLocationOn} from 'react-icons/md'
import {IoMdAdd}from 'react-icons/io'
import Buttons from '../../components/Buttons'
import './order.scss'
import reactotron from '../../ReactotronConfig'
import { useSelector, useDispatch } from 'react-redux'
import { addNewOrder } from '../../Redux/actions/orderActions'
import { toast } from 'react-toastify'
import { RESET_ORDER } from '../../Redux/constants/orderConstants'
import FranchiseeForm from '../Franchisee/FranchiseeForm'
import { RESET_ERROR } from '../../Redux/constants/franchiseeConstants'
import { getFranchisee } from '../../Redux/actions/franchiseeActions'
import Select from 'react-select';
import {Formik,ErrorMessage, Field} from 'formik';
import InputField from '../../components/InputField'
import IconInput from '../../components/IconInput'
import FormSelectField from '../../components/form-select-field'
import MultiInput from '../../components/MultiInput'
import * as Yup from 'yup';
import "yup-phone";

const NewOrder = ({openModal, closeModal, headerColor, headerTitle}) => {

    const dispatch = useDispatch();
    const { orderTypes, error, newOrderType } = useSelector(state => state.order)
    const { loading } = useSelector(state => state.auth)
    const { error : franchiseeError, newFranchisee, franchiseeList } = useSelector(state => state.franchisee)

    const [image, setImage] = useState(null);

    const [openSubModal, setOpenSubModal] = useState(false);
    const [subHeaderColor, setSubHeaderColor] = useState('');
    const [subHeaderTitle, setSubHeaderTitle] = useState('');

    const [openFranchiseeModal, setOpenFranchiseeModal] = useState(false)

    useEffect(() => {
        if(error){
            toast.error(error);
            dispatch({
                type: RESET_ORDER
            })
        }

        if(franchiseeError){
            toast.error(franchiseeError);
            dispatch({
                type: RESET_ERROR
            })
        }

        if(newOrderType){ //close Order type modal
            setOpenSubModal(false)
        }

        if(newFranchisee){
            toast.success("New Franchisee created Successfully");
            setOpenFranchiseeModal(false);
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [error, newOrderType, franchiseeError, newFranchisee])


    const closeSubModel = () => {
        setOpenSubModal(false);
    }

    const closeFranchiseeModal = () => {
        setOpenFranchiseeModal(false)
    }


    useEffect(() => {
        
        dispatch(getFranchisee())
    }, [])

    const saveOrder = async (values) =>  {




        console.log({values})

        var form_data = new FormData();
        form_data.append("customer_name", values.customer_name);
        form_data.append("location", values.location);
        form_data.append("details", values.details);
        form_data.append("date", values.date);
        form_data.append("time",parseInt(values.time));
        form_data.append("total_price", parseInt(values.total_price));
        form_data.append("store_name",values.store_name);
        form_data.append("storeID", values.storeID);
        form_data.append("payment_method", values.payment_method);
        form_data.append("delivery_note", values.delivery_note);
        form_data.append("delivery_boy", values.delivery_boy);
        form_data.append("delivery_type", values.delivery_type);
        form_data.append("delivery_status", values.delivery_status === true ? 'Active': 'Inactive' );
        
        console.log(form_data);

        dispatch(addNewOrder(form_data))
    }

    const saveOrderType = () => {
        //dispatch(saveOrdersType(order_type))
    }

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    return (
        <Modals showModal={openModal} hideModal={closeModal} headerColor={headerColor} modalTitle={headerTitle}>
            
            <Formik 
                initialValues={{
                    customer_name: '',
                    location: '',
                    details: '',
                    date: '',
                    time: '',
                    total_price: '',
                    store_name: '',
                    storeID: '',
                    payment_method: '',
                    delivery_note: '',
                    delivery_status: '',
                    delivery_boy: '',
                    delivery_type: ''
                }}



                validationSchema={
                    
                    Yup.object({
                        customer_name: Yup.string().min(8, 'Must be 8 characters or less').required('Name Required'),
                        location: Yup.string().required('Location is Required'),
                        details: Yup.string().required('Details are Required'),
                        date: Yup.string().required("Date is Required"),
                        time: Yup.string().required("Time is required"),
                        total_price: Yup.string().required('Total Price is Required'),
                        store_name: Yup.string().required('Store Name is Required'),
                        storeID :  Yup.string().required('Store ID is required'),
                        payment_method : Yup.string().required("Payment Method is Required"),
                        delivery_note : Yup.string().required("Delivery note is Required"),
                        delivery_status: Yup.string().required("Delivery status is Required"),
                        delivery_boy : Yup.string().required("Delivery boy Required"),
                        delivery_type: Yup.string().required("Delivery type is Required")
                    })
                }
                onSubmit={(values) => {
                    console.log({values})
                    saveOrder(values)
                }}
                render={({handleChange, handleSubmit, handleBlur, values, errors}) => (
                    <Form>
                        <Row>
                            <InputField fieldName="customer_name" fieldLabel="Customer Name" />
                            <InputField fieldName="location" fieldLabel="Location" fieldType="text" />
                            <InputField fieldName="details" fieldLabel="Details" fieldType="text" />
                            <InputField fieldName="date" fieldLabel="Date" fieldType="text" />
                            <InputField fieldName="time" fieldLabel="Time" fieldType="text" />
                            <InputField fieldName="total_price" fieldLabel="Total Price" fieldType="text" />
                            <InputField fieldName="store_name" fieldLabel="Store Name" fieldType="text" />
                            <InputField fieldName="storeID" fieldLabel="Store ID" fieldType="text" />
                            <InputField fieldName="payment_method" fieldLabel="Payment Method" fieldType="text" />
                            <InputField fieldName="delivery_note" fieldLabel="Delivery Note" fieldType="text" />
                            <Form.Group as={Col} xs="12" sm="12" md="6" controlId="delivery_status" className="formStyle">
                                                <Form.Label className="formLabel">Delivery Status</Form.Label>
                                                <div style={{ display: 'flex', flexDirection: 'row', height: 40, borderRadius: 10, border: "1px solid #417AE85E", backgroundColor: '#417AE80A', alignItems:'center', justifyContent:'center' }}>
                                                    <span style={{ marginRight: 10, fontFamily: "Avenir-Black", color: '#1B2F5B' }}>Deactive</span>
                                                    <Field 
                                                        name='delivery_status'
                                                    >
                                                        {({field, form, meta}) => <Form.Check 
                                                                type="switch"
                                                                id="delivery_status"
                                                                label="Active"
                                                                value={field.value} 
                                                                onChange={field.onChange}
                                                                style={{ fontFamily: "Avenir-Black", color: '#1B2F5B' }}
                                                            />}
                                                    </Field>
                                                </div>
                                            </Form.Group>
                            <InputField fieldName="delivery_boy" fieldLabel="Delivery Boy" fieldType="text" />
                            
                            <FormSelectField
                                label="Delivery Type"
                                type="text"
                                name="delivery_type"
                                
                            >
                               
                                    <option value="ontheway" >On The Way</option>
                                    <option value="delivered" >Delivered</option>
                                    
                                    
                               
                            </FormSelectField>
                            
                        </Row>
                        {headerTitle === 'ADD ORDER' && 
                        <Row>
                            <center>
                                <Buttons backColor="#D42F45" buttonText="ADD" buttonAction={handleSubmit}>
                                    <IoMdAdd style={{color:'#FFFFFF'}} fontSize="2.3em" />
                                </Buttons>
                            </center>
                        </Row>}
                    </Form>
                )}
            />
            
           
        
        </Modals>
    )
}

export default NewOrder
