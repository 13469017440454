import React, { Fragment,useEffect,  useState } from 'react'
import { Card, Col } from 'react-bootstrap'
import { IoMdAddCircle } from 'react-icons/io'
import Modals from '../../components/Modals'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { MdEdit } from 'react-icons/md'
import { AiFillDelete } from 'react-icons/ai'
import Paging from '../../components/Paging'
import Buttons from '../../components/Buttons'
import { BsCheck2 } from 'react-icons/bs'
import { FaTimes } from 'react-icons/fa'
import NewSurprise from './NewSurprise'
import { deleteSingleSurpriseById, getSurprises } from '../../Redux/actions/surpriseActions'
import { RESET_SURPRISE, SURPRISE_INPUT } from '../../Redux/constants/surpriseConstants'
import EditSurprise from './EditSurprise'
import { toast } from 'react-toastify'


const OrderList = () => {

    const dispatch = useDispatch()
    const { surpriseLists, error, updateSuccess, surpriseSuccess, deleteSurprise } = useSelector(state => state.surprise)

    const [currentPage, setCurrentPage] = useState(1)
    const [datas, setDatas] = useState([])
    const [modalShow, setModalShow] = useState(false);
    const [modalShowEdit, setModalShowEdit] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [headerColor, setHeaderColor] = useState('')
    const [headerTitle, setHeaderTitle] = useState('')
    const [selected, setSelected] = useState('')


    useEffect(() => {
        if(error){
            toast.error(error)
            dispatch({
                type : RESET_SURPRISE
            })
        }

        if(updateSuccess){
            setModalShowEdit(false)
            toast.success("Package Updated Successfully");
            dispatch({
                type : RESET_SURPRISE
            })
        }

        if(surpriseSuccess){
            setModalShow(false)
            toast.success("Package Created Successfully");
            dispatch({
                type : RESET_SURPRISE
            })
        }

        if(deleteSurprise){
            setModalShow(false)
            toast.success("Package Deleted Successfully");
            dispatch({
                type : RESET_SURPRISE
            })
        }
    }, [error, updateSuccess, surpriseSuccess, deleteSurprise])

    const createNewSurprise = () => {
        setHeaderColor('#D42F45');
        setHeaderTitle("NEW SURPRISE ORDER");
        setModalShow(true);
    }
    useEffect(() => {
        dispatch(getSurprises())
        console.log({surpriseLists})
    }, [])

    const editSurprise = (surprise) => {
        dispatch({
            type: SURPRISE_INPUT,
            payload: {
                prop: 'surpriseDetails',
                value: null
            }
        })
        setSelected(surprise._id)
        setHeaderColor('#D42F45');
        setHeaderTitle("EDIT SURPRISE ORDER");
        setModalShowEdit(true);
    }


    const deleteSurprises = (surprise) => {
        setSelected(surprise._id);
        setDeleteModalShow(true)
    }

    const deleteSurpriseById = () => {
        dispatch(deleteSingleSurpriseById(selected))
    }

    return (
        <Fragment>
            <Card style={{ borderRadius: 20, marginTop: 30, marginRight: 5 }}>
                <IoMdAddCircle className="add" fontSize="3.5em" 
                onClick={createNewSurprise}
                style={{ position: 'absolute', top: -30, right: -20, cursor: 'pointer' }} />
                <Card.Body style={{ overflowX: 'auto' }}>
                    <table>
                        <thead className="tableHeader">
                            <tr>
                                <th>ID</th>
                                <th>Title</th>
                                <th>Stores</th>
                                <th>Order Items</th>
                                <th>Franchisee</th>
                                <th>Order Cost</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {datas && datas.map((surprise, index) => (
                                <tr key={index}>
                                    <td class="tableContent">{surprise._id}</td>
                                    <td class="tableContent">{surprise.title}</td>
                                    <td class="tableContent">{surprise.stores}</td>
                                    <td class="tableContent">{surprise.details}</td>
                                    <td class="tableContent">{surprise.franchisee}</td>
                                    <td class="tableContent">{surprise.total}</td>
                                
                                    <td class="tableContent">
                                        <MdEdit color="orange" onClick={() => editSurprise(surprise)}/> 
                                        <AiFillDelete class="add" onClick={() => deleteSurprises(surprise)}/>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Card.Body>
                <Col style={{ float: 'right', display:'flex', flexDirection: 'row-reverse', justifyContent: 'flex-start', alignItems: 'center', margin: 30  }}>
                <Card >
                    {surpriseLists && surpriseLists.length>10 && <Paging 
                        datas={surpriseLists} 
                        currentPage={currentPage} 
                        //pageChange={pageChanged} 
                    />}
                </Card>
                </Col>
            </Card>
            <Modals showModal={modalShow} hideModal={() => setModalShow(false)} modalTitle={headerTitle} headerColor={headerColor} >
                <NewSurprise />
            </Modals>
            <Modals showModal={modalShowEdit} hideModal={() => setModalShowEdit(false)} modalTitle={headerTitle} headerColor={headerColor}>
                <EditSurprise id={selected} />
            </Modals>
            <Modals showModal={deleteModalShow} hideModal={()=> setDeleteModalShow(false)} modalTitle={headerTitle} headerColor={headerColor}>
                <p className="deleteText">Are you sure you want to delete this store?</p>
                <div style={{ display: 'flex', alignItems:'center', justifyContent:'center' }}>
                        <Buttons 
                            backColor="#4AAD47" 
                            buttonText="YES" 
                            buttonAction={deleteSurpriseById}
                        >
                            <BsCheck2 style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                        <Buttons backColor="#D42F45" buttonText="No" buttonAction={()=> setDeleteModalShow(false)}>
                            <FaTimes style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                </div>
            </Modals>
        </Fragment>
    )
}

export default OrderList
