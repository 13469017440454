import { 
    LOADING 
} from "../constants/userConstants"
import {  
    CUSTOMER_LIST_FAIL, 
    CUSTOMER_LIST_SUCCESS, 
    DELETE_CUSTOMER_SUCCESS,
    DELETE_CUSTOMER_FAIL,
    CUSTOMER_DETAILS_SUCCESS,
    CUSTOMER_DETAILS_FAIL
} from "../constants/customerConstants";
import axios from '../../CustomAxios'

//Get all CUSTOMERs

export const getCustomerLists = () => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/customers`)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: CUSTOMER_LIST_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: CUSTOMER_LIST_FAIL,
            payload: error
        })
    });
}

//Get customer Details By Id
export const getCustomerDetails = (id) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.get(`admin/customer-info/${id}`, id)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: CUSTOMER_DETAILS_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: CUSTOMER_DETAILS_FAIL,
            payload: error
        })
    });
}

//DELETE single CUSTOMER
export const deleteCustomerListById = (id) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.delete(`admin/delete-customer/${id}`)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: DELETE_CUSTOMER_SUCCESS
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: DELETE_CUSTOMER_FAIL,
            payload: error
        })
    });
}
