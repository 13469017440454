import React, { useEffect } from 'react'
import { Card } from 'react-bootstrap'
import { AiFillDelete } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { getStorePayoutByStoreId } from '../../../Redux/actions/payoutActions'


const Payout = ({storeId}) => {

    const dispatch = useDispatch();

    const { storePayout } = useSelector(state => state.storepayout)

    useEffect(() => {
        dispatch(getStorePayoutByStoreId(storeId))
    }, [])


    return (
        <Card style={{ borderRadius: 20, margin: 10 }}>
            <Card.Body style={{ overflowX: 'auto' }}>
                <table>
                    <thead className="tableHeader">
                        <tr>
                            <th>S.No</th>
                            <th>City</th>
                            <th>Total Orders</th>
                            <th>Total Revenue</th>
                            <th>Pending Payment</th>
                            <th>Franchisee</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {storePayout && storePayout.map((store, index) => (
                            <tr key={index}>
                                <td className="tableContent">{index+1}</td>
                                <td className="tableContent">{store.city}</td>
                                <td className="tableContent">{store.total}</td>
                                <td className="tableContent">{store.total-store.costPrice}</td>
                                <td className="tableContent"></td>
                                <td className="tableContent">{store.franchisee_name}</td>
                                <td className="tableContent">{store.status}</td>
                                <td className="tableContent">
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Card.Body>
        </Card>
)
}

export default Payout
