import React, { Fragment, useEffect, useState } from 'react'
import { Card, Row, Form, Col, InputGroup } from 'react-bootstrap'
import {MdDownloadForOffline} from 'react-icons/md'
import Paging from '../../components/Paging'
import './style.scss'

let monthlyReport = [{
    name: 'Samsung',
    orderscompleted: '10',
    orderscancelled: '10',
    totalorders : '30',
    totalrevenue : '20000'
    
},
{
    name: 'Samsung',
    orderscompleted: '50',
    orderscancelled: '10',
    totalorders : '60',
    totalrevenue : '50000'
},
{
    name: 'Oppo',
    orderscompleted: '20',
    orderscancelled: '10',
    totalorders : '30',
    totalrevenue : '20000'
},
{
    name: 'Samsung',
    orderscompleted: '20',
    orderscancelled: '10',
    totalorders : '30',
    totalrevenue : '20000'
},
{
    name: 'Samsung',
    orderscompleted: '20',
    orderscancelled: '10',
    totalorders : '30',
    totalrevenue : '20000'
},
{
    name: 'Oppo',
    orderscompleted: '20',
    orderscancelled: '10',
    totalorders : '30',
    totalrevenue : '30000'
},
{
    name: 'Oppo',
    orderscompleted: '20',
    orderscancelled: '10',
    totalorders : '30',
    totalrevenue : '20000'
},
{
    name: 'Oppo',
    orderscompleted: '20',
    orderscancelled: '10',
    totalorders : '30',
    totalrevenue : '20000'
},
{
    name: 'Oppo',
    orderscompleted: '20',
    orderscancelled: '10',
    totalorders : '30',
    totalrevenue : '20000'
},
{
    name: 'Nokia',
    orderscompleted: '20',
    orderscancelled: '10',
    totalorders : '30',
    totalrevenue : '20000'
},
{
    name: 'Oppo',
    orderscompleted: '20',
    orderscancelled: '10',
    totalorders : '30',
    totalrevenue : '20000'

}]

const MonthlyReport = () => {

    const [modalShow, setModalShow] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [datas, setDatas] = useState([])
    const [headerColor, setHeaderColor] = useState('')
    const [headerTitle, setHeaderTitle] = useState('')

    useEffect(() => {
        const indexOfLastPost = currentPage * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const currentPosts = (monthlyReport || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }, [])

    const pageChanged = (page)=> {
        setCurrentPage(page)
        const indexOfLastPost = page * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const currentPosts = (monthlyReport || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }

    

    return (
        <Fragment>
            
            <Card style={{ borderRadius: 20, margin: 10, minHeight: '70vh' }}>
                <MdDownloadForOffline class="download" fontSize="3.5em" 
                style={{ position: 'absolute', top: -30, right: -20, cursor: 'pointer' }} />
                <Card.Body style={{ overflowX: 'auto' }}>
                    <table>
                        <thead class="tableHeader">
                            <th>Store Name</th>
                            <th>Orders Completed</th>
                            <th>Orders Cancelled</th>
                            <th>Total Orders</th>
                            <th>Total Revenue</th>
                        </thead>
                        <tbody>
                            {datas && datas.map((report, index) => (
                                <tr key={index}>
                                    <td class="tableContent">{report.name}</td>
                                    <td class="tableContent">{report.orderscompleted}</td>
                                    <td class="tableContent">{report.orderscancelled}</td>
                                    <td class="tableContent">{report.totalorders}</td>
                                    <td class="tableContent">{report.totalrevenue}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Card.Body>
                <Col style={{ float: 'right', display:'flex', flexDirection: 'row-reverse', justifyContent: 'flex-start', alignItems: 'center', margin: 30  }}>
                <Card >
                    {monthlyReport && monthlyReport.length>10 && <Paging datas={monthlyReport} currentPage={currentPage} pageChange={pageChanged} />}
                </Card>
                </Col>
            </Card>
           
            
 
        </Fragment>
    )
}

export default MonthlyReport
