// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import { Redirect, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import React from 'react'
import reactotron from './ReactotronConfig'

const PrivateRoute = ({ component: Component, ...rest }) => {

  const { isAuthenticated } = useSelector(state => state.auth )

  //reactotron.log({isAuthenticated})

  if(isAuthenticated){
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: '/signin', state: { from: props.location } }} />
          )
        }
      />
    )
  }
  else{
    const user = localStorage.getItem("user");
    if(user){
      return (
        <Route
          {...rest}
          render={props =>(
              <Component {...props} />
            ) 
          }
        />
      )
    }
    else{
      return (
        <Route
          {...rest}
          render={props =>(
              <Redirect to={{ pathname: '/signin', state: { from: props.location } }} />
            )
          }
        />
      )
    }
  }
}

export default PrivateRoute