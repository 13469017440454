import React, { Fragment, useEffect, useState } from 'react'
import { Card, Row, Form, Col, InputGroup } from 'react-bootstrap'
import {MdEdit} from 'react-icons/md'
import {AiFillDelete,AiFillEye} from 'react-icons/ai'
import {BsToggle2On,BsToggle2Off, BsCheck2,BsReplyFill} from 'react-icons/bs'
import { MdCheck, MdClose } from 'react-icons/md'
import {IoMdAddCircle,IoMdAdd}from 'react-icons/io'
import { FaTimes } from 'react-icons/fa'
import Paging from '../../components/Paging'
import Modals from '../../components/Modals'
import Buttons from '../../components/Buttons'
import './style.scss'

import reactotron from '../../ReactotronConfig'
import { useSelector, useDispatch } from 'react-redux'
import { getDeliveryboyReview,deleteDeliveryboyReviewById } from '../../Redux/actions/reviewActions'
import { toast } from 'react-toastify'
import { RESET_REVIEW } from '../../Redux/constants/reviewConstants'


const DeliveryReview
= () => {

    const { deliveryboyReview ,error,deleteDeliveryBoyReviewSuccess} = useSelector(state => state.deliveryboyreview)
    
    const dispatch = useDispatch();
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [datas, setDatas] = useState([])
    const [headerColor, setHeaderColor] = useState('')
    const [headerTitle, setHeaderTitle] = useState('')
    const [selectedReview, setselectedReview] = useState('')



    useEffect(() => {
        const indexOfLastPost = currentPage * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const currentPosts = (deliveryboyReview || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }, [])

    const pageChanged = (page)=> {
        setCurrentPage(page)
        const indexOfLastPost = page * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const currentPosts = (deliveryboyReview || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }

  
    useEffect(() => {
        if(error){
            toast.error(error);
            dispatch({
                type: RESET_REVIEW
            })
        }

        
        if(deleteDeliveryBoyReviewSuccess){
            dispatch(getDeliveryboyReview())
            setDeleteModalShow(false);
            dispatch({
                type: RESET_REVIEW
            })
            toast.success("Review Deleted Successfully");
        }
    }, [error,deleteDeliveryBoyReviewSuccess])


    useEffect(() => {
        dispatch(getDeliveryboyReview())
        console.log({deliveryboyReview})
    }, [])

    const deleteReview = (id) => {
        setselectedReview(id);
        setHeaderColor('#D42F45');
        setHeaderTitle("DELETE REVIEW");
        setDeleteModalShow(true);
    }

    const deleteDeliveryboyReview = () => {
        dispatch(deleteDeliveryboyReviewById(selectedReview))
    }

    return (
        <Fragment>
            
            <Card style={{ borderRadius: 20, margin: 10, minHeight: '70vh' }}>
                
                <Card.Body style={{ overflowX: 'auto' }}>
                    <table>
                        <thead class="tableHeader">
                            <th>Store</th>
                            <th>Customer Name</th>
                            <th>Delivery Boy Name</th>
                            <th>Order Details</th>
                            <th>Review Description</th>
                            <th>Rating</th>
                            <th>Respond</th>
                            <th>Approval</th>
                            <th>Actions</th>
                        </thead>
                        <tbody>
                            {datas && datas.map((deliveryboyreview, index) => (
                                <tr key={index}>
                                    <td class="tableContent">{deliveryboyreview.store}</td>
                                    <td class="tableContent">{deliveryboyreview.customer_name}</td>
                                    <td class="tableContent">{deliveryboyreview.delivery_boy_name}</td>
                                    <td class="tableContent">{deliveryboyreview.order_details}</td>
                                    <td class="tableContent">{deliveryboyreview.review_desc}</td>
                                    <td class="tableContent">
                                    
                                        {deliveryboyreview.rating === '1.0' ? <span className="star">&#9733;&#9734;&#9734;&#9734;&#9734;</span> 
                                        : 
                                        deliveryboyreview.rating === '2.0' ? <span className="star">&#9733;&#9733;&#9734;&#9734;&#9734;</span> 
                                        :
                                        deliveryboyreview.rating === '3.0' ? <span className="star">&#9733;&#9733;&#9733;&#9734;&#9734;</span>
                                        :
                                        deliveryboyreview.rating === '4.0' ? <span className="star">&#9733;&#9733;&#9733;&#9733;&#9734;</span>
                                        :
                                         <span className="star">&#9733;&#9733;&#9733;&#9733;&#9733;</span> 
                                    } 
                                    
                                    </td>
                                    <td class="tableContent">
                                        {deliveryboyreview.response === 'replied' ? 
                                        <Buttons backColor="#4AAD47" buttonText="REPLIED" buttonAction={()=> console.log("pressed")}>
                                        <MdCheck style={{color:'#FFFFFF'}} fontSize="2.3em" />
                                        </Buttons>
                                     :   deliveryboyreview.response === 'reply' ? 
                                        <Buttons backColor="#D42F45" buttonText="REPLAY" buttonAction={()=> console.log("pressed")}>
                                        <BsReplyFill style={{color:'#FFFFFF'}} fontSize="2.3em" />
                                        </Buttons>
                                     :  
                                        <Buttons backColor="#9B9B9B" buttonText="REPLAY"  buttonAction={()=> console.log("pressed")}>
                                        <BsReplyFill style={{color:'#FFFFFF'}} fontSize="2.3em" />
                                        </Buttons>}
                                    </td>
                                    <td class="tableContent">
                                        {deliveryboyreview.status === 'approved' ? <span className="approved">APPROVED</span> : deliveryboyreview.status === 'rejected' ? <span className="rejected">REJECTED</span> : (<div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'center' }}>
                                            <MdCheck fontSize="1.5em" style={{ color: '#4AAD47', margin: 5 }} /> 
                                            <MdClose fontSize="1.5em" style={{ color: '#D42F45', margin: 5 }} />
                                        </div>)}
                                    </td>
                                    <td class="tableContent">
                                        <AiFillDelete class="add" onClick={() => deleteReview(deliveryboyreview._id)}/>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Card.Body>
                <Col style={{ float: 'right', display:'flex', flexDirection: 'row-reverse', justifyContent: 'flex-start', alignItems: 'center', margin: 30  }}>
                <Card >
                    {deliveryboyReview && deliveryboyReview.length>10 && <Paging datas={deliveryboyReview} currentPage={currentPage} pageChange={pageChanged} />}
                </Card>
                </Col>
            </Card>
            
            <Modals showModal={deleteModalShow} hideModal={()=> setDeleteModalShow(false)} modalTitle={headerTitle} headerColor={headerColor}>
                <p className="deleteText" >Are you sure you want to delete this Delivery Boy Review?</p>
                <div style={{ display: 'flex', alignItems:'center', justifyContent:'center' }}>
                        <Buttons backColor="#4AAD47" buttonText="YES" buttonAction={deleteDeliveryboyReview}>
                            <BsCheck2 style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                        <Buttons backColor="#D42F45" buttonText="No" buttonAction={()=> console.log("pressed")}>
                            <FaTimes style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                </div>
            </Modals>
 
        </Fragment>
    )
}

export default DeliveryReview
