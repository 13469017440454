import React from 'react'
import { IoMdAdd }from 'react-icons/io'
import { Button, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'

const Buttons = ({backColor, buttonText, buttonAction, children}) => {

    const { loading } = useSelector(state => state.auth)

    if(!loading){
        return (
            <div onClick={buttonAction} style={{ display:'flex', margin: 20, flexDirection:'row', backgroundColor: backColor, width: 150, height: 40, justifyContent:'center', alignItems:'center' }}>
                {children}
                <Button style={{color:'#1B2F5B',backgroundColor:'#FFFFFF' ,fontFamily:'Avenir-Black, Arial, serif',border:'0', height: 35, marginRight: 5}}>
                {buttonText}
                </Button>
            </div>
        )
    }
    else{
        return(
            <div className="loginButton" style={{ display:'flex', justifyContent:'center', alignItems: 'center' }}>
                <Spinner animation="grow" variant="primary" />
            </div>
        )
    }
}

export default Buttons
