import React, { useState } from 'react'
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import './style.scss';
import {FormControl, FormGroup, FormLabel, Col} from 'react-bootstrap';
import { Field, ErrorMessage } from 'formik';

const TagInput = ({fieldName, fieldLabel, readOnly, md, handleChange, tags}) => {


    return (
        <FormGroup controlId={fieldName} as={Col}  xs="12" sm="12" md={md ? md : "4"} className="formStyle">
            <FormLabel className="formLabel">{fieldLabel}</FormLabel>
            <ReactTagInput 
                tags={tags} 
                onChange={handleChange}
                readOnly={readOnly}
            />
        </FormGroup>
    )
}

export default TagInput
