import { Form, Formik } from 'formik'
import React, { useState, useEffect } from 'react'
import { Row ,Col} from 'react-bootstrap'
import ImageInput from '../../components/ImageInput'
import InputField from '../../components/InputField'
import * as Yup from 'yup';
import SelectInput from '../../components/SelectInput'
import Buttons from '../../components/Buttons'
import { IoMdAdd } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { getCategoryLists } from '../../Redux/actions/categoryActions'
import { saveStoresType, updateStoresType } from '../../Redux/actions/storeActions'
import { toast } from 'react-toastify'
import { GiBackwardTime } from 'react-icons/gi'

const EditStoreType = ({store}) => {

    const [image, setImage] = useState('')
    const { categoryLists } = useSelector(state => state.category)
    const [initialDatas, setInitialDatas ] = useState(null)
    const [catList, setCatList ] = useState(null)
    const [imageUri, setImageUri] = useState(store.image)
    const [catValue, setCatValue] = useState(null)

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCategoryLists())
    }, [])

    useEffect(() => {
        if(categoryLists){
            let newCat = categoryLists.map(cate => {
                if(store.category.includes(cate._id)){
                    return { ...cate, selected : true }
                }
                else{
                    return { ...cate }
                }
            })
    
            setCatList(newCat)
        }
        

    }, [categoryLists])


    const updateStoreType = async(values) => {
        let catLists = await categoryLists.filter(cat => values.category.includes(cat._id));
        const formData = new FormData();
        formData.append("id", store._id);
        formData.append("name", values.name);
        formData.append("category", JSON.stringify(catLists));
        if(image){
            formData.append("image", image)
        }
        
        dispatch(updateStoresType(formData))
    }

    return (
        <Formik 
            initialValues={{
                name: store.name,
                requested_by:store.requested_by,
                category : store.category
            }}
            validationSchema={
                Yup.object({
                    name: Yup.string().required('Name Required'),
                    category: Yup.array().required('Category Required')
                })
            }
            onSubmit={(values) => {
                updateStoreType(values)
            }}
            render={({handleChange, handleSubmit, handleBlur, values, errors}) => (
                <Form>
                    <Row>
                    <Col md="12" lg="6" xl="6" >
                        <InputField fieldName="name" fieldLabel="Title" md="12" /> 
                        <SelectInput fieldName="category" fieldLabel="Category" md={12} isMultiple={true}>
                            {categoryLists && categoryLists.map((cat, index) => (
                                <option key={index} value={cat._id}>{cat.name}</option>
                            ))}
                        </SelectInput>
                    </Col>
                    <Col md="12" lg="6" xl="6">
                    
                    <ImageInput 
                                fieldLabel="Store Type Image" 
                                imageHelperText={"512 * 512"} 
                                fieldName={"image"} 
                                image={image} 
                                imageUrl={store.image}
                                changeImage={(e) => setImage(e.target.files[0])} 
                                md={6}
                    />
                    
                    </Col>
                    </Row>
                    <Row>
                        <center>
                            <Buttons backColor="#FF9900" buttonText="UPDATE" buttonAction={handleSubmit}>
                                <GiBackwardTime style={{color:'#FFFFFF'}} fontSize="2.3em" />
                            </Buttons>
                        </center>
                    </Row>
                </Form>
            )}
        />
    )
}

export default EditStoreType
