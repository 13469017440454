import React, { Fragment } from 'react'
import {FormControl, FormGroup, FormLabel, Col} from 'react-bootstrap';
import { Field, ErrorMessage } from 'formik';

const InputField = ({fieldName, fieldType, fieldLabel, readOnly, md}) => {
    return (
        <FormGroup controlId={fieldName} as={Col}  xs="12" sm="12" md={md ? md : "4"} className="formStyle">
            <FormLabel className="formLabel">{fieldLabel}</FormLabel>
            <Field 
                name={fieldName}
            >
                {({field, form, meta}) => <FormControl readOnly={readOnly} className="inputStyle" type={ fieldType ? fieldType : 'text'} value={field.value} onChange={field.onChange} />}
            </Field>
            <ErrorMessage name={fieldName}>
                { msg => <div style={{ color: 'red' }}>{msg}</div> }
            </ErrorMessage>
        </FormGroup>
    )
}

export default InputField
