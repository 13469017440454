import React from 'react'
import { Card } from 'react-bootstrap'
import User from '../../assets/Images/user.png'



let Orders = [{
    id: 1,
    date: '15-06-2021',
    cusName: 'xyz',
    orderItems: 'ABC',
    orderValue: 500,
    cusEmail: 'abc@xyz.com',
    cusNumber : '915431618'
},
{
    id: 2,
    date: '15-06-2021',
    cusName: 'xyz',
    orderItems: 'ABC',
    orderValue: 500,
    cusEmail: 'abc@xyz.com',
    cusNumber : '915431618'
},
{
    id: 3,
    date: '15-06-2021',
    cusName: 'xyz',
    orderItems: 'ABC',
    orderValue: 500,
    cusEmail: 'abc@xyz.com',
    cusNumber : '915431618'
},
{
    id: 4,
    date: '15-06-2021',
    cusName: 'xyz',
    orderItems: 'ABC',
    orderValue: 500,
    cusEmail: 'abc@xyz.com',
    cusNumber : '915431618'
}]

const RecentOrders = () => {
    return (
        <div style={{ marginTop: 20 }}>
            <Card style={{ borderRadius:20 }}>
                <Card.Body className="tableStyle">
                    <span className="recentOrder">Customers Recent Orders</span>
                    <img src={User} style={{ width: '3%', float: 'right' }} />
                    <table style={{ marginTop: '20px' }}>
                        <thead>
                            <tr className="tableHeader">
                                <th >Date</th>
                                <th>Customer Name</th>
                                <th>Ordered Items</th>
                                <th>Order Value</th>
                                <th>Customer Email</th>
                                <th>Customer Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Orders.map((order, index) => (
                                <tr key={index} >
                                    <td className="tableContent">{order.date}</td>
                                    <td className="tableContent">{order.cusName}</td>
                                    <td className="tableContent">{order.orderItems}</td>
                                    <td className="tableContent">{order.orderValue}</td>
                                    <td className="tableContent">{order.cusEmail}</td>
                                    <td className="tableContent">{order.cusNumber}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Card.Body>
            </Card>
        </div>
    )
}

export default RecentOrders
