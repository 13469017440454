import moment from 'moment'
import React, { useEffect, useState, Fragment } from 'react'
import { Card, Col } from 'react-bootstrap'
import { AiFillDelete } from 'react-icons/ai'
import { BsCheck2 } from 'react-icons/bs'
import { FaTimes } from 'react-icons/fa'
import { IoMdAddCircle } from 'react-icons/io'
import { MdEdit } from 'react-icons/md'
import { MdCheck, MdClose } from 'react-icons/md'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useRouteMatch } from 'react-router-dom'
import { toast } from 'react-toastify'
import Buttons from '../../components/Buttons'
import Modals from '../../components/Modals'
import Paging from '../../components/Paging'
import { deleteStoreTypeById, getStoreTypes } from '../../Redux/actions/storeActions'
import { RESET_ERROR } from '../../Redux/constants/franchiseeConstants'
import { RESET_STORE } from '../../Redux/constants/storeConstants'
import EditStoreType from './EditStoreType'
import NewStoreType from './NewStoreType'


const StoreTypeList = () => {

    const dispatch = useDispatch()
    const match = useRouteMatch()
    const { storeTypes, error, deleteStoretype, newStoreType, storeTypeUpdate } = useSelector(state => state.store)

    const [currentPage, setCurrentPage] = useState(1)
    const [datas, setDatas] = useState([])
    const [modalShow, setModalShow] = useState(false);
    const [modalShowEdit, setModalShowEdit] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [headerColor, setHeaderColor] = useState('')
    const [headerTitle, setHeaderTitle] = useState('')
    const [selectedStore, setSelectedStore] = useState('')

    useEffect(() => {
        if(error){
            toast.error(error);
            dispatch({
                type: RESET_STORE
            })
        }

        if(deleteStoretype){
            setDeleteModalShow(false)
            toast.success("Store Type Deleted Successfully");
            dispatch({
                type: RESET_STORE
            })
        }

        if(newStoreType){
            setModalShow(false);
            dispatch({
                type: RESET_STORE
            })
            toast.success("Store Type created successfully");
        }

        if(storeTypeUpdate){
            setModalShowEdit(false);
            dispatch({
                type: RESET_STORE
            })
            dispatch(getStoreTypes())
            toast.success("Store Type Updated successfully");
        }

    }, [error, deleteStoretype, newStoreType, storeTypeUpdate])

    useEffect(() => {
       dispatch(getStoreTypes())
    }, [])

    useEffect(() => {
        if(storeTypes && storeTypes.length>0){
            const indexOfLastPost = currentPage * 10;
            const indexOfFirstPost = indexOfLastPost - 10;
            const currentPosts = (storeTypes || []).slice(indexOfFirstPost, indexOfLastPost);
            setDatas(currentPosts)
        }
    }, [storeTypes])

    const pageChanged = (page)=> {
        setCurrentPage(page)
        const indexOfLastPost = page * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const currentPosts = (storeTypes || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }

    const deleteStores = (id) => {
        setSelectedStore(id);
        setHeaderColor('#D42F45');
        setHeaderTitle("DELETE STORE TYPE");
        setDeleteModalShow(true);
    }

    const deleteStoreById = () => {
        dispatch(deleteStoreTypeById(selectedStore))
    }

    const createNewStoreType = () => {
        setHeaderColor('#D42F45');
        setHeaderTitle("ADD STORE TYPE");
        setModalShow(true);
    }

    const editStoreType = (store) => {
        let cat = store.category.map(st => st._id)
        store.category = cat;
        setSelectedStore(store);
        setHeaderColor('#FF9900');
        setHeaderTitle("EDIT STORE TYPE");
        setModalShowEdit(true)
    }

    return (
        <Fragment>
            <Card style={{ borderRadius: 20, margin: 10 }}>
                <IoMdAddCircle className="add" fontSize="3.5em" 
                onClick={createNewStoreType}
                style={{ position: 'absolute', top: -30, right: -20, cursor: 'pointer' }} />
                <Card.Body style={{ overflowX: 'auto' }}>
                    <table>
                        <thead className="tableHeader">
                            <tr>
                                <th>Store Type Title</th>
                                <th>Category</th>
                                <th>Store Type Image</th>
                                <th>Requested By</th>
                                <th>Approval</th>
                                <th>Created Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {datas && datas.map((store, index) => {
                                let cat = store.category?.map(cat => cat.name)
                            return(
                                <tr key={index}>
                                    <td className="tableContent">{store.name}</td>
                                    <td className="tableContent">{cat?.join()}</td>
                                    <td className="tableContent">
                                        <img src={store.image} width="100px" height="100px" />
                                    </td>
                                    <td className="tableContent">{store.requested_by}</td>
                                    <td className="tableContent">
                                        {store.status === 'Approved' ? <span className="approved">APPROVED</span> : store.status === 'Rejected' ? <span className="rejected">REJECTED</span> : (<div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'center' }}>
                                            <MdCheck fontSize="1.5em" style={{ color: '#4AAD47', margin: 5 }} /> 
                                            <MdClose fontSize="1.5em" style={{ color: '#D42F45', margin: 5 }} />
                                        </div>)}
                                    </td>
                                    <td className="tableContent">{moment(store.created_at).format("DD-MM-YYYY hh:mm A")}</td>
                                    <td className="tableContent">
                                        <MdEdit color="orange" onClick={() => editStoreType(store)} />
                                        <AiFillDelete 
                                        className="add" 
                                        onClick={() => deleteStores(store._id)}
                                        />
                                    </td>
                                </tr>
                            )})}
                        </tbody>
                    </table>
                </Card.Body>
                <Col style={{ float: 'right', display:'flex', flexDirection: 'row-reverse', justifyContent: 'flex-start', alignItems: 'center', margin: 30  }}>
                <Card >
                    {storeTypes && storeTypes.length>10 && <Paging datas={storeTypes} currentPage={currentPage} pageChange={pageChanged} />}
                </Card>
                </Col>
            </Card>
            <Modals showModal={modalShow} hideModal={() => setModalShow(false)} modalTitle={headerTitle} headerColor={headerColor}>
                <NewStoreType />
            </Modals>
            <Modals showModal={modalShowEdit} hideModal={() => setModalShowEdit(false)} modalTitle={headerTitle} headerColor={headerColor}>
                <EditStoreType store={selectedStore} />
            </Modals>
            <Modals showModal={deleteModalShow} hideModal={()=> setDeleteModalShow(false)} modalTitle={headerTitle} headerColor={headerColor}>
                <p className="deleteText">Are you sure you want to delete this store?</p>
                <div style={{ display: 'flex', alignItems:'center', justifyContent:'center' }}>
                        <Buttons backColor="#4AAD47" buttonText="YES" buttonAction={deleteStoreById}>
                            <BsCheck2 style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                        <Buttons backColor="#D42F45" buttonText="No" buttonAction={()=> console.log("Hello")}>
                            <FaTimes style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                </div>
            </Modals>
        </Fragment>
    )
}

export default StoreTypeList
