import { Form, Formik } from 'formik'
import React, { useState, useEffect } from 'react'
import { Row } from 'react-bootstrap'
import ImageInput from '../../components/ImageInput'
import InputField from '../../components/InputField'
import * as Yup from 'yup';
import SelectInput from '../../components/SelectInput'
import Buttons from '../../components/Buttons'
import { IoMdAdd } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { getUnits } from '../../Redux/actions/unitActions'
import {  updateUnit } from '../../Redux/actions/unitActions'
import { toast } from 'react-toastify'
import { GiBackwardTime } from 'react-icons/gi'
import { updateDeliveryCharge } from '../../Redux/actions/franchiseeActions'
import { RESET_CHARGE } from '../../Redux/constants/franchiseeConstants'
import Modals from '../../components/Modals'

const EditDeliveryCharges = ({openModal, closeModal, headerColor, headerTitle, deliverychargelist}) => {
    const { updateSuccess,error } = useSelector(state => state.deliverycharge)
    const [image, setImage] = useState('')
    const { categoryLists } = useSelector(state => state.category)
    const [initialDatas, setInitialDatas ] = useState(null)


    const dispatch = useDispatch();

    // useEffect(() => {
    //     dispatch(getCharge())
    // }, [])

    useEffect(() => {
        if(error){
            toast.error(error);
            dispatch({
                type: RESET_CHARGE
            })
        }

        if(updateSuccess){
            closeModal(true)
            toast.success("Delivery Charge updated Successfully");
            dispatch({
                type: RESET_CHARGE

            })
        }
        
    }, [error, updateSuccess])


    const editDeliveryCharge = async(values) => {
        console.log({values})

        const {id, km,free_delivery_threshold,charge, weather_charge,franchisee} = values
        
        dispatch(updateDeliveryCharge(id,km,free_delivery_threshold,charge, weather_charge,franchisee))
    }

    return (
        <Modals showModal={openModal} hideModal={closeModal} headerColor={headerColor} modalTitle={headerTitle} >
           
        <Formik 
            initialValues={{
                id:deliverychargelist._id,
                km: deliverychargelist.km,
                free_delivery_threshold : deliverychargelist.free_delivery_threshold,
                charge:deliverychargelist.charge,
                weather_charge: deliverychargelist.weather_charge,
            }}
            validationSchema={
                Yup.object({
                    km: Yup.string().required('Within Km Required'),
                    free_delivery_threshold: Yup.string().required('Free Delivery Threshold is Required'),
                })
            }
            onSubmit={(values) => {
                editDeliveryCharge(values)
            }}
            render={({handleChange, handleSubmit, handleBlur, values, errors}) => (
            <Form>
            <Row className="mb-3">
            <InputField fieldName="km" fieldLabel="Within KM" md="6" />
            <InputField fieldName="free_delivery_threshold" fieldLabel="Free Delivery Threshold" md="6" />
            <InputField fieldName="charge" fieldLabel="Delivery Charge" md="6" />
            <InputField fieldName="weather_charge" fieldLabel="Weather Charge" md="6" />
                
           
            </Row>
                    <Row>
                        <center>
                            <Buttons backColor="#FF9900" buttonText="UPDATE" buttonAction={handleSubmit}>
                                <GiBackwardTime style={{color:'#FFFFFF'}} fontSize="2.3em" />
                            </Buttons>
                        </center>
                    </Row>
                </Form>
            )}
        />
        </Modals>
    )
}

export default EditDeliveryCharges
