import React, { Fragment, useState } from 'react'
import { Card, Col,Row } from 'react-bootstrap'
import Header from '../../components/Header'
import Layout from '../layout'
import './style.scss'
import ServiceCategory from './ServiceCategory'
import ServiceListing from './ServiceListing'
import ServiceEnquiries from './ServiceEnquiries'
import SubscriptionFee from './SubscriptionFee'
import { Route, Link, useRouteMatch } from 'react-router-dom'



const Service = ({history}) =>  {

    //const history = createBrowserHistory();

    const match = useRouteMatch()

    console.log({history})

    //const path = history.location.pathname;

    return (
        
        <Layout>
        <Header headerText="SERVICE MANAGEMENT" />
        <Row style={{  width: "100%" }}>
            <Col xs="12" sm="12" md="6" lg="4" xl="3" style={{  borderRadius: 20, marginTop: 30, marginBottom: 30 }}>
                <Link to={`${match.path}/category`} style={{ 
                        color: (history.location.pathname === `${match.path}/category` || history.location.pathname === `${match.path}`) ? '#fff' : '#545454', 
                        textDecoration:'none', 
                         
                    }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: (history.location.pathname === `${match.path}/category` || history.location.pathname === `${match.path}`) ? '#4AAD47' : '#fff'  }}>
                        <Card.Body style={{ display: 'flex', alignItems:'center', flexDirection: 'row', justifyContent: 'center' }}>
                            <span className="headerService">SERVICE CATEGORY</span>
                            
                        </Card.Body>
                    
                    </Card>
                </Link>
            </Col>
            <Col xs="12" sm="12" md="6" lg="4" xl="3" style={{  borderRadius: 20, marginTop: 30, marginBottom: 30 }}>
                <Link to={`${match.path}/listing`} style={{ 
                    color: history.location.pathname === `${match.path}/listing` ? '#fff' : '#545454', 
                    textDecoration:'none', 
                    
                 }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: history.location.pathname === `${match.path}/listing` ? '#4AAD47' : '#fff' }}>
                        <Card.Body style={{ display: 'flex', alignItems:'center', flexDirection: 'row', justifyContent: 'center' }}>
                            <span className="headerService">SERVICE LISTING</span>
                            
                        </Card.Body>
                    
                    </Card>
                </Link>
            </Col>
            <Col xs="12" sm="12" md="6" lg="4" xl="3" style={{  borderRadius: 20, marginTop: 30, marginBottom: 30 }}>
                <Link to={`${match.path}/enquires`} style={{ 
                    color: history.location.pathname === `${match.path}/enquires` ? '#fff' : '#545454', 
                    textDecoration:'none', 
                    
                 }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: history.location.pathname === `${match.path}/enquires` ? '#4AAD47' : '#fff' }}>
                        <Card.Body style={{ display: 'flex', alignItems:'center', flexDirection: 'row', justifyContent: 'center' }}>
                            <span className="headerService">SERVICE ENQUIRIES</span>
                            
                        </Card.Body>
                    
                    </Card>
                </Link>
            </Col>
            <Col xs="12" sm="12" md="6" lg="4" xl="3" style={{  borderRadius: 20, marginTop: 30, marginBottom: 30 }}>
                <Link to={`${match.path}/fee`} style={{ 
                    color: history.location.pathname === `${match.path}/fee` ? '#fff' : '#545454', 
                    textDecoration:'none', 
                    
                }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: history.location.pathname === `${match.path}/fee` ? '#4AAD47' : '#fff'  }}>
                        <Card.Body style={{ display: 'flex', alignItems:'center', flexDirection: 'row', justifyContent: 'center' }}>
                            <span className="headerService">SUBSCRIPTION FEE</span>
                            
                        </Card.Body>
                    
                    </Card>
                </Link>
            </Col>
        </Row> 
            <Route path={`${match.path}`} exact>
                <ServiceCategory/>   
            </Route>
            <Route path={`${match.path}/category`} exact>
                <ServiceCategory/>   
            </Route>
            <Route path={`${match.path}/listing`} exact>
                <ServiceListing/>  
            </Route>
            <Route path={`${match.path}/enquires`} exact>
                <ServiceEnquiries/>
            </Route>
            <Route path={`${match.path}/fee`} exact>
                <SubscriptionFee/>
            </Route>
        
        
        
         
        </Layout>
       
    )
}

export default Service
