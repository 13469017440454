import React from "react";
import { Col, FormGroup, FormLabel } from "react-bootstrap";
import Select from "react-select";
import reactotron from "../ReactotronConfig";

class SelectNew extends React.Component {
    
    handleChange = value => {
      reactotron.log({value})
      // this is going to call setFieldValue and manually update values.topcis
      this.props.onChange(this.props.fieldName, value);
    };
  
    handleBlur = () => {
      // this is going to call setFieldTouched and manually update touched.topcis
      this.props.onBlur(this.props.fieldName, true);
    };
  
    render() {

        const customStyles = {
            menu: (provided, state) => ({
              ...provided,
              width: 'state.selectProps.width',
              borderBottom: '1px dotted pink',
              color: state.selectProps.menuColor,
              padding: 20,
            }),
            control: (base, state) => ({
                ...base,
                borderRadius: 10,
                backgroundColor:'#417AE80A',
                color:'#1B2F5B',
                border: "1px solid #417AE85E"
              }),
          
            singleValue: (provided, state) => {
              const opacity = state.isDisabled ? 0.5 : 1;
              const transition = 'opacity 300ms';
          
              return { ...provided, opacity, transition };
            }
          }

          
      return (
        <FormGroup controlId={this.props.fieldName} as={Col}  xs="12" sm="12" md={this.props.md ? this.props.md : "4"} className="formStyle">
          <FormLabel className="formLabel">{this.props.fieldLabel}</FormLabel>
          <Select
            styles={customStyles}
            options={this.props.options}
            isMulti={this.props.isMultiple}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            value={this.props.value}
            isDisabled={this.props.readOnly}
          />
          {!!this.props.error && this.props.touched && (
            <div style={{ color: "red", marginTop: ".5rem" }}>
              {this.props.error}
            </div>
          )}
        </FormGroup>
      );
    }
  }

  export default SelectNew