import React from 'react'
import { Card, Col } from 'react-bootstrap'
import './style.scss'
import {AiOutlineSearch} from 'react-icons/ai'

const Search = ({ placeHolder, searchValue, searchChanged }) => {
    return(
        <Col xs="12" sm="12" md="6" lg="4" xl="3" style={{  borderRadius: 20, marginTop: 30, marginBottom: 30 }}>
        <Card style={{  borderRadius: 20 }}>
            <input type="text" 
                placeholder={placeHolder} 
                value={searchValue} 
                style={{ 
                    border: 'none', 
                    height: 50, 
                    borderRadius: 20, 
                    marginLeft: 15, 
                    marginRight: 30
                }}
                onChange={searchChanged}
            >
            </input>
            <span className="searchText">
                <AiOutlineSearch   color="white" style={{ fontSize: 30 }} />
            </span>
        </Card>
        </Col>
    )
}

export default Search
