import { Formik, Form } from 'formik'
import React, { useEffect } from 'react'
import { Card, Row } from 'react-bootstrap'
import { GiBackwardTime } from 'react-icons/gi'
import Buttons from '../../../components/Buttons'
import InputField from '../../../components/InputField'
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux'
import { updateStore } from '../../../Redux/actions/storeActions'
import { toast } from 'react-toastify'
import { RESET_STORE } from '../../../Redux/constants/storeConstants'


const BankAccount = ({storeId}) => {

    const dispatch = useDispatch();
    const { storeDetails, error, updateSuccess } = useSelector(state => state.store)

    console.log({storeDetails})

    const updateBankDetails = (values) => {
        let data = {
            id: storeId,
            bank_details: [values]
        }
        dispatch(updateStore(data))
    }

    useEffect(() => {
        if(error){
            toast.error(error);
            dispatch({
                type: RESET_STORE
            })
        }

        if(updateSuccess){
            toast.success("Account Details Updated Successfully");
            dispatch({
                type: RESET_STORE
            })
        }
    }, [error, updateSuccess])

    return (
        <Card style={{ borderRadius: 20, margin: 10 }}>
            <Card.Body style={{ overflowX: 'auto' }}>
                <Formik 
                    initialValues={storeDetails.bank_details ? storeDetails.bank_details[0] : {
                        bank_name: '',
                        branch: '',
                        ifsc_code: '',
                        acnt_number: '',
                        upi : '',
                        reference_note: ''
                    }}
                    validationSchema={
                        Yup.object({
                            bank_name:  Yup.string().required("Bank Name is required"),
                            branch : Yup.string().required("Branch Name is required"),
                            ifsc_code: Yup.string().required("IFSC Code is required"),
                            acnt_number: Yup.string().required("Account Number is required")
                        })
                    }
                    onSubmit={(values) => {
                        console.log(values)
                        updateBankDetails(values)
                    }}
                    render={({handleChange, handleSubmit, handleBlur, values, errors, setFieldValue}) => (
                        <Form>
                            <Row>
                                <InputField fieldName="bank_name" fieldLabel="Bank Name" md="4" />
                                <InputField fieldName="branch" fieldLabel="Branch" md="4" />
                                <InputField fieldName="ifsc_code" fieldLabel="IFSC Code" md="4" />
                                <InputField fieldName="acnt_number" fieldLabel="Account Number" md="4" />
                                <InputField fieldName="upi" fieldLabel="UPI" md="4" />
                                <InputField fieldName="reference_note" fieldLabel="Reference Code" md="4" />
                            </Row>
                            <Row>
                                <center>
                                    <Buttons backColor="#417AE8" buttonText={"UPDATE"} buttonAction={handleSubmit}>
                                        <GiBackwardTime style={{color:'#FFFFFF'}} fontSize="2.3em" />
                                    </Buttons>
                                </center>
                            </Row>
                        </Form>
                    )}
                />
            </Card.Body>
        </Card>
    )
}

export default BankAccount
