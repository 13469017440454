import React, { Fragment, useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import {MdEdit} from 'react-icons/md'
import {AiFillDelete,AiFillEye} from 'react-icons/ai'
import {BsToggle2On,BsToggle2Off, BsCheck2} from 'react-icons/bs'
import {IoMdAddCircle,IoMdAdd}from 'react-icons/io'
import { FaTimes } from 'react-icons/fa'
import Pagination from 'react-paginating';
import Search from '../../components/Search'
import Paging from '../../components/Paging'
import Modals from '../../components/Modals'
import Buttons from '../../components/Buttons'
import CustomersForm from './CustomersForm'
import { useSelector, useDispatch } from 'react-redux'
import { getCustomerLists ,deleteCustomerListById,getCustomerDetails } from '../../Redux/actions/customerActions'
import { toast } from 'react-toastify'
import { RESET_CUSTOMER } from '../../Redux/constants/customerConstants'
import moment from 'moment'
import reactotron from '../../ReactotronConfig'


const CustomersList = () => {
    const { customerList, error, customerSuccess,deleteSuccess } = useSelector(state => state.customer)

    const dispatch = useDispatch();
    const [modalShow, setModalShow] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [datas, setDatas] = useState([])
    const [headerColor, setHeaderColor] = useState('')
    const [headerTitle, setHeaderTitle] = useState('')
    const [search, setSearch] = useState('')
    const [selectedCustomer, setselectedCustomer] = useState('')


    useEffect(() => {
        if(customerList && customerList.length>0){
            reactotron.log({customerList})
            const indexOfLastPost = currentPage * 10;
            const indexOfFirstPost = indexOfLastPost - 10;
            const searchResult = customerList.filter((customers) => customers.name.toLowerCase().includes(search.toLowerCase()) || customers.email.toLowerCase().includes(search.toLowerCase())
            )
            const currentPosts = (searchResult || []).slice(indexOfFirstPost, indexOfLastPost);
            console.log(currentPosts)
            setDatas(currentPosts)
        }
    }, [customerList, search])

    const pageChanged = (page)=> {
        setCurrentPage(page)
        const indexOfLastPost = page * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const searchResult = customerList.filter(customers => customers.name.toLowerCase().contains(search.toLowerCase()) || customers.email.toLowerCase().contains(search.toLowerCase()))
        const currentPosts = (searchResult || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }
  

    useEffect(() => {
        if(deleteSuccess){
            dispatch(getCustomerLists())
            setDeleteModalShow(false);
            dispatch({
                type: RESET_CUSTOMER
            })
            toast.success("Customer Deleted Successfully");
        }
    }, [deleteSuccess])

    useEffect(() => {
        dispatch(getCustomerLists())
    }, [])
    const deleteCustomerById = () => {
        dispatch(deleteCustomerListById(selectedCustomer))
    }
    // const getCustomerById = () => {
    //     dispatch(getCustomerDetails(selectedCustomer))
    // }

    const viewCustomerDetails = (customer) => {
        setselectedCustomer(customer);
        setHeaderColor('#417AE8');
        setHeaderTitle("VIEW CUSTOMER");
        setModalShow(true);
    }

    const deleteCustomer = (id) => {
        setselectedCustomer(id);
        setHeaderColor('#D42F45');
        setHeaderTitle("DELETE CUSTOMER");
        setDeleteModalShow(true);
    }

    return (
        <Fragment>
             <Search placeHolder="SEARCH CUSTOMER" searchValue={search} searchChanged={e => setSearch(e.target.value)} />
            <Card style={{ borderRadius: 20, margin: 10, minHeight: '70vh' }}>
                
                <Card.Body style={{ overflowX: 'auto' }}>
                    <table>
                        <thead class="tableHeader">
                            
                            <th>Name</th>
                            <th>Address</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>Last Login</th>
                            <th>Actions</th>
                        </thead>
                        <tbody>
                            {datas && datas.map((customer, index) => (
                                <tr key={index}>
                                    
                                    <td class="tableContent">{customer.name}</td>
                                    <td class="tableContent">{customer.address}</td>
                                    <td class="tableContent">{customer.email}</td>
                                    <td class="tableContent">{customer.mobile}</td>
                                    <td class="tableContent">{moment(customer.updated_at).format("DD-MM-YYYY hh:mm A")}</td>
                                    <td class="tableContent">
                                        <AiFillEye color="blue" onClick={() => viewCustomerDetails(customer)}/>
                                        <AiFillDelete class="add" onClick={() => deleteCustomer(customer._id)}/>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Card.Body>
                <Col style={{ float: 'right', display:'flex', flexDirection: 'row-reverse', justifyContent: 'flex-start', alignItems: 'center', margin: 30  }}>
                <Card >
                    {customerList && customerList.length>10 && <Paging datas={customerList} currentPage={currentPage} pageChange={pageChanged} />}
                </Card>
                </Col>
            </Card>
            <CustomersForm openModal={modalShow} closeModal={()=> setModalShow(false)} headerColor={headerColor} headerTitle={headerTitle} customerlist={selectedCustomer} />
            
            <Modals showModal={deleteModalShow} hideModal={()=> setDeleteModalShow(false)} modalTitle={headerTitle} headerColor={headerColor}>
                <p className="deleteText">Are you sure you want to delete this customer?</p>
                <div style={{ display: 'flex', alignItems:'center', justifyContent:'center' }}>
                        <Buttons backColor="#4AAD47" buttonText="YES" buttonAction={deleteCustomerById}>
                            <BsCheck2 style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                        <Buttons backColor="#D42F45" buttonText="No" buttonAction={()=> console.log("Hello")}>
                            <FaTimes style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                </div>
            </Modals>
        </Fragment>
    )
}

export default CustomersList
