
import React, { Fragment, useEffect, useState } from 'react'
import { Card,  Col} from 'react-bootstrap'
import { MdCheck,MdPayments,MdOutlinePending } from 'react-icons/md'
import Paging from '../../../components/Paging'
import Buttons from '../../../components/Buttons'
import { useRouteMatch, Link ,Redirect} from 'react-router-dom'


let deliveryboyPayout = [{
    date: '19/12/21',
    name:'Samsung',
    id:'#35',
    total: '2000',
    tips:'5',
    cash:'50',
    delivery:'100',
    upi:'10',
    amount : '50',
},
{
    date: '19/12/21',
    name:'Samsung',
    id:'#35',
    total: '2000',
    tips:'5',
    cash:'50',
    delivery:'100',
    upi:'10',
    amount : '50',
},{
    date: '19/12/21',
    name:'Samsung',
    id:'#35',
    total: '2000',
    tips:'5',
    cash:'50',
    delivery:'100',
    upi:'10',
    amount : '50',
},{
    date: '19/12/21',
    name:'Samsung',
    id:'#35',
    total: '2000',
    tips:'5',
    cash:'50',
    delivery:'100',
    upi:'10',
    amount : '50',
},{
    date: '19/12/21',
    name:'Samsung',
    id:'#35',
    total: '2000',
    tips:'5',
    cash:'50',
    delivery:'100',
    upi:'10',
    amount : '50',
},{
    date: '19/12/21',
    name:'Samsung',
    id:'#35',
    total: '2000',
    tips:'5',
    cash:'50',
    delivery:'100',
    upi:'10',
    amount : '50',
},{
    date: '19/12/21',
    name:'Samsung',
    id:'#35',
    total: '2000',
    tips:'5',
    cash:'50',
    delivery:'100',
    upi:'10',
    amount : '50',
},{
    date: '19/12/21',
    name:'Samsung',
    id:'#35',
    total: '2000',
    tips:'5',
    cash:'50',
    delivery:'100',
    upi:'10',
    amount : '50',
},{
    date: '19/12/21',
    name:'Samsung',
    id:'#35',
    total: '2000',
    tips:'5',
    cash:'50',
    delivery:'100',
    upi:'10',
    amount : '50',
},
]

const DeliveryBoyDetails = () => {

    const [currentPage, setCurrentPage] = useState(1)
    const [datas, setDatas] = useState([])
    const match = useRouteMatch();

    console.log("match", match)

    useEffect(() => {
        const indexOfLastPost = currentPage * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const currentPosts = (deliveryboyPayout || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }, [])

    const pageChanged = (page)=> {
        setCurrentPage(page)
        const indexOfLastPost = page * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const currentPosts = (deliveryboyPayout || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }

    return (
        <Fragment>
            
            <Card style={{ borderRadius: 20, margin: 10, minHeight: '70vh' }}>
                  <Card.Body style={{ overflowX: 'auto' }}>
                    <table>
                        <thead class="tableHeader">
                            <th>Order Date</th>
                            <th>Store Name</th>
                            <th>Order ID</th>
                            <th>Order Total</th>
                            <th>Tips</th>
                            <th>Cash In Hand</th>
                            <th>Delivery Charge</th>
                            <th>UPI Payment</th>
                            <th>Amount Payable</th>
                            
                        </thead>
                        <tbody>
                            {datas && datas.map((payout, index) => (
                                <tr key={index}>
                                    <td class="tableContent">{payout.date}</td>
                                    <td class="tableContent">{payout.name}</td>
                                    <td class="tableContent">{payout.id}</td>
                                    <td class="tableContent">{payout.total}</td>
                                    <td class="tableContent">{payout.tips}</td>
                                    <td class="tableContent">{payout.cash}</td>
                                    <td class="tableContent">{payout.delivery}</td>
                                    <td class="tableContent">{payout.upi}</td>
                                    <td class="tableContent">{payout.amount}</td>
                                    
                                    
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Card.Body>
                <Col style={{ float: 'right', display:'flex', flexDirection: 'row-reverse', justifyContent: 'flex-start', alignItems: 'center', margin: 30  }}>
                <Card >
                    {deliveryboyPayout && deliveryboyPayout.length>10 && <Paging datas={deliveryboyPayout} currentPage={currentPage} pageChange={pageChanged} />}
                </Card>
                </Col>
            </Card>
 
        </Fragment>
        
    )
}

export default DeliveryBoyDetails
