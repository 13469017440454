import React, { Fragment, useEffect, useState } from 'react'
import { Card, Row, Form, Col, InputGroup } from 'react-bootstrap'
import {MdEdit} from 'react-icons/md'
import {AiFillDelete,AiFillEye} from 'react-icons/ai'
import {BsToggle2On,BsToggle2Off, BsCheck2} from 'react-icons/bs'
import { MdCheck, MdClose } from 'react-icons/md'
import {IoMdAddCircle,IoMdAdd}from 'react-icons/io'
import { FaTimes } from 'react-icons/fa'
import Paging from '../../components/Paging'
import Modals from '../../components/Modals'
import Buttons from '../../components/Buttons'


let serviceList = [{
    name: 'Liya',
    providername: 'Hospitals',
    catname: 'Hospitals',
    city: 'Varkala',
    phone: '9090909090',
    schedule: '9 : 00 AM - 8 : 00 PM',
    approvalStatus : 'rejected'
},
{
    name: 'Liya',
    providername: 'Hospitals',
    catname: 'Hospitals',
    city: 'Varkala',
    phone: '9090909090',
    schedule: '9 : 00 AM - 8 : 00 PM',
    approvalStatus : 'approved'
},
{
    name: 'Liya',
    providername: 'Hospitals',
    catname: 'Hospitals',
    city: 'Varkala',
    phone: '9090909090',
    schedule: '9 : 00 AM - 8 : 00 PM',
    approvalStatus : 'approved'
},
{
    name: 'Liya',
    providername: 'Hospitals',
    catname: 'Hospitals',
    city: 'Varkala',
    phone: '9090909090',
    schedule: '9 : 00 AM - 8 : 00 PM',
    approvalStatus : 'approved'
},
{
    name: 'Liya',
    providername: 'Hospitals',
    catname: 'Hospitals',
    city: 'Varkala',
    phone: '9090909090',
    schedule: '9 : 00 AM - 8 : 00 PM',
    approvalStatus : 'rejected'
},
{
    name: 'Liya',
    providername: 'Hospitals',
    catname: 'Hospitals',
    city: 'Varkala',
    phone: '9090909090',
    schedule: '9 : 00 AM - 8 : 00 PM',
    approvalStatus : 'approved'
},
{
    name: 'Liya',
    providername: 'Hospitals',
    catname: 'Hospitals',
    city: 'Varkala',
    phone: '9090909090',
    schedule: '9 : 00 AM - 8 : 00 PM',
    approvalStatus : 'approved'
},
{
    name: 'Liya',
    providername: 'Hospitals',
    catname: 'Hospitals',
    city: 'Varkala',
    phone: '9090909090',
    schedule: '9 : 00 AM - 8 : 00 PM',
    approvalStatus : 'approved'
},
{
    name: 'Liya',
    providername: 'Hospitals',
    catname: 'Hospitals',
    city: 'Varkala',
    phone: '9090909090',
    schedule: '9 : 00 AM - 8 : 00 PM',
    approvalStatus : 'approved'
},
{
    name: 'Liya',
    providername: 'Hospitals',
    catname: 'Hospitals',
    city: 'Varkala',
    phone: '9090909090',
    schedule: '9 : 00 AM - 8 : 00 PM',
    approvalStatus : 'approved'
},
{
  
    name: 'Liya',
    providername: 'Hospitals',
    catname: 'Hospitals',
    city: 'Varkala',
    phone: '9090909090',
    schedule: '9 : 00 AM - 8 : 00 PM',
    approvalStatus : 'approved'

}]

const ServiceEnquiries = () => {

    const [modalShow, setModalShow] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [datas, setDatas] = useState([])
    const [headerColor, setHeaderColor] = useState('')
    const [headerTitle, setHeaderTitle] = useState('')

    useEffect(() => {
        const indexOfLastPost = currentPage * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const currentPosts = (serviceList || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }, [])

    const pageChanged = (page)=> {
        setCurrentPage(page)
        const indexOfLastPost = page * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const currentPosts = (serviceList || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }

    const viewService = () => {
        setHeaderColor('#417AE8');
        setHeaderTitle("VIEW ENQUIRY");
        setModalShow(true);
    }

    const deleteService = (id) => {
        setHeaderColor('#D42F45');
        setHeaderTitle("DELETE SERVICE");
        setDeleteModalShow(true);
    }

    return (
        <Fragment>
            
            <Card style={{ borderRadius: 20, margin: 10, minHeight: '70vh' }}>
                
                <Card.Body style={{ overflowX: 'auto' }}>
                    <center><h4>Coming Soon</h4></center>
                    {/*<table>
                        <thead class="tableHeader">
                            <th>Customer Name</th>
                            <th>Service Provider Name</th>
                            <th>Category Name</th>
                            <th>City</th>
                            <th>Phone Number</th>
                            <th>Scheduled Date & Time</th>
                            <th>Approval</th>
                            <th>Actions</th>
                        </thead>
                        <tbody>
                            {datas && datas.map((service, index) => (
                                <tr key={index}>
                                    <td class="tableContent">{service.name}</td>
                                    <td class="tableContent">{service.providername}</td>
                                    <td class="tableContent">{service.catname}</td>
                                    <td class="tableContent">{service.city}</td>
                                    <td class="tableContent">{service.phone}</td> 
                                    <td class="tableContent">{service.schedule}</td>
                                    <td class="tableContent">
                                        {service.approvalStatus === 'approved' ? <span className="approved">APPROVED</span> : service.approvalStatus === 'rejected' ? <span className="rejected">REJECTED</span> : (<div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'center' }}>
                                            <MdCheck fontSize="1.5em" style={{ color: '#4AAD47', margin: 5 }} /> 
                                            <MdClose fontSize="1.5em" style={{ color: '#D42F45', margin: 5 }} />
                                        </div>)}
                                    </td>
                                    <td class="tableContent">
                                        <AiFillEye color="blue" onClick={() => viewService(service.id)}/>  
                                        <AiFillDelete class="add" onClick={() => deleteService(service.id)}/>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                            </table>*/}
                </Card.Body>
                {/*<Col style={{ float: 'right', display:'flex', flexDirection: 'row-reverse', justifyContent: 'flex-start', alignItems: 'center', margin: 30  }}>
                <Card >
                    {serviceList && serviceList.length>10 && <Paging datas={serviceList} currentPage={currentPage} pageChange={pageChanged} />}
                </Card>
                        </Col>*/}
            </Card>
            {/* <FranchiseeForm openModal={modalShow} closeModal={()=> setModalShow(false)} headerColor={headerColor} headerTitle={headerTitle} />
             */}
            <Modals showModal={deleteModalShow} hideModal={()=> setDeleteModalShow(false)} modalTitle={headerTitle} headerColor={headerColor}>
                <p className="deleteText" >Are you sure you want to delete this Service Enquiry?</p>
                <div style={{ display: 'flex', alignItems:'center', justifyContent:'center' }}>
                        <Buttons backColor="#4AAD47" buttonText="YES" buttonAction={()=> console.log("Hello")}>
                            <BsCheck2 style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                        <Buttons backColor="#D42F45" buttonText="No" buttonAction={()=> console.log("Hello")}>
                            <FaTimes style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                </div>
            </Modals>
            <Modals  showModal={modalShow} hideModal={()=> setModalShow(false)} headerColor={headerColor} modalTitle={headerTitle} >
            <Row>
                <Form.Group as={Col} xs="12" sm="12" md="6" lg="4" xl="4" controlId="formGridFranchiseeName" className="formStyle">
                    <Form.Label className="formLabel">Customer Name</Form.Label>
                    <Form.Control className="inputStyle" type="text"  />
                </Form.Group>
                <Form.Group controlId="formGridName" as={Col}  xs="12" sm="12" md="6" lg="4" xl="4" className="formStyle">
                    <Form.Label className="formLabel">Service Provider Name</Form.Label>
                    <Form.Control className="inputStyle" type="text"  />
                </Form.Group>
                <Form.Group controlId="formGridName" as={Col}  xs="12" sm="12" md="6" lg="4" xl="4" className="formStyle">
                    <Form.Label className="formLabel">Category</Form.Label>
                    <Form.Control className="inputStyle" type="text"  />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group controlId="formGridName" as={Col} xs="12" sm="12" md="6" lg="4" xl="4" className="formStyle">
                    <Form.Label className="formLabel">Phone Number</Form.Label>
                    <Form.Control className="inputStyle" type="text"  />
                </Form.Group>
                <Form.Group controlId="formGridName" as={Col}  xs="12" sm="12" md="6" lg="4" xl="4" className="formStyle">
                    <Form.Label className="formLabel">Scheduled Date & Time</Form.Label>
                    <Form.Control className="inputStyle" type="text"  />
                </Form.Group>
                <Form.Group controlId="formGridName" as={Col} xs="12" sm="12" md="6" lg="4" xl="4" className="formStyle">
                    <Form.Label className="formLabel">Approved By (ID)</Form.Label>
                    <Form.Control className="inputStyle" type="text"  />
                </Form.Group>
            </Row>    
            
           
        </Modals>
 
        </Fragment>
    )
}

export default ServiceEnquiries
