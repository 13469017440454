import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import Modals from '../../components/Modals'
import {IoMdAdd}from 'react-icons/io'
import Buttons from '../../components/Buttons'
import { useSelector, useDispatch } from 'react-redux'
import {Formik, Field} from 'formik';
import { toast } from 'react-toastify'
import InputField from '../../components/InputField'
import FormSelectField from '../../components/form-select-field'
import * as Yup from 'yup';
import "yup-phone";
import { addNewCategory } from '../../Redux/actions/categoryActions'
import { getStoreTypes } from '../../Redux/actions/storeActions'
import { RESET_STORE } from '../../Redux/constants/storeConstants'
import ImageInput from '../../components/ImageInput'
import { getUnits } from '../../Redux/actions/unitActions'
import { RESET_UNIT } from '../../Redux/constants/unitConstants'
import SelectNew from '../../components/SelectNew'

const NewCategory = ({openModal, closeModal, headerColor, modalTitle}) => {
    const { categorySuccess } = useSelector(state => state.category)
    const { unitLists, error: unitError } = useSelector(state => state.unit)
    const [image, setImage] = useState(null)
    const dispatch = useDispatch();

    useEffect(() => {

        if(categorySuccess){
            closeModal(true)
            toast.success("Category Added Successfully");
            dispatch({
                type: RESET_STORE
            })
        }

        if(unitError){
            toast.error(unitError);
            dispatch({
                type: RESET_UNIT
            })
        }
        
    }, [categorySuccess, unitError])


    useEffect(() => {
        dispatch(getUnits())
    }, [])



    const saveCategory = async (values) =>  {
        //const unit = values.units.split(',');


        let formData = new FormData();
        formData.append("name", values.name);
        formData.append("units", JSON.stringify(values.units));
        formData.append("status", values.status === true ? 'Active' : 'InActive');
        formData.append("image", image);

        dispatch(addNewCategory(formData))
    }


    return (
        <Modals showModal={openModal} hideModal={closeModal} headerColor={headerColor} modalTitle={modalTitle} modalSize="lg">
            <Formik 
                initialValues={{
                    name: '',
                    units: '',
                    status: '',
                }}
                validationSchema={
                    
                    Yup.object({
                        name: Yup.string().required('Name Required'),
                        units: Yup.array().required('Units Required'),
                    })
                }
                onSubmit={(values) => {
                    console.log(values)
                    saveCategory(values)
                }}
                render={({handleChange, handleSubmit, handleBlur, setFieldValue, setFieldTouched, values, errors, touched}) => (
                    <Form>
                        <Row>
                            <InputField fieldName="name" fieldLabel="Category Name" md="6" />
                            <SelectNew 
                                md="4"
                                fieldName="units"
                                fieldLabel="Units"
                                options={unitLists}
                                isMultiple={true}
                                value={values.units}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                error={errors.units}
                                touched={touched.units}
                            />
                            <Form.Group as={Col} xs="12" sm="12" md="6" controlId="status" className="formStyle">
                                <Form.Label className="formLabel">Active Status</Form.Label>
                                <div style={{ display: 'flex', flexDirection: 'row', height: 40, borderRadius: 10, border: "1px solid #417AE85E", backgroundColor: '#417AE80A', alignItems:'center', justifyContent:'center' }}>
                                    <span style={{ marginRight: 10, fontFamily: "Avenir-Black", color: '#1B2F5B' }}>Deactive</span>
                                    <Field 
                                        name='Status'
                                    >
                                        {({field, form, meta}) => <Form.Check 
                                                type="switch"
                                                id="status"
                                                label="Active"
                                                value={field.value} 
                                                onChange={field.onChange}
                                                style={{ fontFamily: "Avenir-Black", color: '#1B2F5B' }}
                                            />}
                                    </Field>
                                </div>
                            </Form.Group>
                            <ImageInput 
                                fieldLabel="Thumbnail Image" 
                                imageHelperText={"512 * 512"} 
                                fieldName={"image"} 
                                image={image} 
                                changeImage={(e) => setImage(e.target.files[0])} 
                                md={6}
                            />
                        </Row>
                        <Row>
                            <center>
                                <Buttons backColor="#D42F45" buttonText="ADD" buttonAction={handleSubmit}>
                                    <IoMdAdd style={{color:'#FFFFFF'}} fontSize="2.3em" />
                                </Buttons>
                            </center>
                        </Row>
                    </Form>
                )}
            />
        </Modals>
    )
}

export default NewCategory
