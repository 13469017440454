import React, { Fragment, useEffect, useState } from 'react'
import { Card, Row, Form, Col, InputGroup } from 'react-bootstrap'
import {MdEdit,MdUpload} from 'react-icons/md'
import {AiFillDelete,AiFillEye} from 'react-icons/ai'
import {BsToggle2On,BsToggle2Off, BsCheck2} from 'react-icons/bs'
import { MdCheck, MdClose } from 'react-icons/md'
import {IoMdAddCircle,IoMdAdd}from 'react-icons/io'
import { FaTimes } from 'react-icons/fa'
import Paging from '../../components/Paging'
import Modals from '../../components/Modals'
import ModalMedium from '../../components/ModalMedium'
import Buttons from '../../components/Buttons'

import reactotron from '../../ReactotronConfig'
import { useSelector, useDispatch } from 'react-redux'
import { addNewCost,deleteCostById } from '../../Redux/actions/sliderActions'
import { getCostLists } from '../../Redux/actions/sliderActions'
import { getFranchisee } from '../../Redux/actions/franchiseeActions'
import { toast } from 'react-toastify'
import { RESET_SLIDER } from '../../Redux/constants/sliderConstants'
import Select from 'react-select';
import {Formik, Field} from 'formik';
import InputField from '../../components/InputField'
import IconInput from '../../components/IconInput'
import FormSelectField from '../../components/form-select-field'
import * as Yup from 'yup';
import EditCost from './EditCost'
import SelectNew from '../../components/SelectNew'


const SliderSettings = () => {
    const { costLists, error, costSuccess,updateSuccess,deleteSuccess } = useSelector(state => state.cost)
    const { error : franchiseeError, newFranchisee, franchiseeList } = useSelector(state => state.franchisee)
    const dispatch = useDispatch();
    const { loading } = useSelector(state => state.auth)
    const [search, setSearch] = useState('')
    const [selectedCost, setselectedCost] = useState('')
    const [modalShow, setModalShow] = useState(false);
    const [modalShowEdit, setModalShowEdit] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [datas, setDatas] = useState([])
    const [headerColor, setHeaderColor] = useState('')
    const [headerTitle, setHeaderTitle] = useState('')

    useEffect(() => {
        if(costLists && costLists.length>0){
            reactotron.log({costLists})
            const indexOfLastPost = currentPage * 10;
            const indexOfFirstPost = indexOfLastPost - 10;
            const searchResult = costLists.filter((costs) => costs.title.toLowerCase().includes(search.toLowerCase()) || costs.sliderNo.toLowerCase().includes(search.toLowerCase())
            )
            const currentPosts = (searchResult || []).slice(indexOfFirstPost, indexOfLastPost);
            console.log(currentPosts)
            setDatas(currentPosts)
        }
    }, [costLists, search])

    const pageChanged = (page)=> {
        setCurrentPage(page)
        const indexOfLastPost = page * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const searchResult = costLists.filter(costs => costs.name.toLowerCase().contains(search.toLowerCase()) || costs.sliderNo.toLowerCase().contains(search.toLowerCase()))
        const currentPosts = (searchResult || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }
    useEffect(() => {
        dispatch(getFranchisee())
    }, [])

    
    useEffect(() => {
        if(error){
            toast.error(error);
            dispatch({
                type: RESET_SLIDER
            })
        }
        if(costSuccess){
            setModalShow(false);
            dispatch({
                type: RESET_SLIDER
            })
            dispatch(getCostLists())
            toast.success("New Cost Added Successfully");
            
            
        }
        if(updateSuccess){
            setModalShowEdit(false);
                dispatch({
                    type: RESET_SLIDER
                })
                dispatch(getCostLists())
                toast.success("Cost updated Successfully");
        }
        if(deleteSuccess){
            
            setDeleteModalShow(false);
            dispatch({
                type: RESET_SLIDER
            })
            dispatch(getCostLists())
            toast.success("Cost Deleted Successfully");
            
        }
    }, [error,costSuccess,updateSuccess,deleteSuccess])

    useEffect(() => {
        dispatch(getCostLists())
    }, [])
    
    const createsliderSettings = () => {
        setHeaderColor('#D42F45');
        setHeaderTitle("ADD COST");
        setModalShow(true);
    }
    const editCost = (cost) => {
        setselectedCost(cost);
        setHeaderColor('#FF9900');
        setHeaderTitle("EDIT COST");
        setModalShowEdit(true);
    }
    const deletetheCost = (id) => {
        setselectedCost(id);
        setHeaderColor('#D42F45');
        setHeaderTitle("DELETE COST");
        setDeleteModalShow(true);
    }
    const deleteCostListById = () => {
        dispatch(deleteCostById(selectedCost))
    }

    const saveCost =  (values) =>  {

        let fran = {
            name: values.requested_by.name,
            id: values.requested_by._id
        }
        console.log({values})

        var form_data = new FormData();
        form_data.append("sliderNo", values.sliderNo);
        form_data.append("cost", values.cost);
        form_data.append("requested_by", JSON.stringify(fran));
        dispatch(addNewCost(form_data))
    }
    return (
        <Fragment>
            
            <Card style={{ borderRadius: 20, margin: 10, minHeight: '70vh' }}>
                <IoMdAddCircle class="add" fontSize="3.5em" 
                onClick={createsliderSettings}
                style={{ position: 'absolute', top: -30, right: -20, cursor: 'pointer' }} />
                <Card.Body style={{ overflowX: 'auto' }}>
                    <table>
                        <thead class="tableHeader">
                            <th>Slider Number</th>
                            <th>Cost(Per Day)</th>
                            <th>Requested By</th>
                            <th>Approval</th>
                            <th>Actions</th>
                        </thead>
                        <tbody>
                            {datas && datas.map((cost, index) => (
                                <tr key={index}>
                                    <td class="tableContent">{cost.sliderNo}</td>
                                    <td class="tableContent">{cost.cost}</td>
                                    <td class="tableContent">{cost.requested_by}</td>
                                    <td class="tableContent">
                                        {cost.status === 'approved' ? <span className="approved">APPROVED</span> : cost.status === 'rejected' ? <span className="rejected">REJECTED</span> : (<div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'center' }}>
                                            <MdCheck fontSize="1.5em" style={{ color: '#4AAD47', margin: 5 }} /> 
                                            <MdClose fontSize="1.5em" style={{ color: '#D42F45', margin: 5 }} />
                                        </div>)}
                                    </td>
                                    <td class="tableContent">
                                        <MdEdit color="orange" onClick={() => editCost(cost)} style={{cursor: 'pointer' }} />
                                        <AiFillDelete class="add" onClick={() => deletetheCost(cost._id)} style={{cursor: 'pointer' }}/>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Card.Body>
                <Col style={{ float: 'right', display:'flex', flexDirection: 'row-reverse', justifyContent: 'flex-start', alignItems: 'center', margin: 30  }}>
                <Card >
                    {costLists && costLists.length>10 && <Paging datas={costLists} currentPage={currentPage} pageChange={pageChanged} />}
                </Card>
                </Col>
            </Card>
            
            <Modals showModal={deleteModalShow} hideModal={()=> setDeleteModalShow(false)} modalTitle={headerTitle} headerColor={headerColor}>
                <p className="deleteText" >Are you sure you want to delete this Cost?</p>
                <div style={{ display: 'flex', alignItems:'center', justifyContent:'center' }}>
                        <Buttons backColor="#4AAD47" buttonText="YES" buttonAction={deleteCostListById}>
                            <BsCheck2 style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                        <Buttons backColor="#D42F45" buttonText="No" buttonAction={()=> console.log("pressed")}>
                            <FaTimes style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                </div>
            </Modals>
            {modalShowEdit && <EditCost openModal={modalShowEdit} closeModal={()=> setModalShowEdit(false)} headerColor={headerColor} headerTitle={headerTitle} costlist={selectedCost}  />}
       
            <ModalMedium  showModal={modalShow} hideModal={()=> setModalShow(false)} headerColor={headerColor} modalTitle={headerTitle} >
            <Formik 
                initialValues={{
                    sliderNo: '',
                    cost: '',
                    requested_by:[],
                    
                }}
                validationSchema={
                    
                    Yup.object({
                        
                        sliderNo: Yup.string().required('Slider No is Required'),
                        cost: Yup.string().required("Cost is Required"),
                        //requested_by:Yup.array().required("Requested By is Required"),
                    })
                }
                onSubmit={(values) => {
                    console.log(values)
                    saveCost(values)
                }}
                render={({handleChange, handleSubmit, handleBlur, values, errors,setFieldValue, setFieldTouched, touched}) => (
            <Form> 
            <Row className="mb-3">
                    <InputField fieldName="sliderNo" fieldLabel="Slider No" fieldType="text" md="6" />
                    <InputField fieldName="cost" fieldLabel="Cost(Per Day)" fieldType="text" md="6" />
                    <center>
                    <SelectNew 
                                        md="4"
                                        fieldName="requested_by"
                                        fieldLabel="Requested By"
                                        options={franchiseeList}
                                        isMultiple={false}
                                        value={values.requested_by}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        error={errors.requested_by}
                                        touched={touched.requested_by}
                                    />
                </center>
            </Row>
            <Row>
                <center>
                    <Buttons backColor="#D42F45" buttonText="ADD" buttonAction={handleSubmit}>
                        <IoMdAdd style={{color:'#FFFFFF'}} fontSize="2.3em" />
                    </Buttons>
                </center>
            </Row>
            </Form>
            )}
            />
        </ModalMedium>
 
        </Fragment>
    )
}

export default SliderSettings
