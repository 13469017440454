import React, { Fragment, useState } from 'react'
import { Card, Col,Row } from 'react-bootstrap'
import Header from '../../components/Header'
import Layout from '../layout'
import AfterNoon from './AfterNoon'
import Morning from './Morning'
import Night from './Night'
import Evening from './Evening'
import { Route, Link, useRouteMatch } from 'react-router-dom'



const SetTime = ({history}) =>  {

    //const history = createBrowserHistory();

    const match = useRouteMatch()

    console.log({history})

    //const path = history.location.pathname;

    return (
        
        <Layout>
        <Header headerText="SET TIME" />
        <Row style={{  width: "100%" }}>
            <Col xs="12" sm="12" md="6" lg="4" xl="3" style={{  borderRadius: 20, marginTop: 30, marginBottom: 30 }}>
                <Link to={`${match.path}/morning`} style={{ 
                        color: (history.location.pathname === `${match.path}/morning` || history.location.pathname === `${match.path}`) ? '#fff' : '#545454', 
                        textDecoration:'none', 
                         
                    }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: (history.location.pathname === `${match.path}/morning` || history.location.pathname === `${match.path}`) ? '#4AAD47' : '#fff'  }}>
                        <Card.Body style={{ display: 'flex', alignItems:'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <span className="headerService">MORNING</span>
                            
                        </Card.Body>
                    
                    </Card>
                </Link>
            </Col>
            <Col xs="12" sm="12" md="6" lg="4" xl="3" style={{  borderRadius: 20, marginTop: 30, marginBottom: 30 }}>
                <Link to={`${match.path}/afternoon`} style={{ 
                    color: history.location.pathname === `${match.path}/afternoon` ? '#fff' : '#545454', 
                    textDecoration:'none', 
                    
                 }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: history.location.pathname === `${match.path}/afternoon` ? '#4AAD47' : '#fff' }}>
                        <Card.Body style={{ display: 'flex', alignItems:'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <span className="headerService">AFTERNOON</span>
                            
                        </Card.Body>
                    
                    </Card>
                </Link>
            </Col>
            <Col xs="12" sm="12" md="6" lg="4" xl="3" style={{  borderRadius: 20, marginTop: 30, marginBottom: 30 }}>
                <Link to={`${match.path}/evening`} style={{ 
                    color: history.location.pathname === `${match.path}/evening` ? '#fff' : '#545454', 
                    textDecoration:'none', 
                    
                 }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: history.location.pathname === `${match.path}/evening` ? '#4AAD47' : '#fff' }}>
                        <Card.Body style={{ display: 'flex', alignItems:'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <span className="headerService">EVENING</span>
                            
                        </Card.Body>
                    
                    </Card>
                </Link>
            </Col>
            <Col xs="12" sm="12" md="6" lg="4" xl="3" style={{  borderRadius: 20, marginTop: 30, marginBottom: 30 }}>
                <Link to={`${match.path}/night`} style={{ 
                    color: history.location.pathname === `${match.path}/night` ? '#fff' : '#545454', 
                    textDecoration:'none', 
                    
                }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: history.location.pathname === `${match.path}/night` ? '#4AAD47' : '#fff'  }}>
                        <Card.Body style={{ display: 'flex', alignItems:'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <span className="headerService">NIGHT</span>
                            
                        </Card.Body>
                    
                    </Card>
                </Link>
            </Col>
        </Row> 
            <Route path={`${match.path}`} exact>
                <Morning/>   
            </Route>
            <Route path={`${match.path}/morning`} exact>
                <Morning/>   
            </Route>
            <Route path={`${match.path}/afternoon`} exact>
                <AfterNoon/>  
            </Route>
            <Route path={`${match.path}/evening`} exact>
                <Evening/>
            </Route>
            <Route path={`${match.path}/night`} exact>
                <Night/>
            </Route>
        
        
        
         
        </Layout>
       
    )
}

export default SetTime
