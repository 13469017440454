import React, { Fragment, useEffect, useState } from 'react'
import { Card,  Col, Row,Form} from 'react-bootstrap'
import { MdCheck,MdPayments,MdOutlinePending,MdPayment } from 'react-icons/md'
import Paging from '../../components/Paging'
import Buttons from '../../components/Buttons'
import { useRouteMatch, Link ,Redirect} from 'react-router-dom'
import {AiFillEye} from 'react-icons/ai'
import {HiDotsCircleHorizontal } from 'react-icons/hi'
import Modals from '../../components/Modals'

import reactotron from '../../ReactotronConfig'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { RESET_SERVICE } from '../../Redux/constants/serviceConstants'
import Select from 'react-select';
import InputField from '../../components/InputField'
import IconInput from '../../components/IconInput'
import FormSelectField from '../../components/form-select-field'
import * as Yup from 'yup';
import "yup-phone";
import Fab from '../../components/Fab'

let deliveryboyPayout = [{
    name: 'Sarath',
    city:'Tvm',
    franchisee:'CTN ATL',
    orders: '60',
    total: '2000',
    franchiseepayout:'15000',
    tips:'50',
    cash:'1000',
    amount:'1000',
    status : 'pending',
},
{
    name: 'Sarath',
    city:'Tvm',
    franchisee:'CTN ATL',
    orders: '60',
    total: '2000',
    franchiseepayout:'15000',
    tips:'50',
    cash:'1000',
    amount:'1000',
    status : 'paid',
},
{
    name: 'Sarath',
    city:'Tvm',
    franchisee:'CTN ATL',
    orders: '60',
    total: '2000',
    franchiseepayout:'15000',
    tips:'50',
    cash:'1000',
    amount:'1000',
    status : 'pending',
},
{
    name: 'Sarath',
    city:'Tvm',
    franchisee:'CTN ATL',
    orders: '60',
    total: '2000',
    franchiseepayout:'15000',
    tips:'50',
    cash:'1000',
    amount:'1000',
    status : 'processing',
},
{
    name: 'Sarath',
    city:'Tvm',
    franchisee:'CTN ATL',
    orders: '60',
    total: '2000',
    franchiseepayout:'15000',
    tips:'50',
    cash:'1000',
    amount:'1000',
    status : 'pending',
},
{
    name: 'Sam',
    city:'Tvm',
    franchisee:'CTN ATL',
    orders: '60',
    total: '2000',
    franchiseepayout:'15000',
    tips:'50',
    cash:'1000',
    amount:'1000',
    status : 'processing',
},
{
    name: 'Sam',
    city:'Tvm',
    franchisee:'CTN ATL',
    orders: '60',
    total: '2000',
    franchiseepayout:'15000',
    tips:'50',
    cash:'1000',
    amount:'1000',
    status : 'pending',
},
{
    name: 'Sam',
    city:'Tvm',
    franchisee:'CTN ATL',
    orders: '60',
    total: '2000',
    franchiseepayout:'15000',
    tips:'50',
    cash:'1000',
    amount:'1000',
    status : 'pending',
},
{
    name: 'Sam',
    city:'Tvm',
    stores:'30',
    orders: '60',
    revenue: '2000',
    franchiseepayout:'15000',
    status : 'paid',
},
{
    name: 'Vikram',
    city:'Tvm',
    franchisee:'CTN ATL',
    orders: '60',
    total: '2000',
    franchiseepayout:'15000',
    tips:'50',
    cash:'1000',
    amount:'1000',
    status : 'paid',
},
{
    name: 'Vikram',
    city:'Tvm',
    franchisee:'CTN ATL',
    orders: '60',
    total: '2000',
    franchiseepayout:'15000',
    tips:'50',
    cash:'1000',
    amount:'1000',
    status : 'paid',
    
}]

const DeliveryBoyPayout = () => {
    const [modalShow, setModalShow] = useState(false);
    const [headerColor, setHeaderColor] = useState('')
    const [headerTitle, setHeaderTitle] = useState('')


    const [enableFab, setEnableFab] = useState(false)

    const [currentPage, setCurrentPage] = useState(1)
    const [datas, setDatas] = useState([])
    const match = useRouteMatch();
    const [hover, setHover] = useState(false);
    const [mode, setMode] = useState('pending')
    const onHover = () => {
      setHover(true);
    };
  
    const onLeave = () => {
      setHover(false);
    };

    console.log("match", match)

    useEffect(() => {
        const indexOfLastPost = currentPage * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const filter = deliveryboyPayout.filter(pay => pay.status === mode)
        const currentPosts = (filter || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }, [])

    useEffect(() => {
        setEnableFab(!enableFab)
        const indexOfLastPost = currentPage * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const filter = deliveryboyPayout.filter(pay => pay.status === mode)
        const currentPosts = (filter || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }, [mode])

    const pageChanged = (page)=> {
        setCurrentPage(page)
        const indexOfLastPost = page * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const currentPosts = (deliveryboyPayout || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }
    const createPayout = () => {
        
        setHeaderColor('#D42F45');
        setHeaderTitle("DELIVERY BOY PAYOUT");
        setModalShow(true);
    }
       
    
    return (
        <Fragment>
            
            <Card style={{ borderRadius: 20, margin: 10, minHeight: '70vh' }}>
                <Fab fabClick={()=> setEnableFab(!enableFab)} fabOpen={!enableFab} timesClick={() => setMode('pending')} groupClick={() => setMode('processing')} cashClick={() => setMode('paid')} />
                <Card.Body style={{ overflowX: 'auto' }}>
                    <table>
                        <thead class="tableHeader">
                            <th>Name</th>
                            <th>City</th>
                            <th>Franchisee</th>
                            <th>Orders Delivered</th>
                            <th>Total Amount</th>
                            <th>Tips</th>
                            <th>Cash In Hand</th>
                            <th>Payable</th>
                            <th>Status</th>
                            <th>Action</th>
                        </thead>
                        <tbody>
                            {datas && datas.map((payout, index) => (
                                <tr key={index}>
                                    <td class="tableContent">{payout.name}</td>
                                    <td class="tableContent">{payout.city}</td>
                                    <td class="tableContent">{payout.franchisee}</td>
                                    <td class="tableContent">{payout.orders}</td>
                                    <td class="tableContent">{payout.total}</td>
                                    <td class="tableContent">{payout.tips}</td>
                                    <td class="tableContent">{payout.cash}</td>
                                    <td class="tableContent">{payout.amount}</td>
                                    {/* <td class="tableContent">
                                        {payout.payout === 'pay' ? <Link to={{
                                            pathname: `/Payout/details/delivery`,
                                            state: {
                                                // id: store._id,
                                                name: payout.name
                                            }
                                        }}>
                                            

                                        <Buttons backColor="#D42F45" buttonText="PAY" buttonAction={()=> console.log("pressed")}>
                                        <MdPayments style={{color:'#FFFFFF'}} fontSize="2.3em" />
                                        </Buttons>
                                        </Link>
                                     :   payout.payout === 'paid' ? 
                                        <Buttons backColor="#4AAD47" buttonText="PAID" buttonAction={()=> console.log("pressed")}>
                                        <MdCheck style={{color:'#FFFFFF'}} fontSize="2.3em" />
                                        </Buttons>
                                        
                                     :  
                                        <Buttons backColor="#FC9D21" buttonText="PENDING"  buttonAction={()=> console.log("pressed")}>
                                        <MdOutlinePending style={{color:'#FFFFFF'}} fontSize="2.3em" />
                                        </Buttons>}
                                    </td> */}
                                    <td className="tableContent">
                                        {payout.status !=='Active' &&
                                        <div>
                                            {payout.status === 'paid' ? <span className="paid">PAID</span> : payout.status === 'pending' ? <span className="pending">PENDING</span> : 
                                            <span className="processing">PROCESSING</span>
                                        }</div>}
                                    </td>
                                    <td className="tableContent">
                                    <Link to={{
                                            pathname: `/Payout/details/delivery`,
                                            state: {
                                                // id: store._id,
                                                name: payout.name
                                            }
                                        }}>
                                        <AiFillEye color="blue" />
                                        </Link>
                                        <MdPayments style={{color:'#26CE1A',cursor: 'pointer'}} onClick={createPayout}  />
                                        <MdPayment style={{color:'#D1A733'}} />
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Card.Body>
                <Col style={{ float: 'right', display:'flex', flexDirection: 'row-reverse', justifyContent: 'flex-start', alignItems: 'center', margin: 30  }}>
                <Card >
                    {deliveryboyPayout && deliveryboyPayout.length>10 && <Paging datas={deliveryboyPayout} currentPage={currentPage} pageChange={pageChanged} />}
                </Card>
                </Col>
            </Card>
            <Modals  showModal={modalShow} hideModal={()=> setModalShow(false)} headerColor={headerColor} modalTitle={headerTitle} >
            
           
            <Row>
                <Form.Group as={Col} xs="12" sm="12" md="6" lg="4" xl="6" controlId="formGridFranchiseeName" className="formStyle">
                    <Form.Label className="formLabel">Name</Form.Label>
                    <Form.Control className="inputStyle" type="text"  />
                </Form.Group>
                <Form.Group controlId="formGridName" as={Col}  xs="12" sm="12" md="6" lg="4" xl="6" className="formStyle">
                    <Form.Label className="formLabel">Franchisee Name</Form.Label>
                    <Form.Control className="inputStyle" type="text"  />
                </Form.Group>
            </Row><Row>
                <Form.Group controlId="formGridName" as={Col}  xs="12" sm="12" md="6" lg="4" xl="6" className="formStyle">
                    <Form.Label className="formLabel">City</Form.Label>
                    <Form.Control className="inputStyle" type="text"  />
                </Form.Group>
                <Form.Group controlId="formGridName" as={Col} xs="12" sm="12" md="6" lg="4" xl="6" className="formStyle">
                    <Form.Label className="formLabel">Amount Due</Form.Label>
                    <Form.Control className="inputStyle" type="text"  />
                </Form.Group>
                </Row>
                <Row>
                <Form.Group controlId="formGridName" as={Col}  xs="12" sm="12" md="6" lg="4" xl="6" className="formStyle">
                    <Form.Label className="formLabel">Other Amount</Form.Label>
                    <Form.Control className="inputStyle" type="text"  />
                </Form.Group>
                <Form.Group controlId="formGridName" as={Col} xs="12" sm="12" md="6" lg="4" xl="6" className="formStyle">
                    <Form.Label className="formLabel">Transaction ID</Form.Label>
                    <Form.Control className="inputStyle" type="text"  />
                </Form.Group>
            </Row>    


                            {/* <InputField fieldName="name" fieldLabel="Name" />
                            <InputField fieldName="franchisee" fieldLabel="Franchisee Name" fieldType="text" />
                            <InputField fieldName="city" fieldLabel="City" fieldType="text" />
                            <InputField fieldName="amount" fieldLabel="Amount Due" fieldType="text" />
                            <InputField fieldName="cash" fieldLabel="Other Amount" fieldType="text" />
                            <InputField fieldName="id" fieldLabel="Transaction ID" fieldType="text" />
                             */}
                            
           
            {headerTitle === 'DELIVERY BOY PAYOUT' && <Row>
                <center>
                    <Buttons backColor="#26CE1A" buttonText="PAY" buttonAction={()=> console.log("pressed")}>
                        <MdPayments style={{color:'#FFFFFF'}} fontSize="2.3em" />
                    </Buttons>
                </center>
            </Row>}
            
            
        </Modals>
        </Fragment>
        
    )
}

export default DeliveryBoyPayout
