import React, { Fragment, useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import {MdEdit} from 'react-icons/md'
import {AiFillDelete,AiFillEye} from 'react-icons/ai'
import {BsToggle2On,BsToggle2Off, BsCheck2} from 'react-icons/bs'
import { MdCheck, MdClose } from 'react-icons/md'
import {IoMdAddCircle,IoMdAdd}from 'react-icons/io'
import { FaTimes } from 'react-icons/fa'
import Pagination from 'react-paginating';
import Search from '../../components/Search'
import Paging from '../../components/Paging'
//import NewStore from './NewStore'
import Modals from '../../components/Modals'
import Buttons from '../../components/Buttons'
import FranchiseeForm from './FranchiseeForm'
import { useSelector, useDispatch } from 'react-redux'
import { getFranchisee,deleteFranchiseeById } from '../../Redux/actions/franchiseeActions'
import { toast } from 'react-toastify'
import { RESET_ERROR } from '../../Redux/constants/franchiseeConstants'
import FranchiseeView from './FranchiseeView'
import { createBrowserHistory } from "history";
import { Link, useRouteMatch } from 'react-router-dom'




const FranchiseeList = () => {

    const match = useRouteMatch();

    const [modalShow, setModalShow] = useState(false);
    const [viewModalShow, setViewModalShow] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [datas, setDatas] = useState([])
    const [headerColor, setHeaderColor] = useState('')
    const [headerTitle, setHeaderTitle] = useState('')
    const [selected, setSelected] = useState(null)
    const [search, setSearch] = useState('')
    const dispatch = useDispatch();

    const history = createBrowserHistory()

    const {franchiseeList, error, newFranchisee, deleteSuccess,updateSuccess} = useSelector(state => state.franchisee)


    useEffect(() => {
        if(error){
            toast.error(error);
            dispatch({
                type: RESET_ERROR
            })
        }

        if(newFranchisee){
            setModalShow(false)
            toast.success("New Franchisee Created successfully")
            dispatch({
                type: RESET_ERROR
            })
        }
        if(deleteSuccess){
            dispatch(getFranchisee())
            setDeleteModalShow(false);
            dispatch({
                type: RESET_ERROR
            })
            toast.success("Franchisee Deleted Successfully");
        }
        if(updateSuccess){
            dispatch(getFranchisee())
            dispatch({
                type: RESET_ERROR
            })
            toast.success("Franchisee Updated Successfully");
        }
    }, [error,newFranchisee, deleteSuccess,updateSuccess])

    useEffect(() => {
        if(franchiseeList && franchiseeList.length>0){
            const indexOfLastPost = currentPage * 10;
            const indexOfFirstPost = indexOfLastPost - 10;
            const searchResult = franchiseeList.filter((stores) => stores.name.toLowerCase().includes(search.toLowerCase()) || stores.city.toLowerCase().includes(search.toLowerCase())
            )
            const currentPosts = (searchResult || []).slice(indexOfFirstPost, indexOfLastPost);
            setDatas(currentPosts)
        }
    }, [franchiseeList, search])

    const pageChanged = (page)=> {
        setCurrentPage(page)
        const indexOfLastPost = page * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const searchResult = franchiseeList.filter(stores => stores.name.toLowerCase().contains(search.toLowerCase()) || stores.city.toLowerCase().contains(search.toLowerCase()))
        const currentPosts = (searchResult || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }

    const createNewFranchisee = () => {
        setHeaderColor('#D42F45');
        setHeaderTitle("ADD FRANCHISEE");
        setModalShow(true);
    }

    const viewFranchiseeDetails = (store) => {
        setSelected(store);
        setHeaderColor('#417AE8');
        setHeaderTitle("VIEW FRANCHISEE");
        setViewModalShow(true);
    }

    const deleteFranchisee = (id) => {
        setSelected(id);
        setHeaderColor('#D42F45');
        setHeaderTitle("DELETE FRANCHISEE");
        setDeleteModalShow(true);
    }


    useEffect(() => {
        dispatch(getFranchisee())
    }, [])
    const deleteFranchiseeListById = () => {
        dispatch(deleteFranchiseeById(selected))
    }
    return (
        <Fragment>
            <Search placeHolder="SEARCH FRANCHISEE/CITY" searchValue={search} searchChanged={e => setSearch(e.target.value)}/>
            <Card style={{ borderRadius: 20, margin: 10 }}>
                <IoMdAddCircle className="add" fontSize="3.5em" 
                onClick={createNewFranchisee}
                style={{ position: 'absolute', top: -30, right: -20, cursor: 'pointer' }} />
                <Card.Body style={{ overflowX: 'auto' }}>
                    <table>
                        <thead className="tableHeader">
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Email Address</th>
                                <th>Phone</th>
                                <th>City</th>
                                <th>Employee ID</th>
                                <th>Franchisee Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {datas && datas.map((store, index) => (
                                <tr key={index}>
                                    <td className="tableContent">{store._id}</td>
                                    <td className="tableContent">{store.name}</td>
                                    <td className="tableContent">{store.email}</td>
                                    <td className="tableContent">{store.phonenumber}</td>
                                    <td className="tableContent">{store.city}</td>
                                    <td className="tableContent">{store.emp_id}</td>
                                    <td className="tableContent">
                                        {store.status === 'Active' ? <BsToggle2On color="#0DD404" fontSize="1.5em"/> : <BsToggle2Off fontSize="1.5em" color="#F95555" />}
                                    </td>
                                    
                                    <td className="tableContent">
                                        <AiFillEye color="blue" onClick={() => viewFranchiseeDetails(store)} style={{ cursor: 'pointer' }} />
                                        <Link to={{
                                            pathname: `${match.path}/edit`,
                                            state: {
                                                name: store.name,
                                                franchisee : store
                                            }
                                        }}>
                                        <MdEdit color="orange"  />
                                        </Link>
                                        <AiFillDelete className="add" onClick={() => deleteFranchisee(store._id)} style={{ cursor: 'pointer' }}/>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Card.Body>
                <Col style={{ float: 'right', display:'flex', flexDirection: 'row-reverse', justifyContent: 'flex-start', alignItems: 'center', margin: 30  }}>
                <Card >
                    {franchiseeList && franchiseeList.length>10 && <Paging datas={franchiseeList} currentPage={currentPage} pageChange={pageChanged} />}
                </Card>
                </Col>
            </Card>
            <FranchiseeForm openModal={modalShow} closeModal={()=> setModalShow(false)} headerColor={headerColor} headerTitle={headerTitle} />
            <FranchiseeView openModal={viewModalShow} closeModal={()=> setViewModalShow(false)} headerColor={headerColor} headerTitle={headerTitle} franchisee={selected} />
            <Modals showModal={deleteModalShow} hideModal={()=> setDeleteModalShow(false)} modalTitle={headerTitle} headerColor={headerColor}>
                <p className="deleteText" >Are you sure you want to delete this Franchisee?</p>
                <div style={{ display: 'flex', alignItems:'center', justifyContent:'center' }}>
                        <Buttons backColor="#4AAD47" buttonText="YES" buttonAction={deleteFranchiseeListById}>
                            <BsCheck2 style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                        <Buttons backColor="#D42F45" buttonText="No" buttonAction={()=> console.log("pressed")}>
                            <FaTimes style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                </div>
            </Modals>
        </Fragment>
    )
}

export default FranchiseeList
