import React, { useState, useEffect, useRef } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import Modals from '../../components/Modals'
import {IoMdAdd}from 'react-icons/io'
import Buttons from '../../components/Buttons'
import { useSelector, useDispatch } from 'react-redux'
import {Formik} from 'formik';
import InputField from '../../components/InputField'
import * as Yup from 'yup';
import "yup-phone";
import { getCategoryLists } from '../../Redux/actions/categoryActions'
import SelectInput from '../../components/SelectInput'
import SwitchInput from '../../components/SwitchInput'
import { saveNewProduct } from '../../Redux/actions/productActions'
import TagInput from '../../components/TagInput'
import ImageInput from '../../components/ImageInput'
import SelectNew from '../../components/SelectNew'
import { getUnits } from '../../Redux/actions/unitActions'
import { toast } from 'react-toastify'
import { RESET_STORE } from '../../Redux/constants/storeConstants'
import { RESET_UNIT } from '../../Redux/constants/unitConstants'

const NewProduct = ({openModal, closeModal, headerColor, headerTitle, storeId}) => {


    const [units, setUnits] = useState([])
    const [category, setCategory] = useState('')

    const dispatch = useDispatch();

    const { categoryLists, error } = useSelector(state => state.category)
    

    const [image, setImage] = useState(null);
    const [tags, setTags] = useState([]);

    const proForm = useRef(null)



    useEffect(() => {
        dispatch(getCategoryLists())
        dispatch(getUnits())
    }, [])

    useEffect(() => {
        console.log({category})
        categoryLists && categoryLists.map(cat => {
            if(cat.name === category){
                setUnits(cat.units)
            }
        })
    }, [category])

    useEffect(() => {
        if(error){
            toast.error(error);
            dispatch({
                type: RESET_STORE
            })
        }

    }, [error])



    const saveproduct = async (values) =>  {

        let cat = {
            _id: values.category._id,
            name: values.category.name
        }

        let unit = {
            _id : values.unit._id,
            unit: values.unit.unit,
            varients: values.unit.varients
        }

        var form_data = new FormData();
        form_data.append("name", values.name);
        form_data.append("category", JSON.stringify(cat));
        form_data.append("unit", JSON.stringify(unit));
        form_data.append("description", values.description);
        form_data.append("cost_price", values.cost_price);
        form_data.append("selling_price", values.selling_price);
        form_data.append("offer_price",values.offer_price);
        form_data.append("tags", JSON.stringify(tags));
        form_data.append("status", 'Active');
        form_data.append("storeID", storeId);
        form_data.append("image", image);
        form_data.append("admin_status", 'Approved');

        //console.log(form_data);

        dispatch(saveNewProduct(form_data))
    }




    const tagInputChange = (tags) => {
        setTags(tags)
    }

    const categoryChange = (value, setFieldValue) => {
        console.log(value)
        let units = value.units.map(unit => {
            return { ...unit, lable: unit.unit, value: unit.unit }
        })
        setUnits(units)
        setFieldValue('category', value)
    }


    return (
        <Modals showModal={openModal} hideModal={closeModal} headerColor={headerColor} modalTitle={headerTitle}>
            <Formik 
                innerRef={proForm}
                initialValues={{
                    name : '',
                    category: '',
                    unit : '',
                    description: '',
                    cost_price: '',
                    selling_price: '',
                    offer_price: '',
                    status: '',
                    admin_status :'',
                }}
                validationSchema={
                    Yup.object({
                        name: Yup.string().required('Name Required'),
                        category: Yup.object().required('Category Name Required'),
                        unit: Yup.object().required('Unit name is Required'),
                        cost_price: Yup.string().required("Cost Price is Required"),
                        selling_price: Yup.string().required("Selling price is required"),
                    })
                }
                onSubmit={(values) => {
                    //console.log(values)
                    saveproduct(values)
                }}
                render={({handleChange, handleSubmit, handleBlur, values, errors, setFieldTouched, touched, setFieldValue}) => (
                    <Form>
                        <Row>
                            <Col md="8">
                                <Row>
                                    <InputField fieldName="name" fieldLabel="ProductName" md="6" />
                                    <SelectNew 
                                        md="6"
                                        fieldName="category"
                                        fieldLabel="Product Category"
                                        options={categoryLists}
                                        isMultiple={false}
                                        value={values.category}
                                        onChange={(fieldName, value)=> categoryChange(value, setFieldValue)}
                                        onBlur={setFieldTouched}
                                        error={errors.category}
                                        touched={touched.category}
                                    />
                                    <InputField fieldName="description" fieldLabel="Product Description" fieldType="text" md="12" />
                                </Row>
                            </Col>
                            <ImageInput fieldName="store_image" fieldLabel="Store Image" image={image} md="3" changeImage={e => setImage(e.target.files[0])} imageHelperText="512*512" />
                        </Row>
                        <Row>
                            <InputField fieldName="cost_price" fieldLabel="Cost Price" md="4" />
                            <InputField fieldName="selling_price" fieldLabel="Selling Price" md="4" />
                            <InputField fieldName="offer_price" fieldLabel="Offer Price" md="4" />
                            <SelectNew 
                                md="4"
                                fieldName="unit"
                                fieldLabel="Unit"
                                options={units}
                                isMultiple={false}
                                value={values.unit}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                error={errors.unit}
                                touched={touched.unit}
                            />
                            <TagInput fieldName="tags" fieldLabel="Tags" md="4" handleChange={tagInputChange} tags={tags} />
                            
                        </Row>
                        <Row>
                            <center>
                                <Buttons backColor="#D42F45" buttonText="ADD" buttonAction={handleSubmit}>
                                    <IoMdAdd style={{color:'#FFFFFF'}} fontSize="2.3em" />
                                </Buttons>
                            </center>
                        </Row>
                    </Form>
                )}
            />
            
        </Modals>
    )
}

export default NewProduct
