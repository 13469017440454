import reactotron from "../../ReactotronConfig";
import { 
    LOADING 
} from "../constants/userConstants"
import axios from '../../CustomAxios'
import { 
    NEW_SERVICE_FAIL,
    NEW_SERVICE_SUCCESS,
    SERVICE_LIST_FAIL, 
    SERVICE_LIST_SUCCESS, 
    DELETE_SER_LISTING_SUCCESS,
    DELETE_SER_LISTING_FAIL,
    SER_LISTING_DETAILS_SUCCESS,
    SER_LISTING_DETAILS_FAIL,
    SER_LIST_UPDATE_SUCCESS,
    SER_LIST_UPDATE_FAIL,
    SUB_SERVICE_SUCCESS,
    SUB_SERVICE_FAIL
} from "../constants/serviceConstants";


export const getServiceListings = () => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/services`)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: SERVICE_LIST_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: SERVICE_LIST_FAIL,
            payload: error
        })
    });
}


//ADD NEW STORE

export const addNewService = (details) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/create-service`, details)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_SERVICE_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_SERVICE_FAIL,
            payload: error
        })
    });
}



//DELETE single service listing
export const deleteServiceListingById = (id) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.delete(`admin/delete-service/${id}`)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: DELETE_SER_LISTING_SUCCESS,
            payload: id
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: DELETE_SER_LISTING_FAIL,
            payload: error
        })
    });
}

//Get details of single service category
export const getServiceDetails = (id) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.get(`admin/service/${id}`, id)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: SER_LISTING_DETAILS_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: SER_LISTING_DETAILS_FAIL,
            payload: error
        })
    });
}

//Edit Service
export const updateService = (details) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/edit-service`, details)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: SER_LIST_UPDATE_SUCCESS,
            payload: response.data.service
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: SER_LIST_UPDATE_FAIL,
            payload: error
        })
    });
}

//Get All Service sub category under main Category
export const getSubCategoriesUnderMainCat = (mainId) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.get(`admin/list-subservices/${mainId}`)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        response.data.map(list => {
            list.label = list.name;
            list.value = list.name;
        })
        
        dispatch({
            type: SUB_SERVICE_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: SUB_SERVICE_FAIL,
            payload: error
        })
    });
}
