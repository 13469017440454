import React from 'react'
import Header from '../../components/Header'
import Layout from '../layout'
import UnitList from './UnitList'

const Unit = () => {
    return (
        <Layout>
            <Header headerText="UNIT MANAGEMENT" />
            <UnitList />
        </Layout>
    )
}

export default Unit
