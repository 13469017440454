import React, { Fragment, useEffect, useState } from 'react'
import {Card, Col, Row } from 'react-bootstrap'
import { IoMdAddCircle } from 'react-icons/io'
import { AiFillEye, AiFillDelete } from 'react-icons/ai'
import { MdEdit } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { deleteCategoryChargesById, getCategoryCharges, getCategoryLists, saveCategoryCommission, updateCategoryCommission } from '../../../../Redux/actions/categoryActions'
import { toast } from 'react-toastify'
import { RESET_STORE } from '../../../../Redux/constants/storeConstants'
import moment from 'moment'
import Paging from '../../../../components/Paging'
import Modals from '../../../../components/Modals'
import SelectInput from '../../../../components/SelectInput'
import {Formik, Field, Form} from 'formik';
import * as Yup from 'yup';
import InputField from '../../../../components/InputField'
import Buttons from '../../../../components/Buttons'
import { IoMdAdd } from 'react-icons/io'
import { GiBackwardTime } from 'react-icons/gi'
import { BsCheck2 } from 'react-icons/bs'
import { FaTimes } from 'react-icons/fa'
import reactotron from '../../../../ReactotronConfig'
import SelectNew from '../../../../components/SelectNew'


const Category = ({storeId, name}) => {
    const dispatch = useDispatch();

    const { commisionLists, error, categoryLists, commissionSuccess, commissionUpdate, deleteCommission } = useSelector(state => state.category)
    const { storeDetails } = useSelector(state => state.store)

    //reactotron.log({storeDetails})



    const [currentPage, setCurrentPage] = useState(1)
    const [datas, setDatas] = useState([])
    const [enableModal, setEnableModal] = useState(false)
    const [ modalTitle, setModalTitle ] = useState('');
    const [ headerColor, setHeaderColor ] = useState('');
    const [ commission, setCommission ] = useState(null);
    const [deleteModalShow, setDeleteModalShow] = useState(false)

    useEffect(() => {
        if(storeId){
            dispatch(getCategoryCharges(storeId))
        }
    }, [storeId])

    useEffect(() => {
        dispatch(getCategoryLists())
    }, [])


    useEffect(() => {
        if(error){
            toast.error(error);
            dispatch({
                type: RESET_STORE
            })
        }

        if(commissionSuccess){
            setEnableModal(false)
            //dispatch(getCategoryCharges(storeId))
            toast.success("Category Charges Added Successfully");
            dispatch({
                type: RESET_STORE
            })
        }

        if(commissionUpdate){
            setEnableModal(false)
            //dispatch(getCategoryCharges(storeId))
            toast.success("Category Charges Updated Successfully");
            dispatch({
                type: RESET_STORE
            })
        }

        if(deleteCommission){
            setDeleteModalShow(false)
            //dispatch(getCategoryCharges(storeId))
            toast.success("Category Charges Deleted Successfully");
            dispatch({
                type: RESET_STORE
            })
        }
    }, [error, commissionSuccess, commissionUpdate, deleteCommission])

    useEffect(() => {
        const indexOfLastPost = currentPage * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const currentPosts = (commisionLists || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }, [commisionLists])

    const pageChanged = (page)=> {
        setCurrentPage(page)
        const indexOfLastPost = page * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const currentPosts = (commisionLists || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }

    const newCommission = () => {
        let initialValues = {
            category_name: '',
            category_commission: '',
            franchisee_commission: ''
        }
        setModalTitle("ADD CATEGORY CHARGES");
        setHeaderColor("#D42F45")
        setEnableModal(true);
        setCommission(initialValues);
    }


    //Save Commision
    const saveCommission = (values) => {

        let cat = {
            id: values.category_name._id,
            name: values.category_name.name
        }

        if(modalTitle === "ADD CATEGORY CHARGES"){
            let data = { 
                category: cat,
                category_commission: values.category_commission,
                store_name: name,
                storeID: storeId,
                franchisee_name: storeDetails.franchisee_name.name,
                franchisee_id: storeDetails.franchisee_name.id,
                franchisee_commission: values.franchisee_commission,
                status:'approved'
            }

            dispatch(saveCategoryCommission(data))
        }
        else{
            const data = {
                category : cat,
                category_commission: values.category_commission,
                franchisee_commission: values.franchisee_commission,
                id: values.id
            }

            dispatch(updateCategoryCommission(data))
        }
        
    }

    //Edit Commission
    const editCommission = async(com) => {

        let name = await categoryLists.find(cat => cat._id === com.category.id)

        let data = {
            id: com._id,
            category_name: name,
            category_commission: com.category_commission,
            franchisee_commission: com.franchisee_commission
        }

        setCommission(data);
        setModalTitle("EDIT CATEGORY CHARGES");
        setHeaderColor("#FF9900")
        setEnableModal(true)
    }

    const deleteCommissions = (id) => {
        setCommission(id)
        setDeleteModalShow(true);
        setHeaderColor('#D42F45');
        setModalTitle("DELETE CATEGORY CHARGES");
    }

    const deleteCommissionById = () => {
        dispatch(deleteCategoryChargesById(commission))
    }

    return (
        <Fragment>
            <Card style={{ borderRadius: 20, margin: 10 }}>
                <IoMdAddCircle className="add" fontSize="3.5em" 
                style={{ position: 'absolute', top: -30, right: -20, cursor: 'pointer' }} onClick={newCommission} />
                <Card.Body style={{ overflowX: 'auto' }}>
                    <table>
                        <thead className="tableHeader">
                            <tr>
                                <th>Category Name</th>
                                <th>Category Commission</th>
                                <th>Franchisee Commission</th>
                                <th>Created Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {datas && datas.map((com, index) => (
                                <tr key={index}>
                                    <td className="tableContent">{com.category?.name}</td>
                                    <td className="tableContent">{com.category_commission}</td>
                                    <td className="tableContent">{com.franchisee_commission}</td>
                                    <td className="tableContent">{moment(com.created_at).format("DD-MM-YYYY hh:mm A")}</td>
                                    <td className="tableContent">
                                        <MdEdit color="orange" onClick={() => editCommission(com)} />
                                        <AiFillDelete className="add" onClick={() => deleteCommissions(com._id)} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Card.Body>
                <Col style={{ float: 'right', display:'flex', flexDirection: 'row-reverse', justifyContent: 'flex-start', alignItems: 'center', margin: 30  }}>
                    <Card >
                        {commisionLists && commisionLists.length>10 && <Paging datas={commisionLists} currentPage={currentPage} pageChange={pageChanged} />}
                    </Card>
                </Col>
            </Card>
            <Modals showModal={enableModal} hideModal={() => setEnableModal(false)} modalTitle={modalTitle} headerColor={headerColor}>
                <Formik 
                    initialValues={commission}
                    validationSchema={
                        Yup.object({
                            category_name: Yup.object().required('Categoruy Name Required'),
                            category_commission: Yup.string().required('Category Commission Required'),
                            franchisee_commission: Yup.string().required('Franchisee Commission Required'),
                        })
                    }
                    onSubmit={(values) => {
                        console.log(values)
                        saveCommission(values)
                    }}
                    render={({handleChange, handleSubmit, handleBlur, values, errors, touched, setFieldValue, setFieldTouched}) => (
                        <Form>
                            <Row>
                                <SelectNew 
                                    md="6"
                                    fieldName="category_name"
                                    fieldLabel="Category Name"
                                    options={categoryLists}
                                    isMultiple={false}
                                    value={values.category_name}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors.category_name}
                                    touched={touched.category_name}
                                />
                                <InputField fieldName="category_commission" fieldLabel="Category Commission(%)" md="6" />
                                <InputField fieldName="franchisee_commission" fieldLabel="Franchisee Commission(%)" md="6" />
                            </Row>
                            <Row>
                                <center>
                                    <Buttons backColor={headerColor} buttonText={modalTitle === "ADD CATEGORY CHARGES" ? "ADD" : "UPDATE"} buttonAction={handleSubmit}>
                                        {modalTitle === "ADD CATEGORY CHARGES" ? <IoMdAdd style={{color:'#FFFFFF'}} fontSize="2.3em" /> : <GiBackwardTime style={{color:'#FFFFFF'}} fontSize="2.3em" /> }
                                    </Buttons>
                                </center>
                            </Row>
                        </Form>
                    )}
                />
            </Modals>
            <Modals showModal={deleteModalShow} hideModal={()=> setDeleteModalShow(false)} modalTitle={modalTitle} headerColor={headerColor}>
                <p className="deleteText">Are you sure you want to delete this store?</p>
                <div style={{ display: 'flex', alignItems:'center', justifyContent:'center' }}>
                        <Buttons backColor="#4AAD47" buttonText="YES" buttonAction={deleteCommissionById}>
                            <BsCheck2 style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                        <Buttons backColor="#D42F45" buttonText="No" buttonAction={()=> setDeleteModalShow(false)}>
                            <FaTimes style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                </div>
            </Modals>
        </Fragment>
    )
}

export default Category
