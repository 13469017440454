import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Row, Col, Card, Form  } from 'react-bootstrap'
import { Link, Route, useRouteMatch } from 'react-router-dom'
import Header from '../../../components/Header'
import {createBrowserHistory } from 'history'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import {Formik, Field} from 'formik';
import {AiOutlineCheck} from 'react-icons/ai'
import './style.scss'
import Layout from '../../layout'
import DeliveryBoyDetails from './DeliveryBoyDetails'


const PayoutDetails = () => {

    const match = useRouteMatch();
    const history = createBrowserHistory();
    const [storeId, setStoreId] = useState(history?.location?.state?.id);
    const [header, setHeader] = useState(history?.location?.state?.name);
    

    const scrolldiv = useRef(null)

    useEffect(() => {
        setStoreId(history?.location?.state?.id)
        setHeader(history?.location?.state?.name)
    }, [])

    


    return (
        <Layout>
        <Header headerText={header} />
        <div ref={scrolldiv} style={{  display:'flex', flexDirection: 'row', overflowX:'scroll', flexWrap:'nowrap', borderRadius: 20, marginTop: 30, marginBottom: 30, marginRight: 20, height: 80, scrollbarWidth: 'thin'  }} className="scrollbar-hidden" >
                
                     <Card style={{  height: 58, width:'60%', borderRadius: 20, backgroundColor: history.location.pathname === `${match.path}/product` ? '#4AAD47' : '#fff' }}>
                        <Card.Body style={{ display: 'flex', alignItems:'center', flexDirection: 'row', justifyContent: 'space-between',paddingTop:10  }}>
                            <span className="headerService" style={{ whiteSpace:'nowrap' }}>CUSTOM DATE</span>
                            <span class="divider"> </span>
                            <Col>
                            <span className="headerService" style={{ whiteSpace:'nowrap' }}>FROM</span>
                                        </Col>
                                        <Col>
                                                 <Form.Control 
                                                        type="date"
                                                        id="day1"
                                                        // value={field.value} 
                                                        // onChange={field.onChange}
                                                        style={{ borderRadius: 10 , width:200,}}
                                                />
                                        </Col>
                                        <Col>
                                        <span className="headerService" style={{ whiteSpace:'nowrap' }}>To</span>
                                        </Col>
                                        <Col>
                                        <Form.Control 
                                                        type="date"
                                                        id="day1"
                                                        // value={field.value} 
                                                        // onChange={field.onChange}
                                                        style={{ borderRadius: 10,width:200 }}
                                                />
                                        </Col>
                                        <Col>
                                        
                                        <span className="checkText">
                <AiOutlineCheck   color="white" style={{ fontSize: 30 }} />
            </span>
            </Col>
                                        
                        </Card.Body>
                    
                    </Card>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: history.location.pathname === `${match.path}/product` ? '#4AAD47' : '#fff' }}>
                        <Card.Body style={{ display: 'flex', alignItems:'center', flexDirection: 'row', justifyContent: 'space-between'  }}>
                            <span className="headerService" style={{ whiteSpace:'nowrap' }}>TRANSACTION HISTORY</span>
                            
                        </Card.Body>
                    
                    </Card>
                
               </div> 
            

            <Route path={`${match.path}/delivery`} exact>
                <DeliveryBoyDetails />   
            </Route>
            
            
        </Layout>
    )
}

export default PayoutDetails
