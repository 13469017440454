import reactotron from "../../ReactotronConfig";
import { 
    LOADING 
} from "../constants/userConstants"
import axios from '../../CustomAxios'
import { 
    NEW_TIME_FAIL,
    NEW_TIME_SUCCESS,
    TIME_LIST_FAIL, 
    TIME_LIST_SUCCESS, 
    DELETE_TIME_SUCCESS,
    DELETE_TIME_FAIL,
    TIME_UPDATE_SUCCESS,
    TIME_UPDATE_FAIL

} from "../constants/timeConstants";

export const getTimeLists = (when) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/get-time`,{ params: {
        value: when
    } })
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: TIME_LIST_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: TIME_LIST_FAIL,
            payload: error
        })
    });
}

 

//ADD NEW TIME
export const addNewTime = (from, to, when) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    let data={
        from, to, when
    }
    await axios.post(`admin/create-time`, data)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_TIME_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_TIME_FAIL,
            payload: error
        })
    });
}


//DELETE single TIME 
export const deleteTimeById = (id) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.delete(`admin/delete-time/${id}`)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: DELETE_TIME_SUCCESS
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: DELETE_TIME_FAIL,
            payload: error
        })
    });
}

//Edit TIME
export const updateTime = (id, from, to, when) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
let data={
    id, from, to, when
}

    await axios.post(`admin/edit-time`, data)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: TIME_UPDATE_SUCCESS,
            payload: response.data.time
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: TIME_UPDATE_FAIL,
            payload: error
        })
    });
}
