import axios from '../../CustomAxios'
import { CATEGORY_COMMISION_FAIL, CATEGORY_COMMISION_SUCCESS, CATEGORY_LIST_FAIL,
     CATEGORY_LIST_SUCCESS, DELETE_CATEGORY_COMMISION_FAIL, DELETE_CATEGORY_COMMISION_SUCCESS,
      NEW_CATEGORY_COMMISION_FAIL, NEW_CATEGORY_COMMISION_SUCCESS, NEW_CATEGORY_FAIL, 
      NEW_CATEGORY_SUCCESS, UPDATE_CATEGORY_COMMISION_SUCCESS ,DELETE_CATEGORY_SUCCESS,
      DELETE_CATEGORY_FAIL, UPDATE_CATEGORY_SUCCESS,UPDATE_CATEGORY_FAIL} from '../constants/storeConstants';
import { LOADING } from '../constants/userConstants';

//Get all categories
export const getCategoryLists = () => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/categories`)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })

        await response.data.map(cat => {
            cat.label = cat.name;
            cat.value = cat.name;
        })
        
        dispatch({
            type: CATEGORY_LIST_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: CATEGORY_LIST_FAIL,
            payload: error
        })
    });
}

//Save New Category
export const addNewCategory = (data) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/create-category`, data,{
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: NEW_CATEGORY_SUCCESS,
            payload: response.data.category
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_CATEGORY_FAIL,
            payload: error
        })
    });
}

//get Category charges under store
export const getCategoryCharges = (storeId) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/category-commissions/${storeId}`)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        if(response.data.length>0){
            dispatch({
                type: CATEGORY_COMMISION_SUCCESS,
                payload: response.data
            })
        }
        else{
            dispatch({
                type: CATEGORY_COMMISION_SUCCESS,
                payload: null
            })
        }
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: CATEGORY_COMMISION_FAIL,
            payload: error
        })
    });
}

//Save Category Commission
export const saveCategoryCommission = (values) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/create-category-commission`, values)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: NEW_CATEGORY_COMMISION_SUCCESS,
            payload: response.data.categoryCharge
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_CATEGORY_COMMISION_FAIL,
            payload: error
        })
    });
}

//Update Category Commission
export const updateCategoryCommission = (values) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/edit-category-commission`, values)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: UPDATE_CATEGORY_COMMISION_SUCCESS,
            payload: response.data.categoryCharge
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: UPDATE_CATEGORY_COMMISION_SUCCESS,
            payload: error
        })
    });
}

//DELETE CATEGORY CHARGES
export const deleteCategoryChargesById = (id) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.delete(`admin/delete-commission/${id}`)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: DELETE_CATEGORY_COMMISION_SUCCESS,
            payload: id 
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: DELETE_CATEGORY_COMMISION_FAIL,
            payload: error
        })
    });
}

//Delete CATEGORY
export const deleteCategoryById = (id) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.delete(`admin/delete-category/${id}`)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: DELETE_CATEGORY_SUCCESS,
            payload: id
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: DELETE_CATEGORY_FAIL,
            payload: error
        })
    });
}


//Edit category
export const updateCategory = (data) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.post(`admin/edit-category`, data,{
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: UPDATE_CATEGORY_SUCCESS,
            payload: response.data.category
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: UPDATE_CATEGORY_FAIL,
            payload: error
        })
    });
}
