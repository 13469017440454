import logo from './logo.svg';
import './App.scss';
import Login from './scrrens/Login';
import { Container } from 'react-bootstrap'
import { Route, Redirect } from 'react-router-dom';
import Layout from './scrrens/layout';
import { useSelector } from 'react-redux'
import Dashboard from './scrrens/Dashboard';
import Store from './scrrens/Store';
import Franchisee from './scrrens/Franchisee';
import Order from './scrrens/Order';
import Service from './scrrens/Service';
import User from './scrrens/User';
import Customers from './scrrens/Customers';
import Payout from './scrrens/Payout';
import Review from './scrrens/Review';
import Reports from './scrrens/Reports';
import Notifications from './scrrens/Notifications';
import Sliders from './scrrens/Sliders';
import Coupons from './scrrens/Coupons';
import SetTime from './scrrens/SetTime';
//import Profile from './scrrens/Profile';
import Products from './scrrens/Products';
import PrivateRoute from './PrivateRoute';
import store from './Redux/store'
import { Fragment, useEffect } from 'react';
import { loadUser } from './Redux/actions/userActions'
import Logo from './assets/Images/logo.gif'
import EditFranchise from './scrrens/Franchisee/EditFranchise';
import Category from './scrrens/Category';
import StoreType from './scrrens/storeType';
import Unit from './scrrens/Unit';
import Surprise from './scrrens/Surprise';



function App() {

  const { loading, isAuthenticated } = useSelector(state => state.auth);

  useEffect(() => {
    
    store.dispatch(loadUser());
 
  }, [])

    return (
      <Fragment>
         {loading && <div style={{ position:'absolute',  zIndex: 100, display: 'flex', justifyContent:'center', alignItems:'center', height: '100%', width: '100%' }}> 
            <img src={Logo} style={{ height: '50%', alignSelf:'center' }} />
        </div>}
      <div style={{ backgroundColor: '#E5FAE4', height: '100vh', width: '100vw' }}>
          <Route path="/" exact >
                {isAuthenticated ? <Redirect to="/dashboard" /> : <Redirect to="/signin" />}
          </Route>
          <Route path="/" component={Login} exact />
          <Route path="/signin" component={Login} exact />
          <PrivateRoute path="/dashboard" component={Dashboard} exact />
          <PrivateRoute path="/store/" component={Store}  />
          <PrivateRoute path="/franchisee" component={Franchisee} />
          <PrivateRoute path="/category" component={Category} />
          <PrivateRoute path="/order" component={Order} exact />
          <PrivateRoute path="/Service" component={Service}  />
          <PrivateRoute path="/User" component={User} exact />
          <PrivateRoute path="/Customers" component={Customers} exact />
          <PrivateRoute path="/Payout" component={Payout}  />
          <PrivateRoute path="/Review" component={Review}  />
          <PrivateRoute path="/Reports" component={Reports}  />
          <PrivateRoute path="/Notifications" component={Notifications}  />
          <PrivateRoute path="/Sliders" component={Sliders}  />
          <PrivateRoute path="/Coupons" component={Coupons}  />
          <PrivateRoute path="/SetTime" component={SetTime}  />
          <PrivateRoute path="/storetype/" component={StoreType}  />
          <PrivateRoute path="/unit/" component={Unit}  />
          {/*<Route path="/Profile" component={Profile}  />*/}
          <PrivateRoute path="/Products" component={Products}  />
          <PrivateRoute path="/surprise" component={Surprise}  />
            
      </div>

      </Fragment>
    );
  
}

export default App;
