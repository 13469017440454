import { Form, Formik } from 'formik'
import React, { useState, useEffect } from 'react'
import { Row } from 'react-bootstrap'
import ImageInput from '../../components/ImageInput'
import InputField from '../../components/InputField'
import * as Yup from 'yup';
import SelectInput from '../../components/SelectInput'
import Buttons from '../../components/Buttons'
import { IoMdAdd } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { saveUnit } from '../../Redux/actions/unitActions'
import { toast } from 'react-toastify'

const NewUnit = () => {

    const dispatch = useDispatch();

    const newUnit = async(values) => {
        const vari = values.varients.split(',');


        let formData = new FormData();
        
        formData.append("varients", JSON.stringify(vari));
        formData.append("unit", values.unit);
        dispatch(saveUnit(formData));
       
    }

    return (
        <Formik 
            initialValues={{
                unit: '',
                varients: '',
            }}
            validationSchema={
                Yup.object({
                    unit: Yup.string().required('Unit Required'),
                    varients: Yup.string().required('Varients Required')
                })
            }
            onSubmit={(values) => {
                newUnit(values)
            }}
            render={({handleChange, handleSubmit, handleBlur, values, errors}) => (
                <Form>
                    <Row>
                        <InputField fieldName="unit" fieldLabel="Unit Name" md="6" />
                    
                        <InputField fieldName="varients" fieldLabel="Unit Variables" md="6" />
                    </Row>
                    <Row>
                        <center>
                            <Buttons backColor="#D42F45" buttonText="ADD" buttonAction={handleSubmit}>
                                <IoMdAdd style={{color:'#FFFFFF'}} fontSize="2.3em" />
                            </Buttons>
                        </center>
                    </Row>
                </Form>
            )}
        />
    )
}

export default NewUnit
