export const STORE_LIST_SUCCESS = 'STORE_LIST_SUCCESS';
export const STORE_LIST_FAIL = 'STORE_LIST_FAIL';
export const RESET_STORE = 'RESET_STORE'

export const NEW_STORE_TYPE_SUCCESS = 'NEW_STORE_TYPE_SUCCESS';
export const NEW_STORE_TYPE_FAIL = 'NEW_STORE_TYPE_FAIL';

export const STORE_TYPE_LIST_SUCCESS = 'STORE_TYPE_LIST_SUCCESS';
export const STORE_TYPE_LIST_FAIL = 'STORE_TYPE_LIST_FAIL';

export const NEW_STORE_SUCCESS = 'NEW_STORE_SUCCESS';
export const NEW_STORE_FAIL = 'NEW_STORE_FAIL';

export const STORE_DETAILS_SUCCESS = 'STORE_DETAILS_SUCCESS';
export const STORE_DETAILS_FAIL = 'STORE_DETAILS_FAIL';

export const STORE_UPDATE_SUCCESS = 'STORE_UPDATE_SUCCESS';
export const STORE_UPDATE_FAIL = 'STORE_UPDATE_FAIL';

export const STORE_DELETE_SUCCESS = 'STORE_DELETE_SUCCESS';
export const STORE_DELETE_FAIL = 'STORE_DELETE_FAIL';

export const NEW_SLIDER_SUCCESS = 'NEW_SLIDER_SUCCESS';
export const NEW_SLIDER_FAIL = 'NEW_SLIDER_FAIL';

export const DELETE_SLIDER_SUCCESS = 'DELETE_SLIDER_SUCCESS';
export const DELETE_SLIDER_FAIL = 'DELETE_SLIDER_FAIL';

//Products
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS';
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL';

export const NEW_PRODUCT_SUCCESS = 'NEW_PRODUCT_SUCCESS';
export const NEW_PRODUCT_FAIL = 'NEW_PRODUCT_FAIL';

export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS';
export const PRODUCT_DETAILS_FAIL = 'PRODUCT_DETAILS_FAIL';

export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAIL = 'UPDATE_PRODUCT_FAIL';

export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAIL = 'DELETE_PRODUCT_FAIL';

export const SEARCH_PRODUCT_SUCCESS = 'SEARCH_PRODUCT_SUCCESS'
export const SEARCH_PRODUCT_FAIL = 'SEARCH_PRODUCT_FAIL'
export const CLEAR_SEARCH = 'CLEAR_SEARCH'

export const PRODUCT_INPUT = 'PRODUCT_INPUT';

//Categories
export const CATEGORY_LIST_SUCCESS = 'CATEGORY_LIST_SUCCESS'
export const CATEGORY_LIST_FAIL = 'CATEGORY_LIST_FAIL'

export const NEW_CATEGORY_SUCCESS = 'NEW_CATEGORY_SUCCESS';
export const NEW_CATEGORY_FAIL = 'NEW_CATEGORY_FAIL';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAIL = 'UPDATE_CATEGORY_FAIL';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAIL = 'DELETE_CATEGORY_FAIL';


export const CATEGORY_COMMISION_SUCCESS = 'CATEGORY_COMMISION_SUCCESS';
export const CATEGORY_COMMISION_FAIL = 'CATEGORY_COMMISION_FAIL';

export const NEW_CATEGORY_COMMISION_SUCCESS = 'NEW_CATEGORY_COMMISION_SUCCESS';
export const NEW_CATEGORY_COMMISION_FAIL = 'NEW_CATEGORY_COMMISION_FAIL';

export const UPDATE_CATEGORY_COMMISION_SUCCESS = 'UPDATE_CATEGORY_COMMISION_SUCCESS';
export const UPDATE_CATEGORY_COMMISION_FAIL = 'UPDATE_CATEGORY_COMMISION_FAIL';
export const DELETE_CATEGORY_COMMISION_SUCCESS = 'DELETE_CATEGORY_COMMISION_SUCCESS';
export const DELETE_CATEGORY_COMMISION_FAIL = 'DELETE_CATEGORY_COMMISION_FAIL';

export const DELETE_STORETYPE_SUCCESS = 'DELETE_STORETYPE_SUCCESS';
export const DELETE_STORETYPE_FAIL = 'DELETE_STORETYPE_FAIL';

export const UPDATE_STORETYPE_SUCCESS = 'UPDATE_STORETYPE_SUCCESS';
export const UPDATE_STORETYPE_FAIL = 'UPDATE_STORETYPE_FAIL';

export const STORE_UNDER_FRANCHISEE_SUCCESS = 'STORE_UNDER_FRANCHISEE_SUCCESS';
export const STORE_UNDER_FRANCHISEE_FAIL = 'STORE_UNDER_FRANCHISEE_FAIL';

export const STORE_INPUT = 'STORE_INPUT';

