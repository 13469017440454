import React, { Fragment } from 'react'
import {FormControl, FormGroup, FormLabel, Col, InputGroup} from 'react-bootstrap';
import { Field, ErrorMessage } from 'formik';
import {HiUpload } from 'react-icons/hi'

const InputImage = ({fieldName, fieldType, fieldLabel, readOnly, md, changeImage, fileType, image, imageUrl, placeHolder}) => {
    return (
        <FormGroup controlId={fieldName} as={Col}  xs="12" sm="12" md={md ? md : "4"} className="formStyle">
            <FormLabel className="formLabel">{fieldLabel}</FormLabel>
            <InputGroup style={{ display: 'flex', alignItems: 'center' }}>  
                   
                <Field 
                    name={fieldName}
                >
                    {({field, form, meta}) => <FormControl readOnly={readOnly} className="inputStyle" placeholder={placeHolder} type={ fieldType ? fieldType : 'text'} value={field.value} onChange={field.onChange} />}
                </Field>
                <div style={{ position: 'absolute', right: 4, borderLeft:'1px solid black', height:'100%', cursor: 'pointer' }}>
                    <HiUpload style={{  fontSize: 30, color: '#417AE8', fontWeight: 700, paddingTop: 5 }} />
                    <input type='file' accept={fileType} onChange={changeImage} style={{ position: 'absolute', opacity: 0, right: 0, width: 50 }} />
                </div>
                
            </InputGroup> 
            {image && <img src={URL.createObjectURL(image)} style={{ width: '100%', height: 100 }} />} 
            {!image && imageUrl && <img src={image} style={{ width: '100%', height: 100 }} />}
            <ErrorMessage name={fieldName}>
                { msg => <div style={{ color: 'red' }}>{msg}</div> }
            </ErrorMessage>
        </FormGroup>
    )
}

export default InputImage
