import { Formik, Form, Field, ErrorMessage } from 'formik'
import React, { Fragment, useState, useEffect } from 'react'
import { Card, Col, FormControl, FormGroup, FormLabel, Row } from 'react-bootstrap'
import { BsCheck2 } from 'react-icons/bs'
import { FaTimes } from 'react-icons/fa'
import { GiBackwardTime } from 'react-icons/gi'
import { IoMdAdd, IoMdAddCircle } from 'react-icons/io'
import Buttons from '../../../components/Buttons'
import InputField from '../../../components/InputField'
import Modals from '../../../components/Modals'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { MdEdit } from 'react-icons/md'
import { AiFillDelete } from 'react-icons/ai'
import * as Yup from 'yup';
import { addSlider, deleteSliderById } from '../../../Redux/actions/storeActions'
import { toast } from 'react-toastify'
import { RESET_STORE } from '../../../Redux/constants/storeConstants'

const FILE_SIZE = 160 * 1024;
const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png"
];


const Sliders = ({storeId}) => {

    const dispatch = useDispatch();
    const { storeDetails, error, newSlider, sliderDelete } = useSelector(state => state.store)

    const [enableModal, setEnableModal] = useState(false)
    const [deleteModalShow, setDeleteModalShow] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [headerColor, setHeaderColor] = useState('')
    const [image, setImage] = useState(null)
    const [initialValues, setInitialValues] = useState({})
    const [sliderId, setSliderId] = useState('')


    useEffect(() => {
        if(error){
            toast.error(error)
            dispatch({
                type: RESET_STORE
            })
        }

        if(newSlider){
            setEnableModal(false)
            toast.success("Slider added successfully")
            dispatch({
                type: RESET_STORE
            })
        }
        if(sliderDelete){
            setDeleteModalShow(false)
            toast.success("Slider Deleted successfully")
            dispatch({
                type: RESET_STORE
            })
        }
    }, [error, newSlider, sliderDelete])

    const newSliders = () => {
        let values = {
            title: '',
            image
        }
        setInitialValues(values)
        setHeaderColor('#D42F45');
        setModalTitle("ADD SLIDER");
        setEnableModal(true);
    }

    const imageWidthAndHeight = (provideFile) => {
        // take the given file (which should be an image) and return the width and height
        const imgDimensions = { width: null, height: null };
    
        return new Promise(resolve => {
            const reader = new FileReader();
            
            reader.readAsDataURL(provideFile);
            reader.onload = function () {
                const img = new Image();
                img.src = reader.result;
    
                img.onload = function () {
                    imgDimensions.width = img.width;
                    imgDimensions.height = img.height;
    
                    resolve(imgDimensions);
                }
            };
        });
    }

    const imageDimensionCheck = Yup.addMethod(Yup.mixed, 'imageDimensionCheck', function (message, requiredWidth, requiredHeight) {
        return this.test("image-width-height-check", message, async function (value) {
            const { path, createError } = this;
    
            if (!value) {
                return;
            }
    
            const imgDimensions = await imageWidthAndHeight(value);
    
            if (imgDimensions.width !== requiredWidth) {
                return createError({
                    path,
                    message: `The file width needs to be the ${requiredWidth}px!`
                  });
            }
    
            if (imgDimensions.height !== requiredHeight) {
                return createError({
                    path,
                    message: `The file height needs to be the ${requiredHeight}px!`
                  });
            }
    
            return true;
        });
    });


    const saveSlider = (values) => {
        var form_data = new FormData();
        form_data.append("title", values.title);
        form_data.append("slide", values.image);
        form_data.append("storeID", storeId);

        dispatch(addSlider(form_data))
    }

    const deleteSlider = (slider) => {
        setSliderId(slider)
        setHeaderColor('#D42F45');
        setModalTitle("DELETE SLIDER");
        setDeleteModalShow(true);
    }

    const deleteSliders = () => {
        dispatch(deleteSliderById(sliderId))
    }

    return (
        <Fragment>
            <Card style={{ borderRadius: 20, margin: 10 }}>
                <IoMdAddCircle className="add" fontSize="3.5em" 
                style={{ position: 'absolute', top: -30, right: -20, cursor: 'pointer' }}  onClick={newSliders} />
                <Card.Body style={{ overflowX: 'auto' }}>
                    <table>
                        <thead className="tableHeader">
                            <tr>
                                <th>ID</th>
                                <th>Title</th>
                                <th>Image</th>
                                <th>Created Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {storeDetails?.slider && storeDetails.slider.map((slide, index) => (
                                <tr key={index}>
                                    <td className="tableContent">{slide._id}</td>
                                    <td className="tableContent">{slide.title}</td>
                                    <td className="tableContent"><img src={slide.slide} style={{ width: 200, height: 100 }} /></td>
                                    <td className="tableContent">{moment(slide.created_at).format("DD-MM-YYYY hh:mm A")}</td>
                                    <td className="tableContent">
                                        <AiFillDelete className="add" onClick={() => deleteSlider(slide._id)} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Card.Body>
            </Card>
            <Modals showModal={enableModal} hideModal={() => setEnableModal(false)} modalTitle={modalTitle} headerColor={headerColor} modalSize={"md"}>
                <Formik 
                    initialValues={initialValues}
                    validationSchema={
                        Yup.object({
                            title:  Yup.string().required('Slider Title Required'),
                            image:  Yup.mixed()
                                .required("A file is required")
                                .test("fileSize","File too large",value => value && value.size <= FILE_SIZE)
                                .test("fileFormat","Unsupported Format",value => value && SUPPORTED_FORMATS.includes(value.type))
                                //.imageDimensionCheck('test', 1400, 480)
                        })
                    }
                    onSubmit={(values) => {
                        //console.log(values)
                        saveSlider(values)
                    }}
                    render={({handleChange, handleSubmit, handleBlur, values, errors, setFieldValue}) => (
                        <Form>
                                <InputField fieldName="title" fieldLabel="Title" md="12" />
                                <FormGroup as={Col} xs="12" sm="12" md="6" lg="4" xl="4" controlId="formGridImage" className="formStyle">
                                    <FormLabel className="formLabel">Store Image</FormLabel>
                                    <div className="imageStyles" style={{ width: 450, justifyContent:'center', alignItems:'center', display: 'flex', height: 220 }}>
                                        <input 
                                            type="file"
                                            accept="image/png, image/jpg, image/jpeg"
                                            name="image"
                                            style={{ opacity: 0, position: 'absolute', bottom: 0, height: 200, width: 400 }}
                                            onBlur={handleBlur}
                                            onChange={(event) => {
                                                if(event.currentTarget.files[0]){
                                                    setImage(URL.createObjectURL(event.currentTarget.files[0]))
                                                    setFieldValue("image", event.currentTarget.files[0]);}}
                                                }
                                                
                                        />
                                       {image ? <img src={image} style={{ width: 400, height: 200 }} /> : <span style={{ position: 'absolute', bottom: 50 }}>1400 x 480</span>}
                                    </div>
                                    <ErrorMessage name={"image"}>
                                        { msg => <div style={{ color: 'red', width: 400 }}>{msg}</div> }
                                    </ErrorMessage>
                                </FormGroup>
                            <Row>
                                <center>
                                    <Buttons backColor={headerColor} buttonText={modalTitle === "ADD SLIDER" ? "ADD" : "UPDATE"} buttonAction={handleSubmit}>
                                        {modalTitle === "ADD SLIDER" ? <IoMdAdd style={{color:'#FFFFFF'}} fontSize="2.3em" /> : <GiBackwardTime style={{color:'#FFFFFF'}} fontSize="2.3em" /> }
                                    </Buttons>
                                </center>
                            </Row>
                        </Form>
                    )}
                />
            </Modals>
            <Modals showModal={deleteModalShow} hideModal={()=> setDeleteModalShow(false)} modalTitle={modalTitle} headerColor={headerColor}>
                <p className="deleteText">Are you sure you want to delete this store?</p>
                <div style={{ display: 'flex', alignItems:'center', justifyContent:'center' }}>
                        <Buttons backColor="#4AAD47" buttonText="YES" buttonAction={deleteSliders}>
                            <BsCheck2 style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                        <Buttons backColor="#D42F45" buttonText="No" buttonAction={()=> console.log("Hello")}>
                            <FaTimes style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                </div>
            </Modals>
        </Fragment>
    )
}

export default Sliders
