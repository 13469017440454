import { 
   
    CUSTOMER_LIST_SUCCESS,
    CUSTOMER_LIST_FAIL, 
    RESET_CUSTOMER,
    DELETE_CUSTOMER_SUCCESS,
    DELETE_CUSTOMER_FAIL,
    CUSTOMER_DETAILS_SUCCESS,
    CUSTOMER_DETAILS_FAIL
} from "../constants/customerConstants";

export  const customerReducer = (state = {}, action) => {
    switch(action.type){

        case CUSTOMER_LIST_SUCCESS:
            return {
                ...state,
                customerList: action.payload
            }

        case CUSTOMER_LIST_FAIL:
        case CUSTOMER_DETAILS_FAIL:
        case DELETE_CUSTOMER_FAIL:
            return {
                ...state,
                error: action.payload.message
            }   

        case RESET_CUSTOMER:
            return{
                ...state,
                error: null,
                customerSuccess: null,
                deleteSuccess:null,
                customerDetails:null
                
            }
        case CUSTOMER_DETAILS_SUCCESS:
            return{
                ...state,
                customerDetails: action.payload
            }
        case DELETE_CUSTOMER_SUCCESS:
            return {
                ...state,
                deleteSuccess: true
            }
 
        default: 
            return state;
    }
}