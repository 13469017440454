import { 
    LOADING, 
    LOGIN_SUCCESS, 
    RESET_USER, 
    LOGIN_FAIL, 
    RE_LOGIN,
    NEW_USER_FAIL,
    NEW_USER_SUCCESS,
    USER_LIST_FAIL, 
    USER_LIST_SUCCESS, 
    ROLE_LIST_FAIL,
    ROLE_LIST_SUCCESS,
    NEW_ROLE_SUCCESS,
    NEW_ROLE_FAIL,
    ID_LIST_SUCCESS,
    ID_LIST_FAIL,
    NEW_ID_SUCCESS,
    NEW_ID_FAIL,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAIL,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_FAIL
} from "../constants/userConstants";
import axios from '../../CustomAxios'

//Login User
export const loginUsers = (email, password) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    let data = {
        email,
        password
    }

    await axios.post(`auth/login`, data)
    .then(async response => {
        //console.log(response)
        const { access_token, user } = response.data

        await localStorage.setItem("token", access_token);
        await localStorage.setItem("user", JSON.stringify(user))
        dispatch({
            type: LOGIN_SUCCESS,
            payload: user
        })
    })
    .catch(async error => {
        if(error.status === 401){
            await localStorage.removeItem("token");
            await localStorage.removeItem("user");
            dispatch({
                type: LOGIN_FAIL,
                payload: error
            })
        }
        else{
            dispatch({
                type: LOGIN_FAIL,
                payload: error
            })
        }
        
    });
}

//Load User
export const loadUser = () => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    let user = await localStorage.getItem("user");
    let token = await localStorage.getItem("token");
    if(user){
        dispatch({
            type: LOGIN_SUCCESS,
            payload: JSON.parse(user)
        })
    }
    else{
        dispatch({
            type: RE_LOGIN
        })
    }
    
}

//Get all Users

export const getUserLists = () => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/users`)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: USER_LIST_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: USER_LIST_FAIL,
            payload: error
        })
    });
}


//ADD NEW USER
export const addNewUser = (form_data) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/create-user`, form_data,{
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_USER_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        console.log(error);
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_USER_FAIL,
            payload: error
        })
    });
}


//GET ROLES
export const getRoles = () => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/roles`)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        let details = [];
        await response.data.map(user => {
            details.push({ id: user._id, label: user.name, value : user.slug, created_at: user.created_at })
        })
        dispatch({
            type: ROLE_LIST_SUCCESS,
            payload: details
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: ROLE_LIST_FAIL,
            payload: error
        })
    });
}

//SAVE  Roles
export const saveRole = (role) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    let data = {
        name: role
    }

    await axios.post(`admin/create-role`, data)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        let details = {
            id: response.data.role._id, 
            label: response.data.role.name, 
            value : response.data.role.name, 
            created_at: response.data.role.created_at
        }
        dispatch({
            type: NEW_ROLE_SUCCESS,
            payload: details
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_ROLE_FAIL,
            payload: error
        })
    });
}


//GET ID Proofs
export const getProofs = () => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/proof-types`)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        let details = [];
        await response.data.map(user => {
            details.push({ id: user._id, label: user.name, value : user.name, created_at: user.created_at })
        })
        dispatch({
            type: ID_LIST_SUCCESS,
            payload: details
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: ID_LIST_FAIL,
            payload: error
        })
    });
}

//SAVE  ID Proofs
export const saveProoftype = (idtype) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    let data = {
        name: idtype
    }

    await axios.post(`admin/create-prooftype`, data)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        let details = {
            id: response.data.idtype._id, 
            label: response.data.idtype.name, 
            value : response.data.idtype.name, 
            created_at: response.data.idtype.created_at
        }
        dispatch({
            type: NEW_ID_SUCCESS,
            payload: details
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_ID_FAIL,
            payload: error
        })
    });
}

//DELETE single USER
export const deleteUserListById = (id) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.delete(`admin/delete-user/${id}`)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: DELETE_USER_SUCCESS,
            payload: id
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: DELETE_USER_FAIL,
            payload: error
        })
    });
}


//EDIT USER
export const updateUser = (form_data ) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/edit-user`, form_data,{
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: USER_UPDATE_SUCCESS,
            payload: response.data.user
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: USER_UPDATE_FAIL,
            payload: error
        })
    });
}
