import React, { Fragment, useState } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import Header from '../../components/Header'
import Layout from '../layout'
import EditStore from './EditStore/index'
import './store.scss'
import StoreList from './StoreList'

const Store = () => {

    const match = useRouteMatch();

    console.log(match.path)

    return (
        <Layout>
            <Route path={`${match.path}`} exact>
                <Header headerText="STORE MANAGEMENT" />
                <StoreList />
            </Route>
            <Route path={`${match.path}edit`} >
                <EditStore />
            </Route>
        </Layout>
    )
}

export default Store
