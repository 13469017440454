import { 
    STORE_REVIEW_FAIL, 
    STORE_REVIEW_SUCCESS, 
    PRODUCT_REVIEW_FAIL, 
    PRODUCT_REVIEW_SUCCESS,
    DELIVERYBOY_REVIEW_FAIL, 
    DELIVERYBOY_REVIEW_SUCCESS, 
    DELETE_STORE_REVIEW_SUCCESS,
    DELETE_STORE_REVIEW_FAIL,
    DELETE_PRODUCT_REVIEW_SUCCESS,
    DELETE_PRODUCT_REVIEW_FAIL,
    DELETE_DELIVERYBOY_REVIEW_SUCCESS,
    DELETE_DELIVERYBOY_REVIEW_FAIL,
    RESET_REVIEW
} from "../constants/reviewConstants";

export  const storereviewReducer = (state = {}, action) => {
    switch(action.type){

        case STORE_REVIEW_SUCCESS:
            return {
                ...state,
                storeReview: action.payload
            }
        case DELETE_STORE_REVIEW_SUCCESS:
            return {
                ...state,
                deleteStoreReviewSuccess: true
            } 
        case STORE_REVIEW_FAIL:
        case DELETE_STORE_REVIEW_FAIL:
            return{
                ...state,
                error: action.payload.message
            }
        
        case RESET_REVIEW:
            return{
                ...state,
                error: null,
                deleteStoreReviewSuccess:null,
                
            }
        default: 
            return state;
    }
}

export  const productreviewReducer = (state = {}, action) => {
    switch(action.type){

        case PRODUCT_REVIEW_SUCCESS:
            return {
                ...state,
                productReview: action.payload
            }

        case DELETE_PRODUCT_REVIEW_SUCCESS:
            return {
                ...state,
                deleteProductReviewSuccess: true
            } 
        case PRODUCT_REVIEW_FAIL:
        case DELETE_PRODUCT_REVIEW_FAIL:
            return{
                ...state,
                error: action.payload.message
            }
        
        case RESET_REVIEW:
            return{
                ...state,
                error: null,
                deleteProductReviewSuccess:null,
                
            }
        default: 
            return state;
    }
}

export  const deliveryboyreviewReducer = (state = {}, action) => {
    switch(action.type){

        case DELIVERYBOY_REVIEW_SUCCESS:
            return {
                ...state,
                deliveryboyReview: action.payload
            }

        
        case DELETE_DELIVERYBOY_REVIEW_SUCCESS:
            return {
                ...state,
                deleteDeliveryBoyReviewSuccess: true
            } 
        case DELIVERYBOY_REVIEW_FAIL:
        case DELETE_DELIVERYBOY_REVIEW_FAIL:
            return{
                ...state,
                error: action.payload.message
            }
        
        case RESET_REVIEW:
            return{
                ...state,
                error: null,
                deleteDeliveryBoyReviewSuccess:null,
                
            }
        default: 
            return state;
    }
}
