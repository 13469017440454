import React, { Fragment, useState } from 'react'
import Header from '../../components/Header'
import Layout from '../layout'
import CouponList from './CouponList'




const Coupons = () => {

    return (
        
        <Layout>
            <Header headerText="COUPONS" />
            <CouponList />
            
        </Layout>
       
    )
}

export default Coupons
