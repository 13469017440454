import React, { Fragment, useEffect, useState } from 'react'
import { Card, Row, Form, Col, InputGroup } from 'react-bootstrap'
import {MdEdit,MdUpdate} from 'react-icons/md'
import {AiFillDelete,AiFillEye} from 'react-icons/ai'
import {BsToggle2On,BsToggle2Off, BsCheck2} from 'react-icons/bs'
import { MdCheck, MdClose } from 'react-icons/md'
import {IoMdAddCircle,IoMdAdd}from 'react-icons/io'
import { FaTimes } from 'react-icons/fa'
import { BiCalendarEvent,BiTimeFive } from 'react-icons/bi'
import Paging from '../../components/Paging'
import Modals from '../../components/Modals'
import Buttons from '../../components/Buttons'
import EditServiceListing from './EditServiceListing'

import reactotron from '../../ReactotronConfig'
import { useSelector, useDispatch } from 'react-redux'
import { addNewService, getServiceListings, getSubCategoriesUnderMainCat } from '../../Redux/actions/servicelistingActions'
import { deleteServiceListingById } from '../../Redux/actions/servicelistingActions'
import { toast } from 'react-toastify'
import { RESET_SERVICE, RESET_SER_CATEGORY } from '../../Redux/constants/serviceConstants'
import Select from 'react-select';
import {Formik, Field} from 'formik';
import InputField from '../../components/InputField'
import IconInput from '../../components/IconInput'
import FormSelectField from '../../components/form-select-field'
import * as Yup from 'yup';
import "yup-phone";
import SelectNew from '../../components/SelectNew'
import { getserviceCategories, getServiceLists } from '../../Redux/actions/servicecategoryActions'
import moment from 'moment'

const days = [
    {
        label : 'Sunday',
        value: 'Sunday'
    },
    {
        label : 'Monday',
        value: 'Monday'
    },
    {
        label : 'Tuesday',
        value: 'Tuesday'
    },
    {
        label : 'Wednesday',
        value: 'Wednesday'
    },
    {
        label : 'Thursday',
        value: 'Thursday'
    },
    {
        label : 'Friday',
        value: 'Friday'
    },
    {
        label : 'Saturday',
        value: 'Saturday'
    },
]

const ServiceListing = () => {
    const { serviceListing, error, serviceSuccess,deleteServicelisting, subServices } = useSelector(state => state.servicelisting)
    const { error: categoryError, mainCategories, serviceCategoryList } = useSelector(state => state.servicecategory)
    const dispatch = useDispatch();
    const { loading } = useSelector(state => state.auth)
    
    const [modalShow, setModalShow] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [modalShowEdit, setModalShowEdit] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [datas, setDatas] = useState([])
    const [headerColor, setHeaderColor] = useState('')
    const [headerTitle, setHeaderTitle] = useState('')
    const [selectedService, setselectedService] = useState('')

    useEffect(() => {
        const indexOfLastPost = currentPage * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const currentPosts = (serviceListing || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }, [serviceListing])
    

    const pageChanged = (page)=> {
        setCurrentPage(page)
        const indexOfLastPost = page * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const currentPosts = (serviceListing || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }

    //get Service Main Categories
    useEffect(() => {
        dispatch(getserviceCategories())
        dispatch(getServiceLists())
    }, [])
   
    useEffect(() => {
        if(error){
            toast.error(error);
            dispatch({
                type: RESET_SERVICE
            })
        }
        if(serviceSuccess){
            setModalShow(false);
            dispatch({
                type: RESET_SERVICE
            })
            toast.success("New Service Added Successfully");
        }
        
        
        if(deleteServicelisting){
            //dispatch(getServiceLists())
            setDeleteModalShow(false);
            dispatch({
                type: RESET_SERVICE
            })
            toast.success("Service Deleted Successfully");
        }

        if(categoryError){
            dispatch({
                type: RESET_SER_CATEGORY
            })
            toast.error(categoryError);
        }
    }, [error,serviceSuccess,deleteServicelisting, categoryError])


    useEffect(() => {
        dispatch(getServiceListings())
    }, [])

    const createService = () => {
        setHeaderColor('#D42F45');
        setHeaderTitle("ADD SERVICE");
        setModalShow(true);
    }
    const editService = (service) => {
        setselectedService(service);
        setHeaderColor('#FF9900');
        setHeaderTitle("EDIT SERVICE");
        setModalShowEdit(true);
    }

    const deleteService = (id) => {
        setselectedService(id);
        setHeaderColor('#D42F45');
        setHeaderTitle("DELETE SERVICE");
        setDeleteModalShow(true);
    }
    const deleteServiceById = () => {
        dispatch(deleteServiceListingById(selectedService))
    }
    const saveService =  (values) =>  {


        let cat = {
            _id: values.category._id,
            name: values.category.name,
            mainCategory: values.category.mainCategory,
            mainCategoryID: values.category.mainCategoryID
        }
        let work = values.working_days.map(work => work.label)

        let details = {
            provider_name : values.provider_name,   
            category: cat,
            city: values.city,
            phone_number: values.phone_number,
            working_days: work,
            working_hrs: {
                from: values.working_hrs_from,
                to: values.working_hrs_to
            }
        }

        dispatch(addNewService(details));
       
    }
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


    const handleMainCategory = (value, setFieldName) => {
        console.log(value)
        setFieldName('mainCategory', value)
        dispatch(getSubCategoriesUnderMainCat(value.id))
    }


    return (
        <Fragment>
            
            <Card style={{ borderRadius: 20, margin: 10, minHeight: '70vh' }}>
                <Card.Body>
                    <center><h4>Coming Soon</h4></center>
                </Card.Body>
                {/*<IoMdAddCircle class="add" fontSize="3.5em" 
                onClick={createService}
                style={{ position: 'absolute', top: -30, right: -20, cursor: 'pointer' }} />
                <Card.Body style={{ overflowX: 'auto' }}>
                    <table>
                        <thead class="tableHeader">
                            <tr>
                                <th>Name</th>
                                <th>Category Name</th>
                                <th>City</th>
                                <th>Phone Number</th>
                                <th>Working Days</th>
                                <th>Working Hours</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {datas && datas.map((service, index) => (
                                <tr key={index}>
                                    <td class="tableContent">{service.provider_name}</td>
                                    <td class="tableContent">{service.category.name}</td>
                                    <td class="tableContent">{service.city}</td>
                                    <td class="tableContent">{service.phone_number}</td>
                                    <td class="tableContent">
                                        <div  style={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent:'center' }}>
                                            {service.working_days.map((work, index) => (
                                                <span key={index} style={{ backgroundColor: '#417AE8', borderRadius: 10, padding: 5, color: '#fff', margin: 5 }}>{work.substring(0,3)}</span>
                                            ))}
                                        </div>
                                    </td>
                                    <td class="tableContent">{ `${moment(service.working_hrs.from,"HH:mm").format("hh:mm A")} - ${moment(service.working_hrs.to,"HH:mm").format("hh:mm A")}` }</td>
                                    <td class="tableContent">
                                        <MdEdit color="orange" onClick={() => editService(service)}/>
                                        <AiFillDelete class="add" onClick={() => deleteService(service._id)}/>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Card.Body>
                <Col style={{ float: 'right', display:'flex', flexDirection: 'row-reverse', justifyContent: 'flex-start', alignItems: 'center', margin: 30  }}>
                <Card >
                    {serviceListing && serviceListing.length>10 && <Paging datas={serviceListing} currentPage={currentPage} pageChange={pageChanged} />}
                </Card>
                                            </Col>*/}
            </Card>
            {modalShowEdit && <EditServiceListing openModal={modalShowEdit} closeModal={()=> setModalShowEdit(false)} headerColor={headerColor} headerTitle={headerTitle} servicelist={selectedService}  />}
           
            <Modals showModal={deleteModalShow} hideModal={()=> setDeleteModalShow(false)} modalTitle={headerTitle} headerColor={headerColor}>
                <p className="deleteText" >Are you sure you want to delete this Service Listing?</p>
                <div style={{ display: 'flex', alignItems:'center', justifyContent:'center' }}>
                        <Buttons backColor="#4AAD47" buttonText="YES" buttonAction={deleteServiceById}>
                            <BsCheck2 style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                        <Buttons backColor="#D42F45" buttonText="No" buttonAction={()=> setDeleteModalShow(false)}>
                            <FaTimes style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                </div>
            </Modals>
            <Modals  showModal={modalShow} hideModal={()=> setModalShow(false)} headerColor={headerColor} modalTitle={headerTitle} modalSize="lg">
            <Formik 
                initialValues={{
                    mainCategory: '',
                    provider_name: '',
                    category: '',
                    phone_number: '',
                    city: '',
                    working_days: '',
                    working_hrs_from: '',
                    working_hrs_to: ''
                }}
                validationSchema={
                    
                    Yup.object({
                        provider_name: Yup.string().max(8, 'Must be 8 characters or less').required('Name Required'),
                        category: Yup.object().required('Category Required'),
                        phone_number: Yup.string().phone("IN").required('Phone Number is Required'),
                        city: Yup.string().required("City is Required"),
                        working_days: Yup.array().required("Working Days is required"),
                        working_hrs_from: Yup.string().required('Working hours Start time is Required'),
                        working_hrs_to: Yup.string().required('Working hours end time is Required'),
                    })
                }
                onSubmit={(values) => {
                    //console.log(values)
                    saveService(values)
                }}
                render={({handleChange, handleSubmit, handleBlur, setFieldValue, setFieldTouched, touched, values, errors}) => (
            <Form>      
                <Row>
                    <InputField fieldName="provider_name" fieldLabel="Service Provider Name" />
                    <SelectNew 
                        fieldName="mainCategory"
                        fieldLabel="Main Category"
                        options={mainCategories}
                        isMultiple={false}
                        value={values.mainCategory}
                        onChange={(fieldName, value) => handleMainCategory(value, setFieldValue) }
                        onBlur={setFieldTouched}
                        error={errors.mainCategory}
                        touched={touched.mainCategory}
                    />
                    <SelectNew 
                        fieldName="category"
                        fieldLabel="Category"
                        options={subServices}
                        isMultiple={false}
                        value={values.category}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        error={errors.category}
                        touched={touched.category}
                    />
                    <InputField fieldName="city" fieldLabel="City" fieldType="text" />
                    <InputField fieldName="phone_number" fieldLabel="Phone Number" fieldType="text" />
                    <SelectNew 
                        md="4"
                        fieldName="working_days"
                        fieldLabel="Working Days"
                        options={days}
                        isMultiple={true}
                        value={values.working_days}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        error={errors.working_days}
                        touched={touched.working_days}
                    />
                    <p className="formLabel">Working Hours</p>
                    <InputField fieldName="working_hrs_from" fieldLabel="From Time" fieldType="time" />
                    <InputField fieldName="working_hrs_to" fieldLabel="To Time" fieldType="time" />
                </Row>
                {headerTitle === 'ADD SERVICE' && <Row>
                    <center>
                        <Buttons backColor="#D42F45" buttonText="ADD" buttonAction={handleSubmit}>
                            <IoMdAdd style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                    </center>
                </Row>}
                {headerTitle === 'EDIT SERVICE' && <Row>
                    <center>
                        <Buttons backColor="#FF9900" buttonText="UPDATE" buttonAction={()=> console.log("pressed")}>
                                <MdUpdate style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                    </center>
                </Row>}
            </Form>
            )}
            />
        </Modals>
        
        </Fragment>
    )
}

export default ServiceListing
