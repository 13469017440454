import React from 'react'
import {
    Chart,
    Geom,
    Axis,
    Tooltip,
    Coord,
  } from 'bizcharts';
import DataSet from '@antv/data-set';
import { Card } from 'react-bootstrap';

const Graph = () => {

    const data = [
        {
          profession: 'Groceries',
          highest: 10,
          minimum: 6,
          mean: 8,
        },
        {
          profession: 'Food',
          highest: 12,
          minimum: 1,
          mean: 66625,
        },
        {
          profession: 'Stationary',
          highest: 12,
          minimum: 2,
          mean: 72536,
        },
        {
          profession: 'Beverages',
          highest: 13,
          minimum: 2,
          mean: 75256,
        }
    ];

    const ds = new DataSet();
    const dv = ds.createView().source(data);
    dv.transform({
      type: 'map',

      callback(row) {
        // 加工数据后返回新的一行，默认返回行数据本身
        row.range = [row.minimum, row.highest];
        return row;
      },
    });

    return (
        <Card style={{ borderRadius:20, marginTop: 20 }}>
            <Card.Body style={{ display:'flex', flexDirection: 'column' }}>
                <span className="torder">Todays Orders</span>
                <span className="torder" style={{ fontSize: 20 }}>1322</span>
                <Chart  
                    height={250} 
                    data={dv} 
                    padding={[20, 50, 50, 60]} 
                    minimum={9}
                    autoFit>
                    <Coord transpose />
                    <Axis
                        name="profession"
                        label={{
                            offset: 12,
                        }}
                    />
                    <Axis name="range" />
                    <Tooltip />
                    <Geom type="interval" position="profession*range" />
                </Chart>
            </Card.Body>
        </Card>
    )
}

export default Graph
