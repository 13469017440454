export const NEW_COUPON_SUCCESS = 'NEW_COUPON_SUCCESS'
export const NEW_COUPON_FAIL = 'NEW_COUPON_FAIL'

export const RESET_ERROR = 'RESET_ERROR'

export const COUPON_LIST_SUCCESS = 'COUPON_LIST_SUCCESS';
export const COUPON_LIST_FAIL = 'COUPON_LIST_FAIL';

export const UPDATE_COUPON_SUCCESS = 'UPDATE_COUPON_SUCCESS'
export const UPDATE_COUPON_FAIL = 'UPDATE_COUPON_FAIL'

export const DELETE_COUPON_SUCCESS = 'DELETE_COUPON_SUCCESS'
export const DELETE_COUPON_FAIL = 'DELETE_COUPON_FAIL'