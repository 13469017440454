import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import Modals from '../../components/Modals'
import {IoMdAdd}from 'react-icons/io'
import Buttons from '../../components/Buttons'
import { useSelector, useDispatch } from 'react-redux'
import {Formik, Field} from 'formik';
import { toast } from 'react-toastify'
import InputField from '../../components/InputField'
import FormSelectField from '../../components/form-select-field'
import * as Yup from 'yup';
import "yup-phone";
import { saveNewNotification ,getNotification} from '../../Redux/actions/notificationActions'

import { RESET_NOTIFICATION } from '../../Redux/constants/notificationConstants'


const AddSmsNotification = ({openModal, closeModal, headerColor, modalTitle}) => {
    const { newNotification,error } = useSelector(state => state.notification)
    const dispatch = useDispatch();

    useEffect(() => {
        if(error){
            toast.error(error);
            dispatch({
                type: RESET_NOTIFICATION
            })
        }

        if(newNotification){
            dispatch(getNotification("sms"))
            closeModal(true)
            toast.success("New Sms Notification Added Successfully");
            dispatch({
                type: RESET_NOTIFICATION
            })
        }
        
    }, [error, newNotification])


   



    const saveNotification =  (values) =>  {


        console.log({values})
        var form_data = new FormData();
        form_data.append("title", values.title);
        form_data.append("screen", values.screen);
        form_data.append("description", values.description);
        form_data.append("type", values.type);
        
        

        console.log(form_data);

        dispatch(saveNewNotification(form_data))
        
    }


    return (
        <Modals showModal={openModal} hideModal={closeModal} headerColor={headerColor} modalTitle={modalTitle} >
            <Formik 
                initialValues={{
                    title: '',
                    screen: '',
                    description: '',
                    type:'sms'
                    
                }}
                validationSchema={
                    
                    Yup.object({
                        title: Yup.string().required('Name Required'),
                        screen: Yup.string().required('Screen is Required'),
                        description: Yup.string().required('Description is Required'),
                        
                        
                    })
                }
                onSubmit={(values) => {
                    console.log(values)
                    saveNotification(values)
                }}
                render={({handleChange, handleSubmit, handleBlur, values, errors}) => (
                    <Form>  
            <Row className="mb-3">
                    <InputField fieldName="title" fieldLabel="Title" fieldType="text" md="6"/>
                    <InputField fieldName="screen" fieldLabel="Screen" fieldType="text" md="6"/>
                    <InputField fieldName="description" fieldLabel="Notification Description" fieldType="text" md="12"/>
                   
                
                
            </Row>
            <Row>
                <center>
                    <Buttons backColor="#D42F45" buttonText="ADD" buttonAction={handleSubmit}>
                        <IoMdAdd style={{color:'#FFFFFF'}} fontSize="2.3em" />
                    </Buttons>
                </center>
            </Row>
            </Form>
            )}
            />
        </Modals>
    )
}

export default AddSmsNotification
