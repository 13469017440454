import React from 'react'
import Header from '../../components/Header'
import Layout from '../layout'
import OrderList from './OrderList'

const Surprise = () => {
    return (
        <Layout>
            <Header headerText={"SURPRISE ORDERS"} />
            <OrderList />
        </Layout>
    )
}

export default Surprise
