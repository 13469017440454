export const DELETE_UNIT_SUCCESS = 'DELETE_UNIT_SUCCESS';
export const DELETE_UNIT_FAIL = 'DELETE_UNIT_FAIL';

export const UPDATE_UNIT_SUCCESS = 'UPDATE_UNIT_SUCCESS';
export const UPDATE_UNIT_FAIL = 'UPDATE_UNIT_FAIL';

export const NEW_UNIT_SUCCESS = 'NEW_UNIT_SUCCESS';
export const NEW_UNIT_FAIL = 'NEW_UNIT_FAIL';

export const UNIT_LIST_SUCCESS = 'UNIT_LIST_SUCCESS';
export const UNIT_LIST_FAIL = 'UNIT_LIST_FAIL';

export const RESET_UNIT = 'RESET_UNIT';