import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import Reactotron from '../ReactotronConfig'


//import reducers

import { authReducer } from './reducers/userReducer'
import { storeReducer, productReducer, categoryReducer } from './reducers/storeReducer'
import { franchiseeReducer, deliverychargeReducer } from './reducers/franchiseeReducer'
import { orderReducer } from './reducers/orderReducer'
import { servicelistingReducer } from './reducers/servicelistingReducer'
import { subscriptionReducer } from './reducers/subscriptionReducer'
import { userReducer } from './reducers/userReducer'
import { costReducer, sliderReducer } from './reducers/sliderReducer'
import { timeReducer } from './reducers/timeReducer'
import { servicecategoryReducer } from './reducers/servicecategoryReducer'
import { customerReducer } from './reducers/customerReducer'
import { storepayoutReducer, franchiseepayoutReducer, deliverypayoutReducer, adminpayoutReducer} from './reducers/payoutReducer'
import { storereviewReducer, productreviewReducer, deliveryboyreviewReducer} from './reducers/reviewReducer'
import { notificationReducer } from './reducers/notificationReducer'
import { surpriseReducer } from './reducers/surpriseReducer'
import {couponReducer} from './reducers/couponReducer'
import { unitReducer } from './reducers/unitReducer'

const appReducer = combineReducers({
    auth: authReducer,
    store : storeReducer,
    user : userReducer,
    franchisee : franchiseeReducer,
    deliverycharge : deliverychargeReducer,
    customer : customerReducer,
    order : orderReducer,
    servicelisting : servicelistingReducer,
    product: productReducer,
    category: categoryReducer,
    subscription : subscriptionReducer,
    slider : sliderReducer,
    cost:costReducer,
    time : timeReducer,
    servicecategory : servicecategoryReducer,
    storepayout:storepayoutReducer,
    franchiseepayout:franchiseepayoutReducer,
    deliverypayout:deliverypayoutReducer,
    adminpayout:adminpayoutReducer,
    storereview:storereviewReducer,
    productreview:productreviewReducer,
    deliveryboyreview:deliveryboyreviewReducer,
    notification : notificationReducer,
    surprise: surpriseReducer,
    coupon:couponReducer,
    unit:unitReducer
})

const reducer = (state, action) => {
    if (action.type === 'RESET_USER') {
        return appReducer(undefined, action)
    }

    return appReducer(state, action)
}




const middleware = [ thunk ];

const store = createStore(reducer, compose(applyMiddleware(...middleware), Reactotron.createEnhancer()));

export default store; 