import React, { useState, useEffect } from 'react'
import { Row, Form, Col, InputGroup, FormGroup, FormLabel } from 'react-bootstrap'
import Modals from '../../components/Modals'
import {
    MdFoodBank,MdUpdate
} from 'react-icons/md'
import {
    IoMdAdd
} from 'react-icons/io'
import {GiShop} from 'react-icons/gi'
import {AiTwotoneShop} from 'react-icons/ai'
import Buttons from '../../components/Buttons'
import './style.scss'
import { Field, ErrorMessage,Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import InputField from '../../components/InputField'
import FormSelectField from '../../components/form-select-field'
import * as Yup from 'yup';
import "yup-phone";
import { saveNewCoupon } from '../../Redux/actions/couponActions'
import { RESET_ERROR } from '../../Redux/constants/couponConstants'
import { getStoreLists } from '../../Redux/actions/storeActions'
import { getCategoryLists } from '../../Redux/actions/categoryActions'

const CouponForm = ({openModal, closeModal, headerColor, headerTitle}) => {
    const { newCoupon,error } = useSelector(state => state.coupon)
    const dispatch = useDispatch();
    const { storeList } = useSelector(state => state.store)
    const { categoryLists } = useSelector(state => state.category)
    useEffect(() => {
        if(error){
            toast.error(error);
            dispatch({
                type: RESET_ERROR
            })
        }

        if(newCoupon){
            closeModal(true)
            toast.success("Category Added Successfully");
            dispatch({
                type: RESET_ERROR
            })
        }
        
    }, [error, newCoupon])

    useEffect(() => {
        dispatch(getStoreLists())
        
    }, [])
    useEffect(() => {
        dispatch(getCategoryLists())
    }, [])

    const saveCoupon = async (values) =>  {
        
        console.log({values})

        const { title, description,code,limitation,exp_date,stores,category,product } = values
        dispatch(saveNewCoupon(title, description,code,limitation,exp_date,stores,category,product));
       
       
    }

    return (
        <Modals showModal={openModal} hideModal={closeModal} headerColor={headerColor} modalTitle={headerTitle}>
            <Formik 
                initialValues={{
                    title: '',
                    description:'',
                    code:'',
                    limitation: '',
                    exp_date: '',
                    stores:[],
                    category:[],
                    product:[],
                }}
                validationSchema={
                    
                    Yup.object({
                        title: Yup.string().required('Title is Required'),
                        description: Yup.string().required('Description is Required'),
                    })
                }
                onSubmit={(values) => {
                    console.log(values)
                    saveCoupon(values)
                }}
                render={({handleChange, handleSubmit, handleBlur, values, errors}) => (
                    <Form>
            <Row className="mb-3">
            <InputField fieldName="title" fieldLabel="Coupon Title" md="4" />
            <InputField fieldName="description" fieldLabel="Coupon Description" md="8" />
            <InputField fieldName="code" fieldLabel="Coupon Code" md="4" />
            <InputField fieldName="limitation" fieldLabel="Coupon Limitation" md="4" />
            <InputField fieldName="exp_date" fieldLabel="Expiry Date" md="4" />
                
            <span class="applicable">APPLICABLE FIELDS</span>   
                
            <div className="inputareaCoupon">
            <InputGroup className="mb-2">
                        
                      
                        <FormSelectField
                                        md="12"
                                        label="Store"
                                        type="text"
                                        name="stores"
                                        multiple={true}

                                    >   
                                        {storeList && storeList.map((store, index) => (
                                            <option key={index} value={store.name}>{store.name}</option>
                                        ))}
                        </FormSelectField>
                        
                        <GiShop class="shop" fontSize="2.5em" style={{ position: 'absolute', top: -30, right: -20, cursor: 'pointer' }} />
            </InputGroup>
                    </div>

                    <div className="inputareaCoupon">
                    <InputGroup className="mb-2">
                        
                       <FormSelectField
                                        md="12"
                                        type="text"
                                        name="category"
                                        multiple={true}
                                        label="Category"
                                    >   
                                        {categoryLists && categoryLists.map((category, index) => (
                                            <option key={index} value={category.name}>{category.name}</option>
                                        ))}
                        </FormSelectField>
                        
                        <AiTwotoneShop class="shop" fontSize="2.5em" style={{ position: 'absolute', top: -30, right: -20, cursor: 'pointer' }} />
                        
                        </InputGroup>
                        
                    </div>
                
            

                    <div className="inputareaCoupon">        
                    <InputGroup className="mb-2">
                        
                    
                        <FormSelectField
                                        md="12"
                                        type="text"
                                        name="product"
                                        multiple={true}
                                        label="Product"
                                    >   
                                        
                                            <option value="product" >Product 1</option>
                                        
                        </FormSelectField>
                        <MdFoodBank class="shop" fontSize="2.5em" style={{ position: 'absolute', top: -30, right: -20, cursor: 'pointer' }} />
                     
                        </InputGroup>
                        
                        
                   
                    </div>
                
                
                {/* <Form.Group as={Col} xs="12" sm="12" md="6" lg="4" xl="4" controlId="formGridAddress" className="formStyle">
                    <Form.Label className="formLabel">Category</Form.Label>
                    <InputGroup className="mb-2">
                        <Form.Control className="inputareaCoupon"   />
                        <AiTwotoneShop class="shop" fontSize="2.5em" style={{ position: 'absolute', top: -30, right: -20, cursor: 'pointer' }} />
                    </InputGroup>
                </Form.Group>
                <Form.Group as={Col} xs="12" sm="12" md="6" lg="4" xl="4" controlId="formGridAddress" className="formStyle">
                    <Form.Label className="formLabel">Product</Form.Label>
                    <InputGroup className="mb-2">
                        <Form.Control className="inputareaCoupon"   />
                        <MdFoodBank class="shop" fontSize="2.5em" style={{ position: 'absolute', top: -30, right: -20, cursor: 'pointer' }} />
                    </InputGroup>
                </Form.Group> */}
                
            </Row>
            {headerTitle === 'ADD COUPON' && <Row>
                <center>
                    <Buttons backColor="#D42F45" buttonText="ADD" buttonAction={handleSubmit}>
                        <IoMdAdd style={{color:'#FFFFFF'}} fontSize="2.3em" />
                    </Buttons>
                </center>
            </Row>}
            
            </Form>
                )}
            />
        </Modals>
    )
}

export default CouponForm
