import React, { Fragment, useState } from 'react'
import Header from '../../components/Header'
import Layout from '../layout'
import UserList from './UserList'




const User = () => {

    

    return (
        
        <Layout>
            <Header headerText="USER MANAGEMENT" />
            <UserList />
            
        </Layout>
       
    )
}

export default User
