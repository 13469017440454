import React from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import Modals from '../../components/Modals'
import InputGroup from 'react-bootstrap/InputGroup'
import {MdDeliveryDining,MdOutlineUpdate} from 'react-icons/md'
import {RiSecurePaymentLine} from 'react-icons/ri'
import {IoMdAdd}from 'react-icons/io'
import Modal from 'react-bootstrap/Modal'
import CloseButton from 'react-bootstrap/CloseButton'

const EditOrder = ({openModal, closeModal ,headerColor}) => {
    return (
        <Modals showModal={openModal} hideModal={closeModal} headerColor={headerColor} modalTitle="EDIT ORDER">
             
            <Row className="mb-3">
                <Form.Group controlId="formGridName" as={Col}  xs="12" sm="12" md="6" lg="4" xl="4" className="formStyle">
                    <Form.Label className="formLabel">Customer Name</Form.Label>
                    <Form.Control className="inputStyle" type="email"  />
                </Form.Group>
                <Form.Group as={Col} xs="12" sm="12" md="6" lg="8" xl="8" controlId="formGridDetails" className="formStyle">
                    <Form.Label className="formLabel" >Order Details</Form.Label>
                    <Form.Control type="text" className="inputareaStyle" />
                </Form.Group>
                {/* <Form.Group controlId="formGridCity" as={Col}  xs="12" sm="12" md="6" lg="4" xl="4" className="formStyle">
                    <Form.Label className="formLabel">Location</Form.Label>
                    <Form.Control type="text" className="inputStyle" />
                </Form.Group> */}
                
                <Form.Group as={Col} xs="12" sm="12" md="6" lg="4" xl="4" controlId="formGridAddress" className="formStyle">
                    <Form.Label className="formLabel">Date & Time</Form.Label>
                    <Form.Control type="text" className="inputStyle" />
                </Form.Group>
                <Form.Group as={Col} xs="12" sm="12" md="6" lg="4" xl="4" controlId="formGridAddress" className="formStyle">
                    <Form.Label className="formLabel">Total Price</Form.Label>
                    <Form.Control type="text" className="inputStyle" />
                </Form.Group>
                
                
                <Form.Group as={Col} xs="12" sm="12" md="6" lg="4" xl="4" controlId="formGridFranchiseeName" className="formStyle">
                    <Form.Label className="formLabel">Delivery Status</Form.Label>
                    <Form.Select className="inputStyle" >
                        <option></option>
                        <option></option>
                    </Form.Select>
                </Form.Group>
                <Form.Group as={Col} xs="12" sm="12" md="6" lg="4" xl="4" controlId="formGridFranchiseeName" className="formStyle">
                    <Form.Label className="formLabel">Delivery Boy</Form.Label>
                    <Form.Select className="inputStyle" >
                        <option></option>
                        <option></option>
                    </Form.Select>
                </Form.Group>
                <Form.Group as={Col} xs="12" sm="12" md="6" lg="4" xl="4" controlId="formGridAddress" className="formStyle">
                    <Form.Label className="formLabel" >Tax</Form.Label>
                    <InputGroup className="mb-2">
                        <Form.Control className="workStyle"   />
                        <InputGroup.Text style={{color:'#1B2F5B',backgroundColor:'#FFFFFF',borderColor:'#B4CBF7' , borderTopRightRadius: 10, borderBottomRightRadius: 10}}>
                            <MdDeliveryDining fontSize="1.5em" color="orange"/>
                        </InputGroup.Text>
                    </InputGroup>
                </Form.Group>
                <Form.Group as={Col} xs="12" sm="12" md="6" lg="4" xl="4" controlId="formGridAddress" className="formStyle">
                    <Form.Label className="formLabel">Payment Method</Form.Label>
                    <InputGroup className="mb-2">
                        <Form.Control className="workStyle"   />
                        <InputGroup.Text style={{color:'#1B2F5B',backgroundColor:'#FFFFFF',borderColor:'#B4CBF7' , borderTopRightRadius: 10, borderBottomRightRadius: 10}}>
                            <RiSecurePaymentLine fontSize="1.5em" color="orange"/>
                        </InputGroup.Text>
                    </InputGroup>
                </Form.Group>
            </Row>
            <Row>
                <center>
                    <div style={{ display:'flex', flexDirection:'row', backgroundColor:'#FF9900', width: 120, height: 40, justifyContent:'center', alignItems:'center' }}>
                        <MdOutlineUpdate style={{color:'#FFFFFF',backgroundColor:'#FF9900'}} fontSize="2.5em" />
                        <Button style={{color:'#1B2F5B',backgroundColor:'#FFFFFF' ,fontFamily:'Avenir-Black, Arial, serif',border:'0', height: 35, marginRight: 5}}>
                        UPDATE
                        </Button>
                    </div>
                </center>
            </Row>
      
        </Modals>
    )
}

export default EditOrder
