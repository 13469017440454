import React, { Fragment, useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import {MdEdit} from 'react-icons/md'
import {AiFillDelete,AiFillEye} from 'react-icons/ai'
import {BsToggle2On,BsToggle2Off, BsCheck2} from 'react-icons/bs'
import { MdCheck, MdClose } from 'react-icons/md'
import {IoMdAddCircle,IoMdAdd}from 'react-icons/io'
import { FaTimes } from 'react-icons/fa'
import Paging from '../../components/Paging'
import Modals from '../../components/Modals'
import Buttons from '../../components/Buttons'
import CouponForm from './CouponForm'
import { toast } from 'react-toastify'
import { RESET_ERROR } from '../../Redux/constants/couponConstants'
import { useSelector, useDispatch } from 'react-redux'
import { getCoupon,deleteCouponById } from '../../Redux/actions/couponActions'

import ViewCoupon from './ViewCoupon'
import EditCoupon from './EditCoupon'

 

const CouponList = () => {

    
    const [modalShow, setModalShow] = useState(false);
    const [viewModalShow, setViewModalShow] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [modalShowEdit, setModalShowEdit] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [datas, setDatas] = useState([])
    const [headerColor, setHeaderColor] = useState('')
    const [headerTitle, setHeaderTitle] = useState('')
    const dispatch = useDispatch();
    const [selected, setSelected] = useState(null)

    const {couponLists,deleteSuccess,error} = useSelector(state => state.coupon)



    useEffect(() => {
        if(couponLists){
            const indexOfLastPost = currentPage * 10;
            const indexOfFirstPost = indexOfLastPost - 10;
            const currentPosts = (couponLists || []).slice(indexOfFirstPost, indexOfLastPost);
            setDatas(currentPosts)
        }
        
    }, [couponLists])

    const pageChanged = (page)=> {
        setCurrentPage(page)
        const indexOfLastPost = page * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const currentPosts = (couponLists || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }
    useEffect(() => {
        if(error){
            toast.error(error);
            dispatch({
                type: RESET_ERROR
            })
        }

        
        if(deleteSuccess){
            
            setDeleteModalShow(false);
            dispatch({
                type: RESET_ERROR
            })
            toast.success("Coupon Deleted Successfully");
        }
    }, [error,deleteSuccess])
    
    useEffect(() => {
        dispatch(getCoupon())
    }, [])


    const deleteById = () => {
        dispatch(deleteCouponById(selected))
    }

    const createNewCoupon = () => {
        setHeaderColor('#D42F45');
        setHeaderTitle("ADD COUPON");
        setModalShow(true);
    }

    const viewCouponDetails = (coupon) => {
        setSelected(coupon);
        setHeaderColor('#417AE8');
        setHeaderTitle("VIEW COUPON");
        setViewModalShow(true);
    }

    const deleteCoupon = (id) => {
        setSelected(id);
        setHeaderColor('#D42F45');
        setHeaderTitle("DELETE COUPON");
        setDeleteModalShow(true);
    }

    const editCoupon = (coupon) => {
        setSelected(coupon);
        setHeaderColor('#FF9900');
        setHeaderTitle("EDIT COUPON");
        setModalShowEdit(true);
    }
    
    

    return (
        <Fragment>
            <br/>
             <Card style={{ borderRadius: 20, margin: 10, minHeight: '70vh' }}>
                <IoMdAddCircle className="add" fontSize="3.5em" 
                onClick={createNewCoupon}
                style={{ position: 'absolute', top: -30, right: -20, cursor: 'pointer' }} />
                <Card.Body style={{ overflowX: 'auto' }}>
                    <table>
                        <thead className="tableHeader">
                            <tr>
                                <th>ID</th>
                                <th>Title</th>
                                <th>Coupon Code</th>
                                <th>Applicable Field</th>
                                <th>Limitation</th>
                                <th>Expiry Date</th>
                                <th>Approval</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {datas && datas.map((coupon, index) => (
                                <tr key={index}>
                                    <td className="tableContent">{coupon._id}</td>
                                    <td className="tableContent">{coupon.title}</td>
                                    <td className="tableContent">{coupon.code}</td>
                                    <td className="tableContent">{coupon.stores}</td>
                                    <td className="tableContent">{coupon.limitation}</td>
                                    <td className="tableContent">{coupon.exp_date}</td>
                                    <td className="tableContent">
                                        {coupon.status === 'Approved' ? <span className="approved">APPROVED</span> : coupon.status === 'Rejected' ? <span className="rejected">REJECTED</span> : (<div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'center' }}>
                                            <MdCheck fontSize="1.5em" style={{ color: '#4AAD47', margin: 5 }} /> 
                                            <MdClose fontSize="1.5em" style={{ color: '#D42F45', margin: 5 }} />
                                        </div>)}
                                    </td>
                                    <td className="tableContent">
                                        <AiFillEye color="blue" onClick={()=>viewCouponDetails(coupon)} />
                                        <MdEdit color="orange" onClick={()=>editCoupon(coupon)}/>
                                        <AiFillDelete className="add" onClick={() => deleteCoupon(coupon._id)}/>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Card.Body>
                <Col style={{ float: 'right', display:'flex', flexDirection: 'row-reverse', justifyContent: 'flex-start', alignItems: 'center', margin: 30  }}>
                <Card >
                    {couponLists && couponLists.length>10 && <Paging datas={couponLists} currentPage={currentPage} pageChange={pageChanged} />}
                </Card>
                </Col>
            </Card>
            {modalShow && <CouponForm openModal={modalShow} closeModal={()=> setModalShow(false)} headerColor={headerColor} headerTitle={headerTitle} />}  
            {viewModalShow && <ViewCoupon openModal={viewModalShow} closeModal={()=> setViewModalShow(false)} headerColor={headerColor} headerTitle={headerTitle} coupons={selected}  />}
            {modalShowEdit && <EditCoupon openModal={modalShowEdit} closeModal={()=> setModalShowEdit(false)} headerColor={headerColor} headerTitle={headerTitle} coupons={selected}  />}
           
            <Modals showModal={deleteModalShow} hideModal={()=> setDeleteModalShow(false)} modalTitle={headerTitle} headerColor={headerColor}>
                <p className="deleteText">Are you sure you want to delete this coupon?</p>
                <div style={{ display: 'flex', alignItems:'center', justifyContent:'center' }}>
                        <Buttons backColor="#4AAD47" buttonText="YES" buttonAction={deleteById}>
                            <BsCheck2 style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                        <Buttons backColor="#D42F45" buttonText="No" buttonAction={()=> console.log("Hello")}>
                            <FaTimes style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>  
                </div>
            </Modals>
        </Fragment>
    )
}

export default CouponList
