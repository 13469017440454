import { Form, Formik } from 'formik'
import React, { useState, useEffect } from 'react'
import { Row } from 'react-bootstrap'
import ImageInput from '../../components/ImageInput'
import InputField from '../../components/InputField'
import * as Yup from 'yup';
import SelectInput from '../../components/SelectInput'
import Buttons from '../../components/Buttons'
import { IoMdAdd } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { getUnits } from '../../Redux/actions/unitActions'
import {  updateUnit } from '../../Redux/actions/unitActions'
import { toast } from 'react-toastify'
import { GiBackwardTime } from 'react-icons/gi'

const EditUnit = ({unit}) => {

    const [image, setImage] = useState('')
    const { categoryLists } = useSelector(state => state.category)
    const [initialDatas, setInitialDatas ] = useState(null)


    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUnits())
    }, [])

   


    const editUnit = async(values) => {
        const vari = values.varients.split(',');

        let formData = new FormData();
        formData.append("id", values.id);
        formData.append("varients", JSON.stringify(vari));
        formData.append("unit", values.unit);
        
        dispatch(updateUnit(formData))
    }

    return (
        <Formik 
            initialValues={{
                id:unit._id,
                unit: unit.unit,
                varients : unit.varients
            }}
            validationSchema={
                Yup.object({
                    unit: Yup.string().required('Unit Required'),
                    varients: Yup.string().required('Varients Required'),
                })
            }
            onSubmit={(values) => {
                editUnit(values)
            }}
            render={({handleChange, handleSubmit, handleBlur, values, errors}) => (
                <Form>
                    <Row>
                        <InputField fieldName="unit" fieldLabel="Unit Name" md="6" />
                    
                        <InputField fieldName="varients" fieldLabel="Unit Variables" md="6" />
                    </Row>
                    <Row>
                        <center>
                            <Buttons backColor="#FF9900" buttonText="UPDATE" buttonAction={handleSubmit}>
                                <GiBackwardTime style={{color:'#FFFFFF'}} fontSize="2.3em" />
                            </Buttons>
                        </center>
                    </Row>
                </Form>
            )}
        />
    )
}

export default EditUnit
