export const STORE_REVIEW_SUCCESS = 'STORE_REVIEW_SUCCESS';
export const STORE_REVIEW_FAIL = 'STORE_REVIEW_FAIL';
export const RESET_REVIEW = 'RESET_REVIEW';
export const PRODUCT_REVIEW_FAIL = 'PRODUCT_REVIEW_FAIL';
export const PRODUCT_REVIEW_SUCCESS = 'PRODUCT_REVIEW_SUCCESS';
export const DELIVERYBOY_REVIEW_FAIL = 'DELIVERYBOY_REVIEW_FAIL';
export const DELIVERYBOY_REVIEW_SUCCESS = 'DELIVERYBOY_REVIEW_SUCCESS';
export const DELETE_STORE_REVIEW_SUCCESS = 'DELETE_STORE_REVIEW_SUCCESS';
export const DELETE_STORE_REVIEW_FAIL = 'DELETE_STORE_REVIEW_FAIL';
export const DELETE_PRODUCT_REVIEW_SUCCESS = 'DELETE_PRODUCT_REVIEW_SUCCESS';
export const DELETE_PRODUCT_REVIEW_FAIL = 'DELETE_PRODUCT_REVIEW_FAIL';
export const DELETE_DELIVERYBOY_REVIEW_SUCCESS = 'DELETE_DELIVERYBOY_REVIEW_SUCCESS';
export const DELETE_DELIVERYBOY_REVIEW_FAIL = 'DELETE_DELIVERYBOY_REVIEW_FAIL';
