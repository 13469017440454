import React, { useState, useEffect } from 'react'
import { Row, Form, Col, InputGroup } from 'react-bootstrap'
import Modals from '../../components/Modals'
import {
    MdUpload
} from 'react-icons/md'
import {
    IoMdAdd
} from 'react-icons/io'
import Buttons from '../../components/Buttons'
import { useSelector, useDispatch } from 'react-redux'
import { getCustomerDetails } from '../../Redux/actions/customerActions'
import { toast } from 'react-toastify'
import { RESET_STORE } from '../../Redux/constants/storeConstants'
import FranchiseeForm from '../Franchisee/FranchiseeForm'
import { RESET_ERROR } from '../../Redux/constants/franchiseeConstants'
import { getFranchisee } from '../../Redux/actions/franchiseeActions'
import {Formik, Field} from 'formik';
import InputField from '../../components/InputField'
import IconInput from '../../components/IconInput'
import FormSelectField from '../../components/form-select-field'
import MultiInput from '../../components/MultiInput'
import * as Yup from 'yup';
import "yup-phone";
import SwitchInput from '../../components/SwitchInput'
import moment from 'moment'

const CustomersForm = ({openModal, closeModal, headerColor, headerTitle,customerlist}) => {
    const [initialDatas, setInitialDatas] = useState(null)
    
    useEffect(() => {
        async function getDetails() {

            if(customerlist){
                const details = {
                    name: customerlist.name,
                    email: customerlist.email,
                    mobile: customerlist.mobile,
                    city: customerlist.city,
                    address: customerlist.address,
                    created_at:customerlist.created_at,
                    totalOrders: customerlist.totalOrders,
                    store_name: customerlist.store_name,
                    category_name: customerlist.category_name,
                   
                }
    
            
                console.log({details})
                setInitialDatas(details)
            }
        }

        getDetails();
    }, [customerlist])


    return (
        <Modals showModal={openModal} hideModal={closeModal} headerColor={headerColor} modalTitle={headerTitle}>
            {initialDatas && 
            <Formik 
                initialValues={initialDatas}
                render={({handleChange, handleSubmit, handleBlur, values, errors}) => (
                    <Form>
            <Row className="mb-3">
            <InputField fieldName="name" fieldLabel="Name" />
            <InputField fieldName="email" fieldLabel="Email Address" />
            <InputField fieldName="mobile" fieldLabel="Phone Number" />
            
                           
            <InputField fieldName="city" fieldLabel="City" md="4"/>
            <InputField fieldName="address" fieldLabel="Address" md="8"/>
            
            <InputField fieldName="totalOrders" fieldLabel="Total Orders" />
            <InputField fieldName="store_name" fieldLabel="Most Ordered (Store)" />
            <InputField fieldName="category_name" fieldLabel="Most Ordered (Category)" />
            
                <center>
                
                <Form.Group controlId="formGridName" as={Col}  xs="12" sm="12" md="4" lg="4" xl="4" className="formStyle">
                                <Form.Label  className="formLabel" >Registered Date</Form.Label>
                                <Field name="created_at">
                                    {({field, form, meta}) =>  <Form.Control 
                                        readOnly
                                        className="inputStyle"  
                                        value={ moment(field.value).format("DD-MM-YYYY hh:mm A") } 
                                        onChange={field.onChange} 
                                    /> }
                                </Field>
                </Form.Group>
                </center>
            
                
                
            </Row>
            </Form>
            )}
            />}
        </Modals>
    )
}

export default CustomersForm
