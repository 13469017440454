import React, { Fragment, useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import {MdEdit} from 'react-icons/md'
import {AiFillDelete,AiFillEye} from 'react-icons/ai'
import {BsToggle2On,BsToggle2Off, BsCheck2} from 'react-icons/bs'
import {IoMdAddCircle,IoMdAdd}from 'react-icons/io'
import { FaTimes } from 'react-icons/fa'
import Pagination from 'react-paginating';
import Search from '../../components/Search'
import Paging from '../../components/Paging'
import NewStore from './NewStore'
import Modals from '../../components/Modals'
import Buttons from '../../components/Buttons'
import { useSelector, useDispatch } from 'react-redux'
import { deleteStoresById, getStoreLists } from '../../Redux/actions/storeActions'
import { toast } from 'react-toastify'
import { RESET_STORE, STORE_INPUT } from '../../Redux/constants/storeConstants'
import moment from 'moment'
import reactotron from '../../ReactotronConfig'
import ViewStore from './ViewStore'
import { useRouteMatch, Link } from 'react-router-dom'



const StoreList = () => {

    const { storeList, error, storeSuccess, deleteStore } = useSelector(state => state.store)

    const dispatch = useDispatch();
    const match = useRouteMatch();

    const [modalShow, setModalShow] = useState(false);
    const [modalShowView, setModalShowView] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [datas, setDatas] = useState([])
    const [headerColor, setHeaderColor] = useState('')
    const [headerTitle, setHeaderTitle] = useState('')
    const [search, setSearch] = useState('')
    const [selectedStore, setSelectedStore] = useState('')

    useEffect(() => {
        if(storeList && storeList.length>0){
            const indexOfLastPost = currentPage * 10;
            const indexOfFirstPost = indexOfLastPost - 10;
            const searchResult = storeList.filter((stores) => stores.name.toLowerCase().includes(search.toLowerCase()) || stores.city.toLowerCase().includes(search.toLowerCase())
            )
            const currentPosts = (searchResult || []).slice(indexOfFirstPost, indexOfLastPost);
            setDatas(currentPosts)
        }
    }, [storeList, search])

    useEffect(() => {
        if(storeSuccess){
            setModalShow(false);
            dispatch({
                type: RESET_STORE
            })
            toast.success("New Store Added Successfully");
        }

        if(deleteStore){
            dispatch(getStoreLists())
            setDeleteModalShow(false);
            dispatch({
                type: RESET_STORE
            })
            toast.success("Store Deleted Successfully");
        }
    }, [storeSuccess, deleteStore])


    useEffect(() => {
        dispatch(getStoreLists())
    }, [])

    const pageChanged = (page)=> {
        setCurrentPage(page)
        const indexOfLastPost = page * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const searchResult = storeList.filter(stores => stores.name.toLowerCase().contains(search.toLowerCase()) || stores.city.toLowerCase().contains(search.toLowerCase()))
        const currentPosts = (searchResult || []).slice(indexOfFirstPost, indexOfLastPost);
        setDatas(currentPosts)
    }

    const createNewStore = () => {
        setHeaderColor('#D42F45');
        setHeaderTitle("ADD STORE");
        setModalShow(true);
    }

    const viewStoreDetails = (store) => {
        dispatch({
            type: STORE_INPUT,
            payload: {
                prop: 'storeDetails',
                payload: null
            }
        })
        setSelectedStore(store);
        setHeaderColor('#417AE8');
        setHeaderTitle("VIEW STORE");
        setModalShowView(true);
    }

    const deleteStores = (id) => {
        setSelectedStore(id);
        setHeaderColor('#D42F45');
        setHeaderTitle("DELETE STORE");
        setDeleteModalShow(true);
    }

    const deleteStoreById = () => {
        dispatch(deleteStoresById(selectedStore))
    }

    return (
        <Fragment>
            <Search placeHolder="SEARCH STORE/CITY" searchValue={search} searchChanged={e => setSearch(e.target.value)} />
            <Card style={{ borderRadius: 20, margin: 10 }}>
                <IoMdAddCircle className="add" fontSize="3.5em" 
                onClick={createNewStore}
                style={{ position: 'absolute', top: -30, right: -20, cursor: 'pointer' }} />
                <Card.Body style={{ overflowX: 'auto' }}>
                    <table>
                        <thead className="tableHeader">
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Address</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>City</th>
                                <th>Status</th>
                                <th>Created Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {datas && datas.map((store, index) => (
                                <tr key={index}>
                                    <td className="tableContent">{store._id}</td>
                                    <td className="tableContent">{store.name}</td>
                                    <td className="tableContent">{store.address}</td>
                                    <td className="tableContent">{store.email}</td>
                                    <td className="tableContent">{store.phone}</td>
                                    <td className="tableContent">{store.city}</td>
                                    <td className="tableContent"><BsToggle2On className="green" fontSize="1.5em"/></td>
                                    <td className="tableContent">{moment(store.created_at).format("DD-MM-YYYY hh:mm A")}</td>
                                    <td className="tableContent">
                                        <AiFillEye color="blue" style={{ cursor:'pointer' }} onClick={() => viewStoreDetails(store._id)} />
                                        <Link to={{
                                            pathname: `${match.path}edit`,
                                            state: {
                                                id: store._id,
                                                name: store.name
                                            }
                                        }} onClick={() => dispatch({
                                            type: STORE_INPUT,
                                            payload: {
                                                prop: 'storeDetails',
                                                payload: null
                                            }
                                        })}>
                                            <MdEdit color="orange" />
                                        </Link>
                                        <AiFillDelete className="add" onClick={() => deleteStores(store._id)}/>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Card.Body>
                <Col style={{ float: 'right', display:'flex', flexDirection: 'row-reverse', justifyContent: 'flex-start', alignItems: 'center', margin: 30  }}>
                <Card >
                    {storeList && storeList.length>10 && <Paging datas={storeList} currentPage={currentPage} pageChange={pageChanged} />}
                </Card>
                </Col>
            </Card>
            <NewStore openModal={modalShow} closeModal={()=> setModalShow(false)} headerColor={headerColor} headerTitle={headerTitle}  />
            {modalShowView && <ViewStore openModal={modalShowView} closeModal={()=> setModalShowView(false)} headerColor={headerColor} headerTitle={headerTitle} storeId={selectedStore}  />}
            <Modals showModal={deleteModalShow} hideModal={()=> setDeleteModalShow(false)} modalTitle={headerTitle} headerColor={headerColor}>
                <p className="deleteText">Are you sure you want to delete this store?</p>
                <div style={{ display: 'flex', alignItems:'center', justifyContent:'center' }}>
                        <Buttons backColor="#4AAD47" buttonText="YES" buttonAction={deleteStoreById}>
                            <BsCheck2 style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                        <Buttons backColor="#D42F45" buttonText="No" buttonAction={()=> setDeleteModalShow(false)}>
                            <FaTimes style={{color:'#FFFFFF'}} fontSize="2.3em" />
                        </Buttons>
                </div>
            </Modals>
        </Fragment>
    )
}

export default StoreList
