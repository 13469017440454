import React, { Fragment, useState } from 'react'
import { Card, Col,Row } from 'react-bootstrap'
import Header from '../../components/Header'
import Layout from '../layout'
import { Route, Link, useRouteMatch } from 'react-router-dom'
import StorePayout from './StorePayout'
import FranchiseePayout from './FranchiseePayout'
import DeliveryBoyPayout from './DeliveryBoyPayout'
import AdminPayout from './AdminPayout'
import Search from '../../components/Search'
import { createBrowserHistory } from 'history'
import './style.scss'

const Management = () =>  {

    const history = createBrowserHistory();

    const match = useRouteMatch()

    console.log({history})

    //const path = history.location.pathname;

    return (
        
        <Layout>
        <Header headerText="PAYOUT MANAGEMENT" />
        <Row style={{  width: "100%" }}>
            <Col style={{  borderRadius: 20, marginTop: 30, marginBottom: 30 }}>
                <Link to={`${match.path}/storepayout`} style={{ 
                        color: (history.location.pathname === `${match.path}/storepayout` || history.location.pathname === `${match.path}`) ? '#fff' : '#545454', 
                        textDecoration:'none', 
                         
                    }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: (history.location.pathname === `${match.path}/storepayout` || history.location.pathname === `${match.path}`) ? '#4AAD47' : '#fff', justifyContent:'center'  }}>
                        <span className="headerPayout" style={{ textAlign:'center' }}>STORE PAYOUT</span>
                    </Card>
                </Link>
            </Col>
            <Col style={{  borderRadius: 20, marginTop: 30, marginBottom: 30 }}>
                <Link to={`${match.path}/franchiseepayout`} style={{ 
                        color: history.location.pathname === `${match.path}/franchiseepayout` ? '#fff' : '#545454', 
                        textDecoration:'none', 
                        justifyContent:'center'
                    }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: history.location.pathname === `${match.path}/franchiseepayout` ? '#4AAD47' : '#fff', justifyContent:'center' }}>
                        <span className="headerPayout" style={{ textAlign:'center' }}>FRANCHISEE PAYOUT</span>
                    </Card>
                </Link>
            </Col>
            
            <Col style={{  borderRadius: 20, marginTop: 30, marginBottom: 30 }}>
                <Link to={`${match.path}/delivery`} style={{ 
                            color: history.location.pathname === `${match.path}/delivery` ? '#fff' : '#545454', 
                            textDecoration:'none', 
                            justifyContent:'center'
                        }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: history.location.pathname === `${match.path}/delivery` ? '#4AAD47' : '#fff', justifyContent:'center' }}>
                        <span className="headerPayout" style={{ textAlign:'center' }}>DELIVERY BOY PAYOUT</span>
                    
                    </Card>
                </Link>
            </Col>
            <Col style={{  borderRadius: 20, marginTop: 30, marginBottom: 30 }}>
                <Link to={`${match.path}/adminpayout`} style={{ 
                    color: history.location.pathname === `${match.path}/adminpayout` ? '#fff' : '#545454', 
                    textDecoration:'none', 
                    justifyContent:'center'
                 }}>
                    <Card style={{  height: 58,borderRadius: 20, backgroundColor: history.location.pathname === `${match.path}/adminpayout` ? '#4AAD47' : '#fff', justifyContent:'center' }}>
                        <span className="headerPayout" style={{ textAlign:'center' }}>ADMIN PAYOUT</span>
                    </Card>
                </Link>
            </Col>
            
            
            <Search placeHolder="SEARCH" />
        </Row> 
            <Route path={`${match.path}`} exact>
                <StorePayout/>   
            </Route>
            <Route path={`${match.path}/storepayout`} exact>
                <StorePayout/>   
            </Route>
            <Route path={`${match.path}/franchiseepayout`} exact>
                <FranchiseePayout/>   
            </Route>

            <Route path={`${match.path}/delivery`} exact>
                <DeliveryBoyPayout/>   
            </Route>
            <Route path={`${match.path}/adminpayout`} exact>
                <AdminPayout/>  
            </Route>
            
            
        
        
         
        </Layout>
       
    )
}

export default Management
