import React, { Fragment } from 'react'
import {FormControl, FormGroup, FormLabel, Col, Form} from 'react-bootstrap';
import { Field, ErrorMessage } from 'formik';
import {MdLocationOn} from 'react-icons/md'


const MultiInput = ({fieldName, fieldType, fieldLabel, fieldName2, readOnly, md}) => {
    return (
        <Col xs="12" sm="12" md={md ? md : "4"} className="formStyle">
            <FormLabel className="formLabel">{fieldLabel}</FormLabel>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Form.Group controlId={fieldName} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Field 
                        name={fieldName}
                    >
                        {({field, form, meta}) => <Form.Control readOnly={readOnly} className="inputStyle" style={{ marginRight: 5 }} value={field.value} onChange={field.onChange} />}
                    </Field>
                    <ErrorMessage name={fieldName}>
                        { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                </Form.Group>
                <Form.Group controlId={fieldName2} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Field 
                        name={fieldName2}
                    >
                        {({field, form, meta}) => <Form.Control readOnly={readOnly} className="inputStyle" style={{ marginRight: 5 }} value={field.value} onChange={field.onChange} />}
                    </Field>
                    <ErrorMessage name={fieldName2}>
                        { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                </Form.Group>
                <MdLocationOn className="loc" fontSize="2.5em" />
            </div>
        </Col>
    )
}

export default MultiInput
