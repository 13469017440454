import reactotron from "../../ReactotronConfig";
import { 
    LOADING 
} from "../constants/userConstants"
import axios from '../../CustomAxios'
import { 
    NEW_SUBSCRIPTION_FAIL,
    NEW_SUBSCRIPTION_SUCCESS,
    SUBSCRIPTION_LIST_FAIL, 
    SUBSCRIPTION_LIST_SUCCESS, 
    DELETE_SUBSCRIPTION_SUCCESS,
    DELETE_SUBSCRIPTION_FAIL,
    SUBSCRIPTION_UPDATE_SUCCESS,
    SUBSCRIPTION_UPDATE_FAIL
} from "../constants/serviceConstants";


export const getSubscriptionLists = () => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/subscription-fees`)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: SUBSCRIPTION_LIST_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: SUBSCRIPTION_LIST_FAIL,
            payload: error
        })
    });
}


//ADD NEW SUBSCRIPTION
export const addNewSubscription = (provider_name, category,phone_number,city,subscription_fee,expiry_date) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    let data = {
        provider_name,
        category,
        phone_number,
        city,
        subscription_fee,
        expiry_date
    }
    await axios.post(`admin/create-subscription`, data)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_SUBSCRIPTION_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: NEW_SUBSCRIPTION_FAIL,
            payload: error
        })
    });
}


//DELETE single service listing
export const deleteSubscriptionById = (id) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.delete(`admin/delete-subscription/${id}`)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: DELETE_SUBSCRIPTION_SUCCESS
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: DELETE_SUBSCRIPTION_FAIL,
            payload: error
        })
    });
}
//edit Subscription Fee

export const updateSubscriptionFee = (id, provider_name, category,phone_number,city,subscription_fee,expiry_date ) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
let data=
{
    id, provider_name, category,phone_number,city,subscription_fee,expiry_date 
}

    await axios.post(`admin/edit-subscription`, data)  
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: SUBSCRIPTION_UPDATE_SUCCESS,
            payload: response.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: SUBSCRIPTION_UPDATE_FAIL,
            payload: error
        })
    });
}



