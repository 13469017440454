import React, { Fragment, useState } from 'react'
import Header from '../../components/Header'
import Layout from '../layout'
import CustomersList from './CustomersList'




const Customers = () => {

    

    return (
        
        <Layout>
            <Header headerText="CUSTOMERS" />
            <CustomersList />
            
        </Layout>
       
    )
}

export default Customers
