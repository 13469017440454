import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import OrderImg from '../../assets/Images/orderBack.png'
import User from '../../assets/Images/user.png'
import './style.scss'
import {
    MdStore
} from 'react-icons/md'
import { Link } from 'react-router-dom'
import {
    BsFileEarmarkSpreadsheet
} from 'react-icons/bs'

const DashCard = () => {
    return (
        <Row>
            <Col xs={12} sm={12} md={6} lg={3} xl={3} style={{ paddingTop: 40 }}>
                <Card style={{ borderRadius: 20, minHeight: 180 }}>
                <Link to="/Order" className="link">
                    <Card.Body style={{ display: 'flex', flexDirection:'column' }}>
                        <span className="ordertotal">Total</span>
                        <span className="order">Orders</span>
                        <span className="orderCount">50</span>
                    </Card.Body>
                </Link>
                    <img src={OrderImg} style={{ width: '50%', height: 90, position:'absolute', bottom: 0, right: 0 }} />
                </Card>
            </Col>
            <Col xs={12} sm={12} md={6} lg={3} xl={3} style={{ paddingTop: 40 }}>
                <Card style={{ borderRadius: 20, minHeight: 180, backgroundColor: '#FF9D97' }}>
                <Link to="/Store" className="link">
                    <Card.Body style={{ marginTop: 20 }}>
                        <div>
                            <span style={{ padding: 10, backgroundColor:'#fff', borderRadius: 5 }}>
                                <MdStore size={30} color="#D42F45" />
                            </span>
                            <span className="totalStore">Total Stores</span>
                        </div>
                        <div style={{ display:'flex', alignItems:'center', justifyContent:'center', marginTop: 5 }}>
                            <div style={{ backgroundColor: '#E5FAE4', width: 80, height: 80, borderRadius: 40, display:'flex', justifyContent:'center', alignItems:'center' }}>
                            <span className="storeCount">28</span>
                            </div>
                        </div>
                    </Card.Body>
                </Link>
                </Card>
            </Col>
            <Col xs={12} sm={12} md={6} lg={3} xl={3} style={{ paddingTop: 40 }}>
                <Card style={{ borderRadius: 20, minHeight: 180 }} className="customerCard">
                <Link to="/Customers" className="link">
                    <Card.Body>
                        <span style={{ float: 'right' }} className="customerCount">150</span>
                        <div style={{ display: 'flex', flexDirection:'row', alignItems:'center', height: 130 }}>
                            <img src={User} style={{ width: '20%' }} />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span className="totalCus">Total</span>
                                <span className="cusTotal">Customers</span>
                            </div>
                           
                        </div>
                    </Card.Body>
                </Link>
                </Card>
            </Col>
            <Col xs={12} sm={12} md={6} lg={3} xl={3} style={{ paddingTop: 40 }}>
                <Card style={{ borderRadius: 20, minHeight: 180 }} className="productCard">
                <Link to="/Products" className="link">
                    <Card.Body >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <span className="totProducts">Total</span>
                            <span className="products">Products</span>
                        </div>
                        <div style={{ display: 'flex', flexDirection:'row', alignItems:'center' }}>
                            <div className="prodIcon">
                                <BsFileEarmarkSpreadsheet style={{ color: 'white', fontSize: 20 }} />
                            </div>
                            <span className="totalProducts">10000000</span>
                        </div>
                    </Card.Body>
                    </Link>
                </Card>
            </Col>
        </Row>
    )
}

export default DashCard
